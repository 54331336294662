import axios from "axios";
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { guardian } from "../../../route";
import { msisdnValidate } from "../../../utils/Validation";
import Layout from "../../layout/Layout";
const VerifyOtp = () => {

    const [isError,setIsError] = useState(false);
    const [errorMsg,setErrorMsg] = useState('')
    const [otpCode,setOtpCode] = useState('');
    const [msisdn,setMsisdn] = useState('');
    const [timer, setTimer] = useState(120);

    const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

    useEffect(() => {
        let time;
        if( timer > 0 ){
            time = setTimeout(timeOutCallback, 1000);
        } 
        return ()=>{
          clearTimeout(time);
        }
  
    }, [ timer, timeOutCallback]);

    useEffect(()=>{
        const get_data = localStorage.getItem('g_forget_msisdn');

        if(!get_data) {
            window.location.href = "/";
        } else {
            setMsisdn(get_data);
        }
    },[])

    const handleOnChange = (event) => {
        setOtpCode(event.target.value);
    }

    const handleOTPVerify = (e) => {
        e.preventDefault();
        const data = JSON.stringify({"msisdn": msisdn, "secret_key": otpCode});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp/verification',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                if(response.data.success) {
                    window.location.href = `${guardian}/change-password`
                } else {
                    setIsError(true);
                    setErrorMsg(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
                setIsError(true);
                setErrorMsg("Something wrong!!")
            });
    }
    
    const onReSendOtp = (e) => {
        e.preventDefault()
        const data1 = JSON.stringify({"msisdn": msisdn});
        if(!msisdnValidate(msisdn)) {
            setIsError(true);
            setErrorMsg("Enter Valid Phone Number!!")
            return;
        }

        const config1 = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp_resend',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data1
        };

        axios(config1)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                toast(response.data.message);
                if(response.data.success){
                    setIsError(true)
                    setErrorMsg(response.data.message)
                    setTimer(120)
                } else {
                    setIsError(true);
                    setErrorMsg(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                setIsError(true);
                setErrorMsg("Something wrong!!")
            });
    }

    const handleClose = () => {
        setIsError(!isError);
    }

    return (
        <>

            <Layout>
                <div className="guardianLoginWrap">
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover/>

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4">

                                <div className="formWrap">
                                    <div className="formInner">
                                        <div className="formCenter">
                                            <h3 className="text-center">Verify Phone Number</h3>

                                            <>
                                                {isError ?

                                                    <Alert variant='danger' onClick={handleClose} dismissible={true}>
                                                        {errorMsg}
                                                    </Alert>
                                                    :
                                                    <span></span>
                                                }
                                            </>

                                            <form onSubmit={handleOTPVerify}>
                                                {timer > 0 &&
                                                    <center className="mb-2">
                                                        <Badge bg="danger" className="px-4 py-2 m-auto"> {Math.floor(timer / 60)}:{timer - Math.floor(timer / 60) * 60}</Badge>
                                                    </center>
                                                }
                                                <div className="form-group">
                                                    <label htmlFor="otpCode">Enter OTP</label>
                                                    <h5>Mobile NO: {msisdn}</h5>
                                                    <input type="number"
                                                           name="otpCode"
                                                           value={otpCode}
                                                           className="form-control"
                                                           id="otpCode"
                                                           onChange={handleOnChange}
                                                           placeholder="Verification OTP..." required/>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary form-control">Verify OTP
                                                    </button>
                                                </div>
                                                {timer === 0 &&
                                                    <div className="text-center">
                                                        Didn't receive your OTP?{" "}
                                                        <Link onClick={onReSendOtp}>Resend OTP</Link>
                                                    </div>
                                                }


                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
            
        </>
    );
};

export default VerifyOtp;