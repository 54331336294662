import React, {Suspense, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import Layout from "../../layout/Layout";
import axios from "axios";
import TutorItem from "../../common/TutorItem/TutorItem";
import HeaderTitle from "../../common/HeaderTitle";
import ReSearch from "./ReSearch";
import LoaderComponent from "../../common/LoaderComponent";

const SearchDetails = () => {
    const [loader,setLoader] = useState(true);

    const search = useLocation().search;
    const district_search = new URLSearchParams(search).get('district_search');
    const location_search = new URLSearchParams(search).get('location_search');
    const medium_search = new URLSearchParams(search).get('medium_search');
    const class_search = new URLSearchParams(search).get('class_search');
    const subject_search = new URLSearchParams(search).get('subject_search');

    const [searchDetails, setSearchDetails] = useState([]);

    useEffect(()=>{
        getSearchData();
        document.title = "SEARCH"
    },[]);

    const getSearchData = () => {
        const data = JSON.stringify({
            "district_search": district_search,
            "location_search": location_search,
            "medium_search": medium_search,
            "class_search": class_search,
            "subject_search": subject_search
        });
        console.log(data);
        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/search',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                setSearchDetails(response.data.data);
                setLoader(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <Layout>
            {/*Banner*/}
            <div className="verifiedFormWrap">

                {/*Search*/}
                    <ReSearch/>
                {/*End Search*/}

            </div>

            {/*search details*/}
            <div className="FeaturedTutorsWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-start ExplorerIteM">
                            <HeaderTitle title="Search List"/>
                        </div>

                            {loader ?
                                <LoaderComponent/>
                                :
                                searchDetails.length > 0 ?
                                searchDetails.map((item, i) =>
                                    <TutorItem item={item} key={i} />
                                )
                                :
                                <h5>No Tutor found...</h5>

                            }

                    </div>
                </div>
            </div>
            {/*end search details*/}


        </Layout>
    );
};

export default SearchDetails;