import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { apiSlice } from "../../features/api/apiSlice";
import { useGetAllEducationInfoQuery } from "../../features/tutor/tutorApi";
import Biman from "../../frontend/img/default_logo.png";
import {
  getMonth,
  getYear
} from "../../utils/Common";

const EducationInfo = ({ user_data }) => {
  const { data, isError, error } = useGetAllEducationInfoQuery({id:user_data.uuid}) || {};
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState([]);

  const [isShowError, setIsShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const dispatch = useDispatch()


  const handleOnAdd = () => {
    setIsShowError(false);
    setIsAdd(!isAdd);
    reset();
  };

  const handleOnEduEdit = (v) => {
    setIsShowError(false);
    const config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + `/api/web/v1/edit_education/${v}`,
      headers: {
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setEditData(response.data.data);
        setValue("id", response.data.data.id);
        setValue("institute_name", response.data.data.institute_name);
        setValue("subject", response.data.data.subject);
        setValue("start_month", response.data.data.start_month);
        setValue("start_year", response.data.data.start_year);
        setValue("passing_year", response.data.data.passing_year);
        setValue("grade", response.data.data.grade);
        setValue("description", response.data.data.description);
        setIsEdit(!isEdit);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleOnEduEditClose = () => {
    setIsEdit(false);
    setIsShowError(false);
  };

  const onEducationInfoSubmit = (e) => {
    const pattern = new RegExp(/^(\d+(\.\d+)?)$/);

    if (e.start_year > e.passing_year) {
      toast("End year must be greater than start year!!!");
      setErrorMsg("End year must be greater than start year!!!");
      setIsShowError(true);
      return;
    }
    if (!pattern.test(e.grade)) {
      toast("Grade must be Number!!!");
      setErrorMsg("Grade must be Number!!!");
      setIsShowError(true);
      return;
    }
    if (!(e.grade > 0 && e.grade <= 5)) {
      toast("Grade must be between 1 to 5!!!");
      setErrorMsg("Grade must be between 1 to 5!!!");
      setIsShowError(true);
      return;
    }

    setIsShowError(false);

    const data = JSON.stringify({
      tutors_id: user_data.uuid,
      institute_name: e.institute_name,
      subject: e.subject,
      start_month: e.start_month,
      start_year: e.start_year,
      passing_month: e.passing_month,
      passing_year: e.passing_year,
      grade: parseFloat(e.grade).toFixed(2),
      description: e.description,
    });

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/insert_education_info",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        toast(response.data.message);
        setIsAdd(false);

        dispatch(apiSlice.util.invalidateTags(['education_info']));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onEducationInfoUpdate = (e) => {
    const pattern = new RegExp(/^(\d+(\.\d+)?)$/);

    if (e.start_year > e.passing_year) {
      toast("End year must be greater than start year!!!");
      setErrorMsg("End year must be greater than start year!!!");
      setIsShowError(true);
      return;
    }
    if (!pattern.test(e.grade)) {
      toast("Grade must be Number!!!");
      setErrorMsg("Grade must be Number!!!");
      setIsShowError(true);
      return;
    }
    if (!(e.grade > 0 && e.grade <= 5)) {
      toast("Grade must be between 1 to 5!!!");
      setErrorMsg("Grade must be between 1 to 5!!!");
      setIsShowError(true);
      return;
    }

    setIsShowError(false);
    //alert(JSON.stringify(er));
    const data = JSON.stringify({
      id: e.id,
      academicinf_id: editData.academicinf_id,
      institute_name: e.institute_name,
      subject: e.subject,
      start_month: e.start_month,
      start_year: e.start_year,
      passing_month: e.passing_month,
      passing_year: e.passing_year,
      grade: parseFloat(e.grade).toFixed(2),
      description: e.description,
    });

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/update_education",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setIsEdit(false);
        toast(response.data.message);
        dispatch(apiSlice.util.invalidateTags(['education_info']));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteEducation = (e) => {
    const config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + `/api/web/v1/delete_education/${e}`,
      headers: {
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setIsEdit(false);
        toast(response.data.message);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onCloseMsg = () => {
    setIsShowError(false);
  };

  const reset = () => {
    setValue("institute_name", "");
    setValue("subject", "");
    setValue("start_month", "");
    setValue("start_year", "");
    setValue("passing_month", "");
    setValue("passing_year", "");
    setValue("grade", "");
    setValue("description", "");
  };

  return (
    <>
      <div className="EducationWrap">
        <div className="col-12">
          <div className="tuitionInfoInner">
            <h2 className="Tuition-InfoText">Education</h2>

            {/*Button trigger modal*/}
            <div className="EducationEditButton">
              <a
                className="EditSolidButton AddEditGlobBtn"
                href="javascript:void(0);"
                onClick={handleOnAdd}
              >
                <i className="fa fa-lg fa-plus"></i>
                <p className="EditTExt"></p>
              </a>
            </div>
          </div>
          <div>
            {data?.data &&
              data?.data.map((allEducation) => {
                return (
                  <div className="AcademyContactWrap">
                    <img
                      src={Biman}
                      className="img-fluid"
                      alt="Responsiv"
                    />
                    <div className="ProfileTextInner">
                      <div>
                        <h3 className="ProfileName">
                          {allEducation.institute_name}
                        </h3>
                        <p className="Designation">{allEducation.subject}</p>
                        <span className="YearText">
                          {allEducation.start_year} -{" "}
                          {allEducation.passing_year}
                        </span>
                      </div>
                      <div style={{ marginLeft: "auto", marginRight: "25px" }}>
                        <a
                          href="javascript:void(0);"
                          className="EditSolidButton AddEditGlobBtn"
                          onClick={() => {
                            handleOnEduEdit(allEducation.academicinf_id);
                          }}
                        >
                          <i className="fa fa-lg fa-edit"></i>
                          <span className="EditTExt"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      {/*Modal*/}
      <Modal
        show={isAdd}
        onHide={handleOnAdd}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={handleSubmit(onEducationInfoSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Add education</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isShowError ? (
              <Alert variant="danger" onClick={onCloseMsg} dismissible>
                {errorMsg}
              </Alert>
            ) : (
              <></>
            )}
            {/*    form*/}
            <span className="text-danger pb-2">* Indicates required</span>

            <div className="form-row">
              <div className="row">
                {/*Institute Name*/}
                <div className="form-group col-md-12 pb-2">
                  <label htmlFor="name">
                    Institute Name
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="institute_name"
                    {...register("institute_name", { required: true })}
                    className="form-control"
                    id="institute_name"
                    placeholder="Ex: Boostom Univeristy"
                  />
                  <span className="text-danger">
                    {errors.institute_name && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        Institute Name is a required field
                      </span>
                    )}
                  </span>
                </div>
                {/*end Institute Name*/}
                {/*Field of study*/}
                <div className="form-group col-md-12 pb-2">
                  <label htmlFor="name">
                    Field of study
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="subject"
                    {...register("subject", { required: true })}
                    className="form-control"
                    id="subject"
                    placeholder="Ex: Business"
                  />
                  <span className="text-danger">
                    {errors.subject && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        Field of study is a required field
                      </span>
                    )}
                  </span>
                </div>
                {/*end Field of study*/}
                {/*Start Date*/}
                <div className="form-group col-md-6 pb-2">
                  <label htmlFor="start_year">
                    Start date
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    id="inputState"
                    name="start_month"
                    {...register("start_month")}
                    className="form-control"
                  >
                    <option selected value="">
                      Months
                    </option>
                    {getMonth().map((month, index) => {
                      return <option value={month.name}>{month.name}</option>;
                    })}
                  </select>
                  <span className="text-danger">
                    {errors.start_month && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        This is a required field
                      </span>
                    )}
                  </span>
                </div>
                <div className="form-group col-md-6 pb-2">
                  <label htmlFor="start_year"></label>
                  <select
                    id="inputState"
                    name="start_year"
                    {...register("start_year")}
                    className="form-control"
                  >
                    <option selected value="">
                      Year
                    </option>
                    {getYear().map((year, index) => {
                      return <option value={year}>{year}</option>;
                    })}
                  </select>
                  <span className="text-danger">
                    {errors.start_year && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        This is a required field
                      </span>
                    )}
                  </span>
                </div>
                {/*end Start Date*/}
                {/*end Date*/}
                <div className="form-group col-md-6 pb-2">
                  <label htmlFor="passing_month">
                    End date (or expected)
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    id="inputState"
                    name="passing_month"
                    {...register("passing_month")}
                    className="form-control"
                  >
                    <option selected value="">
                      Months
                    </option>
                    {getMonth().map((month, index) => {
                      return <option value={month.name}>{month.name}</option>;
                    })}
                  </select>
                  <span className="text-danger">
                    {errors.passing_month && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        This is a required field
                      </span>
                    )}
                  </span>
                </div>
                <div className="form-group col-md-6 pb-2">
                  <label htmlFor="passing_year"></label>
                  <select
                    id="inputState"
                    name="passing_year"
                    {...register("passing_year")}
                    className="form-control"
                  >
                    <option selected value="">
                      Year
                    </option>
                    {getYear().map((year, index) => {
                      return <option value={year}>{year}</option>;
                    })}
                  </select>
                  <span className="text-danger">
                    {errors.passing_year && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        This is a required field
                      </span>
                    )}
                  </span>
                </div>
                {/*end End Date*/}
                {/*Grade*/}
                <div className="form-group col-md-12 pb-2">
                  <label htmlFor="grade">
                    Grade
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="grade"
                    {...register("grade", { required: true })}
                    className="form-control"
                    id="grade"
                    placeholder="Ex: 5.00"
                  />
                  <span className="text-danger">
                    {errors.grade && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        Grade is a required field
                      </span>
                    )}
                  </span>
                </div>
                {/*end Grade*/}
                {/*description*/}
                <div className="form-group col-md-12 pb-2">
                  <label htmlFor="description">Description</label>
                  <textarea
                    name="description"
                    {...register("description")}
                    className="form-control"
                    id="description"
                  ></textarea>
                </div>
                {/*end description*/}
              </div>
            </div>
            {/*    end form */}
          </Modal.Body>
          <Modal.Footer>
            <a className="btn btn-md btn-danger" onClick={handleOnAdd}>
              Close
            </a>
            <button type="submit" className="btn btn-md btn-primary">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/*  Edit Model   */}
      <Modal
        show={isEdit}
        onHide={handleOnEduEditClose}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={handleSubmit(onEducationInfoUpdate)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit education</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isShowError ? (
              <Alert variant="danger" onClick={onCloseMsg} dismissible>
                {errorMsg}
              </Alert>
            ) : (
              <></>
            )}
            {/*    form*/}
            <span className="text-danger pb-2">* Indicates required</span>

            <div className="form-row">
              <div className="row">
                {/*Institute Name*/}
                <div className="form-group col-md-12 pb-2">
                  <label htmlFor="name">
                    Institute Name
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="institute_name"
                    {...register("institute_name", { required: true })}
                    className="form-control"
                    id="institute_name"
                    placeholder="Ex: Boostom Univeristy"
                  />
                  <span className="text-danger">
                    {errors.institute_name && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        Institute Name is a required field
                      </span>
                    )}
                  </span>
                </div>
                {/*end Institute Name*/}
                {/*Field of study*/}
                <div className="form-group col-md-12 pb-2">
                  <label htmlFor="name">
                    Field of study
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="subject"
                    {...register("subject", { required: true })}
                    className="form-control"
                    id="subject"
                    placeholder="Ex: Business"
                  />
                  <span className="text-danger">
                    {errors.subject && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        Field of study is a required field
                      </span>
                    )}
                  </span>
                </div>
                {/*end Field of study*/}
                {/*Start Date*/}
                <div className="form-group col-md-6 pb-2">
                  <label htmlFor="start_year">
                    Start date
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    id="inputState"
                    name="start_month"
                    {...register("start_month")}
                    className="form-control"
                  >
                    <option selected>Months</option>
                    {getMonth().map((month, index) => {
                      return <option value={month.name}>{month.name}</option>;
                    })}
                  </select>
                  <span className="text-danger">
                    {errors.start_month && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        This is a required field
                      </span>
                    )}
                  </span>
                </div>
                <div className="form-group col-md-6 pb-2">
                  <label htmlFor="start_year"></label>
                  <select
                    id="inputState"
                    name="start_year"
                    {...register("start_year")}
                    className="form-control"
                  >
                    <option selected>Year</option>
                    {getYear().map((year, index) => {
                      return <option value={year}>{year}</option>;
                    })}
                  </select>
                  <span className="text-danger">
                    {errors.start_year && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        This is a required field
                      </span>
                    )}
                  </span>
                </div>
                {/*end Start Date*/}
                {/*end Date*/}
                <div className="form-group col-md-6 pb-2">
                  <label htmlFor="passing_month">
                    End date (or expected)
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    id="inputState"
                    name="passing_month"
                    {...register("passing_month")}
                    className="form-control"
                  >
                    <option selected>Months</option>
                    {getMonth().map((month, index) => {
                      return <option value={month.name}>{month.name}</option>;
                    })}
                  </select>
                  <span className="text-danger">
                    {errors.passing_month && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        This is a required field
                      </span>
                    )}
                  </span>
                </div>
                <div className="form-group col-md-6 pb-2">
                  <label htmlFor="passing_year"></label>
                  <select
                    id="inputState"
                    name="passing_year"
                    {...register("passing_year")}
                    className="form-control"
                  >
                    <option selected>Year</option>
                    {getYear().map((year, index) => {
                      return <option value={year}>{year}</option>;
                    })}
                  </select>
                  <span className="text-danger">
                    {errors.passing_year && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        This is a required field
                      </span>
                    )}
                  </span>
                </div>
                {/*end End Date*/}
                {/*Grade*/}
                <div className="form-group col-md-12 pb-2">
                  <label htmlFor="grade">
                    Grade
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="grade"
                    {...register("grade", { required: true })}
                    className="form-control"
                    id="grade"
                    placeholder="Ex: 5.00"
                  />
                  <span className="text-danger">
                    {errors.grade && (
                      <span>
                        <i className="fa fa-minus-circle"></i>
                        Grade is a required field
                      </span>
                    )}
                  </span>
                </div>
                {/*end Grade*/}
                {/*description*/}
                <div className="form-group col-md-12 pb-2">
                  <label htmlFor="description">Description</label>
                  <textarea
                    name="description"
                    {...register("description")}
                    className="form-control"
                    id="description"
                  ></textarea>
                </div>
                {/*end description*/}
              </div>
            </div>
            {/*    end form */}
          </Modal.Body>
          <Modal.Footer>
            <a
              className="btn btn-md btn-outline-dark pull-left"
              onClick={() => {
                deleteEducation(editData.academicinf_id);
              }}
            >
              Delete education
            </a>
            <a className="btn btn-md btn-danger" onClick={handleOnEduEditClose}>
              Close
            </a>
            <button type="submit" className="btn btn-md btn-primary">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {/*  end Edit Model   */}
    </>
  );
};

export default EducationInfo;
