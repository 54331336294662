export function getLearningPlace() {
    const preferred_place = [
        {
            id: '1',
            district_name: 'Dhaka',
            area: [
                {
                    area_id: '1',
                    area_name: 'Dhanmondi'
                },
                {
                    area_id: '2',
                    area_name: 'Mirpur'
                }
            ]
        },
        {
            id: '2',
            district_name: 'Chittagong',
            area: [
                {
                    area_id: '1',
                    area_name: 'GEC'
                },
                {
                    area_id: '2',
                    area_name: '2 no Gate'
                }
            ]
        }
    ];
    return preferred_place;
}