export const upazillas_en = {
    "4": [
        {
            "value": 999,
            "title": "Barguna"
        },
        {
            "value": 266,
            "title": "Amtali"
        },
        {
            "value": 267,
            "title": "Barguna Sadar"
        },
        {
            "value": 268,
            "title": "Betagi"
        },
        {
            "value": 269,
            "title": "Bamna"
        },
        {
            "value": 270,
            "title": "Pathorghata"
        },
        {
            "value": 271,
            "title": "Taltali"
        }
    ],
    "6": [
        {
            "value": 1000,
            "title": "Barisal"
        },
        {
            "value": 249,
            "title": "Barisal Sadar"
        },
        {
            "value": 250,
            "title": "Bakerganj"
        },
        {
            "value": 251,
            "title": "Babuganj"
        },
        {
            "value": 252,
            "title": "Wazirpur"
        },
        {
            "value": 253,
            "title": "Banaripara"
        },
        {
            "value": 254,
            "title": "Gournadi"
        },
        {
            "value": 255,
            "title": "Agailjhara"
        },
        {
            "value": 256,
            "title": "Mehendiganj"
        },
        {
            "value": 257,
            "title": "Muladi"
        },
        {
            "value": 258,
            "title": "Hizla"
        },
        {
            "value": 497,
            "title": "Bukhainagar"
        },
        {
            "value": 4971,
            "title": "Saheberhat"
        },
        {
            "value": 4972,
            "title": "Sugandia"
        },
        {
            "value": 4973,
            "title": "Patang"
        },
        {
            "value": 4974,
            "title": "Kashipur"
        },
        {
            "value": 4975,
            "title": "Jaguarhat"
        },
    ],
    "9": [
        {
            "value": 1001,
            "title": "Bhola"
        },
        {
            "value": 259,
            "title": "Bhola Sadar"
        },
        {
            "value": 260,
            "title": "Borhan Sddin"
        },
        {
            "value": 261,
            "title": "Charfesson"
        },
        {
            "value": 262,
            "title": "Doulatkhan"
        },
        {
            "value": 263,
            "title": "Monpura"
        },
        {
            "value": 264,
            "title": "Tazumuddin"
        },
        {
            "value": 265,
            "title": "Lalmohan"
        }
    ],
    "42": [
        {
            "value": 1002,
            "title": "Jhalakathi"
        },
        {
            "value": 230,
            "title": "Jhalakathi Sadar"
        },
        {
            "value": 231,
            "title": "Kathalia"
        },
        {
            "value": 232,
            "title": "Nalchity"
        },
        {
            "value": 233,
            "title": "Rajapur"
        }
    ],
    "78": [
        {
            "value": 1003,
            "title": "Patuakhali"
        },
        {
            "value": 234,
            "title": "Bauphal"
        },
        {
            "value": 235,
            "title": "Patuakhali Sadar"
        },
        {
            "value": 236,
            "title": "Dumki"
        },
        {
            "value": 237,
            "title": "Dashmina"
        },
        {
            "value": 238,
            "title": "Kalapara"
        },
        {
            "value": 239,
            "title": "Mirzaganj"
        },
        {
            "value": 240,
            "title": "Galachipa"
        },
        {
            "value": 241,
            "title": "Rangabali"
        }
    ],
    "79": [
        {
            "value": 1004,
            "title": "Pirojpur"
        },
        {
            "value": 242,
            "title": "Pirojpur Sadar"
        },
        {
            "value": 243,
            "title": "Nazirpur"
        },
        {
            "value": 244,
            "title": "Kawkhali"
        },
        {
            "value": 245,
            "title": "Zianagar"
        },
        {
            "value": 246,
            "title": "Bhandaria"
        },
        {
            "value": 247,
            "title": "Mathbaria"
        },
        {
            "value": 248,
            "title": "Nesarabad"
        }
    ],
    "3": [
        {
            "value": 1005,
            "title": "Bandarban"
        },
        {
            "value": 97,
            "title": "Bandarban Sadar"
        },
        {
            "value": 98,
            "title": "Alikadam"
        },
        {
            "value": 99,
            "title": "Naikhongchhari"
        },
        {
            "value": 100,
            "title": "Rowangchhari"
        },
        {
            "value": 101,
            "title": "Lama"
        },
        {
            "value": 102,
            "title": "Ruma"
        },
        {
            "value": 103,
            "title": "Thanchi"
        }
    ],
    "12": [
        {
            "value": 1006,
            "title": "Brahmanbaria"
        },
        {
            "value": 24,
            "title": "Brahmanbaria Sadar"
        },
        {
            "value": 25,
            "title": "Kasba"
        },
        {
            "value": 26,
            "title": "Nasirnagar"
        },
        {
            "value": 27,
            "title": "Sarail"
        },
        {
            "value": 28,
            "title": "Ashuganj"
        },
        {
            "value": 29,
            "title": "Akhaura"
        },
        {
            "value": 30,
            "title": "Nabinagar"
        },
        {
            "value": 31,
            "title": "Bancharampur"
        },
        {
            "value": 32,
            "title": "Bijoynagar"
        }
    ],
    "13": [
        {
            "value": 1007,
            "title": "Chandpur"
        },
        {
            "value": 52,
            "title": "Haimchar"
        },
        {
            "value": 53,
            "title": "Kachua"
        },
        {
            "value": 54,
            "title": "Shahrasti"
        },
        {
            "value": 55,
            "title": "Chandpur Sadar"
        },
        {
            "value": 56,
            "title": "Matlab South"
        },
        {
            "value": 57,
            "title": "Hajiganj"
        },
        {
            "value": 58,
            "title": "Matlab North"
        },
        {
            "value": 59,
            "title": "Faridgonj"
        }
    ],
    "15": [
        {
            "value": 1008,
            "title": "Chattogram"
        },
        {
            "value": 65,
            "title": "Rangunia"
        },
        {
            "value": 66,
            "title": "Sitakunda"
        },
        {
            "value": 67,
            "title": "Mirsharai"
        },
        {
            "value": 68,
            "title": "Patiya"
        },
        {
            "value": 69,
            "title": "Sandwip"
        },
        {
            "value": 70,
            "title": "Banshkhali"
        },
        {
            "value": 71,
            "title": "Boalkhali"
        },
        {
            "value": 72,
            "title": "Anwara"
        },
        {
            "value": 73,
            "title": "Chandanaish"
        },
        {
            "value": 74,
            "title": "Satkania"
        },
        {
            "value": 75,
            "title": "Lohagara"
        },
        {
            "value": 76,
            "title": "Hathazari"
        },
        {
            "value": 77,
            "title": "Fatikchhari"
        },
        {
            "value": 78,
            "title": "Raozan"
        },
        {
            "value": 79,
            "title": "Karnafuli"
        },
        {
            "value": 498,
            "title": "Anowara"
        },
        {
            "value": 154981,
            "title": "Chittagong Sadar"
        },
        {
            "value": 154982,
            "title": "Bayezid Bostami"
        },
        {
            "value": 154983,
            "title": "Chandgaon"
        },
        {
            "value": 154983,
            "title": "Chawkbazar"
        },
        {
            "value": 154984,
            "title": "Chittagong Cantonment"
        },
        {
            "value": 154985,
            "title": "Chittagong Airport"
        },
        {
            "value": 154986,
            "title": "Chittagong Bandar"
        },
        {
            "value": 154987,
            "title": "Chittagong GPO"
        },
        {
            "value": 154988,
            "title": "Firozshah"
        },
        {
            "value": 154989,
            "title": "Halishahar"
        },
        {
            "value": 1549810,
            "title": "Patenga"
        },
    ],
    "19": [
        {
            "value": 1009,
            "title": "Cumilla"
        },
        {
            "value": 1,
            "title": "Debidwar"
        },
        {
            "value": 2,
            "title": "Barura"
        },
        {
            "value": 3,
            "title": "Brahmanpara"
        },
        {
            "value": 4,
            "title": "Chandina"
        },
        {
            "value": 5,
            "title": "Chauddagram"
        },
        {
            "value": 6,
            "title": "Daudkandi"
        },
        {
            "value": 7,
            "title": "Homna"
        },
        {
            "value": 8,
            "title": "Laksam"
        },
        {
            "value": 9,
            "title": "Muradnagar"
        },
        {
            "value": 10,
            "title": "Nangalkot"
        },
        {
            "value": 11,
            "title": "Comilla Sadar"
        },
        {
            "value": 12,
            "title": "Meghna"
        },
        {
            "value": 13,
            "title": "Monohargonj"
        },
        {
            "value": 14,
            "title": "Sadarsouth"
        },
        {
            "value": 15,
            "title": "Titas"
        },
        {
            "value": 16,
            "title": "Burichang"
        },
        {
            "value": 17,
            "title": "Lalmai"
        },
        {
            "value": 503,
            "title": "Cumilla City Corporation"
        }
    ],
    "22": [
        {
            "value": 1010,
            "title": "Cox's Bazar"
        },
        {
            "value": 80,
            "title": "Coxsbazar Sadar"
        },
        {
            "value": 81,
            "title": "Chakaria"
        },
        {
            "value": 82,
            "title": "Kutubdia"
        },
        {
            "value": 83,
            "title": "Ukhiya"
        },
        {
            "value": 84,
            "title": "Moheshkhali"
        },
        {
            "value": 85,
            "title": "Pekua"
        },
        {
            "value": 86,
            "title": "Ramu"
        },
        {
            "value": 87,
            "title": "Teknaf"
        }
    ],
    "30": [
        {
            "value": 1011,
            "title": "Feni"
        },
        {
            "value": 18,
            "title": "Chhagalnaiya"
        },
        {
            "value": 19,
            "title": "Feni Sadar"
        },
        {
            "value": 20,
            "title": "Sonagazi"
        },
        {
            "value": 21,
            "title": "Fulgazi"
        },
        {
            "value": 22,
            "title": "Parshuram"
        },
        {
            "value": 23,
            "title": "Daganbhuiyan"
        }
    ],
    "46": [
        {
            "value": 1012,
            "title": "Khagrachhari"
        },
        {
            "value": 88,
            "title": "Khagrachhari Sadar"
        },
        {
            "value": 89,
            "title": "Dighinala"
        },
        {
            "value": 90,
            "title": "Panchari"
        },
        {
            "value": 91,
            "title": "Laxmichhari"
        },
        {
            "value": 92,
            "title": "Mohalchari"
        },
        {
            "value": 93,
            "title": "Manikchari"
        },
        {
            "value": 94,
            "title": "Ramgarh"
        },
        {
            "value": 95,
            "title": "Matiranga"
        },
        {
            "value": 96,
            "title": "Guimara"
        }
    ],
    "51": [
        {
            "value": 1013,
            "title": "Lakshmipur"
        },
        {
            "value": 60,
            "title": "Lakshmipur Sadar"
        },
        {
            "value": 61,
            "title": "Kamalnagar"
        },
        {
            "value": 62,
            "title": "Raipur"
        },
        {
            "value": 63,
            "title": "Ramgati"
        },
        {
            "value": 64,
            "title": "Ramganj"
        }
    ],
    "75": [
        {
            "value": 1014,
            "title": "Noakhali"
        },
        {
            "value": 43,
            "title": "Noakhali Sadar"
        },
        {
            "value": 44,
            "title": "Companiganj"
        },
        {
            "value": 45,
            "title": "Begumganj"
        },
        {
            "value": 46,
            "title": "Hatia"
        },
        {
            "value": 47,
            "title": "Subarnachar"
        },
        {
            "value": 48,
            "title": "Kabirhat"
        },
        {
            "value": 49,
            "title": "Senbug"
        },
        {
            "value": 50,
            "title": "Chatkhil"
        },
        {
            "value": 51,
            "title": "Sonaimori"
        }
    ],
    "84": [
        {
            "value": 1015,
            "title": "Rangamati"
        },
        {
            "value": 33,
            "title": "Rangamati Sadar"
        },
        {
            "value": 34,
            "title": "Kaptai"
        },
        {
            "value": 35,
            "title": "Kawkhali"
        },
        {
            "value": 36,
            "title": "Baghaichari"
        },
        {
            "value": 37,
            "title": "Barkal"
        },
        {
            "value": 38,
            "title": "Langadu"
        },
        {
            "value": 39,
            "title": "Rajasthali"
        },
        {
            "value": 40,
            "title": "Belaichari"
        },
        {
            "value": 41,
            "title": "Juraichari"
        },
        {
            "value": 42,
            "title": "Naniarchar"
        }
    ],
    "26": [
        {
            "value": 1016,
            "title": "Dhaka"
        },
        {
            "value": 26497,
            "title": "Tejgaon"
        },
        {
            "value": 26494,
            "title": "Dhaka Cantonment"
        },
        {
            "value": 26495,
            "title": "Kamlapur"
        },
        {
            "value": 26496,
            "title": "Dhanmondi"
        },
        {
            "value": 26497,
            "title": "Gulshan"
        },
        {
            "value": 26498,
            "title": "Banani"
        },
        {
            "value": 26499,
            "title": "Jatrabari"
        },
        {
            "value": 264100,
            "title": "Uttara"
        },
        {
            "value": 264100,
            "title": "Airport Area"
        },
        {
            "value": 2641002,
            "title": "Abdullahpur"
        },
        {
            "value": 264101,
            "title": "Khilgaon"
        },
        {
            "value": 264102,
            "title": "Khilkhet"
        },
        {
            "value": 264103,
            "title": "Lalbag"
        },
        {
            "value": 264104,
            "title": "Mirpur"
        },
        {
            "value": 2641041,
            "title": "Mirpur 1"
        },
        {
            "value": 2641042,
            "title": "Mirpur 2"
        },
        {
            "value": 2641042,
            "title": "Mirpur 6"
        },
        {
            "value": 2641042,
            "title": "Mirpur 7"
        },
        {
            "value": 2641042,
            "title": "Mirpur 10"
        },
        {
            "value": 2641042,
            "title": "Mirpur 11"
        },
        {
            "value": 2641042,
            "title": "Mirpur 12"
        },
        {
            "value": 2641042,
            "title": "Mirpur 13"
        },
        {
            "value": 2641042,
            "title": "Mirpur 14"
        },
        {
            "value": 264105,
            "title": "Mohammadpur"
        },
        {
            "value": 264106,
            "title": "Motijheel"
        },
        {
            "value": 264107,
            "title": "New market"
        },
        {
            "value": 264108,
            "title": "Palton"
        },
        {
            "value": 264109,
            "title": "Ramna"
        },
        {
            "value": 365,
            "title": "Savar"
        },
        {
            "value": 366,
            "title": "Dhamrai"
        },
        {
            "value": 367,
            "title": "Keraniganj"
        },
        {
            "value": 368,
            "title": "Nawabganj"
        },
        {
            "value": 369,
            "title": "Dohar"
        },
        {
            "value": 26493,
            "title": "Demra"
        },
        {
            "value": 264110,
            "title": "Joypara"
        },
        {
            "value": 264111,
            "title": "Sabujbag"
        },
        {
            "value": 264112,
            "title": "Sutrapur"
        },
        {
            "value": 264112,
            "title": "Asad Gate"
        },
        {
            "value": 2641121,
            "title": "Nikunjo-1"
        },
        {
            "value": 2641122,
            "title": "Nikunjo-2"
        },
    ],
    "29": [
        {
            "value": 1017,
            "title": "Faridpur"
        },
        {
            "value": 390,
            "title": "Faridpur Sadar"
        },
        {
            "value": 391,
            "title": "Alfadanga"
        },
        {
            "value": 392,
            "title": "Boalmari"
        },
        {
            "value": 393,
            "title": "Sadarpur"
        },
        {
            "value": 394,
            "title": "Nagarkanda"
        },
        {
            "value": 395,
            "title": "Bhanga"
        },
        {
            "value": 396,
            "title": "Charbhadrasan"
        },
        {
            "value": 397,
            "title": "Madhukhali"
        },
        {
            "value": 398,
            "title": "Saltha"
        }
    ],
    "33": [
        {
            "value": 1018,
            "title": "Gazipur"
        },
        {
            "value": 317,
            "title": "Kaliganj"
        },
        {
            "value": 318,
            "title": "Kaliakair"
        },
        {
            "value": 319,
            "title": "Kapasia"
        },
        {
            "value": 320,
            "title": "Gazipur Sadar"
        },
        {
            "value": 321,
            "title": "Sreepur"
        },
        {
            "value": 499,
            "title": "Gazipur City Corporation"
        }
    ],
    "35": [
        {
            "value": 1019,
            "title": "Gopalganj"
        },
        {
            "value": 385,
            "title": "Gopalganj Sadar"
        },
        {
            "value": 386,
            "title": "Kashiani"
        },
        {
            "value": 387,
            "title": "Tungipara"
        },
        {
            "value": 388,
            "title": "Kotalipara"
        },
        {
            "value": 389,
            "title": "Muksudpur"
        }
    ],
    "48": [
        {
            "value": 1020,
            "title": "Kishoreganj"
        },
        {
            "value": 345,
            "title": "Itna"
        },
        {
            "value": 346,
            "title": "Katiadi"
        },
        {
            "value": 347,
            "title": "Bhairab"
        },
        {
            "value": 348,
            "title": "Tarail"
        },
        {
            "value": 349,
            "title": "Hossainpur"
        },
        {
            "value": 350,
            "title": "Pakundia"
        },
        {
            "value": 351,
            "title": "Kuliarchar"
        },
        {
            "value": 352,
            "title": "Kishoreganj Sadar"
        },
        {
            "value": 353,
            "title": "Karimgonj"
        },
        {
            "value": 354,
            "title": "Bajitpur"
        },
        {
            "value": 355,
            "title": "Austagram"
        },
        {
            "value": 356,
            "title": "Mithamoin"
        },
        {
            "value": 357,
            "title": "Nikli"
        }
    ],
    "54": [
        {
            "value": 1021,
            "title": "Madaripur"
        },
        {
            "value": 381,
            "title": "Madaripur Sadar"
        },
        {
            "value": 382,
            "title": "Shibchar"
        },
        {
            "value": 383,
            "title": "Kalkini"
        },
        {
            "value": 384,
            "title": "Rajoir"
        }
    ],
    "56": [
        {
            "value": 1022,
            "title": "Manikganj"
        },
        {
            "value": 358,
            "title": "Harirampur"
        },
        {
            "value": 359,
            "title": "Saturia"
        },
        {
            "value": 360,
            "title": "Manikganj Sadar"
        },
        {
            "value": 361,
            "title": "Gior"
        },
        {
            "value": 362,
            "title": "Shibaloy"
        },
        {
            "value": 363,
            "title": "Doulatpur"
        },
        {
            "value": 364,
            "title": "Singiar"
        }
    ],
    "59": [
        {
            "value": 1023,
            "title": "Munshiganj"
        },
        {
            "value": 370,
            "title": "Munshiganj Sadar"
        },
        {
            "value": 371,
            "title": "Sreenagar"
        },
        {
            "value": 372,
            "title": "Sirajdikhan"
        },
        {
            "value": 373,
            "title": "Louhajanj"
        },
        {
            "value": 374,
            "title": "Gajaria"
        },
        {
            "value": 375,
            "title": "Tongibari"
        }
    ],
    "67": [
        {
            "value": 1024,
            "title": "Narayanganj"
        },
        {
            "value": 328,
            "title": "Araihazar"
        },
        {
            "value": 329,
            "title": "Bandar"
        },
        {
            "value": 330,
            "title": "Narayanganj Sadar"
        },
        {
            "value": 331,
            "title": "Rupganj"
        },
        {
            "value": 332,
            "title": "Sonargaon"
        },
        {
            "value": 500,
            "title": "Narayanganj City Corporation"
        }
    ],
    "68": [
        {
            "value": 1025,
            "title": "Narsingdi"
        },
        {
            "value": 311,
            "title": "Belabo"
        },
        {
            "value": 312,
            "title": "Monohardi"
        },
        {
            "value": 313,
            "title": "Narsingdi Sadar"
        },
        {
            "value": 314,
            "title": "Palash"
        },
        {
            "value": 315,
            "title": "Raipura"
        },
        {
            "value": 316,
            "title": "Shibpur"
        }
    ],
    "82": [
        {
            "value": 1026,
            "title": "Rajbari"
        },
        {
            "value": 376,
            "title": "Rajbari Sadar"
        },
        {
            "value": 377,
            "title": "Goalanda"
        },
        {
            "value": 378,
            "title": "Pangsa"
        },
        {
            "value": 379,
            "title": "Baliakandi"
        },
        {
            "value": 380,
            "title": "Kalukhali"
        }
    ],
    "86": [
        {
            "value": 1027,
            "title": "Shariatpur"
        },
        {
            "value": 322,
            "title": "Shariatpur Sadar"
        },
        {
            "value": 323,
            "title": "Naria"
        },
        {
            "value": 324,
            "title": "Zajira"
        },
        {
            "value": 325,
            "title": "Gosairhat"
        },
        {
            "value": 326,
            "title": "Bhedarganj"
        },
        {
            "value": 327,
            "title": "Damudya"
        }
    ],
    "93": [
        {
            "value": 1028,
            "title": "Tangail"
        },
        {
            "value": 333,
            "title": "Basail"
        },
        {
            "value": 334,
            "title": "Bhuapur"
        },
        {
            "value": 335,
            "title": "Delduar"
        },
        {
            "value": 336,
            "title": "Ghatail"
        },
        {
            "value": 337,
            "title": "Gopalpur"
        },
        {
            "value": 338,
            "title": "Madhupur"
        },
        {
            "value": 339,
            "title": "Mirzapur"
        },
        {
            "value": 340,
            "title": "Nagarpur"
        },
        {
            "value": 341,
            "title": "Sakhipur"
        },
        {
            "value": 342,
            "title": "Tangail Sadar"
        },
        {
            "value": 343,
            "title": "Kalihati"
        },
        {
            "value": 344,
            "title": "Dhanbari"
        }
    ],
    "1": [
        {
            "value": 1029,
            "title": "Bagerhat"
        },
        {
            "value": 215,
            "title": "Fakirhat"
        },
        {
            "value": 216,
            "title": "Bagerhat Sadar"
        },
        {
            "value": 217,
            "title": "Mollahat"
        },
        {
            "value": 218,
            "title": "Sarankhola"
        },
        {
            "value": 219,
            "title": "Rampal"
        },
        {
            "value": 220,
            "title": "Morrelganj"
        },
        {
            "value": 221,
            "title": "Kachua"
        },
        {
            "value": 222,
            "title": "Mongla"
        },
        {
            "value": 223,
            "title": "Chitalmari"
        }
    ],
    "18": [
        {
            "value": 1030,
            "title": "Chuadanga"
        },
        {
            "value": 192,
            "title": "Chuadanga Sadar"
        },
        {
            "value": 193,
            "title": "Alamdanga"
        },
        {
            "value": 194,
            "title": "Damurhuda"
        },
        {
            "value": 195,
            "title": "Jibannagar"
        }
    ],
    "41": [
        {
            "value": 1031,
            "title": "Jashore"
        },
        {
            "value": 171,
            "title": "Manirampur"
        },
        {
            "value": 172,
            "title": "Abhaynagar"
        },
        {
            "value": 173,
            "title": "Bagherpara"
        },
        {
            "value": 174,
            "title": "Chougachha"
        },
        {
            "value": 175,
            "title": "Jhikargacha"
        },
        {
            "value": 176,
            "title": "Keshabpur"
        },
        {
            "value": 177,
            "title": "Jessore Sadar"
        },
        {
            "value": 178,
            "title": "Sharsha"
        }
    ],
    "44": [
        {
            "value": 1032,
            "title": "Jhenaidah"
        },
        {
            "value": 224,
            "title": "Jhenaidah Sadar"
        },
        {
            "value": 225,
            "title": "Shailkupa"
        },
        {
            "value": 226,
            "title": "Harinakundu"
        },
        {
            "value": 227,
            "title": "Kaliganj"
        },
        {
            "value": 228,
            "title": "Kotchandpur"
        },
        {
            "value": 229,
            "title": "Moheshpur"
        }
    ],
    "47": [
        {
            "value": 1033,
            "title": "Khulna"
        },
        {
            "value": 206,
            "title": "Paikgasa"
        },
        {
            "value": 207,
            "title": "Fultola"
        },
        {
            "value": 208,
            "title": "Digholia"
        },
        {
            "value": 209,
            "title": "Rupsha"
        },
        {
            "value": 210,
            "title": "Terokhada"
        },
        {
            "value": 211,
            "title": "Dumuria"
        },
        {
            "value": 212,
            "title": "Botiaghata"
        },
        {
            "value": 213,
            "title": "Dakop"
        },
        {
            "value": 214,
            "title": "Koyra"
        },
        {
            "value": 494,
            "title": "khulna city corporation"
        }
    ],
    "50": [
        {
            "value": 1034,
            "title": "Kushtia"
        },
        {
            "value": 196,
            "title": "Kushtia Sadar"
        },
        {
            "value": 197,
            "title": "Kumarkhali"
        },
        {
            "value": 198,
            "title": "Khoksa"
        },
        {
            "value": 199,
            "title": "Mirpur"
        },
        {
            "value": 200,
            "title": "Daulatpur"
        },
        {
            "value": 201,
            "title": "Bheramara"
        }
    ],
    "95": [
        {
            "value": 1035,
            "title": "Magura"
        },
        {
            "value": 202,
            "title": "Shalikha"
        },
        {
            "value": 203,
            "title": "Sreepur"
        },
        {
            "value": 204,
            "title": "Magura Sadar"
        },
        {
            "value": 205,
            "title": "Mohammadpur"
        }
    ],
    "57": [
        {
            "value": 1036,
            "title": "Magura"
        },
        {
            "value": 186,
            "title": "Mujibnagar"
        },
        {
            "value": 187,
            "title": "Meherpur Sadar"
        },
        {
            "value": 188,
            "title": "Gangni"
        }
    ],
    "65": [
        {
            "value": 1037,
            "title": "Magura"
        },
        {
            "value": 189,
            "title": "Narail Sadar"
        },
        {
            "value": 190,
            "title": "Lohagara"
        },
        {
            "value": 191,
            "title": "Kalia"
        }
    ],
    "87": [
        {
            "value": 1038,
            "title": "Satkhira"
        },
        {
            "value": 179,
            "title": "Assasuni"
        },
        {
            "value": 180,
            "title": "Debhata"
        },
        {
            "value": 181,
            "title": "Kalaroa"
        },
        {
            "value": 182,
            "title": "Satkhira Sadar"
        },
        {
            "value": 183,
            "title": "Shyamnagar"
        },
        {
            "value": 184,
            "title": "Tala"
        },
        {
            "value": 185,
            "title": "Kaliganj"
        }
    ],
    "39": [
        {
            "value": 1039,
            "title": "Jamalpur"
        },
        {
            "value": 475,
            "title": "Jamalpur Sadar"
        },
        {
            "value": 476,
            "title": "Melandah"
        },
        {
            "value": 477,
            "title": "Islampur"
        },
        {
            "value": 478,
            "title": "Dewangonj"
        },
        {
            "value": 479,
            "title": "Sarishabari"
        },
        {
            "value": 480,
            "title": "Madarganj"
        },
        {
            "value": 481,
            "title": "Bokshiganj"
        }
    ],
    "61": [
        {
            "value": 1040,
            "title": "Mymensingh"
        },
        {
            "value": 462,
            "title": "Fulbaria"
        },
        {
            "value": 463,
            "title": "Trishal"
        },
        {
            "value": 464,
            "title": "Bhaluka"
        },
        {
            "value": 465,
            "title": "Muktagacha"
        },
        {
            "value": 466,
            "title": "Mymensingh Sadar"
        },
        {
            "value": 467,
            "title": "Dhobaura"
        },
        {
            "value": 468,
            "title": "Phulpur"
        },
        {
            "value": 469,
            "title": "Haluaghat"
        },
        {
            "value": 470,
            "title": "Gouripur"
        },
        {
            "value": 471,
            "title": "Gafargaon"
        },
        {
            "value": 472,
            "title": "Iswarganj"
        },
        {
            "value": 473,
            "title": "Nandail"
        },
        {
            "value": 474,
            "title": "Tarakanda"
        },
        {
            "value": 501,
            "title": "Mymansing city corporation"
        }
    ],
    "72": [
        {
            "value": 1041,
            "title": "Netrokona"
        },
        {
            "value": 482,
            "title": "Barhatta"
        },
        {
            "value": 483,
            "title": "Durgapur"
        },
        {
            "value": 484,
            "title": "Kendua"
        },
        {
            "value": 485,
            "title": "Atpara"
        },
        {
            "value": 486,
            "title": "Madan"
        },
        {
            "value": 487,
            "title": "Khaliajuri"
        },
        {
            "value": 488,
            "title": "Kalmakanda"
        },
        {
            "value": 489,
            "title": "Mohongonj"
        },
        {
            "value": 490,
            "title": "Purbadhala"
        },
        {
            "value": 491,
            "title": "Netrokona Sadar"
        }
    ],
    "89": [
        {
            "value": 1042,
            "title": "Sherpur"
        },
        {
            "value": 457,
            "title": "Sherpur Sadar"
        },
        {
            "value": 458,
            "title": "Nalitabari"
        },
        {
            "value": 459,
            "title": "Sreebordi"
        },
        {
            "value": 460,
            "title": "Nokla"
        },
        {
            "value": 461,
            "title": "Jhenaigati"
        }
    ],
    "10": [
        {
            "value": 1043,
            "title": "Bogura"
        },
        {
            "value": 122,
            "title": "Kahaloo"
        },
        {
            "value": 123,
            "title": "Bogra Sadar"
        },
        {
            "value": 124,
            "title": "Shariakandi"
        },
        {
            "value": 125,
            "title": "Shajahanpur"
        },
        {
            "value": 126,
            "title": "Dupchanchia"
        },
        {
            "value": 127,
            "title": "Adamdighi"
        },
        {
            "value": 128,
            "title": "Nondigram"
        },
        {
            "value": 129,
            "title": "Sonatala"
        },
        {
            "value": 130,
            "title": "Dhunot"
        },
        {
            "value": 131,
            "title": "Gabtali"
        },
        {
            "value": 132,
            "title": "Sherpur"
        },
        {
            "value": 133,
            "title": "Shibganj"
        }
    ],
    "38": [
        {
            "value": 1044,
            "title": "Joypurhat"
        },
        {
            "value": 150,
            "title": "Akkelpur"
        },
        {
            "value": 151,
            "title": "Kalai"
        },
        {
            "value": 152,
            "title": "Khetlal"
        },
        {
            "value": 153,
            "title": "Panchbibi"
        },
        {
            "value": 154,
            "title": "Joypurhat Sadar"
        }
    ],
    "64": [
        {
            "value": 1045,
            "title": "Naogaon"
        },
        {
            "value": 160,
            "title": "Mohadevpur"
        },
        {
            "value": 161,
            "title": "Badalgachi"
        },
        {
            "value": 162,
            "title": "Patnitala"
        },
        {
            "value": 163,
            "title": "Dhamoirhat"
        },
        {
            "value": 164,
            "title": "Niamatpur"
        },
        {
            "value": 165,
            "title": "Manda"
        },
        {
            "value": 166,
            "title": "Atrai"
        },
        {
            "value": 167,
            "title": "Raninagar"
        },
        {
            "value": 168,
            "title": "Naogaon Sadar"
        },
        {
            "value": 169,
            "title": "Porsha"
        },
        {
            "value": 170,
            "title": "Sapahar"
        }
    ],
    "69": [
        {
            "value": 1046,
            "title": "Natore"
        },
        {
            "value": 143,
            "title": "Natore Sadar"
        },
        {
            "value": 144,
            "title": "Singra"
        },
        {
            "value": 145,
            "title": "Baraigram"
        },
        {
            "value": 146,
            "title": "Bagatipara"
        },
        {
            "value": 147,
            "title": "Lalpur"
        },
        {
            "value": 148,
            "title": "Gurudaspur"
        },
        {
            "value": 149,
            "title": "Naldanga"
        }
    ],
    "70": [
        {
            "value": 1047,
            "title": "Chapainawabganj"
        },
        {
            "value": 155,
            "title": "Chapainawabganj Sadar"
        },
        {
            "value": 156,
            "title": "Gomostapur"
        },
        {
            "value": 157,
            "title": "Nachol"
        },
        {
            "value": 158,
            "title": "Bholahat"
        },
        {
            "value": 159,
            "title": "Shibganj"
        }
    ],
    "76": [
        {
            "value": 1048,
            "title": "Pabna"
        },
        {
            "value": 113,
            "title": "Sujanagar"
        },
        {
            "value": 114,
            "title": "Ishurdi"
        },
        {
            "value": 115,
            "title": "Bhangura"
        },
        {
            "value": 116,
            "title": "Pabna Sadar"
        },
        {
            "value": 117,
            "title": "Bera"
        },
        {
            "value": 118,
            "title": "Atghoria"
        },
        {
            "value": 119,
            "title": "Chatmohar"
        },
        {
            "value": 120,
            "title": "Santhia"
        },
        {
            "value": 121,
            "title": "Faridpur"
        }
    ],
    "81": [
        {
            "value": 1049,
            "title": "Rajshahi"
        },
        {
            "value": 134,
            "title": "Paba"
        },
        {
            "value": 135,
            "title": "Durgapur"
        },
        {
            "value": 136,
            "title": "Mohonpur"
        },
        {
            "value": 137,
            "title": "Charghat"
        },
        {
            "value": 138,
            "title": "Puthia"
        },
        {
            "value": 139,
            "title": "Bagha"
        },
        {
            "value": 140,
            "title": "Godagari"
        },
        {
            "value": 141,
            "title": "Tanore"
        },
        {
            "value": 142,
            "title": "Bagmara"
        },
        {
            "value": 495,
            "title": " Rajshahi city corporation"
        }
    ],
    "88": [
        {
            "value": 1050,
            "title": "Sirajganj"
        },
        {
            "value": 104,
            "title": "Belkuchi"
        },
        {
            "value": 105,
            "title": "Chauhali"
        },
        {
            "value": 106,
            "title": "Kamarkhand"
        },
        {
            "value": 107,
            "title": "Kazipur"
        },
        {
            "value": 108,
            "title": "Raigonj"
        },
        {
            "value": 109,
            "title": "Shahjadpur"
        },
        {
            "value": 110,
            "title": "Sirajganj Sadar"
        },
        {
            "value": 111,
            "title": "Tarash"
        },
        {
            "value": 112,
            "title": "Ullapara"
        }
    ],
    "27": [
        {
            "value": 1051,
            "title": "Dinajpur"
        },
        {
            "value": 404,
            "title": "Nawabganj"
        },
        {
            "value": 405,
            "title": "Birganj"
        },
        {
            "value": 406,
            "title": "Ghoraghat"
        },
        {
            "value": 407,
            "title": "Birampur"
        },
        {
            "value": 408,
            "title": "Parbatipur"
        },
        {
            "value": 409,
            "title": "Bochaganj"
        },
        {
            "value": 410,
            "title": "Kaharol"
        },
        {
            "value": 411,
            "title": "Fulbari"
        },
        {
            "value": 412,
            "title": "Dinajpur Sadar"
        },
        {
            "value": 413,
            "title": "Hakimpur"
        },
        {
            "value": 414,
            "title": "Khansama"
        },
        {
            "value": 415,
            "title": "Birol"
        },
        {
            "value": 416,
            "title": "Chirirbandar"
        }
    ],
    "32": [
        {
            "value": 1052,
            "title": "Gaibandha"
        },
        {
            "value": 428,
            "title": "Sadullapur"
        },
        {
            "value": 429,
            "title": "Gaibandha Sadar"
        },
        {
            "value": 430,
            "title": "Palashbari"
        },
        {
            "value": 431,
            "title": "Saghata"
        },
        {
            "value": 432,
            "title": "Gobindaganj"
        },
        {
            "value": 433,
            "title": "Sundarganj"
        },
        {
            "value": 434,
            "title": "Phulchari"
        }
    ],
    "49": [
        {
            "value": 1053,
            "title": "Kurigram"
        },
        {
            "value": 448,
            "title": "Kurigram Sadar"
        },
        {
            "value": 449,
            "title": "Nageshwari"
        },
        {
            "value": 450,
            "title": "Bhurungamari"
        },
        {
            "value": 451,
            "title": "Phulbari"
        },
        {
            "value": 452,
            "title": "Rajarhat"
        },
        {
            "value": 453,
            "title": "Ulipur"
        },
        {
            "value": 454,
            "title": "Chilmari"
        },
        {
            "value": 455,
            "title": "Rowmari"
        },
        {
            "value": 456,
            "title": "Charrajibpur"
        }
    ],
    "52": [
        {
            "value": 1054,
            "title": "Lalmonirhat"
        },
        {
            "value": 417,
            "title": "Lalmonirhat Sadar"
        },
        {
            "value": 418,
            "title": "Kaliganj"
        },
        {
            "value": 419,
            "title": "Hatibandha"
        },
        {
            "value": 420,
            "title": "Patgram"
        },
        {
            "value": 421,
            "title": "Aditmari"
        }
    ],
    "73": [
        {
            "value": 1055,
            "title": "Nilphamari"
        },
        {
            "value": 422,
            "title": "Syedpur"
        },
        {
            "value": 423,
            "title": "Domar"
        },
        {
            "value": 424,
            "title": "Dimla"
        },
        {
            "value": 425,
            "title": "Jaldhaka"
        },
        {
            "value": 426,
            "title": "Kishorganj"
        },
        {
            "value": 427,
            "title": "Nilphamari Sadar"
        }
    ],
    "77": [
        {
            "value": 1056,
            "title": "Panchagarh"
        },
        {
            "value": 399,
            "title": "Panchagarh Sadar"
        },
        {
            "value": 400,
            "title": "Debiganj"
        },
        {
            "value": 401,
            "title": "Boda"
        },
        {
            "value": 402,
            "title": "Atwari"
        },
        {
            "value": 403,
            "title": "Tetulia"
        }
    ],
    "85": [
        {
            "value": 1057,
            "title": "Rangpur"
        },
        {
            "value": 440,
            "title": "Rangpur Sadar"
        },
        {
            "value": 441,
            "title": "Gangachara"
        },
        {
            "value": 442,
            "title": "Taragonj"
        },
        {
            "value": 443,
            "title": "Badargonj"
        },
        {
            "value": 444,
            "title": "Mithapukur"
        },
        {
            "value": 445,
            "title": "Pirgonj"
        },
        {
            "value": 446,
            "title": "Kaunia"
        },
        {
            "value": 447,
            "title": "Pirgacha"
        },
        {
            "value": 496,
            "title": "Rungpur City Corporation"
        }
    ],
    "94": [
        {
            "value": 1058,
            "title": "Thakurgaon"
        },
        {
            "value": 435,
            "title": "Thakurgaon Sadar"
        },
        {
            "value": 436,
            "title": "Pirganj"
        },
        {
            "value": 437,
            "title": "Ranisankail"
        },
        {
            "value": 438,
            "title": "Haripur"
        },
        {
            "value": 439,
            "title": "Baliadangi"
        }
    ],
    "36": [
        {
            "value": 1059,
            "title": "Habiganj"
        },
        {
            "value": 292,
            "title": "Nabiganj"
        },
        {
            "value": 293,
            "title": "Bahubal"
        },
        {
            "value": 294,
            "title": "Ajmiriganj"
        },
        {
            "value": 295,
            "title": "Baniachong"
        },
        {
            "value": 296,
            "title": "Lakhai"
        },
        {
            "value": 297,
            "title": "Chunarughat"
        },
        {
            "value": 298,
            "title": "Habiganj Sadar"
        },
        {
            "value": 299,
            "title": "Madhabpur"
        },
        {
            "value": 504,
            "title": "Shayestagonj"
        }
    ],
    "58": [
        {
            "value": 1060,
            "title": "Moulvibazar"
        },
        {
            "value": 285,
            "title": "Barlekha"
        },
        {
            "value": 286,
            "title": "Kamolganj"
        },
        {
            "value": 287,
            "title": "Kulaura"
        },
        {
            "value": 288,
            "title": "Moulvibazar Sadar"
        },
        {
            "value": 289,
            "title": "Rajnagar"
        },
        {
            "value": 290,
            "title": "Sreemangal"
        },
        {
            "value": 291,
            "title": "Juri"
        }
    ],
    "90": [
        {
            "value": 1061,
            "title": "Moulvibazar"
        },
        {
            "value": 300,
            "title": "Sunamganj Sadar"
        },
        {
            "value": 301,
            "title": "South Sunamganj"
        },
        {
            "value": 302,
            "title": "Bishwambarpur"
        },
        {
            "value": 303,
            "title": "Chhatak"
        },
        {
            "value": 304,
            "title": "Jagannathpur"
        },
        {
            "value": 305,
            "title": "Dowarabazar"
        },
        {
            "value": 306,
            "title": "Tahirpur"
        },
        {
            "value": 307,
            "title": "Dharmapasha"
        },
        {
            "value": 308,
            "title": "Jamalganj"
        },
        {
            "value": 309,
            "title": "Shalla"
        },
        {
            "value": 310,
            "title": "Derai"
        }
    ],
    "91": [
        {
            "value": 1062,
            "title": "Moulvibazar"
        },
        {
            "value": 272,
            "title": "Balaganj"
        },
        {
            "value": 273,
            "title": "Beanibazar"
        },
        {
            "value": 274,
            "title": "Bishwanath"
        },
        {
            "value": 275,
            "title": "Companiganj"
        },
        {
            "value": 276,
            "title": "Fenchuganj"
        },
        {
            "value": 277,
            "title": "Golapganj"
        },
        {
            "value": 278,
            "title": "Gowainghat"
        },
        {
            "value": 279,
            "title": "Jaintiapur"
        },
        {
            "value": 280,
            "title": "Kanaighat"
        },
        {
            "value": 281,
            "title": "Sylhet Sadar"
        },
        {
            "value": 282,
            "title": "Zakiganj"
        },
        {
            "value": 283,
            "title": "Dakshinsurma"
        },
        {
            "value": 284,
            "title": "Osmaninagar"
        },
        {
            "value": 502,
            "title": "Sylet City Corporation"
        }
    ],
    "96":[
        {
            "value": 1063,
            "title": "Meherpur"
        },
        {
            "value": 555,
            "title": "Meherpur-S"
        },
        {
            "value": 556,
            "title": "Mujib Nagar"
        },
        {
            "value": 557,
            "title": "Gangni"
        }
    ],
    "97":[
        {
            "value": 1064,
            "title": "Narail"
        },
        {
            "value": 558,
            "title": "Kalia"
        },
        {
            "value": 559,
            "title": "Lohagara"
        },
        {
            "value": 560,
            "title": "Narail-S"
        }
    ],
    "98":[
        {
            "value": 1065,
            "title": "Sylhet"
        },
        {
            "value": 561,
            "title": "Balaganj"
        },
        {
            "value": 562,
            "title": "Beanibazar"
        },
        {
            "value": 563,
            "title": "Biswanath"
        },
        {
            "value": 564,
            "title": "Companiganj"
        },
        {
            "value": 565,
            "title": "Dakshin Surma"
        },
        {
            "value": 566,
            "title": "Fenchuganj"
        },
        {
            "value": 567,
            "title": "Golapganj"
        },
        {
            "value": 568,
            "title": "Gowainghat"
        },
        {
            "value": 569,
            "title": "Gowainghat"
        },
        {
            "value": 570,
            "title": "Kanaighat"
        },
        {
            "value": 571,
            "title": "Osmaninagar"
        },
        {
            "value": 572,
            "title": "Sylhet-S"
        },
        {
            "value": 573,
            "title" : "Zakiganj"
        }
    ],
    "99":[
        {
            "value": 1066,
            "title": "Sunamganj"
        },
        {
            "value": 574,
            "title" : "Biswamvarpur"
        },
        {
            "value": 575,
            "title" : "Chatak"
        },
        {
            "value": 576,
            "title" : "Zakiganj"
        },
        {
            "value": 577,
            "title" : "Dakhin Sunamganj"
        },
        {
            "value": 578,
            "title" : "Derai"
        },
        {
            "value": 579,
            "title" : "Dharmapasha"
        },
        {
            "value": 580,
            "title" : "Doarabazar"
        },
        {
            "value": 581,
            "title" : "Jagannathpur"
        },
        {
            "value": 582,
            "title" : "Jamalganj"
        },
        {
            "value": 583,
            "title" : "Sulla"
        },
        {
            "value": 584,
            "title" : "Sunamganj-S"
        }
    ]
}
