export function getEducationMedium() {
    const preferred_subject = [
        {
            id: '2',
            medium_name: 'Bangla Medium',
            class_name: [
                {
                    class_id: '1',
                    class_name: 'Class 9',
                    subject: [
                        {
                            subject_id: '1',
                            subject_name: 'ICT'
                        },
                        {
                            subject_id: '2',
                            subject_name: 'ICT 2'
                        }
                    ]
                },
                {
                    class_id: '2',
                    class_name: 'Class 10',
                    subject: [
                        {
                            subject_id: '1',
                            subject_name: 'Mathematics'
                        },
                        {
                            subject_id: '2',
                            subject_name: 'Mathematics 2'
                        }
                    ]
                }
            ]
        },
        {
            id: '3',
            medium_name: 'English Medium',
            class_name: [
                {
                    class_id: '1',
                    class_name: 'OLevel',
                    subject: [
                        {
                            subject_id: '1',
                            subject_name: 'Easy writing'
                        }
                    ]
                },
                {
                    class_id: '2',
                    class_name: 'ALevel',
                    subject: [
                        {
                            subject_id: '1',
                            subject_name: 'Easy writing 2'
                        }
                    ]
                }
            ]
        }
    ];
    return preferred_subject;
}