import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { districts_en } from "../../utils/location/District";
import { upazillas_en } from "../../utils/location/Upazilla";
import { class_name_en } from "../../utils/medium_data/ClassData";
import { medium_en } from "../../utils/medium_data/Medium";
import { subject_en } from "../../utils/medium_data/subject3";
import HeaderTitle from "../common/HeaderTitle";
import LoaderComponent from "../common/LoaderComponent";
import RequestIteam from "../common/Request/RequestIteam";
import TutorsAdvetisement from "../common/TutorsAdvetisement";
import Layout from "../layout/Layout";
const Tuitons = () => {
    
    const [allGuardianRequest,setAllGuardianRequest] = useState([])
    const [page,setPage] = useState(1);
    const [pageSize,setPageSize] = useState(2)
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [isLoader, setIsLoader] = useState(true);

    //
    const [medium_id, setMediumId] = useState('')

    const [allUpozilla,setAllUpozilla] = useState([])
    const [allClass, setAllClass] = useState([])
    const [allSubject, setAllSubject] = useState([])

    //filterData

    const [district,setDistrict] = useState('')
    const [selectArea,setSelectArea] = useState('')
    const [medium,setMedium] = useState('');
    const [class_name,setClass_name] = useState('');
    const [subject,setSubject] = useState([]);

    ////end_filterData
    let {id} = useParams();
    useEffect(()=> {
        onHandleFilter(district,selectArea,medium,class_name,subject)
        setIsLoader(false)
    },[])

    //depend
    const onHandleChangeDistrict = (e) => {
        setSelectArea('')
        if(e.target.value == "") {
            setAllUpozilla([])
            setDistrict('')
            setSelectArea('')
            onHandleFilter("","",medium,class_name,subject);
            return false;
        }
        setDistrict(districts_en.find(x => x.value == e.target.value).title)
        setAllUpozilla(upazillas_en[e.target.value])

        onHandleFilter(districts_en.find(x => x.value == e.target.value).title,"",medium,class_name,subject);
    }
    const onHandleChangeArea = (e) => {
        if(e.target.value == "") {
            setSelectArea('')
            onHandleFilter(district,"",medium,class_name,subject);
            return false;
        }
        setSelectArea(e.target.value)
        onHandleFilter(district,e.target.value,medium,class_name,subject);
    }
    

    const onChangeMedium = (e) => {
        setClass_name("")
        setSubject([])
        if(e.target.value === ""){
            setAllClass([])
            setAllSubject([])
            setMedium("")
            onHandleFilter(district,selectArea,"","","");
            return true
        }

        setMediumId(e.target.value);
        setAllClass(class_name_en[e.target.value] ?? []);

        setMedium(medium_en.find(mn => mn.value == e.target.value).title)
        onHandleFilter(district,selectArea,medium_en.find(mn => mn.value == e.target.value).title,"",[]);
    }

    const onChangeClass = (e) => {
        setClass_name("")
        setSubject([])
        if(e.target.value === ""){
            setAllSubject([])
            onHandleFilter(district,selectArea,medium,"","");
            return true
        }

        setAllSubject(subject_en[e.target.value] ?? []);
        setClass_name(class_name_en[medium_id].find(cn => cn.value == e.target.value).title ?? "")

        onHandleFilter(district,selectArea,medium,class_name_en[medium_id].find(cn => cn.value == e.target.value).title ?? "",[]);
    }
    
    const onChangeSubject = (e) => {
        let {name, value, checked} = e.target;
        if(value === "") {
            setSubject([])
            onHandleFilter(district,selectArea,medium,class_name,[]);
            return false;
        }
        if(checked){
            if(subject.includes(value)){
                setSubject(subject.filter(sel => sel !== value))
                onHandleFilter(district,selectArea,medium,class_name,subject.filter(sel => sel !== value));
            }else{
                setSubject([...subject,value])
                onHandleFilter(district,selectArea,medium,class_name,[...subject,value]);
            }
        }else{
            setSubject(subject.filter(sel => sel !== value))
            onHandleFilter(district,selectArea,medium,class_name,subject.filter(sel => sel !== value));
        }
        
    }
    //end depend
    

    const onHandleFilter = (district,selectArea,medium,class_name,subject) => {
        setPage(1)
        setPage(1)
        setPageSize(2)
        let page = 1;
        let pageSize = 2;
        setLoading(true);
        const data = JSON.stringify({
            "district": district ?? "",
            "area": selectArea ?? "",
            "medium": medium ?? "",
            "subject": subject ?? "",
            "class_name": class_name ?? ""
        });
            let config = {};
        if(!id){
            config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL+`/api/web/v1/get_all_tutor_request_url?page=${page}&pageSize=${pageSize}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
                },
                data: data
            };
        }else{
            let string = '';
            if(!district && !selectArea && !medium && !subject.length>0 && !class_name){
                string=process.env.REACT_APP_API_URL + `/api/web/v1/get_all_tutor_request_url/${id}`;
            }else{
                string = process.env.REACT_APP_API_URL+`/api/web/v1/get_all_tutor_request_url?page=${page}&pageSize=${pageSize}`
            }
            config = {
                method: 'post',
                url: string,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
                },
                data: data
            };
        }

        
        axios(config)
            .then(function (response) {
                setLoading(false)
                setTotalPages(response.data.totalPages)
                setAllGuardianRequest(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    

    const nextPage = () => {
        setPage(page + 1)
        setLoading(true);
        const page1 = page + 1;
        const data = JSON.stringify({
            "district": district ?? "",
            "area": selectArea ?? "",
            "medium": medium ?? "",
            "subject": subject ?? "",
            "class_name": class_name ?? ""
        });
     
        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/get_all_tutor_request_url?page=${page1}&pageSize=${pageSize}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setLoading(false)
                setTotalPages(response.data.totalPages)
                setAllGuardianRequest([...allGuardianRequest,...response.data.data]);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    if(isLoader) {
        return <LoaderComponent/>;
    } else {
        return (
            <Layout>
                <div className="tuition-page-content">

                    <div className="container">

                        <div className="row">
                            <HeaderTitle title={'Tutor wanted'}/>
                            <div className="col-12 pb-2 b-2">
                                <span style={{color:"#6b6b6b"}}>Home > Tuitions</span>
                                <span style={{color:"#0071c2"}}>
                            {district.length > 0 ? ' > '+district : ''}
                                    {selectArea.length > 0 ? ' > '+selectArea : ''}
                                    {medium.length > 0 ? ' > '+medium : ''}
                                    {class_name.length > 0 ? ' > '+class_name : ''}
                                    {subject.length > 0 ? ' > '+subject : ''}
                            </span>
                                <span style={{color:"#6b6b6b"}}>&nbsp; > Search results</span>
                            </div>
                            <div className="col-lg-4">
                                <div className="tuition-filter">
                                    <div className="card">
                                        <div className="card-header bg-header">
                                            Search Tuition Job
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {/*{selectDistrict} {selectArea}*/}
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <select className="form-select"
                                                                name="district_filter"
                                                                onChange={(e)=>{
                                                                    onHandleChangeDistrict(e)
                                                                }}
                                                                aria-label="Default select example">
                                                            <option selected value="">Select District</option>
                                                            {
                                                                districts_en.map((district)=>{
                                                                    return (
                                                                        <option value={district.value}>{district.title}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-12">
                                                        <select className="form-select"
                                                                name="area_filter"
                                                                onChange={(e)=> {
                                                                    onHandleChangeArea(e)
                                                                }}
                                                                aria-label="Default select example">
                                                            <option selected value="" >Select area</option>
                                                            {
                                                                allUpozilla.map((area)=>{
                                                                    return (
                                                                        <option value={area.title}>{area.title}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-12">
                                                        <select className="form-select"
                                                                name="medium_filter"
                                                                onChange={(e) => {
                                                                    onChangeMedium(e)
                                                                }}
                                                                aria-label="Default select example">
                                                            <option selected value="">Select Medium</option>
                                                            {
                                                                medium_en.map((medium)=>{
                                                                    return (
                                                                        <option value={medium.value}>{medium.title}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-12">
                                                        <select className="form-select"
                                                                name="class_filter"
                                                                onChange={(e)=>{
                                                                    onChangeClass(e)
                                                                }}
                                                                aria-label="Default select example">
                                                            <option selected value="">Select Class</option>
                                                            {
                                                                allClass.map((classdata)=>{
                                                                    return (
                                                                        <option value={classdata.value}>{classdata.title}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-12">
                                                        <label className="col-form-label">
                                                            Preferred Subjects:
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <br/>
                                                            {
                                                                allSubject.map((subject,index)=>{
                                                                    return (
                                                                        <div key={index} className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                //name="t_preferred_class[]"
                                                                                onChange={onChangeSubject}
                                                                                id={subject.value}
                                                                                value={subject.title}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={subject.value}>
                                                                                {subject.title} 
                                                                            </label>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <TutorsAdvetisement/>
                            </div>

                            <div className="col-lg-8">
                                {allGuardianRequest.length > 0 ?
                                    allGuardianRequest.map((tuition_data,index)=> (
                                        <RequestIteam tuition_data={tuition_data} index={index} />
                                    ))

                                    :

                                    <span>No Tution Found...</span>
                                }
                                {totalPages > page ?

                                    <button className="load-more" onClick={() => {
                                        nextPage()
                                    }}>
                                        {loading ?
                                            <>
                                                <i className="fa fa-spinner fa-spin"></i> Loading...
                                            </>
                                            :
                                            'Load More'
                                        }
                                    </button>

                                    :

                                    <span></span>

                                }
                            </div>

                        </div>
                    </div>
                </div>

            </Layout>
        );
    }
};

export default Tuitons;