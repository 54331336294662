import React from "react";
import { Link } from "react-router-dom";
import { tutor_details } from "../../../route";
import { titleCase } from "../../../utils/helper";

const TutorItem = ({item}) => {

    const id = item.uuid

    return (
        <div className="col-lg-3 col-md-6 col-sm-6 col-6 FeaturedCatagory">
            <a className="TutorItemAllHover" href={`${tutor_details}/${id}`}>
                <div className="card TutorItem">
                    <div className="TutorIcon">
                        <img src={item.t_photo} className="img-fluid" alt="..."/>
                    </div>
                    { item.is_verified === 1 && <img src="./verified.png" alt="" className="verified-icon"/> }
                    <div className="TutorInner">
                        <h4 className="Tutor-Name" title={item.t_name}>{titleCase(item.t_name)}</h4>
                        

                        <span className="MonthlySallaryText">
                            {item.t_salary > 0 ?
                                <span>{item?.t_salary} tk/month</span>

                                :

                                <span>Negotiable</span>
                            }

                        </span>
                        <p className="Location">
                            {
                                item.t_preferred_district_to_teach != null && 
                                item.t_preferred_district_to_teach !== 'null' && 
                                item.t_preferred_district_to_teach !== '[]' ?
                                item.t_preferred_district_to_teach : <span>&nbsp;</span>
                            }
                        </p>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                    </div>
                    <Link to={`${tutor_details}/${id}`} className="DetailsButton TutorLightButton">See Details</Link>
                </div>
            </a>
        </div>

    )
}
export default TutorItem;