import axios from "axios";
import React, { useState } from 'react';
import { Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { media } from "../../route";
import { authGuardianValidate } from "../../utils/GuardianAuth";
import { authValidate } from "../../utils/TutionAuth";
// import JobListSuggestion from "../common/JobListSuggestion";
import { msisdnValidate } from "../../utils/Validation";
import Layout from "../layout/Layout";


const MediaLogin = () => {

    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    const [resMsg, setResMsg] = useState("");
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowMsg, setIsShowMsg] = useState(false);
    const [loginResponse, setLoginResponse] = useState();
    const [tutorUUID, setTutorUUID] = useState('');



    const history = useHistory();
    
    const onSubmit = (e) => {

        const data = JSON.stringify({"m_msisdn": e.msisdn, "m_password": e.password});
        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/media/media_login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response => {
                if (response.data.success) {
                    setTutorUUID(response.data.data.uuid)
                    setLoginResponse(JSON.stringify(response.data.data))

                    if(history.location.search){
                        localStorage.clear();
                        localStorage.setItem('mediaLogin', JSON.stringify(response.data.data));
                        history.push(`/${history.location.search.slice(5)}`);
                    }else{
                        localStorage.clear();
                        localStorage.setItem('mediaLogin', JSON.stringify(response.data.data));
                        history.push(`${media}/dashboard`);
                    }
                   
                } else {
                    toast(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                setIsShowMsg(true);
                setResMsg(error.response.data.message);
            });
    }
    
    const onShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    }

    if (authValidate()) {
        if(history.location.search){
            history.push(`/${history.location.search.slice(5)}`);
        }else{
            history.push("/tutor/dashboard");
        }
    }

    if (authGuardianValidate()) {
        history.push("/guardian/dashboard");
    }

    const onCloseMsg = () => {
        setIsShowMsg(!isShowMsg);
    }


    return (
        <>
            <Layout>
                <div className="tutorLoginWrap">
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover/>

                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-12 col-sm-12 col-md-12 col-lg-4">

                                <div className="formWrap">
                                    <div className="formInner">
                                        <div className="formCenter">
                                            <h3 className="text-center">Media Log In</h3>

                                            <>
                                                {isShowMsg ?
                                                    <Alert variant="danger" onClick={onCloseMsg} dismissible>
                                                        {resMsg}
                                                    </Alert>
                                                    :
                                                    <></>
                                                }
                                            </>

                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="form-group">
                                                    <label htmlFor="mobile_number">Mobile Number</label>
                                                    <input type="number"
                                                           name="msisdn"
                                                           className="form-control"
                                                           id="mobile_number"
                                                           {...register("msisdn", {required: true})}
                                                           placeholder="Mobile Number..."
                                                           min={0}
                                                           />
                                                    <small id="mobile_number" className="text-danger">
                                                        {errors.msisdn && <span>This field is required</span>}
                                                    </small>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Password</label>
                                                    <div className="loginInput">
                                                        <input type={isShowPassword ? 'text' : 'password'}
                                                               name="password"
                                                               className="form-control"
                                                               {...register("password", {required: true})}
                                                               id="exampleInputPassword1"
                                                               placeholder="Password"/>
                                                        <i onClick={onShowPassword} className={isShowPassword?"fa fa-eye":"fa fa-eye-slash"}></i>
                                                    </div>
                                                    <small id="mobile_number" className="form-text text-danger">
                                                        {errors.password && <span>Password field is required</span>}
                                                    </small>
                                                </div>
                                                <div className="form-group d-flex justify-content-between">
                                                    <div className="rem">
                                                        <div className="remItem">
                                                            <label htmlFor="rem">
                                                                <input type="checkbox" id='rem'/> Remember me
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        <Link to={`${media}/forget-password`} className="form-check-label"
                                                              htmlFor="exampleCheck1">Forgot Password?</Link>
                                                    </p>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary form-control">Login
                                                    </button>
                                                </div>
                                                <div className="text-center">
                                                    Don't have an account?
                                                    <Link to={`${media}/signup`} className="form-check-label"
                                                          htmlFor="exampleCheck1"> &nbsp; Sign Up </Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <JobListSuggestion/> */}

                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default MediaLogin;