import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { useGetThreeFeaturedTutorsQuery } from "../../features/tutor/tutorApi";
import { tutor } from '../../route';
import { Auth, authValidate } from '../../utils/TutionAuth';
import TutoritemMini from "../common/TutorItem/TutoritemMini";
import TutorsAdvetisement from "../common/TutorsAdvetisement";
import Layout from "../layout/Layout";
import EducationInfo from "./EducationInfo";
import Login from "./Login";
import PersonalInfo from "./PersonalInfo";
import TuitionInfo from "./TuitionInfo";
import TutorExperience from "./TutorExperience";



const TutorDashboard = () => {

    const[uuid,setUUID] = useState('')
    const[loader,setLoader] = useState(true);
    const history = useHistory()
  
    useEffect(() => {
      if (!authValidate()) {
        history.push(`${tutor}/login`);
      }else{
        const{uuid} = Auth();
        setUUID(uuid)
        setLoader(false)
      }
    },[])

    const [userData, setuserData] = useState(JSON.parse(localStorage.getItem('loginData')));
    const { data:featureTutor } = useGetThreeFeaturedTutorsQuery() || {};

    useEffect( ()=>{
        setuserData(JSON.parse(localStorage.getItem('loginData')))
    },[])
    
    if(!authValidate()) {
        return <Login/>
    }


    return (
        <>

            <Layout>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover/>
                <div className="bgGray pt-2">
                    {/*SocialProfileWrap section*/}
                        <PersonalInfo user_data={userData}/>
                    {/*End SocialProfileWrap section*/}

                    {/*About section*/}
                    <div className="AboutWrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">

                                    <div className="Tuition-InfoWrap">


                                        {/*end_tuition-Info*/}
                                        <TuitionInfo/>
                                        {/*<TuitionInfoNew user_data={Auth()}/>*/}
                                        {/*end_tuition-Info*/}

                                        {/*EducationWrap Section*/}
                                        <EducationInfo user_data={userData}/>
                                        {/*End EducationWrap Section*/}


                                        {/*ExperienceProfileWrap Section*/}
                                        <TutorExperience user_data={userData}/>
                                        {/*End ExperienceProfileWrap Section*/}


                                    </div>
                                </div>


                                <div className="col-lg-4">
                                    {/*people_also_viewed*/}
                                    {/*    <TutorView/>*/}
                                    {/*people_also_viewed*/}
                                    {/*featured_tutors*/}
                                    <div className="PeopleInner2Wrap">
                                        <h2 className="ViewText">Featured Tutors</h2>
                                        {featureTutor?.data.length > 0 ?
                                            featureTutor && featureTutor?.data.map((item,i)=>{
                                                return(
                                                    <TutoritemMini item={item} key={i}/>
                                                );
                                            })
                                            :
                                            <span>No Featured Tutor!!</span>
                                        }

                                    </div>
                                    {/*end featured_tutors*/}
                                    {/*advertise*/}
                                    <TutorsAdvetisement/>
                                    {/*advertise*/}
                                </div>

                            </div>
                        </div>
                    </div>
                    {/*end About section*/}
                </div>

            </Layout>

        </>
    );
};

export default TutorDashboard;