import React, {useEffect, useState} from 'react';
import Layout from "../../layout/Layout";
import {AuthGuardian, authGuardianValidate} from "../../../utils/GuardianAuth";
import axios from "axios";
import {ToastContainer} from "react-toastify";
import HeaderTitle from "../../common/HeaderTitle";
import {Link} from "react-router-dom";
import {tutor_details, tutor_request} from "../../../route";
import GSideBar from "../GSideBar";
import TutorsAdvetisement from "../../common/TutorsAdvetisement";
import {Modal} from "react-bootstrap";
import moment from "moment";

const GRequestResponse = () => {
    const [jobList,setJobList] = useState([])

    useEffect(()=> {
        if(authGuardianValidate()){
            getRequestResponse(AuthGuardian().uuid);
        }
    },[])
    
    const getRequestResponse = (guardian_id) => {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/tutor_request_response/${guardian_id}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setJobList(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    
    return (
        <Layout>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover/>

            <div className="container">
                <div className="row">
                    <HeaderTitle first_title="All Tutor" second_title="Request"/>
                    <div className="col-lg-8 pt-3">

                        {/*All Request*/}
                        <div className="guardian-tutor-request-list">
                            {jobList.length > 0 ?
                                <>
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col">Job ID</th>
                                            <th scope="col">View Job</th>
                                            <th scope="col">Given Contact Information</th>
                                            <th scope="col">View Tutor Profile</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            jobList && jobList.map((data) => {
                                                return (
                                                    <tr>
                                                        <td>Job:--{data.job_id}</td>
                                                        <td>
                                                            <Link to={`${tutor_request}/${data.job_id}`} className="btn btn-sm btn-success">
                                                                <i className="fa fa-eye"></i>
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            Name: {data.name} <br/>
                                                            Mobile: {data.mobile}
                                                        </td>
                                                        <td>
                                                            <Link to={`${tutor_details}/${data.tutors_id}`}  className="btn btn-sm btn-primary">
                                                                <i className="fa fa-eye"></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </>
                                :
                                <p className='mb-4'>
                                        No Response <Link to={tutor_request}> To get a lot of response post tutor request in our portal!</Link>
                                </p>
                            }
                        </div>
                        {/*End All request */}



                    </div>
                    <div className="col-lg-4">
                        {/* <GSideBar/> */}
                        <TutorsAdvetisement/>
                    </div>
                </div>
            </div>

        </Layout>
    );
};

export default GRequestResponse;