import axios from "axios";
import React, { useState } from 'react';
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AuthGuardian } from '../../../utils/GuardianAuth';
import { authValidate } from '../../../utils/TutionAuth';
import { msisdnValidate } from "../../../utils/Validation";
import Layout from "../../layout/Layout";

const ForgetPassword = () => {

    const history = useHistory();
    
    if (authValidate() || AuthGuardian()) {
        history.push("/tutor/dashboard");
    }
    const initialState = {
        msisdn : ''
    }
    const [msisdnData,setMsisdnData] = useState(initialState);
    const [isError,setIsError] = useState(false);
    const [errorMsg,setErrorMsg] = useState('');

    const handleOnChange = (event) => {
        const {name,value} = event.target;
        setMsisdnData({
            ...msisdnData,
            [name] : value
        })
    }

    const checkTutorExists = (e) => {
        e.preventDefault()
        const data2 = JSON.stringify({"msisdn": msisdnData.msisdn});
        if(!msisdnValidate(msisdnData.msisdn)) {
            setIsError(true);
            setErrorMsg("Enter Valid Phone Number!!")
            return;
        }

        //check

        const config2 = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/is_tuitor_exists',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data2
        };

        axios(config2)
            .then(function (response) {
                console.log(response.data)
                if(response.data.success) {
                    handleSendOTP();
                } else {
                    setIsError(true);
                    setErrorMsg(response.data.message)
                }
            })
            .catch(function (error) {
                setIsError(true);
                setErrorMsg(error.response.data.message)
            });
        //endcheck
    }

    const handleSendOTP = () => {
        const data1 = JSON.stringify({"msisdn": msisdnData.msisdn});
        if(!msisdnValidate(msisdnData.msisdn)) {
            setIsError(true);
            setErrorMsg("Enter Valid Phone Number!!")
            return;
        }

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp_send',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data1
        };

        axios(config)
            .then(response=> {
                console.log(response.data);
                toast(response.data.message);
                if(response.data.success){
                    localStorage.setItem('forget_msisdn',msisdnData.msisdn);
                    window.location.href="/otp-verify";
                } else {
                    setIsError(true);
                    setErrorMsg(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                setIsError(true);
                setErrorMsg("Something wrong!!")
            });
    }

    const handleClose = () => {
        setIsError(!isError);
    }




    return (
        <>
            <Layout>
                <div className="guardianLoginWrap">
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover/>

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4">

                                <div className="formWrap">
                                    <div className="formInner">
                                        <div className="formCenter">
                                            <h3 className="text-center">Reset Password</h3>

                                            <>
                                            {isError ?

                                                <Alert variant='danger' onClick={handleClose} dismissible={true}>
                                                    {errorMsg}
                                                </Alert>
                                                :
                                                <span></span>
                                            }
                                            </>

                                            <form onSubmit={checkTutorExists}>
                                                <div className="form-group">
                                                    <label htmlFor="mobile_number">Enter Phone Number</label>
                                                    <input type="number"
                                                           name="msisdn"
                                                           value={msisdnData.msisdn}
                                                           className="form-control"
                                                           id="mobile_number"
                                                           onChange={handleOnChange}
                                                           placeholder="Mobile Number..." required/>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary form-control">Send OTP
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        </>
    );
};

export default ForgetPassword;