import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list:{}
};

const tutorSlice = createSlice({
    name: "tutor",
    initialState,
    reducers: {
        guardianAppliedTuitionList: (state, action) => {
            state.list = action.payload;
        },
    },
});

export const {guardianAppliedTuitionList} = tutorSlice.actions;
export default tutorSlice.reducer;
