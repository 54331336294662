import React, {useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import {msisdnValidate} from "../../../../utils/Validation";
import {Alert} from "react-bootstrap";
import {guardian} from "../../../../route";
import VerifyOtp from "./VerifyOtp";
import ChangePassword from "./ChangePassword";

const ForgetPassword = ({onHandleForgetPassword}) => {
    const initialState = {
        msisdn : ''
    }


    const [msisdnData,setMsisdnData] = useState(initialState);
    const [forgetType,setForgetType] = useState('');
    const [isError,setIsError] = useState(false);
    const [errorMsg,setErrorMsg] = useState('');

    const handleOnChange = (event) => {
        const {name,value} = event.target;
        setMsisdnData({
            ...msisdnData,
            [name] : value
        })

        setIsError(false);
        setErrorMsg("")
    }

    const checkTutorExists = (e) => {
        e.preventDefault()
        const data2 = JSON.stringify({"g_msisdn": msisdnData.msisdn});
        if(!msisdnValidate(msisdnData.msisdn)) {
            setIsError(true);
            setErrorMsg("Enter Valid Phone Number!!")
            return;
        }

        //check

        const config2 = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/is_guardian_exists`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data2
        };

        axios(config2)
            .then(function (response) {
                if(!response.data.success) {
                    setIsError(true);
                    setErrorMsg(response.data.message)
                } else {
                    handleSendOTP();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        //endcheck
    }

    const handleSendOTP = () => {
        const data1 = JSON.stringify({"msisdn": msisdnData.msisdn});
        if(!msisdnValidate(msisdnData.msisdn)) {
            setIsError(true);
            setErrorMsg("Enter Valid Phone Number!!")
            return;
        }

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp_send',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data1
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                toast(response.data.message);
                if(response.data.success) {
                    localStorage.setItem('g_forget_msisdn',msisdnData.msisdn);
                    //window.location.href=`${guardian}/otp-verify`;
                    onHandleForgetPassword('OTP-VERIFY');
                } else {
                    setIsError(true);
                    setErrorMsg(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                setIsError(true);
                setErrorMsg("Something wrong!!")
            });
    }

    const handleClose = () => {
        setIsError(!isError);
    }

    const onHandleForgetType = () => {
        setForgetType('CHANGE-PASSWORD')
    }


    return (
        <>


          <div className="formCenter">
                            <h3 className="text-center">Reset Password</h3>

                            <>
                                {isError ?

                                    <Alert variant='danger' onClick={handleClose} dismissible={true}>
                                        {errorMsg}
                                    </Alert>
                                    :
                                    <span></span>
                                }
                            </>

                            <form onSubmit={checkTutorExists}>
                                <div className="form-group">
                                    <label htmlFor="mobile_number">Enter Phone Number</label>
                                    <input type="number"
                                           name="msisdn"
                                           value={msisdnData.msisdn}
                                           className="form-control"
                                           id="mobile_number"
                                           onChange={handleOnChange}
                                           placeholder="Mobile Number..." required/>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary form-control">Send OTP
                                    </button>
                                </div>
                            </form>

                        </div>



        </>
    );
};

export default ForgetPassword;