import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useGetFeaturedTutorsQuery } from "../../features/tutor/tutorApi";
import { tutor_details } from "../../route";
import HeaderTitle from '../common/HeaderTitle';
import TutorItem from "../common/TutorItem/TutorItem";

const HomeFeatureTutor = () => {

    const { data, isLoading, isError, error } = useGetFeaturedTutorsQuery() || {};
    return (
        <div className="FeaturedTutorsWrap">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-start ExplorerIteM">
                        <HeaderTitle title={'Explore Featured Tutors'} />
                    </div>

                    
                       {
                        data?.data.length > 0 && data?.data.map((item, i) =>
                           <TutorItem item={item} key={i} />
                         )
                       }  
                    
                </div>

            </div>
        </div>
    );
};

export default HomeFeatureTutor;