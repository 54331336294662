import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { msisdnValidate } from "../../../../utils/Validation";

const VerifyOtp = ({ onHandleForgetPassword }) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [msisdn, setMsisdn] = useState("");

  useEffect(() => {
    const get_data = localStorage.getItem("g_forget_msisdn");

    if (!get_data) {
      window.location.href = "/";
    } else {
      setMsisdn(get_data);
    }
  }, []);

  const handleOnChange = (event) => {
    setOtpCode(event.target.value);
  };

  const handleOTPVerify = (e) => {
    e.preventDefault();
    const data = JSON.stringify({ msisdn: msisdn, secret_key: otpCode });

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/otp/verification",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.success) {
          //window.location.href = `${guardian}/change-password`
          onHandleForgetPassword("CHANGE-PASSWORD");
        } else {
          setIsError(true);
          setErrorMsg(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsError(true);
        setErrorMsg("Something wrong!!");
      });
  };

  const onReSendOtp = (e) => {
    e.preventDefault();
    const data1 = JSON.stringify({ msisdn: msisdn });
    if (!msisdnValidate(msisdn)) {
      setIsError(true);
      setErrorMsg("Enter Valid Phone Number!!");
      return;
    }

    const config1 = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/otp_send",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data1,
    };

    axios(config1)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        toast(response.data.message);
        if (response.data.success) {
          setIsError(true);
          setErrorMsg(response.data.message);
        } else {
          setIsError(true);
          setErrorMsg(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsError(true);
        setErrorMsg("Something wrong!!");
      });
  };

  const handleClose = () => {
    setIsError(!isError);
  };

  return (
    <>
      <h3 className="text-center">Verify Phone Number</h3>

      <>
        {isError ? (
          <Alert variant="danger" onClick={handleClose} dismissible={true}>
            {errorMsg}
          </Alert>
        ) : (
          <span></span>
        )}
      </>

      <form onSubmit={handleOTPVerify}>
        <div className="form-group">
          <label htmlFor="otpCode">Enter OTP</label>
          <h5>Mobile NO: {msisdn}</h5>
          <input
            type="number"
            name="otpCode"
            value={otpCode}
            className="form-control"
            id="otpCode"
            onChange={handleOnChange}
            placeholder="OTP Verification..."
            required
          />
        </div>
        <div className="form-group">
          <button type="submit" className="btn btn-primary form-control">
            Verify OTP
          </button>
        </div>
        <div className="text-center">
          Didn't receive your code?{" "}
          <Link onClick={onReSendOtp}>Resend code</Link>
        </div>
      </form>
    </>
  );
};

export default VerifyOtp;
