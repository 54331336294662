import axios from "axios";
import React, { Component } from 'react';
import { Modal, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import circle2 from "../../frontend/img/circle2.png";
import graduationsmall from "../../frontend/img/graduationsmall.png";
import primiumshape from "../../frontend/img/primiumshape.png";
import shadowpic2 from "../../frontend/img/shadowpic2.png";
import { Auth, authValidate } from "../../utils/TutionAuth";

class PersonalInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            // notification: '',
            image: '',
            image_preview: null,
            isPhotoEdit: false,
            isLoader: true,
            userPersonalInfo: [],
            userProfilePercentage: [],
            errMsg: {}
        }
        this.getPersonalInfo = this.getPersonalInfo.bind(this);
        this.getProfilePercentage = this.getProfilePercentage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onPersonalInfoSubmit = this.onPersonalInfoSubmit.bind(this);
        this.handleOnEdit = this.handleOnEdit.bind(this);
        this.handleOnPhotoEdit = this.handleOnPhotoEdit.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.onPhotoSubmit = this.onPhotoSubmit.bind(this);
        this.handleOnShowMore = this.handleOnShowMore.bind(this);
        // this.getTotalNotification = this.getTotalNotification.bind(this);
    }

    componentDidMount() {
        if(authValidate()){
            this.getPersonalInfo();
            this.getProfilePercentage();
            // this.getTotalNotification();
        } else {
            window.location.href = "/"
        }
    }

    handleChange(event) {
        let userPersonalInfo = this.state.userPersonalInfo;

        if (event.target.name == "t_resume") {
            //validation
            if (!this.resumeFileValidation(event.target.files[0])) {
                return false;
            }
            //end_validation
            userPersonalInfo[event.target.name] = event.target.files[0];
            this.setState({
                userPersonalInfo
            });
        } else {
            userPersonalInfo[event.target.name] = event.target.value;
            this.setState({
                userPersonalInfo
            });
        }

    }
   
    getPersonalInfo() {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/get_perosnal_info/${this.props.user_data.uuid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(response => {
                this.setState({
                    userPersonalInfo: response.data.data
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getProfilePercentage() {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/profile_percentage/${this.props.user_data.uuid}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(response => {
                this.setState({
                    userProfilePercentage: response.data.data
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleOnEdit() {
        this.setState({
            isEdit: !this.state.isEdit
        })
    }

    handleOnPhotoEdit() {
        this.setState({
            isPhotoEdit: !this.state.isPhotoEdit
        })
    }

    onPersonalInfoSubmit(e) {
        e.preventDefault();


        if(!this.state.userPersonalInfo.t_gender.length) {
            this.setState({
                errMsg: {
                    t_gender_err : 'Required field!'
                }
            })
            return false;
        }


        //file_upload
        const resume_data = new FormData();
        resume_data.append('tutors_id', this.state.userPersonalInfo.id);
        resume_data.append('t_resume', this.state.userPersonalInfo.t_resume);

        const config1 = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/uplode_resume',
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog',
            },
            data: resume_data
        };

        axios(config1)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
        //end_file_upload

        //user_update
        const data2 = JSON.stringify({"id": Auth().uuid, "name": this.state.userPersonalInfo.t_name});

        const config2 = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/tutors_update',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data2
        };

        axios(config2)
            .then(function (response) {
                if(response.data.success) {
                    localStorage.setItem('loginData', JSON.stringify(response.data.data));
                    window.location.reload(true);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        //user_update

        const data = JSON.stringify({
            "uuid": this.state.userPersonalInfo.uuid,
            "t_name": this.state.userPersonalInfo.t_name,
            "t_msisdn": this.state.userPersonalInfo.t_msisdn,
            "t_address": this.state.userPersonalInfo.t_address,
            "t_gender": this.state.userPersonalInfo.t_gender,
            "t_highest_degree": this.state.userPersonalInfo.t_highest_degree,
            "t_works_at": this.state.userPersonalInfo.t_works_at,
            "t_studied_at": this.state.userPersonalInfo.t_studied_at,
            "t_days_per_week": this.state.userPersonalInfo.t_days_per_week,
            "t_student_serve": this.state.userPersonalInfo.t_student_serve,
            "t_year_exp": this.state.userPersonalInfo.t_year_exp,
            "t_about": this.state.userPersonalInfo.t_about
        });
        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/update_perosnal_info',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response => {
                toast(response.data.message);
                this.setState({
                    isEdit: false
                })
                this.getPersonalInfo();
                this.getProfilePercentage();

                let localData = JSON.parse(localStorage.getItem('loginData'));
                let updatedData = {...localData,name:this.state.userPersonalInfo.t_name}
                localStorage.setItem('loginData',JSON.stringify(updatedData))
                window.location.reload()
                
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onImageChange(event) {

        //validation
        this.setState({
            errMsg: {
                t_photo_err: ''
            }
        })
        if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
            this.setState({
                errMsg: {
                    t_photo_err: 'Upload valid File!'
                }
            })
            return false;
        }
        //end_validation

        this.setState({
            image: event.target.files[0],
            image_preview: URL.createObjectURL(event.target.files[0])
        })
    }

    onPhotoSubmit(e) {
        e.preventDefault();
        //validation
        this.setState({
            errMsg: {
                t_photo_err: ''
            }
        })

        if(this.state.image.size > 1000141){
            
            this.setState({
                errMsg: {
                    t_photo_err: 'Please upload image below 1MB'
                }
            })
            return false;
        }

        if (!this.state.image) {
            this.setState({
                errMsg: {
                    t_photo_err: 'Upload valid Image!'
                }
            })
            return false;
        }
        
        if (!this.state.image.name.match(/\.(jpg|jpeg|JPEG|png)$/)) {
            this.setState({
                errMsg: {
                    t_photo_err: 'Upload valid Image!'
                }
            })
            return false;
        }
        //end_validation


        const data = new FormData();
        data.append('tutors_id', this.state.userPersonalInfo.uuid);
        data.append('t_photo', this.state.image);


        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/uplode_profile',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response => {
                toast('User Profile Updated');
                this.setState({
                    isPhotoEdit: false
                })
                this.getPersonalInfo();
                this.getProfilePercentage();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    resumeFileValidation(eFile) {
        this.setState({
            errMsg: {
                t_resume_err: ''
            }
        })
        if (!eFile.name.match(/\.(pdf|docx|doc)$/)) {
            this.setState({
                errMsg: {
                    t_resume_err: 'Upload valid File!'
                }
            })
            return false;
        }
        if(eFile.size > 1024000){
            this.setState({
                errMsg: {
                    t_resume_err : 'File size not more than 1MB!'
                }
            })
            return false;
        }
        return true;
    }

    // getTotalNotification() {
    //     const config = {
    //         method: 'get',
    //         url: process.env.REACT_APP_API_URL+`/api/web/v1/number_of_notification/${Auth().tutors_id}`,
    //         headers: {
    //             'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
    //         }
    //     };

    //     axios(config)
    //         .then(response=> {
    //             this.setState({
    //                 notification: response.data.data
    //             })
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    handleOnShowMore() {
        const dots = document.getElementById("dots");
        const moreText = document.getElementById("more");
        const btnText = document.getElementById("myBtn");

        if (dots.style.display === "none") {
            dots.style.display = "inline ";
            btnText.innerHTML = "View More <i class='fa fa-angle-down'>";
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = "View Less <i class='fa fa-angle-up'>";
            moreText.style.display = "inline";
        }
    }

    render() {
        const {isEdit, image, image_preview, isPhotoEdit, userPersonalInfo, userProfilePercentage} = this.state;

        return (
            <>

                <div className="SocialProfileWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12 ppImg">
                                <div className="ProfileWrapInner">
                                    <div className="shadowimg1">
                                        <img src={shadowpic2} className="img-fluid" alt="Responsive"/>
                                    </div>
                                    <div className="circle2">
                                        <img src={circle2} className="img-fluid" alt="Responsive"/>
                                    </div>
                                    <div className="PrimiumImg">
                                        <img src={primiumshape} className="img-fluid" alt="Responsive"/>
                                    </div>
                                    <div className="ProfileImg">
                                        <div className="proImgInner">
                                            <div className="uploadImg">
                                                <div className="iconInner" onClick={this.handleOnPhotoEdit}>
                                                    <i className="i fa fa-camera"></i>
                                                </div>
                                            </div>
                                            <img src={userPersonalInfo.t_photo} className="img-fluid"
                                                    alt={userPersonalInfo.t_name}/>
                                                
                                            <div className="dashboard-verified-icon">
                                                { userPersonalInfo.is_verified === 1 && <img src="/verified.png" alt="" className="personal-infor-verified"/> }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ProfileTextwrap" title={userPersonalInfo.t_name}>
                                        <h1 className="UserNametext" style={{textTransform:"capitalize"}}>
                                            {/* {`${userPersonalInfo.t_name}`.substr(0,12)}
                                            {`${userPersonalInfo.t_name}`.length > 12 ?
                                                <>...</>
                                                :
                                                <></>
                                            } */}
                                             {userPersonalInfo.t_name}
                                            
                                        </h1>
                                        <p className="Graduation" title={userPersonalInfo.t_highest_degree}>
                                            <img src={graduationsmall} className="img-fluid" alt="Responsive"/>
                                            &nbsp;
                                            {/* {`${userPersonalInfo.t_highest_degree}`.substr(0,12)}
                                            {`${userPersonalInfo.t_highest_degree}`.length > 12 ?
                                                <>...</>
                                                :
                                                <></>
                                            } */}
                                            {userPersonalInfo.t_highest_degree}
                                        </p>
                                        <span className="Location" title={`${userPersonalInfo.t_preferred_area_to_teach}`
                                            .replace(/\[|\]|"/g,'')}>
                                            <i className="fs-5 fa fa-map-marker"></i> &nbsp;
                                            {`${userPersonalInfo.t_preferred_area_to_teach}`
                                                .replace(/\[|\]|"/g,'')
                                            }
                                        </span>
                                    </div>
                                    <div className="DownloadAddInner">
                                        {/* <div className="downloadWrap">
                                            {userPersonalInfo.t_resume == "" ?
                                                <a className="ButtonSolidwrap Buttonlight" href={userPersonalInfo.t_resume} onClick={()=> {
                                                    alert("You have not uploaded any resume. Please upload your resume in pdf format.")
                                                }}>
                                                    Download Resume
                                                </a>
                                                :
                                                <a className="ButtonSolidwrap Buttonlight" href={userPersonalInfo.t_resume}>Download
                                                    Resume
                                                </a>
                                            }
                                        </div> */}

                                        <div className="AddEditLogoWrap">
                                            <div className="EditModalButton AddEditGlobBtn">
                                                <div className="EditLogoWrap">
                                                    <a href="javascript:void(0);" className="EditSolidButton AddEditGlobBtn"
                                                       onClick={this.handleOnEdit}>
                                                        <i className='fa fa-edit'></i>
                                                        <span className="EditTExt">&nbsp;Edit</span>
                                                    </a>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="ServiceDigitWrap">
                                    <div className="col-12">
                                        <div className="ServiceInnerWrap">
                                            <div className="ExperienceWrap1">
                                                <span className="Numberwrap">{userPersonalInfo.t_year_exp}</span>
                                                <h3 className="ServiceText">Years of Experience</h3>
                                            </div>
                                            <div className="ExperienceWrap2">
                                                <span className="Numberwrap">{userPersonalInfo.t_student_serve}</span>
                                                <h3 className="ServiceText">Total Student Service</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="VersityWorksInner">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="ContactWrap">
                                                <span className="WorksItem">Studied at</span>
                                                <p className="ContactText DU-logo">
                                                    <i className="fa fa-graduation-cap">&nbsp;</i>
                                                    <span title={userPersonalInfo.t_studied_at}>
                                                        {/* {`${userPersonalInfo.t_studied_at}`.substr(0,12)}
                                                        {`${userPersonalInfo.t_studied_at}`.length > 12 ?
                                                            <>...</>
                                                            :
                                                            <></>
                                                        } */}
                                                        {userPersonalInfo.t_studied_at}
                                                    
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="ContactWrap">
                                                <span className="WorksItem">Works at</span>
                                                <p className="ContactText" title={userPersonalInfo.t_works_at}>
                                                    <i className="fa fa-briefcase" >&nbsp;</i>
                                                    {/*<img src={BDBimanLogo} className="img-fluid" alt="Responsive"/>*/}
                                                    {`${userPersonalInfo.t_works_at}`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="ContactWrap">
                                                <span className="WorksItem">See</span>
                                                <p className="ContactText" title={userPersonalInfo.t_msisdn}>
                                                    <i className="fa fa-phone">&nbsp;</i>&nbsp;
                                                    {`${userPersonalInfo.t_msisdn}`.substr(2,12)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="ContactWrap">
                                                <span className="WorksItem">Home Location</span>
                                                <p className="ContactText" title={userPersonalInfo.t_address}>
                                                    <i className="fa fa-map-marker">&nbsp;</i>
                                                    {`${userPersonalInfo.t_address}`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="AboutTextInner">
                                            <div className="pb-2 pt-2">
                                                <h5>Profile Completion: {userProfilePercentage} %</h5>
                                                <ProgressBar variant="success" now={userProfilePercentage}
                                                            label={`${userProfilePercentage}%`}/>
                                            </div>
                                            <div className="aBoutViewIn col-12">

                                                <div className="ServiceInnerWrap">
                                                    <h3 className="AboutText">About</h3> 

                                                    {`${userPersonalInfo.t_about}`.length > 0 ?

                                                        <p className="TeachingText">
                                                            {` ${userPersonalInfo.t_about}`.substr(0, 202) ?? " "} {}
                                                            <span id="dots" style={{display: "inline"}}>
                                                                {` ${userPersonalInfo.t_about}`.length > 202 ?
                                                                    <span>...</span>
                                                                    :
                                                                    <></>
                                                                }
                                                            </span>
                                                                <span id="more" style={{display: "none"}}>
                                                                {` ${userPersonalInfo.t_about}`.substr(202, `${userPersonalInfo.t_about}`.length) ?? " "}
                                                            </span>
                                                        </p>

                                                        :

                                                        <p className="TeachingText">
                                                            <span id="dots" style={{display: "inline"}}>
                                                                Empty...
                                                            </span>
                                                            <span id="more" style={{display: "none"}}>

                                                            </span>
                                                        </p>

                                                    }

                                                </div>

                                            </div>
                                        </div>
                                        {`${userPersonalInfo.t_about}`.length > 200 ?
                                            <div className="ViewMoreInner">
                                                <button onClick={this.handleOnShowMore} id="myBtn">View more <i
                                                    className='fa fa-angle-up'></i></button>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>




                            </div>
                            {/*Contact with this tutors*/}
                            <div className="col-lg-4 col-12">
                                {/* <TutorMenu notification={this.state.notification}/> */}
                            </div>
                            {/*end Contact with this tutors*/}
                        </div>
                    </div>
                </div>


                {/*profileImage*/}
                <Modal
                    show={isPhotoEdit}
                    onHide={this.handleOnPhotoEdit}
                    backdrop="static"
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Profile photo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="tutor-img-upload">
                                <div className="preview-img">
                                    {image_preview !== null ?
                                        <img src={image_preview} className="img-fluid"
                                             alt="Responsive"/>
                                        :
                                        <img src={userPersonalInfo.t_photo} className="img-fluid"
                                             alt="Responsive"/>
                                    }
                                </div>
                                <div className="upload-btn">
                                    <div className="form-group col-md-12">
                                        <input type="file" name="file" onChange={this.onImageChange} required/>
                                    </div>
                                    <span className="text-danger">{this.state.errMsg.t_photo_err}</span>
                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <a className="btn btn-md btn-danger" onClick={this.handleOnPhotoEdit}>
                                Close
                            </a>
                            <button type="submit" className="btn btn-md btn-primary"
                                    onClick={this.onPhotoSubmit}>
                                Upload Photo
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>
                {/*end_profileImage*/}

                


                {/*Personal Info Update*/}
                <Modal
                    show={isEdit}
                    onHide={this.handleOnEdit}
                    backdrop="static"
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='PersonalInfo'
                >
                    <form onSubmit={this.onPersonalInfoSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Contact Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/*    form*/}
                            <span className="text-danger">*</span> Indicates
                            required

                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label htmlFor="name">
                                        Full name
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="text"
                                           name="t_name"
                                           className="form-control"
                                           value={userPersonalInfo.t_name}
                                           onChange={this.handleChange}
                                           id="name"
                                           maxLength="120"
                                           placeholder="Full Name..." required/>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="home_location">
                                            Home Location
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="text"
                                               name="t_address"
                                               value={userPersonalInfo.t_address}
                                               onChange={this.handleChange}
                                               className="form-control"
                                               id="home_location"
                                               maxLength="120"
                                               placeholder="e.x.: Dhaka" required/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="home_location">Gender<span className="text-danger">*</span>
                                        </label>
                                        <select name="t_gender" id="t_gender" onChange={this.handleChange} className="form-control">
                                            <option selected value="">---Select Gender---</option>
                                            <option value="male" selected={userPersonalInfo.t_gender == 'male'}>Male</option>
                                            <option value="female" selected={userPersonalInfo.t_gender == 'female'}>Female</option>
                                        </select>
                                        <span className="text-danger">{this.state.errMsg.t_gender_err}</span>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="t_highest_degree">Highest Degree<span
                                        className="text-danger">*</span></label>
                                    <input type="text"
                                           name="t_highest_degree"
                                           value={userPersonalInfo.t_highest_degree}
                                           onChange={this.handleChange}
                                           className="form-control"
                                           maxLength="120"
                                           id="t_highest_degree"
                                           placeholder="e.x.: B.Sc. (Hons) in Mathematics" required/>
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="studied_at">Studied at<span
                                        className="text-danger">*</span></label>
                                    <input type="text"
                                           name="t_studied_at"
                                           value={userPersonalInfo.t_studied_at}
                                           onChange={this.handleChange}
                                           className="form-control"
                                           id="studied_at"
                                           maxLength="120"
                                           placeholder="e.x.: Dhaka University" required/>
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="works_at">Works at</label>
                                    <input type="text"
                                           name="t_works_at"
                                           value={userPersonalInfo.t_works_at}
                                           onChange={this.handleChange}
                                           className="form-control"
                                           maxLength="120"
                                           id="works_at" placeholder="Work now..." required/>
                                </div>
                                <div className="row">
                                    {/*<div className="form-group col-md-4">*/}
                                    {/*    <label htmlFor="available">*/}
                                    {/*        Available in Week*/}
                                    {/*        <span className="text-danger">*</span>*/}
                                    {/*    </label>*/}
                                    {/*    <input type="number"*/}
                                    {/*           name="t_days_per_week"*/}
                                    {/*           {...register("t_days_per_week", { required: true })}*/}
                                    {/*           className="form-control"*/}
                                    {/*           id="available" placeholder="e.x.: 3"/>*/}
                                    {/*    <span className="text-danger">*/}
                                    {/*        {errors.t_days_per_week &&*/}
                                    {/*        <span>*/}
                                    {/*            <i className="fa fa-minus-circle"></i>*/}
                                    {/*            Available in Week is a required field*/}
                                    {/*        </span>}*/}
                                    {/*    </span>*/}
                                    {/*</div>*/}
                                    <div className="form-group col-md-6">
                                        <label htmlFor="student_service">
                                            Total Student Service
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="number"
                                               name="t_student_serve"
                                               value={userPersonalInfo.t_student_serve}
                                               onChange={this.handleChange}
                                               className="form-control"
                                               id="student_service"
                                               min="0"
                                               max="99999"
                                               placeholder="e.x.: 150" required/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="experience">
                                            Years of Experience
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="number"
                                               name="t_year_exp"
                                               value={userPersonalInfo.t_year_exp}
                                               onChange={this.handleChange}
                                               className="form-control"
                                               id="experience"
                                               min="0"
                                               max="50"
                                               placeholder="e.x.: 2" required/>
                                    </div>
                                    {/* <div className="form-group col-md-4">
                                        <label htmlFor="t_resume">
                                            Upload Resume
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="file"
                                               name="t_resume"
                                               onChange={this.handleChange}
                                               className="form-control"
                                               id="t_resume"/>
                                        <span className="text-danger">
                                           {this.state.errMsg.t_resume_err}
                                        </span>
                                    </div> */}
                                </div>

                                <div className="form-group col-md-12">
                                    <label htmlFor="name">
                                        About
                                        <span className="text-danger">*</span>
                                    </label>
                                    <textarea name="t_about"
                                              value={userPersonalInfo.t_about}
                                              onChange={this.handleChange}
                                              className="form-control"
                                              id="name"
                                              maxLength="350"
                                              placeholder="About..." required>
                                                                        </textarea>
                                </div>


                            </div>

                            {/*    end form */}
                        </Modal.Body>
                        <Modal.Footer>
                            <span className="btn btn-md btn-danger" onClick={this.handleOnEdit}>
                                Close
                            </span>
                            <button type="submit"
                                    className="btn btn-md btn-primary">
                                Save
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>
                {/*End_Personal Info Update*/}

                {/*Location Add*/}
                {/*<Modal show={isLocationAdd} onHide={handleCloseLocation}>*/}
                {/*    <form onSubmit={handleSubmit(handleOnLocationAdd)}>*/}
                {/*        <Modal.Header closeButton>*/}
                {/*            <Modal.Title>Add Preferred Areas to Teach</Modal.Title>*/}
                {/*        </Modal.Header>*/}
                {/*        <Modal.Body>*/}
                {/*            <div className="form-group col-md-12">*/}
                {/*                <label htmlFor="area">*/}
                {/*                    Preferred Areas to Teach:*/}
                {/*                    <span className="text-danger">*</span>*/}
                {/*                </label>*/}
                {/*                {areaData.map(area => {*/}
                {/*                    return (*/}
                {/*                        <div key={area.id} className="form-check">*/}
                {/*                            <input className="form-check-input"*/}
                {/*                                   {...register("t_preferred_area_to_teach[]")}*/}
                {/*                                   name="t_preferred_area_to_teach[]"*/}
                {/*                                   type="checkbox"*/}
                {/*                                   value={area.area_name}*/}
                {/*                                   id={area.id}/>*/}
                {/*                            <label className="form-check-label"*/}
                {/*                                   htmlFor={area.id}>*/}
                {/*                                {area.area_name}*/}
                {/*                            </label>*/}
                {/*                        </div>*/}
                {/*                    )*/}
                {/*                })}*/}
                {/*            </div>*/}
                {/*        </Modal.Body>*/}
                {/*        <Modal.Footer>*/}
                {/*            <a className="btn btn-sm btn-danger"*/}
                {/*               onClick={handleCloseLocation}>*/}
                {/*                Close*/}
                {/*            </a>*/}
                {/*            <button className="btn btn-sm btn-primary"*/}
                {/*                    onClick={handleCloseLocation}>*/}
                {/*                Save*/}
                {/*            </button>*/}
                {/*        </Modal.Footer>*/}
                {/*    </form>*/}
                {/*</Modal>*/}
                {/*end Location Add*/}

            </>
        );
    }
}

export default PersonalInfo;