import React, {useEffect} from 'react';
import HeaderTitle from "../common/HeaderTitle";
import Layout from "../layout/Layout";

const Faq = () => {
    useEffect(()=> {
        document.title = "FAQ";
    },[])
    return (
        <Layout>
            <div className="container">
                <div className="row pb-5">
                    <HeaderTitle first_title="Faq" second_title=""/>
                    Not Provide...
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </Layout>
    );
};

export default Faq;