import React, { useEffect, useState } from "react";
import mehrun from "../../frontend/HomeImge/mehrun.png";
import axios from "axios";
import { Link } from "react-router-dom";
import TutorItem from "../common/TutorItem/TutorItem";
import Marquee from "react-fast-marquee";
import HeaderTitle from "../common/HeaderTitle";

// TinySlider
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";
import { useGetRecentTutorsQuery } from "../../features/tutor/tutorApi";

// for TinySlider
const tinySliderSettings = {
  lazyload: true,
  nav: false,
  mouseDrag: true,
  loop: true,
  items: 1,
  gutter: 20,
  responsive: {
    0: {
      items: 2,
    },
    420: {
      items: 2,
      control: false,
    },
    768: {
      items: 3,
    },
    991: {
      items: 4,
    },
  },
};

const RecentlyJoined = () => {
  const { data, isLoading, isError, error } = useGetRecentTutorsQuery() || {};

  return (
    <>
      {data?.data && data?.data.length > 0 && (
        <div className="FeaturedTutorsWrap">
          <div className="container">
            <div className="row">
              <div className="col-12 text-start ExplorerIteM">
                <HeaderTitle title={"Recently Joined Tutors"} />
              </div>

              <div className="FeaturedTutorsSliderWrap">
                <div className="arrow arrow-left">
                  <button>
                    <i className="fa fa-angle-left"></i>
                  </button>
                </div>
                <div className={"arrow arrow-right"}>
                  <button className="">
                    <i className="fa fa-angle-right"></i>
                  </button>
                </div>
                <TinySlider settings={tinySliderSettings}>
                  {data?.data.map((item, i) => (
                    <TutorItem item={item} key={i} />
                  ))}
                </TinySlider>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RecentlyJoined;
