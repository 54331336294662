import React, {useEffect, useState} from 'react';
import HeaderTitle from "../common/HeaderTitle";
import TutorItem from "../common/TutorItem/TutorItem";
import Layout from "../layout/Layout";
import axios from "axios";

const FeMaleTutors = () => {
    const pageSize = 4;
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    const [allTutors, setAllTutors] = useState([]);
    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        getAllFeMaleTutors();
        setIsLoader(false)

    }, [page])
    
    const getAllFeMaleTutors = () => {
        const data = JSON.stringify({"t_gender": "female"});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/get_female_tutor?page=${page}&pageSize=${pageSize}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setAllTutors([...allTutors,...response.data.data]);
                setTotalPages(response.data.totalPages)
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    
    return (
        <Layout>

            <div className="FeaturedTutorsWrap">
                <div className="container">
                    <div className="row">
                        <HeaderTitle title="All Female Tutor List"/>

                        {allTutors.length > 0 ?
                            allTutors.map((item, i) =>
                                <TutorItem item={item} key={i}/>
                            )
                            :
                            <span>No Tutor Found...</span>
                        }
                    </div>
                    {totalPages > page ?
                        <button className="load-more" onClick={() => {
                            setPage(page + 1)
                            getAllFeMaleTutors()
                        }}>
                            {loading ? 'Loading...' : 'Load More'}
                        </button>
                        :
                        <span>&nbsp;</span>
                    }
                </div>
            </div>

        </Layout>
    );
};

export default FeMaleTutors;