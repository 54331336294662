import axios from "axios";
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import footerLogo from '../../frontend/HomeImge/FooterLogoTutorBD.png';

const Footer = () => {
    const [subEmail, setSubEmail] = useState('')

    const onHandleChange = (e) => {
        setSubEmail(e.target.value)
    }

    const onHandleSubmitSubscribe = (e) => {
        e.preventDefault()
        const data = JSON.stringify({"email": subEmail});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/subscribe',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                toast(response.data.message);
            })
            .catch(function (error) {
                console.log(error);
                toast(error);
            });
    }
    return (
        <footer className="FooterBox">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover/>
            <div className="FotterTextInner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="footerItem">
                                <div className="FooterLogo">
                                    <Link to="/">
                                        <img src={footerLogo} className="img-fluid footer-logo" alt="logo"/>
                                    </Link>
                                </div>
                                <p className="LogoPara">When writing your teaching statement, make clear why, how, and
                                    what you teach. It should be one to two pages in length. It is not an article on
                                    teaching.</p>
                                <div className="SocialFooterEnd">
                                    <ul className="SocialLogoWrap">
                                        <li>
                                            <a href="https://facebook.com/tutorbdhub" className="SocialButton RegSubSolidButton">
                                                <i className="fa fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/"
                                               className="SocialButton RegSubSolidButton">
                                                <i className="fa fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/" className="SocialButton RegSubSolidButton">
                                                <i className="fa fa-twitter"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="footerTopSingleItem">
                                <h4 className="footerTitle wow fadeInUp" data-wow-delay="0.2s">Contact Info</h4>
                                <div className="footerAddress">
                                    <p><i className="fa fa-map-marker"></i>Panthapath, Dhaka-1207 Bangladesh
                                    </p>
                                    <a href="mailto:tutorbd.network@gmail.com"><p><i className="fa fa-envelope-o"></i> tutorbd.network@gmail.com </p></a>
                                    {/* <a href="tel:+88029134990"><p><i className="fa fa-phone"></i>+8801717400118</p></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="footerTopSingleItem">
                                <div className="footerMap">
                                    <div className="contact_map" id="contact_map">
                                        <iframe
                                            src="https://maps.google.com/maps?q=23.75228824996486,%2090.37936590611528&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                            allowFullScreen></iframe>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerbottom">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <div className="copyrightWrap">
                                <p>Copyright © {new Date().getFullYear()} TutorBD Bangladesh</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;