import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { guardian, tutor_request } from "../../route";
import { AuthGuardian, authGuardianValidate } from "../../utils/GuardianAuth";
import Layout from "../layout/Layout";
import GSideBarTutorRequest from "./GSideBarTutorRequest";

const GRequestTutorResponse = () => {
    const [guardianDetails,setGuardianDetails] = useState([]);

    const history = useHistory();

    useEffect(()=>{
        if(authGuardianValidate()){
            getGuardianDetails(AuthGuardian().uuid);
        }
    },[])

    // if(!authGuardianValidate()){
    //     history.push("/guardian/login");
    // }

    console.log(authGuardianValidate());

    const getGuardianDetails = (id) => {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/get_guardian_details/${id}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                setGuardianDetails(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
 
    return (
        <Layout>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover/>

            <div className="container">
                <div className="row">

                    <div className="col-lg-8 text-center text-success pt-5 pb-5">
                        <h5>Your tutor request has been successfully posted.
                            {(  
                                guardianDetails.g_name == "" ||
                                guardianDetails.g_msisdn == ""  ||
                                guardianDetails.g_email == "" ||
                                guardianDetails.g_address == "" ||
                                guardianDetails.g_district == "" ||
                                guardianDetails.g_area == "" ||
                                guardianDetails.g_postal == "" ||
                                guardianDetails.g_no_kid == ""
                            ) ?

                                <>
                                    To get better tutor suggestion around your area, please complete your <Link to={`${guardian}/dashboard`}>profile.</Link>
                                    <br/>
                                    <br/>
                                    <Link to={`${guardian}/dashboard`} className="btn btn-md btn-outline-success">Edit Profile</Link> &nbsp;&nbsp;
                                </>

                                :

                                <span></span>

                            }
                            <br/>
                            <br/>
                            <a href={tutor_request} className="btn btn-md btn-outline-primary">
                                Create Another Request</a>
                        </h5>
                    </div>

                    <div className="col-lg-4">
                        <GSideBarTutorRequest/>
                    </div>

                </div>
            </div>

        </Layout>
    );
};

export default GRequestTutorResponse;