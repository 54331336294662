export const postcode_en = {
    "Savar":["1340","1341","1342","1343","1344","1345","1346","1347","1348","1349"],
    "Dhamrai":["1350","1351"],
    "Keraniganj":["1312","1311","1313","1310"],
    "Nawabganj":["1320","1321","1322","1323","1324","1325"],
    "Dohar":['1330','1330'],
    "Demra":['1360','1362','1361'],
    "Dhaka Cantonment":['1206'],
    "Kamlapur":['1351'],
    "Dhanmondi":['1209'],
    "Gulshan":['1213'],
    "Banani":['1212'],
    "Jatrabari":['1232'],
    "Joypara":['1330','1332','1331'],
    "Khilgaon":['1219'],
    "Khilkhet":['1229'],
    "Nikunjo-1":['1229'],
    "Nikunjo-2":['1229'],
    "Lalbag":['1211'],
    "Mirpur":["1216"],
    "Mirpur 1":["1216"],
    "Mirpur 2":["1216"],
    "Mirpur 6":["1216"],
    "Mirpur 7":["1216"],
    "Mirpur 10":["1216"],
    "Mirpur 11":["1216"],
    "Mirpur 12":["1216"],
    "Mirpur 13":["1216"],
    "Mirpur 14":["1216"],
    "Mohammadpur":["1207","1225"],
    "Asad Gate":["1207","1225"],
    "Motijheel":["1222","1223"],
    "New market":["1205"],
    "Palton":["1000"],
    "Ramna":["1217"],
    "Sabujbag":["1217"],
    "Sutrapur":["1100","1203","1204"],
    "Tejgaon":["1208","1215"],
    "Uttara":["1230"],
    "Airport Area":["1230"],
    "Abdullahpur":["1230"],

    "Amtali":["8710"],
    "Bamna":["8730"],
    "Barguna Sadar":["8700","8701"],
    "Betagi":["8740","8741"],
    "Pathorghata":["8721","8720"],

    "Pirganj":["5110","5470"],
    "Baliadangi":["5140","5141"],

    "Satkhira Sadar":["9403","9402","9401","9400"],
    "Kalaroa":["9415","9413","9412","9410","9414","9411"],
    "Kaliganj":["1720","1721","1722","1723"],

    "Kalia":["7520"],

    "Magura Sadar":["7600"],
    "Sreepur":["1743","1747","1748","1745","1744","1740"],

    "Kumarkhali":["7010","7011"],
    "Bheramara":["7042","7040","7041"],

    "Paikgachha":["9284","9285","9281","9282","9283","9280"],
    "Phultala":["9210"],

    "Maheshpur":["7340"],
    "Kotchandpur":["7330"],

    "Keshabpur":["7450"],
    "Jessore Sadar":["7406","7402","7407","7404","7403","7400","7401","7405"],
    "Chaugachha":["7410"],
    "Jhikargachha":["7420"],

    "Damurhuda":["7222","7220","7221"],
    "Alamdanga":["7210","7211"],

    "Bagerhat Sadar":["9300","9301","9302"],
    "Chitalmari":["9361","9360"],
    "Fakirhat":["9372","9370","9371"],
    "Kachua":["3630","3631","3632","3633"],
    "Mollahat":["9383","9382","9381","9380","9384","9385"],
    "Rampal":["9341","9343","9340","9342"],

    "Sylhet Sadar":["3106","3107","3104","3111","3112","3103","3113","3108","3109","3114","3105","3100","3102","3101"],
    "Bishwanath":["3130","3131","3133","3132","3134"],
    "Balaganj":["3120","3125","3122","3128","3124","3121","3127","3129","3126","3123"],
    "Fenchuganj":["3116","3117"],
    "Kanaighat":["3181","3183","3180","3182"],

    "Chhatak":["3080","3081","3082","3893","3083","3084","3088","3087","3085","3086"],
    "Sunamganj Sadar":["3001","3002","3000"],
    "Tahirpur":["3030"],

    "Moulvibazar Sadar":["3203","3201","3202","3200"],
    "Kamalganj":["3220","3221","3224","3222","3223"],
    "Kulaura":["3237","3234","3235","3230","3232","3233","3231"],
    "Rajnagar":["3240"],

    "Baniachang":["3350","3351","3352"],
    "Bahubal":["3310"],
    "Chunarughat":["3321","3320","3322"],
    "Madhabpur":["3331","3330","3333","3332"],
    "Nabiganj":["3373","3372","3371","3374","3370"],

    "Rangamati Sadar":["4500"],
    "Kaptai":["4531","4530","4533","4532"],
    "Kaukhali":["8513","8512","8510","8511"],

    "Noakhali Sadar":["3811","3812","3809","3803","3807","3808","3806","3801","3800","3804","3802"],
    "Begumganj":["3831","3847","3822","3824","3820","3837","3821","3843","3848","3828","3825","3844","3829","3833","3842","3838","3823","3839","3841","3835","3834","3827","3832","3845"],
    "Chatkhil":["3879","3873","3870","3878","3877","3872","3871","3874","3881","3882","3883","3875"],

    "Ramganj":["3721","3725","3723","3724","3722","3720"],

    "Mahalchhari":["4430"],
    "Manikchhari":["4460"],
    "Matiranga":["4450"],
    "Panchhari":["4410"],

    "Kutubdia":["4720"],
    "Ramu":["4730"],
    "Teknaf":["4761","4762","4760"],
    "Ukhia":["4750"],

    "Barura":["3560","3562","3561"],
    "Brahmanpara":["3526"],
    "Chandina":["3510","3511"],
    "Daudkandi":["3518","3516","3519","3517"],
    "Homna":["3546"],
    "Comilla Sadar":["3501","3500","3503","3502","3504"],
    "Laksam":["3572","3570","3571"],
    "Muradnagar":["3543","3542","3540","3545","3541","3544"],

    "Boalkhali":["4366","4369","4365","4368","4363","4367","4364"],
    "Fatikchhari":["4352","4350","4354","4353","4351","4355"],
    "Hathazari":["4331","4335","4332","4330","4333","4339","4334","4337","4338"],
    "Lohagara":["4398","4396","4397"],
    "Mirsharai":["4321","4325","4323","4322","4324","4327","4320","4328"],
    "Patiya":["4371","4370"],
    "Rangunia":["4361","4360"],
    "Sandwip":["4300","4301","4302"],
    "Satkania":["4387","4388","4386"],
    "Sitakunda":["4312","4311","4313","4315","4316","4317","4314","4310"],
    "Anowara":["4376","4378","4377"],
    "Chittagong Sadar":["4221","4211","4215","4210"],
    "Bayezid Bostami":["4210"],
    "Chandgaon":["4212"],
    "Chawkbazar":["4203"],
    "Chittagong Cantonment":["4220"],
    "Chittagong Airport":["4205"],
    "Chittagong Bandar":["4100"],
    "Chittagong GPO":["4000"],
    "Firozshah":["4207"],
    "Halishahar":["4207","4216","4225"],
    "Patenga":["4204","4222"],

    "Chandpur Sadar":["3602","3600","3601","3603"],
    "Faridganj":["3651","3650","3653","3655","3654","3652"],
    "Shahrasti":["3623","3624","3621","3622","3620"],

    "Nasirnagar":["3441","3440"],
    "Nabinagar":["3419","3417","3411","3410","3412","3414","3418","3415","3413"],
    "Sarail":["3432","3430","3431"],
    "Kasba":["3462","3463","3464","3460","3461"],
    "Akhaura":["3450","3451","3452"],

    "Bhandaria":["8550","8552","8551"],
    "Mathbaria":["8565","8563","8562","8560","8566","8564","8561"],
    "Nazirpur":["8540","8541"],
    "Pirojpur Sadar":["8501","8502","8500"],

    "Bauphal":["8621","8620","8622","8624","8623"],
    "Dashmina":["8630"],
    "Galachipa":["8640","8641"],

    "Kathalia":["8431","8430","8432","8433"],
    "Rajapur":["8410"],

    "Bhola Sadar":["8300","8301"],

    "Muladi":["8252","8251","8250"],
    "Bakerganj":["8205","8204","8202","8203"],
    "Babuganj":["8210","8216","8212","8213","8215","8211","8214"],
    "Barisal Sadar":["8200","8201","8206","8205","8204","8202","8203"],
    "Barishal City Corporation":["8200","8201","8206","8205","8204","8202","8203"],
    "Bukhainagar":["8201"],
    "Saheberhat":["8202"],
    "Sugandia":["8203"],
    "Patang":["8204"],
    "Kashipur":["8205"],
    "Jaguarhat":["8206"],
    "Banaripara":["8530","8531"],
    "Wazirpur":["8216","8212","8213"],
    "Agailjhara":["8205","8204","8202","8203"],

    "Badarganj":["5430","5431"],
    "Mithapukur":["5460"],
    "Gangachara":["5410"],
    "Kaunia":["5441","5440"],
    "Rangpur Sadar":["5402","5403","5404","5405","5400","5401"],
    "Pirgachha":["5450"],
    "Taraganj":["5420"],

    "Boda":["5010"],
    "Tetulia":["5030"],

    "Nilphamari Sadar":["5300","5301"],
    "Jaldhaka":["5330"],
    "Domar":["5341","5340"],
    "Dimla":["5350","5351"],

    "Aditmari":["5510"],
    "Hatibandha":["5530"],
    "Patgram":["5541","5542","5540"],

    "Kurigram Sadar":["5600","5601","5680"],
    "Bhurungamari":["5670"],
    "Phulbari":["5260"],
    "Rajarhat":["5611","5610"],
    "Ulipur":["5621","5620"],
    "Chilmari":["5630","5631"],

    "Gaibandha sadar":["5700"],
    "Gobindaganj":["5740","5741"],
    "Palashbari":["5730"],
    "Sundarganj":["5721","5720"],

    "Birampur":["5266"],
    "Birganj":["5220"],
    "Biral":["5210"],
    "Khansama":["5230","5231"],
    "Dinajpur Sadar":["5201","5200"],
    "Parbatipur":["5250"],

    "Sirajganj Sadar":["6701","6702","6700"],
    "Belkuchi":["6740","6751","6742","6741","6752"],
    "Kazipur":["6712","6710","6711"],
    "Shahjadpur":["6772","6773","6771","6770"],
    "Tarash":["6780"],

    "Bagha":["6281","6280"],
    "Charghat":["6270","6271"],
    "Durgapur":["6240"],
    "Godagari":["6290","6291"],

    "Bera":["6680","6682","6681","6683"],
    "Bhangura":["6640"],
    "Chatmohar":["6630"],
    "Ishwardi":["6621","6620","6622","6623"],
    "Pabna Sadar":["6602","6601","6600"],
    "Sujanagar":["6661","6660"],

    "Bholahat":["6330"],
    "Shibganj":["5810"],

    "Natore Sadar":["6402","6401","6403","6400","6402","6401","6403","6400"],

    "Naogaon Sadar":["6500"],
    "Niamatpur":["6520"],
    "Raninagar":["6591","6590"],
    "Patnitala":["6540"],
    "Sapahar":["6561","6560"],
    "Badalgachhi":["6570"],

    "Akkelpur":["5940","5941","5942"],
    "Kalai":["5930"],
    "Khetlal":["5920"],
    "Panchbibi":["5910"],

    "Bogra Sadar":["5801","5800"],
    "Sherpur":["5841","5842","5840"],
    "Dhunat":["5850","5851"],
    "Nandigram":["5860"],
    "Sariakandi":["5831","5830"],

    "Tangail Sadar":["1901","1903","1904","1902","1900"],
    "Sakhipur":["1951","1950"],
    "Basail":["1920"],
    "Madhupur":["1997","1996"],
    "Ghatail":["1982","1983","1980","1984","1981"],
    "Kalihati":["1973","1974","1970","1977","1976","1972","1975","1971"],
    "Nagarpur":["1937","1936","1938"],
    "Mirzapur":["1941","1944","1942","1940","1945","1943"],
    "Gopalpur":["1990","1992","1991"],
    "Delduar":["1910","1913","1914","1911","1915","1912"],
    "Bhuapur":["1960"],

    "Nakla":["2151","2150"],
    "Nalitabari":["2111","2110"],

    "Naria":["8021","8022","8024","8020","8023"],
    "Jajira":["8010"],
    "Gosairhat":["8050"],

    "Baliakandi":["7730","7731"],
    "Pangsha":["7723","7720","7721","7722"],
    "Rajbari Sadar":["7710","7711","7700"],

    "Kendua":["2480"],
    "Atpara":["2470"],
    "Barhatta":["2440"],
    "Kalmakanda":["2430"],
    "Madan":["2490"],
    "Mohanganj":["2446"],
    "Khaliajuri":["2460","2462"],

    "Narsingdi Sador":["1600"],
    "Belabo":["1640"],
    "Palash":["1612","1613","1611","1610"],
    "Raypura":["1631","1632","1630"],
    "Shibpur":["1620"],

    "Araihazar":["1450","1451"],
    "Bandar":["1410","1413","1411","1414","1412"],
    "Rupganj":["1462","1461","1464","1463","1460"],

    "Bhaluka":["2240"],
    "Trishal":["2221","2223","2222","2220"],
    "Haluaghat":["2261","2260","2262"],
    "Muktagachha":["2210"],
    "Dhobaura":["2416","2417"],
    "Fulbaria":["2216"],
    "Mymensingh Sadar":["2202","2204","2201","2200","2205","2203"],
    "Nandail":["2291","2290"],
    "Phulpur":["2251","2250","2252"],

    "Sirajdikhan":["1542","1541","1543","1544","1540"],

    "Manikganj Sadar":["1804","1802","1803","1801","1800"],
    "Saturia":["1811","1810"],

    "Madaripur Sadar":["7901","7903","7902","7900","7904"],
    "Kalkini":["7920","7921"],
    "Rajoir":["7911","7910"],

    "Bajitpur":["2336","2338","2337"],
    "Itna":["2390"],
    "Karimganj":["2310"],
    "Katiadi":["2331","2330"],
    "Kuliarchar":["2341","2340"],
    "Nikli":["2360"],
    "Pakundia":["2326"],

    "Islampur":["2021","2022","2020"],

    "Gopalganj Sadar":["8102","8013","8100","8101"],
    "Kashiani":["8133","8130","8131","8132"],
    "Kotalipara":["8110"],
    "Tungipara":["8121","8120"],

    "Sripur":["1741","1742"],
    "Faridpur Sadar":["7802","7803","7800","7801"],
    "Boalmari":["7860","7861"],
    "Alfadanga":["7870"],
    "Bhanga":["7830"],
    "Nagarkanda":["7840","7841"],
    "Charbhadrasan":["7810"],
    "Sadarpur":["7822","7821","7820"]
}