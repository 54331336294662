import axios from "axios";
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import Layout from "../layout/Layout";

import { Alert, Badge } from "react-bootstrap";
import { AuthGuardian, authGuardianValidate } from "../../utils/GuardianAuth";
import { authValidate } from "../../utils/TutionAuth";
import { msisdnValidate } from "../../utils/Validation";
// import TuitionSuggestion from "../common/TuitionSuggestion";
import { useCallback, useEffect } from "react";
import GDashboard from "./GDashboard";
import { authMediaValidate } from "../../utils/MediumAuth";
import { media } from "../../route";
const GSignUp = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [inputData,setInputData] = useState({
        msisdn : "",
        otp_code: "",
        g_name: "",
        g_email: "",
        g_msisdn: "",
        g_photo: "",
        g_gender: "",
        g_login_by: ""
    })
    const [isOtpForm,setIsOtpForm] = useState(false);
    const [otpData,setOtpData] = useState('');
    const [resMsg,setResMsg] = useState("");
    const [isShowMsg,setIsShowMsg] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [timer, setTimer] = useState(0);

    const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
  

    useEffect(() => {
        let time;
        if( timer > 0 ){
            time = setTimeout(timeOutCallback, 1000);
        } 
        return ()=>{
          clearTimeout(time);
        }
  
    }, [ timer, timeOutCallback]);
  

    const onShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    };

    const history = useHistory();

    const handleOnChange = (e) => {
        setResMsg("");
        setIsShowMsg(false);

        setInputData({
            ...inputData,
            [e.target.name]: e.target.value
        });
    }

    const isGuardianExists = (e) => {
        e.preventDefault();

        if (!msisdnValidate(inputData.g_msisdn)) {
            setResMsg("Please enter valid mobile number.");
            setIsShowMsg(true);
            return false;
        }

        //check_exist

        const existData = JSON.stringify({"g_msisdn": inputData.g_msisdn});

        const existConfig = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/is_guardian_exists`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: existData
        };

        axios(existConfig)
            .then(function (response) {
                if(response.data.success) {
                    setIsShowMsg(true)
                    setResMsg(response.data.message)
                    return true;
                } else {
                    onSubmitMsisdn();
                }
            })
            .catch(function (error) {
                setIsShowMsg(true)
                setResMsg(error.response.data.message)
            });

        //end_check_exist
    }

    const onSubmitMsisdn = () => {

        if (!msisdnValidate(inputData.g_msisdn)) {
            setResMsg("Please enter valid mobile number.");
            setIsShowMsg(true);
            return false;
        }

        const data = JSON.stringify({"msisdn": inputData.g_msisdn});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp_send',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                if(response.data.success) {
                    setResMsg("")
                    setIsShowMsg(false)
                    setOtpData(response.data.data.secret_key)
                    setIsOtpForm(true)
                    setIsShowMsg(false);
                    setTimer(60*2)
                } else {
                    setIsShowMsg(true)
                    setResMsg(response.data.message)
                }
            })
            .catch(error=> {
                // setIsShowMsg(true)
                setResMsg("Invalid Mobile Number")
                console.log(error);
            });
    }
    const onSubmitVerifyOTP = (e) => {
        e.preventDefault()

        const data = JSON.stringify({
            "msisdn": inputData.g_msisdn,
            "secret_key": inputData.otp_code
        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp/verification',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                if(response.data.success){
                    onGuardianSignupSubmit()
                } else {
                    setIsShowMsg(true)
                    setResMsg(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onGuardianSignupSubmit = () => {

        const data = JSON.stringify({
            "g_name": inputData.g_name,
            "g_email": "",
            "g_msisdn": inputData.g_msisdn,
            "g_password": inputData.g_password,
            "g_photo": "",
            "g_gender": inputData.g_gender,
            "g_login_by": 'manually'
        });

        if(!msisdnValidate(inputData.g_msisdn)){
            setResMsg("Enter Valid Phone Number.");
            setIsShowMsg(true);
            return false;
        }

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/guardian_signup',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                if(response.data.success){
                    localStorage.setItem('guardianLogin',JSON.stringify(response.data.data));
                    history.push("/guardian/dashboard");
                } else {
                    setResMsg(response.data.message);
                    setIsShowMsg(true);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onReSendOtp = (e) => {
        e.preventDefault();
        const data = JSON.stringify({ msisdn: inputData.g_msisdn });
    
        const config = {
          method: "post",
          url: process.env.REACT_APP_API_URL + "/api/web/v1/otp_resend",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
          },
          data: data,
        };
    
        axios(config)
          .then(function (response) {
            setIsShowMsg(true);
            setResMsg("OTP Resent");
            setTimer(60*2)
          })
          .catch((error) => {
            setIsShowMsg(true);
            setResMsg("Something went wrong!");
            console.log(error);
          });
      };

    const onCloseMsg = () => {
        setIsShowMsg(!isShowMsg);
    }

    if(authGuardianValidate()){
        history.push("/guardian/dashboard");
    }

    if(authValidate()){
        history.push("/tutor/dashboard");
    }

    if (authMediaValidate()) {
        history.push(`${media}/dashboard`);
    }

    return (
        <Layout>

            {AuthGuardian()?
                <GDashboard/>
                :

                <div className="guardianLoginWrap">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <div className="formWrap">
                                    <div className="formInner">
                                        <div className="formCenter">


                                            <h3 className="text-center">Guardian Sign up</h3>
                                            <>
                                                {isShowMsg ?
                                                    <Alert variant="danger" onClick={onCloseMsg} dismissible>
                                                        {resMsg}
                                                    </Alert>
                                                    :
                                                    <></>
                                                }
                                            </>

                                            {isOtpForm ?

                                                <>
                                                    {timer > 0 &&
                                                        <center className="mb-2">
                                                            <Badge bg="danger" className="px-4 py-2 m-auto"> {Math.floor(timer / 60)}:{timer - Math.floor(timer / 60) * 60}</Badge>
                                                       </center>
                                                    }
                                                    <p className="mb-2">
                                                        Verification OTP sent to {inputData.g_msisdn}.
                                                    </p>

                                                    <form onSubmit={onSubmitVerifyOTP}>
                                                        <div className="form-group">
                                                            <input type="number"
                                                                   name="otp_code"
                                                                   className="form-control form-control-lg"
                                                                   id="otp_code"
                                                                   onChange={handleOnChange}
                                                                   aria-describedby="otp_verify"
                                                                   placeholder="Verification OTP"
                                                                   required/>
                                                        </div>
                                                        <center className="">
                                                            <button className="btn btn-md otp-button">
                                                                Verify OTP
                                                                <i className="fa fa-send"></i>
                                                            </button>
                                                        </center>
                                                        {timer === 0 &&
                                                                <div className="text-center">
                                                                    Didn't receive your OTP?{" "}
                                                                    <Link onClick={onReSendOtp}>Resend OTP</Link>
                                                                </div>
                                                        } 
                                                    </form>

                                                </>

                                                :

                                                <form onSubmit={isGuardianExists}>

                                                    <div className="form-group">
                                                        <label htmlFor="g_name">Name</label>
                                                        <input type="text"
                                                               name="g_name"
                                                               className="form-control"
                                                               id="g_name"
                                                               onChange={handleOnChange}
                                                               placeholder="Name..."
                                                               required={true}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="mobile_number">Mobile Number</label>
                                                        <input type="number"
                                                               name="g_msisdn"
                                                               className="form-control"
                                                               id="mobile_number"
                                                               onChange={handleOnChange}
                                                               placeholder="Mobile Number..."
                                                               required={true}
                                                        />
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Select Gender:</label>
                                                        <select class="form-select form-select-sm" name="g_gender" aria-label=".form-select-sm example" onChange={handleOnChange}>
                                                            <option value="">Select gender</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                        </select>
                                                        <div class="text-danger"></div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">
                                                        Password
                                                        </label>
                                                        <div className="loginInput">
                                                        <input
                                                            type={isShowPassword ? "text" : "password"}
                                                            name="g_password"
                                                            onChange={handleOnChange}
                                                            className="form-control"
                                                            id="exampleInputPassword1"
                                                            placeholder="Password"
                                                            required={true}
                                                        />
                                                        <i
                                                            onClick={onShowPassword}
                                                            className={
                                                            isShowPassword
                                                                ? "fa fa-eye"
                                                                : "fa fa-eye-slash"
                                                            }
                                                        ></i>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary form-control">Signup</button>

                                                    <div className="pt-2 text-center">
                                                        Already have an account?
                                                        <Link to="/guardian/login"
                                                              className="form-check-label"
                                                              htmlFor="exampleCheck1">
                                                            {"  "} Sign In {" "}
                                                        </Link>
                                                    </div>

                                                </form>

                                            }




                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <TuitionSuggestion/> */}

                        </div>
                    </div>
                </div>

            }


        </Layout>
    );
};

export default GSignUp;