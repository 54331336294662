import axios from "axios";
import React, { useState } from 'react';
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ChangePassword from "./forgetpassword/ChangePassword";
import ForgetPassword from "./forgetpassword/ForgetPassword";
import VerifyOtp from "./forgetpassword/VerifyOtp";

const TuitionRequestLogin = ({handleSubmitRequest}) => {

    const [inputData,setInputData] = useState({
        msisdn : "",
        otp_code: "",
        g_name: "",
        g_email: "",
        g_msisdn: "",
        g_password: "",
        g_photo: "",
        g_login_by: ""
    })

    const [resMsg, setResMsg] = useState("");
    const [forgetType,setForgetType] = useState('');
    const [isShowMsg, setIsShowMsg] = useState(false);

    const handleOnChange = (e) => {
        setResMsg("");
        setIsShowMsg(false);

        setInputData({
            ...inputData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmitLogin = (e) => {
        e.preventDefault();

        const data = JSON.stringify({"g_msisdn": inputData.g_msisdn, "g_password": inputData.g_password});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/guardian_login`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if(response.data.success) {
                    toast(response.data.message)
                    localStorage.clear();
                    localStorage.setItem('guardianLogin', JSON.stringify(response.data.data));

                    handleSubmitRequest();

                } else {
                    setIsShowMsg(true)
                    setResMsg(response.data.message)
                }
            })
            .catch(function (error) {
                setIsShowMsg(true)
                setResMsg(error.response.data.message)
            });


    }

    const onCloseMsg = () => {
        setIsShowMsg(!isShowMsg);
    }

    const onHandleForgetPassword = (type) => {
        setForgetType(type);
    }

    return (
        <>

            <div className="formWrap">
                    <div className="formInner">
                        <div className="formCenter">

                            {forgetType == 'ForgetPassword' ?

                                <ForgetPassword onHandleForgetPassword={onHandleForgetPassword}/>

                                :

                                <>
                                    {forgetType == 'OTP-VERIFY' ?

                                        <VerifyOtp onHandleForgetPassword={onHandleForgetPassword}/>

                                        :

                                        <>
                                            {forgetType == 'CHANGE-PASSWORD' ?

                                                <ChangePassword handleSubmitRequest={handleSubmitRequest}/>

                                                :

                                                <>
                                                    <h5 className="text-center">
                                                        You are already a registered user.Please login.
                                                    </h5>

                                                    {isShowMsg ?
                                                        <Alert variant="danger" onClick={onCloseMsg} dismissible>
                                                            {resMsg}
                                                        </Alert>
                                                        :
                                                        <></>
                                                    }


                                                    <form onSubmit={handleSubmitLogin}>
                                                        <div className="form-group">
                                                            <label htmlFor="mobile_number">Mobile Number</label>
                                                            <input type="number"
                                                                   name="g_msisdn"
                                                                   className="form-control"
                                                                   onChange={handleOnChange}
                                                                   id="mobile_number"
                                                                   placeholder="Mobile Number..."
                                                                   required={true}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputPassword1">Password</label>
                                                            <input type="password"
                                                                   name="g_password"
                                                                   className="form-control"
                                                                   onChange={handleOnChange}
                                                                   id="exampleInputPassword1"
                                                                   placeholder="Password"/>
                                                        </div>
                                                        <div className="form-group d-flex justify-content-between">
                                                            <div className="rem">
                                                                <div className="remItem">
                                                                    <label htmlFor="rem">
                                                                        <input type="checkbox" id='rem'/> Remember me
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <p>
                                                                <Link  onClick={()=>{
                                                                    onHandleForgetPassword('ForgetPassword');
                                                                }} className="form-check-label"
                                                                      htmlFor="exampleCheck1">Forgot Password?</Link>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-primary form-control">Login
                                                            </button>
                                                        </div>
                                                    </form>

                                                </>

                                            }
                                        </>

                                    }
                                </>


                            }



                        </div>
                    </div>
                </div>

        </>
    );
};

export default TuitionRequestLogin;