import React, {useRef, useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import ClientCaptcha from "react-client-captcha";

const Captcha = (props) => {
    const [code, setCode] = useState(null);

    const onCaptchaChange = (e) => {
        if (code && code === e.target.value) {
            props.onChangeHandleCaptch(true);
        } else {
            props.onChangeHandleCaptch(false);
        }
    }
    return (
        <div className="captcha-wrapper">
            <ClientCaptcha
                captchaCode={code => setCode(code)}
                charsCount={5}
                fontColor="#222"
                fontFamily="Arial, Tahoma"
                width={300}
                retryIconSize={12}
                backgroundColor="#F3F9FF"
                chars="abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
                height={30}
            />
            <input type="text"
                   maxLength="6"
                   className="form-control"
                   placeholder="Type The Character Above..."
                   onChange={onCaptchaChange} />
        </div>
    );
};

export default Captcha;