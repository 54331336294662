import React from 'react';
import add from '../../frontend/img/add.png'
import addmyke from '../../frontend/img/addmyke.png'
import {home} from "../../route";

const TutorsAdvetisement = () => {
    return (

            <div className="advertisement">
                <div>
                    <img src={add} className="img-fluid" alt="Responsive-Image"/>
                    <div>
                        <a className="buttonAddWrap" href={home}>Contact us</a>
                    </div>
                </div>
                <div>
                    <img src={addmyke} className="img-fluid" alt="Responsive-Image"/>
                </div>
            </div>
    );
};

export default TutorsAdvetisement;