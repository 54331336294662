import axios from "axios";
import React, { useCallback, useEffect, useState } from 'react';
import { tutor } from "../../route";
import { subject_en } from "../../utils/medium_data/subject3";
import { useGetPersonalInfoQuery } from "../../features/tutor/tutorApi";
import { Auth } from '../../utils/TutionAuth';
const TuitionInfo = () => {

    const { data, isError, error } = useGetPersonalInfoQuery({id:Auth().uuid}) || {};

    const [tuitionInfo,setTuitionInfo] = useState([]);
    const [subjects,setSubjects] = useState([])

    useEffect(()=>{
        setTuitionInfo(data?.data);

        if(data?.data.t_preferred_class_id.length > 0){
            setSubjects(findSubjects(JSON.parse(data?.data.t_preferred_class_id)))
        } 
    },[data?.data])


    const findSubjects = (classIDArr) => {
        const subjectsArr = [];
        classIDArr.map((classID) => {
            subject_en[classID].map((subject) => {
                if(data?.data.t_preferred_subjects.includes(subject.value)){
                    subjectsArr.push(subject.title)
                }
            })
        })
        return subjectsArr;
    }

    return (
        <>
        {tuitionInfo&&
            <div className="TuitionInfoItem">
            <div className="col-12">
                <div className="tuitionInfoInner">
                    <h2 className="Tuition-InfoText">Tuition-Info</h2>
                        <div className="TuitionsEditButton">
                            <a href={`${tutor}/tuition-info/edit`} className="EditSolidButton AddEditGlobBtn">
                                <i className='fa fa-edit'></i>
                                <span className="EditTExt"> Edit</span>
                            </a>
                        </div>

                </div>
                <div className="tuitionSalaryInner">
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Expected Minimum Salary
                        </label>
                    </div>
                    <p className="MonthText">
                        {data?.data.t_salary ?? '0'}  tk/month
                        <span className="NegTaiableText">
                            {data?.data.t_is_Negotiable ?  ': Negotiable' : ''}
                        </span>
                    </p>
                </div>
                <div className="tuitionInner">
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Days in Week
                        </label>
                    </div>
                    <p className="WeekText">
                        {`${tuitionInfo.t_days_per_week}`
                            .replace(/\[|\]|"/g,'')
                        }
                    </p>
                </div>
                <div className="tuitionInner">
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Preferred Tutoring Style
                        </label>
                    </div>
                    <p className="PrivateText">
                        {`${tuitionInfo.t_preferred_tuition}`
                            .replace(/\[|\]|"/g,'')
                        }
                    </p>
                </div>
                <div className="tuitionInner">
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Place of Learning
                        </label>
                    </div>
                    <p className="VisitPara">
                        {`${tuitionInfo.t_place_of_learning}`
                            .replace(/\[|\]|"/g,'')
                        }
                    </p>
                </div>
                <div className="tuitionInner">
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Extra Facilities
                        </label>
                    </div>
                    <p className="ProvideText">
                        {`${tuitionInfo.t_extra_facilities}`
                            .replace(/\[|\]|"/g,'')
                        }
                    </p>
                </div>
                <div className="tuitionInner">
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Preferred Medium of Education
                        </label>
                    </div>
                    <p className="MediumPara">
                        {`${tuitionInfo.t_preferred_medium_of_Eduction}`
                            .replace(/\[|\]|"/g,'')
                        }
                    </p>
                </div>
                <div className="tuitionInner">
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Preferred Subjects
                        </label>
                    </div>
                    <p className="SubjectText">
                        {
                             subjects && subjects.map((subject) => (
                                <span>{ subject + ", " }</span>
                            ))
                        }
                    </p>
                </div>
                <div className="tuitionInner">
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Preferred District to Teach
                        </label>
                        
                    </div>
                    <p className="Edit">
                        {`${tuitionInfo.t_preferred_district_to_teach}`
                            .replace(/\[|\]|"/g,'')
                        }
                    </p>
                </div>
                <div className="tuitionInner">
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Preferred Areas to Teach
                        </label>
                    </div>
                    <p className="Edit">
                        {`${tuitionInfo.t_preferred_area_to_teach}`
                            .replace(/\[|\]|"/g,'')
                        }
                    </p>
                </div>
            </div>
           </div>
        }


        </>
    );
};

export default TuitionInfo;