import React, { useEffect, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { getMonth, getYear } from "../../utils/Common";

import axios from "axios";
import { toast } from "react-toastify";
import Biman from "../../frontend/img/default_logo.png";

const TutorExperience = ({ user_data }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const [allExperience, setAllExperience] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [isExpEdit, setIsExpEdit] = useState(false);
  const [isShowEnd, setisShowEnd] = useState(false);
  const [editData, setEditData] = useState([]);

  const [isShowError, setIsShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getAllExperience();
  }, []);

  const getAllExperience = () => {
    const config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `/api/web/v1/get_all_experience/${user_data.uuid}`,
      headers: {
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
    };

    axios(config)
      .then((response) => {
        setAllExperience(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleOnAdd = () => {
    setIsShowError(false);
    setIsAdd(!isAdd);
    setisShowEnd(false)
    reset();
  };

  const handleOnExpEditClose = () => {
    setIsExpEdit(!isExpEdit);
    setIsShowError(false);
  };

  const handleIsCurrentWork = (e) => {
    if (e.target.value) {
      setisShowEnd(!isShowEnd);
    }
  };

  const onExperienceInfoSubmit = (e) => {
    setIsShowError(false);
    if (!e.is_current) {
      if (parseInt(e.start_date) > parseInt(e.end_date)) {
        toast("End year must be greater than start year!!!");
        setErrorMsg("End year must be greater than start year!!!");
        setIsShowError(true);
        return;
      }
    }

    const data = JSON.stringify({
      tutors_id: user_data.uuid,
      title: e.title,
      employment_type: e.employment_type,
      company_name: e.company_name,
      location: e.location,
      start_date: e.start_date,
      start_month: e.start_month,
      end_date: e.end_date,
      end_month: e.end_month,
      is_current: e.is_current ? 1 : 0,
      description: e.description,
    });
    console.log(data);
    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/insert_experience",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        toast(response.data.message);
        getAllExperience();
        setIsAdd(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleOnExpEdit = (e) => {
    //alert(e);
    const config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + `/api/web/v1/edit_experience/${e}`,
      headers: {
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setEditData(response.data.data);
        setValue("id", response.data.data.id);
        setValue("title", response.data.data.title);
        setValue("company_name", response.data.data.company_name);
        setValue("location", response.data.data.location);
        setValue("start_date", response.data.data.start_date);
        setValue("start_month", response.data.data.start_month);
        setValue("end_date", response.data.data.end_date);
        setValue("end_month", response.data.data.end_month);
        setValue("is_current", response.data.data.is_current);
        if (response.data.data.is_current) {
          setisShowEnd(true);
        } else {
          setisShowEnd(false);
        }
        setValue("description", response.data.data.description);
        handleOnExpEditClose(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleOnExpUpdate = (e) => {

    setIsShowError(false);
    if (!e.is_current) {
      if (e.start_date >= e.end_date) {
        toast("End year must be greater than start year!!!");
        setErrorMsg("End year must be greater than start year!!!");
        setIsShowError(true);
        return;
      }
    }


    const data = JSON.stringify({
      id: e.id,
      tutors_experience_id:editData.tutors_experience_id,
      title: e.title,
      employment_type: e.employment_type,
      company_name: e.company_name,
      location: e.location,
      start_date: e.start_date,
      start_month: e.start_month,
      end_date: e.end_date,
      end_month: e.end_month,
      is_current: e.is_current ? 1 : 0,
      description: e.description,
    });


    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/update_experience",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        getAllExperience();
        setIsExpEdit(false);
        toast(response.data.message);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteExperience = (e) => {
    setIsShowError(false);
    const config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + `/api/web/v1/delete_experience/${e}`,
      headers: {
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        getAllExperience();
        setIsExpEdit(false);
        toast(response.data.message);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onCloseMsg = () => {
    setIsShowError(false);
  };

  const reset = () => {
    setValue("title", "");
    setValue("company_name", "");
    setValue("start_month", "");
    setValue("start_date", "");
    setValue("end_month", "");
    setValue("end_date", "");
    setValue("location", "");
    setValue("is_current", "");
    setValue("description", "");
  };

  return (
    <>
      <div className="ExperienceProfileWrap EducationWrap">
        <div className="col-12">
          <div className="tuitionInfoInner">
            <h2 className="Tuition-InfoText">Experience</h2>
            {/*Button trigger modal*/}
            <div className="EditButtonTwo_Wrap">
              <a
                href="javascript:void(0);"
                className="EditSolidButton AddEditGlobBtn"
                onClick={handleOnAdd}
              >
                <i className="fa fa-lg fa-plus"></i>
                <p className="EditTExt "></p>
              </a>
            </div>
          </div>
          {allExperience &&
            allExperience.map((experience, index) => {
              return (
                <>
                  <div className="AcademyContactWrap">
                    <img
                      src={Biman}
                      className="img-fluid"
                      alt="Responsive"
                    />
                    <div className="ProfileTextInner">
                      <div>
                        <h3 className="ProfileName">
                          {experience.title} at {experience.company_name}
                        </h3>
                        <span className="YearText">
                          { experience.start_month?.substr(0,3) } { experience.start_month &&' ' } {experience.start_date}
                          {experience.is_current ? (
                            <span> { ' - ' } Present </span>
                          ) : (
                            <span className="">
                            { '  -  ' + experience.end_month?.substr(0,3) } { experience.end_month && ' '}{experience.end_date}
                            </span>
                          )}
                        </span>
                        <p className="Designation">{experience.description}</p>
                      </div>
                      <div>
                        
                        <a
                          href="javascript:void(0);"
                          className="EditSolidButton AddEditGlobBtn"
                          onClick={() => {
                            handleOnExpEdit(experience.tutors_experience_id);
                          }}
                        >
                          <i className="fa fa-lg fa-edit"></i>
                          <span className="EditTExt"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>

        {/*  Edit Model   */}
        <Modal
          show={isExpEdit}
          onHide={handleOnExpEditClose}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <form onSubmit={handleSubmit(handleOnExpUpdate)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit experience</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isShowError ? (
                <Alert variant="danger" onClick={onCloseMsg} dismissible>
                  {errorMsg}
                </Alert>
              ) : (
                <></>
              )}
              {/*    form*/}
              <span className="text-danger">*</span> Indicates required
              <div className="form-row">
                <div className="row">
                  {/*Institute Name*/}
                  <div className="form-group col-md-12 pb-2">
                    <label htmlFor="name">
                      Title
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="title"
                      {...register("title", { required: true })}
                      className="form-control"
                      id="title"
                      placeholder="Ex: Retail Sales Manager."
                    />
                    <span className="text-danger">
                      {errors.title && (
                        <span>
                          <i className="fa fa-minus-circle"></i>
                          Title is a required field
                        </span>
                      )}
                    </span>
                  </div>
                  {/*end Institute Name*/}
                  {/*Company Name*/}
                  <div className="form-group col-md-12 pb-2">
                    <label htmlFor="company_name">
                      Company name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="company_name"
                      {...register("company_name", { required: true })}
                      className="form-control"
                      id="company_name"
                      placeholder="Ex: Microsoft"
                    />
                    <span className="text-danger">
                      {errors.company_name && (
                        <span>
                          <i className="fa fa-minus-circle"></i>
                          Company name is a required field
                        </span>
                      )}
                    </span>
                  </div>
                  {/*end Company Name*/}
                  {/*Company Name*/}
                  <div className="form-group col-md-12 pb-2">
                    <label htmlFor="location">
                      Location
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="location"
                      {...register("location", { required: true })}
                      className="form-control"
                      id="location"
                      placeholder="Ex: London, United Kingdom"
                    />
                    <span className="text-danger">
                      {errors.location && (
                        <span>
                          <i className="fa fa-minus-circle"></i>
                          Location is a required field
                        </span>
                      )}
                    </span>
                  </div>
                  {/*end Company Name*/}
                  {/*I am currently work in  hear*/}
                  <div className="form-group col-md-12 pb-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="is_current"
                        {...register("is_current")}
                        onChange={handleIsCurrentWork}
                        type="checkbox"
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        I am currently working in this role.
                      </label>
                    </div>
                  </div>
                  {/*end I am currently work in  hear*/}
                  {/*Start Date*/}
                  <div className="form-group col-md-6 pb-2">
                    <label htmlFor="start_year">
                      Start date
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      name="start_month"
                      {...register("start_month")}
                      className="form-control"
                    >
                      <option selected value={''}>Months</option>
                      {getMonth().map((month, index) => {
                        return <option value={month.name}>{month.name}</option>;
                      })}
                    </select>
                    <span className="text-danger">
                      {errors.start_month && (
                        <span>
                          <i className="fa fa-minus-circle"></i>
                          This is a required field
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="form-group col-md-6 pb-2">
                    <label htmlFor="start_date"></label>
                    <select
                      id="inputState"
                      name="start_date"
                      {...register("start_date")}
                      className="form-control"
                    >
                      <option selected value={''}>Year</option>
                      {getYear().map((year, index) => {
                        return <option value={year}>{year}</option>;
                      })}
                    </select>
                    <span className="text-danger">
                      {errors.start_date && (
                        <span>
                          <i className="fa fa-minus-circle"></i>
                          This is a required field
                        </span>
                      )}
                    </span>
                  </div>
                  {/*end Start Date*/}
                  {/*end Date*/}
                  <div className="form-group col-md-6 pb-2">
                    <label htmlFor="passing_month">
                      End date (or expected)
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      name="end_month"
                      {...register("end_month")}
                      className="form-control"
                      disabled={isShowEnd}
                    >
                      <option selected value={''}>Months</option>
                      {getMonth().map((month, index) => {
                        return <option value={month.name}>{month.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className="form-group col-md-6 pb-2">
                    <label htmlFor="end_date"></label>
                    <select
                      id="inputState"
                      name="end_date"
                      {...register("end_date")}
                      className="form-control"
                      disabled={isShowEnd}
                    >
                      <option selected value={''}>Year</option>
                      {getYear().map((year, index) => {
                        return <option value={year}>{year}</option>;
                      })}
                    </select>
                    <span className="text-danger">
                      {errors.end_date && (
                        <span>
                          <i className="fa fa-minus-circle"></i>
                          This is a required field
                        </span>
                      )}
                    </span>
                  </div>
                  {/*end End Date*/}
                  {/*description*/}
                  <div className="form-group col-md-12 pb-2">
                    <label htmlFor="description">Description</label>
                    <textarea
                      name="description"
                      {...register("description")}
                      className="form-control"
                      id="description"
                      maxLength="350"
                    ></textarea>
                    <span className="text-red">Character Limit: 350</span>
                  </div>
                  {/*end description*/}
                </div>
              </div>
              {/*    end form */}
            </Modal.Body>
            <Modal.Footer>
              <a
                className="btn btn-md btn-outline-dark pull-left"
                onClick={() => {
                  deleteExperience(editData.id);
                }}
              >
                Delete Experience
              </a>
              <a
                className="btn btn-md btn-danger"
                onClick={handleOnExpEditClose}
              >
                Close
              </a>
              <button type="submit" className="btn btn-md btn-primary">
                Save
              </button>
            </Modal.Footer>
          </form>
        </Modal>
        {/*  end Edit Model   */}

        {/*add Experience*/}
        <Modal
          show={isAdd}
          onHide={handleOnAdd}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <form onSubmit={handleSubmit(onExperienceInfoSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>Add experience</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isShowError ? (
                <Alert variant="danger" onClick={onCloseMsg} dismissible>
                  {errorMsg}
                </Alert>
              ) : (
                <></>
              )}
              {/*    form*/}
              <span className="text-danger">*</span> Indicates required
              <div className="form-row">
                <div className="row">
                  {/*Institute Name*/}
                  <div className="form-group col-md-12 pb-2">
                    <label htmlFor="name">
                      Title
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="title"
                      {...register("title", { required: true })}
                      className="form-control"
                      id="title"
                      placeholder="Ex: Retail Sales Manager."
                    />
                    <span className="text-danger">
                      {errors.title && (
                        <span>
                          <i className="fa fa-minus-circle"></i>
                          Title is a required field
                        </span>
                      )}
                    </span>
                  </div>
                  {/*end Institute Name*/}
                  {/*Company Name*/}
                  <div className="form-group col-md-12 pb-2">
                    <label htmlFor="company_name">
                      Company name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="company_name"
                      {...register("company_name", { required: true })}
                      className="form-control"
                      id="company_name"
                      placeholder="Ex: Microsoft"
                    />
                    <span className="text-danger">
                      {errors.company_name && (
                        <span>
                          <i className="fa fa-minus-circle"></i>
                          Company name is a required field
                        </span>
                      )}
                    </span>
                  </div>
                  {/*end Company Name*/}
                  {/*Company Name*/}
                  <div className="form-group col-md-12 pb-2">
                    <label htmlFor="location">
                      Location
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="location"
                      {...register("location", { required: true })}
                      className="form-control"
                      id="location"
                      placeholder="Ex: London, United Kingdom"
                    />
                    <span className="text-danger">
                      {errors.location && (
                        <span>
                          <i className="fa fa-minus-circle"></i>
                          Location is a required field
                        </span>
                      )}
                    </span>
                  </div>
                  {/*end Company Name*/}
                  {/*I am currently work in  hear*/}
                  <div className="form-group col-md-12 pb-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="is_current"
                        {...register("is_current")}
                        onChange={handleIsCurrentWork}
                        type="checkbox"
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        I am currently working in this role.
                      </label>
                    </div>
                  </div>
                  {/*end I am currently work in  hear*/}
                  {/*Start Date*/}
                  <div className="form-group col-md-6 pb-2">
                    <label htmlFor="start_year">
                      Start date
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      name="start_month"
                      {...register("start_month")}
                      className="form-control"
                    >
                      <option selected value={''}>
                        Months
                      </option>
                      {getMonth().map((month, index) => {
                        return <option value={month.name}>{month.name}</option>;
                      })}
                    </select>
                    <span className="text-danger">
                      {errors.start_month && (
                        <span>
                          <i className="fa fa-minus-circle"></i>
                          This is a required field
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="form-group col-md-6 pb-2">
                    <label htmlFor="start_date"></label>
                    <select
                      id="inputState"
                      name="start_date"
                      {...register("start_date")}
                      className="form-control"
                    >
                      <option selected value={''}>
                        Year
                      </option>
                      {getYear().map((year, index) => {
                        return <option value={year}>{year}</option>;
                      })}
                    </select>
                    <span className="text-danger">
                      {errors.start_date && (
                        <span>
                          <i className="fa fa-minus-circle"></i>
                          This is a required field
                        </span>
                      )}
                    </span>
                  </div>
                  {/*end Start Date*/}
                  {/*end Date*/}
                  <div className="form-group col-md-6 pb-2">
                    <label htmlFor="passing_month">
                      End date (or expected)
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      name="end_month"
                      {...register("end_month")}
                      className="form-control"
                      disabled={isShowEnd}
                    >
                      <option selected value={''}>
                        Months
                      </option>
                      {getMonth().map((month, index) => {
                        return <option value={month.name}>{month.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className="form-group col-md-6 pb-2">
                    <label htmlFor="end_date"></label>
                    <select
                      id="inputState"
                      name="end_date"
                      {...register("end_date")}
                      className="form-control"
                      disabled={isShowEnd}
                    >
                      <option selected value={''}>
                        Year
                      </option>
                      {getYear().map((year, index) => {
                        return <option value={year}>{year}</option>;
                      })}
                    </select>
                    <span className="text-danger">
                      {errors.end_date && (
                        <span>
                          <i className="fa fa-minus-circle"></i>
                          This is a required field
                        </span>
                      )}
                    </span>
                  </div>
                  {/*end End Date*/}
                  {/*description*/}
                  <div className="form-group col-md-12 pb-2">
                    <label htmlFor="description">Description</label>
                    <textarea
                      name="description"
                      {...register("description")}
                      className="form-control"
                      id="description"
                      maxLength="350"
                    ></textarea>
                    <span className="text-red">Character Limit: 350</span>
                  </div>
                  {/*end description*/}
                </div>
              </div>
              {/*    end form */}
            </Modal.Body>
            <Modal.Footer>
              <a className="btn btn-md btn-danger" onClick={handleOnAdd}>
                Close
              </a>
              <button type="submit" className="btn btn-md btn-primary">
                Save
              </button>
            </Modal.Footer>
          </form>
        </Modal>
        {/*End add Experience*/}
      </div>
    </>
  );
};

export default TutorExperience;
