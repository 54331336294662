export function getLearningPlace() {
    const learning_place = [
        { id: 1, slug: "class_rooms",name: "Class Rooms"},
        { id: 2, slug: "coaching_center",name: "Coaching Center"},
        { id: 3, slug: "home_visit",name: "Home Visit"},
        { id: 4, slug: "my_place_Home",name: "My Place/Home"}
    ];
    return learning_place;
}

export function getExtraFacilities() {
    const extra_facilities = [
        { id: 1, slug: "provide_hands_Note",name: "Provide Hands Note"},
        { id: 2, slug: "video_tutorials_provide",name: "Video Tutorials Provide"},
        { id: 3, slug: "ppt_presentation",name: "PPT Presentation"},
        { id: 4, slug: "online Help",name: "Online Help"}
    ];
    return extra_facilities;
}

export function getMonth() {
    const months = [
        { id: 1, slug: "january",name: "January"},
        { id: 2, slug: "february",name: "February"},
        { id: 3, slug: "march",name: "March"},
        { id: 4, slug: "April",name: "April"},
        { id: 5, slug: "May",name: "May"},
        { id: 6, slug: "June",name: "June"},
        { id: 7, slug: "July",name: "July"},
        { id: 8, slug: "August",name: "August"},
        { id: 9, slug: "September",name: "September"},
        { id: 10, slug: "October",name: "October"},
        { id: 11, slug: "November",name: "November"},
        { id: 12, slug: "December",name: "December"}
    ];
    return months;
}

export function getWeek() {
    const week = [
        { id: 1, slug: "sat",name: "SATURDAY"},
        { id: 2, slug: "sun",name: "SUNDAY"},
        { id: 3, slug: "mon",name: "MONDAY"},
        { id: 4, slug: "tues",name: "TUESDAY"},
        { id: 5, slug: "wed",name: "WEDNESDAY"},
        { id: 6, slug: "thurs",name: "THURSDAY"},
        { id: 7, slug: "Fri",name: "FRIDAY"}
    ];
    return week;
}

export function getYear() {
    const rows = [];
    for (let i = 1991; i <= new Date().getFullYear(); i++) {
        rows.push(i);
    }
    return rows;
}

export function getSalaryRange() {
    const rows = [];
    for (let i = 1000; i <= 2000; i++) {
        rows.push(i +'-'+ (i * 2));
    }
    return rows;
}
