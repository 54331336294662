import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useGetAppliedJobListQuery } from "../../../features/tutor/tutorApi";
import { tutor_request } from "../../../route";
import { Auth, authValidate } from "../../../utils/TutionAuth";
import Layout from "../../layout/Layout";

const JobsList = () => {
    const { data, isError, error } = useGetAppliedJobListQuery({id:Auth().uuid}) || {};

    useEffect(()=> {
        if(!authValidate()) {
            window.location.href = "/"
        }
    },[])

    return (
        <Layout>

            <div className="bgGray">

                <div className="container pt-5">
                    <div className="row h50vh align-items-center">

                        <div className="col-lg-12 col-12">
                            <div className="tutor-notification-table mb-4">
                                <div className="card table-responsive">
                                    <div className="card-header">
                                        <h5>
                                            My Applied Jobs
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        {data?.data.length > 0 ?

                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Serial No</th>
                                                    <th scope="col">View Job</th>
                                                    <th scope="col">Applied Date</th>
                                                    {/*<th scope="col">Number of Viewed</th>*/}
                                                    {/*<th scope="col">Last view</th>*/}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    data?.data && data?.data.map((data,index) => {
                                                        return (
                                                            <tr>
                                                                <th>{++index}</th>
                                                                <td>
                                                                    <Link to={`${tutor_request}/${data.job_id}`} className="btn btn-sm btn-success">
                                                                        <i className="fa fa-eye"></i>
                                                                    </Link>
                                                                </td>
                                                                <td>{moment(data.created_at).format("ll")}</td>
                                                                {/*<td>{moment(data.created_at).format("ll")}</td>*/}
                                                                {/*<td>{moment(data.updated_at).format("ll")}</td>*/}
                                                            </tr>
                                                        );
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                            :
                                            <span> No Jobs</span>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/*Contact with this tutors*/}
                        <div className="col-lg-4 col-12">
                            {/* <TutorMenu notification={notification}/> */}
                        </div>
                        {/*end Contact with this tutors*/}

                    </div>
                </div>

            </div>

        </Layout>
    );
};

export default JobsList;