import React, {useEffect} from 'react';
import Layout from "../layout/Layout";
import HeaderTitle from "../common/HeaderTitle";

const Help = () => {
    useEffect(()=> {
        document.title = "HELP"
    },[])
    return (
        <Layout>
            <div className="container">
                <div className="row pb-5">
                    <HeaderTitle first_title="Help" second_title=""/>
                    Help Content Need
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </Layout>
    );
};

export default Help;