import { apiSlice } from "../api/apiSlice";
import { guardianAppliedTuitionList } from "./tutorSlice";
export const tutorApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getFeaturedTutors: builder.query({
            query: () => `/api/web/v1/get_all_featured_tutors`,
            keepUnusedDataFor:86400
        }),

        getRecentTutors: builder.query({
            query: () => `/api/web/v1/get_all_recently_tutors`,
            keepUnusedDataFor:86400
        }),

        getTutors: builder.query({
            query: ({page,pageSize}) =>{
                let string = `api/web/v1/get_all_tutors?page=${page}&pageSize=${pageSize}`
                return string
            },
            keepUnusedDataFor:86400
        }),


        getMoreTutors: builder.query({
            query: ({ page, pageSize }) => {
                const queryString =`api/web/v1/get_all_tutors?page=${page}&pageSize=${pageSize}`;
                return queryString;
            },
            async onQueryStarted({ page, pageSize },{ queryFulfilled, dispatch }) {
                try {
                    const response = await queryFulfilled;
                    if (response) {
                        dispatch(
                            apiSlice.util.updateQueryData(
                                "getTutors",
                                {page:1,pageSize:12},
                                (draft) => {
                                    draft.data.push(...response.data.data)
                                }
                            )
                        );
                    }
                } catch (err) { }
            },
        }),
        
        getNotification: builder.query({
            query: ({id}) =>{
                let string = `/api/web/v1/get_notification/${id}`;
                return string
            },
            keepUnusedDataFor:1800
        }),
        getGuardianAppliedTuitionList: builder.mutation({
            query: ({id}) => ({
                url: `/api/web/v1/guardian-applied-tuition-list/${id}`,
                method: "POST",
                body: {},
            }),
            async onQueryStarted({ page, pageSize },{ queryFulfilled, dispatch }) {
                try {
                    const response = await queryFulfilled;
                    if (response) {
                        dispatch(guardianAppliedTuitionList(response.data));
                    }
                } catch (err) { }
            },
        }),
        getAppliedJobList: builder.query({
            query: ({id}) =>{
                let string =`/api/web/v1/get_applied_jobslist/${id}`;
                return string
            },
            providesTags: ['applied_joblist'],
        }),

        getPersonalInfo: builder.query({
            query: ({id}) =>{
                let string =`/api/web/v1/get_perosnal_info/${id}`;
                return string
            },
            providesTags: ['personal_info'],
            refetchOnMountOrArgChange:true

        }),

        getThreeFeaturedTutors: builder.query({
            query: () =>{
                let string =`/api/web/v1/get_three_featured_tutors`;
                return string
            },
            keepUnusedDataFor:86400
        }),
        getAllEducationInfo: builder.query({
            query: ({id}) =>{
                let string = `/api/web/v1/get_all_education/${id}`
                return string
            },
            providesTags: ['education_info'],
            refetchOnMountOrArgChange:true
        }),
        
    }),
});

export const {
    useGetFeaturedTutorsQuery,
    useGetRecentTutorsQuery,
    useGetTutorsQuery,
    useGetMoreTutorsQuery,
    useGetGuardianAppliedTuitionListMutation,
    useGetNotificationQuery,
    useGetAppliedJobListQuery,
    useGetPersonalInfoQuery,
    useGetThreeFeaturedTutorsQuery,
    useGetAllEducationInfoQuery
} = tutorApi;
