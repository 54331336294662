import axios from "axios";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { msisdnValidate } from "../../utils/Validation";
// import JobListSuggestion from "../common/JobListSuggestion";
import { useCallback, useEffect } from "react";
import Layout from "../layout/Layout";
import { AuthMedia, authMediaValidate } from "../../utils/MediumAuth";
import { media } from "../../route";
import { authGuardianValidate } from "../../utils/GuardianAuth";
import { authValidate } from "../../utils/TutionAuth";

const MediaSignup = () => {
  const [mediaData, setMedia] = useState({
    name: "",
    msisdn: "",
    password: "",
    otp_code: "",
  });
  const [formType, setFormType] = useState("sign-up");
  // const [formType, setFormType] = useState("otp-verification");
  const [otpData, setOtpData] = useState("");
  const [resMsg, setResMsg] = useState("");
  const [isShowMsg, setIsShowMsg] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [timer, setTimer] = useState(0);
  const [signupResponse, setSignupResponse] = useState();
  const [mediaUUID, setmediaUUID] = useState('');

  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
  

  useEffect(() => {
      let time;
      if( timer > 0 ){
          time = setTimeout(timeOutCallback, 1000);
      } 
      return ()=>{
        clearTimeout(time);
      }

  }, [ timer, timeOutCallback]);


  const onShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const history = useHistory();

  const onHandleChange = (e) => {
    setMedia({
      ...mediaData,
      [e.target.name]: e.target.value,
    });
  };

  const checkTutorExists = (e) => {
    e.preventDefault();
    const data2 = JSON.stringify({ m_msisdn: mediaData.msisdn });

    if (!msisdnValidate(mediaData.msisdn)) {
      setResMsg("Enter Valid Phone Number.");
      setIsShowMsg(true);
      return false;
    }

    //check
    const config2 = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/media/is_media_exists",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data2,
    };

    axios(config2)
      .then(function (response) {
        if (response.data.success) {
          setResMsg("You have already registered.Please Login.");
          setIsShowMsg(true);
          return false;
        } else {
          onSubmitMsisdn();
        }
      })
      .catch(function (error) {
        console.log(error);
        setResMsg(error.response.data.message);
        setIsShowMsg(true);
      });
    //endcheck
  };

  //submit msisdn
  const onSubmitMsisdn = (event) => {
    const data = JSON.stringify({ msisdn: mediaData.msisdn });

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/otp_send",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success) {
          setFormType("otp-verification");
          setResMsg("");
          setOtpData(response.data.data.secret_key);
          setTimer(60*2);
          setIsShowMsg(false);
        } else {
          setIsShowMsg(true);
          setResMsg(response.data.message);
        }
      })
      .catch((error) => {
        setResMsg("Invalid Mobile Number");
        console.log(error);
      });
  };

  //submit and verify opt
  const onSubmitVerifyOTP = (event) => {
    event.preventDefault();

    const data = JSON.stringify({
      msisdn: mediaData.msisdn,
      secret_key: mediaData.otp_code,
    });

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/otp/verification",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          onSubmit();
          setResMsg(response.data.message)
        } else {
          setIsShowMsg(true);
          setResMsg('Invalid OTP');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //signup
  const onSubmit = (e) => {
    const data = JSON.stringify({
      m_name: mediaData.name,
      m_msisdn: mediaData.msisdn,
      m_password: mediaData.password,
    });

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/media/media_signup",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setmediaUUID(response.data.data.uuid)
          setSignupResponse(JSON.stringify(response.data.data))
          localStorage.setItem('mediaLogin',JSON.stringify(response.data.data))
          history.push(`${media}/dashboard`)
        } else {
          toast(response.data.message);
          setResMsg(response.data.message);
          setIsShowMsg(true);
        }
      })
      .catch(function (error) {
        toast(error);
        setResMsg(error);
        setIsShowMsg(true);
      });
  };

  const onReSendOtp = (e) => {
    e.preventDefault();
    const data = JSON.stringify({ msisdn: mediaData.msisdn });

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/otp_resend",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setIsShowMsg(true);
        setResMsg("OTP Resent");
        setTimer(60*2)
      })
      .catch((error) => {
        setIsShowMsg(true);
        setResMsg("Invalid Mobile Number");
        console.log(error);
      });
  };

  if (authMediaValidate() || AuthMedia()) {
    history.push("/media/dashboard");
  }

  if(authGuardianValidate()){
    history.push("/guardian/dashboard");
  }

  if(authValidate()){
    history.push("/guardian/dashboard");
  }

  const onCloseMsg = () => {
    setIsShowMsg(!isShowMsg);
  };

  
  if (formType == "otp-verification") {
    return (
      <Layout>
        <div className="guardianLoginWrap">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="formWrap">
                  <div className="formInner">
                    <div className="formCenter">
                      <h3 className="text-center">OTP Verification</h3>
                     
                      <form onSubmit={onSubmitVerifyOTP}>
                        <fieldset>
                          <p className="mb-2">
                            Verification OTP sent to {mediaData.msisdn}.
                          </p>

                          <>
                            {isShowMsg ? (
                              <Alert
                                variant="info"
                                onClick={onCloseMsg}
                                dismissible
                              >
                                {resMsg}
                              </Alert>
                            ) : (
                              <></>
                            )}
                          </>

                          <input
                            type="number"
                            className="form-control form-control-lg"
                            name="otp_code"
                            onChange={onHandleChange}
                            placeholder="Verification OTP..."
                            required
                          />
                          <center className="py-4">
                            <button className="btn btn-md otp-button">
                              Verify OTP
                              <i className="fa fa-send"></i>
                            </button>
                          </center>
                          <div className="d-flex justify-content-between">
                          Didn't receive your OTP?
                          {timer === 0 ? (
                              <Link onClick={onReSendOtp}>Resend OTP</Link>
                            ) : (
                              <b>
                                {Math.floor(timer / 60)}:
                                {timer - Math.floor(timer / 60) * 60}
                              </b>
                            )}
                        </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  } else {
    return (
      <>
        <Layout>
          <div className="tutorLoginWrap">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />

            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="formWrap">
                    <div className="formInner">
                      <div className="formCenter">
                        <h3 className="text-center">Media Sign Up</h3>

                        <>
                          {isShowMsg ? (
                            <Alert
                              variant="info"
                              onClick={onCloseMsg}
                              dismissible
                            >
                              {resMsg}
                            </Alert>
                          ) : (
                            <></>
                          )}
                        </>

                        <form onSubmit={checkTutorExists}>
                          <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                              type="text"
                              name="name"
                              onChange={onHandleChange}
                              className="form-control"
                              id="name"
                              placeholder="Name..."
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="mobile_number">Mobile Number</label>
                            <input
                              type="number"
                              name="msisdn"
                              onChange={onHandleChange}
                              className="form-control"
                              id="mobile_number"
                              placeholder="Mobile Number..."
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Password
                            </label>
                            <div className="loginInput">
                              <input
                                type={isShowPassword ? "text" : "password"}
                                name="password"
                                onChange={onHandleChange}
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="Password"
                                required
                              />
                              <i
                                onClick={onShowPassword}
                                className={
                                  isShowPassword
                                    ? "fa fa-eye"
                                    : "fa fa-eye-slash"
                                }
                              ></i>
                            </div>
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-primary form-control"
                            >
                              Signup
                            </button>
                          </div>
                        </form>
                        <div className="text-center">
                          Already have an account?
                          <Link
                            to={`/media/login`}
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                          >
                            &nbsp; Sign In{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <JobListSuggestion/> */}

              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
};

export default MediaSignup;
