import axios from "axios";
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import Layout from "../layout/Layout";

const OtpVerificationPage = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const [isError,setIsError] = useState(false);
    const [error,setError] = useState("");

    const onOtpSubmit = (e) => {
        const get_data = localStorage.getItem('g_otpVerify');
        const userdata = JSON.parse(get_data);
        const data = JSON.stringify({"msisdn": userdata.user_msisdn, "secret_key": e.secret_key});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp/verification',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                if(response.data.success){
                    guardianVerify(userdata.user_id);
                    guardianPostVerify(userdata.request_id);
                } else{
                    setError(response.data.message);
                    setIsError(true);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const guardianVerify = (e) => {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/guardian_verified/${e}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                localStorage.setItem('gLoginData',JSON.stringify(e));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const guardianPostVerify = (e) => {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/guardian_post_verified/${e}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                //localStorage.removeItem("g_otpVerify");
                window.location.href="/guardian/dashboard";
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <Layout>

            <div className="container">
                <div className="col-lg-8">
                    {isError ?

                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>

                        :
                        <></>
                    }
                    <form onSubmit={handleSubmit(onOtpSubmit)}>
                        <div className="form-group col-6">
                            <label htmlFor="otp_verify" className="form-label">OTP:</label>
                            <input type="number"
                                   name="secret_key"
                                   {...register("secret_key",{ required: true })}
                                   className="form-control" id="otp_verify"
                                   aria-describedby="otp_verify" placeholder="OTP" required/>
                            <span className="text-danger">
                                    {errors.secret_key?.type === 'required' && "OTP is required"}
                                </span>
                        </div>
                        <div className="form-group p-2">
                            <button type="submit" className="btn btn-success">
                                Verified
                            </button>
                        </div>
                    </form>

                </div>
                <div className="col-lg-4">

                </div>
            </div>

        </Layout>
    );
};

export default OtpVerificationPage;