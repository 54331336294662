import React, {useState} from 'react';

function ImageUplodeTest(props) {
    const [userInfo, setuserInfo] = useState({
        file:[],
        filepreview:null,
    });

    const handleInputChange = (event) => {
        alert(event.target.files[0]);
        setuserInfo({
            ...userInfo,
            file:event.target.files[0],
            filepreview:URL.createObjectURL(event.target.files[0]),
        });

    }

    const [isSucces, setSuccess] = useState(null);

    const submit = async () =>{
        const formdata = new FormData();
        formdata.append('avatar', userInfo.file);

        alert(userInfo.file)
    }


    return (
        <div>

            <div className="container mr-60">
                <h3 className="text-white">React Image Upload And Preview Using Node Js - <span> codeat21.com </span> </h3>

                <div className="formdesign">
                    {isSucces !== null ? <h4> {isSucces} </h4> :null }
                    <div className="form-row">
                        <label className="text-white">Select Image :</label>
                        <input type="file" className="form-control" name="upload_file"  onChange={handleInputChange} />
                    </div>

                    <div className="form-row">
                        <button type="submit" className="btn btn-dark" onClick={()=>submit()} > Save </button>

                    </div>
                </div>

                {userInfo.filepreview !== null ?
                    <img className="previewimg"  src={userInfo.filepreview} alt="UploadImage" />
                    : null}

            </div>
            <div className="text-white">
                <form>
                    <div className="row">
                        <div className="container">
                            <div className="col-md-6 col-12 md-12">
                                <input type="submit"/>
                                <imput className="type">
                                    <div className="container">

                                    </div>
                                </imput>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    );
}

export default ImageUplodeTest;