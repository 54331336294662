import axios from "axios";
import React, { useState } from 'react';
import { Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { media, tutor } from "../../route";
import { authGuardianValidate } from "../../utils/GuardianAuth";
import { districts_en } from "../../utils/location/District";
import { upazillas_en } from "../../utils/location/Upazilla";
import { authMediaValidate } from "../../utils/MediumAuth";
import { medium_en } from "../../utils/medium_data/Medium";
import { authValidate } from "../../utils/TutionAuth";
// import JobListSuggestion from "../common/JobListSuggestion";
import { msisdnValidate } from "../../utils/Validation";
import Layout from "../layout/Layout";


const Login = () => {

    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    const [resMsg, setResMsg] = useState("");
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowMsg, setIsShowMsg] = useState(false);
    const [formType, setFormType] = useState("login");
    const [select_area, setSelectArea] = useState([])
    const [district, setDistrict] = useState('')
    const [area, setArea] = useState([])
    const [medium, setMedium] = useState([])
    const [mediumId, setMediumId] = useState([])
    const [loginResponse, setLoginResponse] = useState();
    const [tutorUUID, setTutorUUID] = useState('');
    const [inputErrors,setErrors] = useState({});


    const history = useHistory();
    const onSubmit = (e) => {
        const data = JSON.stringify({"msisdn": e.msisdn, "password": e.password});

        if (!msisdnValidate(e.msisdn)) {
            setResMsg("Enter Valid Phone Number.");
            setIsShowMsg(true);
            return false;
        }

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/tutors_login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response => {
                if (response.data.success) {
                    setTutorUUID(response.data.data.uuid)
                    setLoginResponse(JSON.stringify(response.data.data))

                    const getPersonalInfoConfig = {
                        method: 'get',
                        url: process.env.REACT_APP_API_URL+ `/api/web/v1/get_perosnal_info/${response.data.data.uuid}`,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
                        }
                    };

                    axios(getPersonalInfoConfig)
                        .then(response2 => {

                            if(JSON.parse(response2.data.data.t_preferred_area_to_teach).length <= 0 || 
                                response2.data.data.t_preferred_district_to_teach.length <= 0 || 
                                JSON.parse(response2.data.data.t_preferred_medium_of_Eduction).length <= 0)
                            {
                                setFormType('preprofile')
                            }else{
                                toast('Successfully loggedin');
                                if(history.location.search){
                                    localStorage.clear();
                                    localStorage.setItem('loginData', JSON.stringify(response.data.data));
                                    history.push(`/${history.location.search.slice(5)}`);
                                }else{
                                    localStorage.clear();
                                    localStorage.setItem('loginData', JSON.stringify(response.data.data));
                                    history.push("/tutor/dashboard");
                                }
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                   
                } else {
                    toast(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                setIsShowMsg(true);
                setResMsg(error.response.data.message);
            });
    }
    
    const onShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    }

    if (authValidate()) {
        if(history.location.search){
            history.push(`/${history.location.search.slice(5)}`);
        }else{
            history.push("/tutor/dashboard");
        }
    }

    if (authGuardianValidate()) {
        history.push("/guardian/dashboard");
    }

    if (authMediaValidate()) {
        history.push(`${media}/dashboard`);
    }

    const onCloseMsg = () => {
        setIsShowMsg(!isShowMsg);
    }

     //update tutor tuition prefrrence area
    const onChangeDistrict = (e) => {
        e.preventDefault()
        setSelectArea([])
        setDistrict(districts_en.find((district) => district.value == e.target.value)?.title)
        setSelectArea(upazillas_en[e.target.value])
    }

    const onChangeArea = (event) => {
        if(event.target.checked) {
            if (area.includes(event.target.value)) {
                setArea(area.filter(sel => sel !== event.target.value));
            } else {
                setArea([...area, event.target.value]);
            }
        } else {
            setArea(area.filter(sel => sel !== event.target.value));
        }
    }

    const onChangeMedium = (e) => {
        let {name,value,checked} = e.target;

        //medium_store
        const mValue = medium_en.find(x => x.value == e.target.value).title;
        if(checked) {
            if (medium.includes(value)) {
                setMedium(medium.filter(sel => sel != mValue));
            } else {
                setMedium([...medium, mValue]);
            }
        } else {
            setMedium(medium.filter(sel => sel != mValue));
        }
        getSelectMediumID(checked,value)
    }


    const getSelectMediumID = (checked,id) => {
        let tmp = mediumId;
        if(checked) {
            tmp.push(id)
        } else {
            const index = tmp.indexOf(id);
            if (index !== -1) {
                tmp.splice(index, 1);
            }
        }
        setMediumId(tmp)
    }

    const validate = () => {
        let errors = {};
        let hasError = false;


        if (medium.length <= 0) {
            hasError = true;
            errors["preferredMediumOFEduction"] = "Preferred Medium of Eduction is a required field!!!"
        }

        if (area.length <= 0) {
            hasError = true;
            errors["preferredAreaToTeach"] = "Preferred Area To Teach is a required field!!!"
        }

        setErrors(errors);

        return hasError;
    }

    

    const handlePrefrredSubmit = (e) => {

        e.preventDefault();
        
        setErrors({})
        if(validate()){
            return false;
        }

        const data = JSON.stringify({
            "uuid": tutorUUID,
            "t_salary": '',
            "t_is_Negotiable": 0,
            "t_days_per_week": '',
            "t_status": "1",
            "t_preferred_tuition": '',
            "t_place_of_learning": '',
            "t_extra_facilities": '',
            "t_preferred_class": '',
            "t_preferred_class_id": '',
            "t_preferred_subjects": '',
            "t_preferred_medium_of_Eduction": JSON.stringify(medium),
            "t_preferred_medium_of_Eduction_id": JSON.stringify(mediumId),
            "t_preferred_district_to_teach": district,
            "t_preferred_area_to_teach": JSON.stringify(area)
        });
    
        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/update_tution_info',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                localStorage.clear();
                localStorage.setItem("loginData", loginResponse);
                window.location.href=`${tutor}/dashboard`;
                toast(response.data.message);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    if(formType === 'preprofile'){
        return(
            <Layout>
                <div className="container">
                  <div className="row">
                      <div className="col-12">
                          <div className="tuition-info-edit mb-4">
                              <div className="card">
                                  <div className="card-header">
                                      Tuition Prefrred Info<br/>
                                  </div>
                                  <div className="card-body">
                                      <form onSubmit={handlePrefrredSubmit}>
                                          <div className="form-group row">
                                              <label className="col-form-label col-sm-4 pt-0">
                                                  Preferred District to Teach:
                                                  <span className="text-danger">*</span>
                                              </label>
                                              <div className="col-sm-8 row">
      
                                                  <div className="form-group col-12 col-lg-8">
                                                      <select
                                                              name="t_district"
                                                              id="inputState"
                                                              onChange={onChangeDistrict}
                                                              className="form-control">
                                                          <option selected value="">Select Districts...</option>
                                                          {
                                                              districts_en.map((district, index)=>{
                                                                  return (
                                                                      <option value={district.value} key={index}>{district.title}</option>
                                                                  )
                                                              })
                                                          }
                                                      </select>
                                                  </div>
                                              </div>
                                          </div>
      
                                          <div className="form-group row">
                                              <label className="col-form-label col-sm-4 pt-0">
                                                  Preferred Areas to Teach:
                                                  <span className="text-danger">*</span>
                                              </label>
                                              <div className="col-sm-8 row">
                                                  {
                                                      select_area.map((area, index)=>{
                                                          return (
                                                              <div key={index} className="form-check col-12 col-lg-6">
                                                                  <input
                                                                      className="form-check-input"
                                                                      type="checkbox"
                                                                      onChange={onChangeArea}
                                                                      id={area.value}
                                                                      value={area.title}
                                                                  />
                                                                  <label className="form-check-label" htmlFor={area.value}>
                                                                      {area.title}
                                                                  </label>
                                                              </div>
                                                          );
                                                      })
                                                  }
                                                  <span className="text-danger">
                                                    {inputErrors.preferredAreaToTeach}
                                                  </span>
                                              </div>
                                          </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-sm-4 pt-0">
                                                    Preferred Medium of Education:
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-sm-8 row">
                                                    {

                                                        medium_en.map((data,index)=> {
                                                            return (
                                                                <div key={index} className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        onChange={onChangeMedium}
                                                                        id={data.value}
                                                                        value={data.value}
                                                                        checked={medium.indexOf(data.title) != -1}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={data.value}>
                                                                        {data.title}
                                                                    </label>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <span className="text-danger">
                                                        {errors.preferredMediumOFEduction}
                                                    </span>
                                                </div>
                                          </div>
                                          <center>
                                              <button type='submit' className="btn btn-md btn-success">Update Tuition Info <i className="fa fa-send"></i></button>
                                          </center>
      
                                      </form>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
            </Layout>
          )
    }else{
        return (
            <>
                <Layout>
                    <div className="tutorLoginWrap">
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover/>
    
                        <div className="container">
                            <div className="row justify-content-center">
    
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
    
                                    <div className="formWrap">
                                        <div className="formInner">
                                            <div className="formCenter">
                                                <h3 className="text-center">Tutor Log In</h3>
    
                                                <>
                                                    {isShowMsg ?
                                                        <Alert variant="danger" onClick={onCloseMsg} dismissible>
                                                            {resMsg}
                                                        </Alert>
                                                        :
                                                        <></>
                                                    }
                                                </>
    
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-group">
                                                        <label htmlFor="mobile_number">Mobile Number</label>
                                                        <input type="number"
                                                               name="msisdn"
                                                               className="form-control"
                                                               id="mobile_number"
                                                               {...register("msisdn", {required: true})}
                                                               placeholder="Mobile Number..."
                                                               min={0}
                                                               />
                                                        <small id="mobile_number" className="text-danger">
                                                            {errors.msisdn && <span>This field is required</span>}
                                                        </small>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">Password</label>
                                                        <div className="loginInput">
                                                            <input type={isShowPassword ? 'text' : 'password'}
                                                                   name="password"
                                                                   className="form-control"
                                                                   {...register("password", {required: true})}
                                                                   id="exampleInputPassword1"
                                                                   placeholder="Password"/>
                                                            <i onClick={onShowPassword} className={isShowPassword?"fa fa-eye":"fa fa-eye-slash"}></i>
                                                        </div>
                                                        <small id="mobile_number" className="form-text text-danger">
                                                            {errors.password && <span>Password field is required</span>}
                                                        </small>
                                                    </div>
                                                    <div className="form-group d-flex justify-content-between">
                                                        <div className="rem">
                                                            <div className="remItem">
                                                                <label htmlFor="rem">
                                                                    <input type="checkbox" id='rem'/> Remember me
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <p>
                                                            <Link to="/forget-password" className="form-check-label"
                                                                  htmlFor="exampleCheck1">Forgot Password?</Link>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-primary form-control">Login
                                                        </button>
                                                    </div>
                                                    <div className="text-center">
                                                        Don't have an account?
                                                        <Link to="/tutor/signup" className="form-check-label"
                                                              htmlFor="exampleCheck1"> &nbsp; Sign Up </Link>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                {/* <JobListSuggestion/> */}
    
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
};

export default Login;