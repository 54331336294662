import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list:{}
};

const guardianSlice = createSlice({
    name: "guardian",
    initialState,
    reducers: {},
});

export const {} = guardianSlice.actions;
export default guardianSlice.reducer;
