import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import HeaderLogo from "../../frontend/HomeImge/HeaderLogo.png";
import {
  choose,
  guardian,
  home,
  media,
  tuition,
  tutor,
  tutors,
  tutor_request,
} from "../../route";
import { AuthGuardian, authGuardianValidate } from "../../utils/GuardianAuth";
import { AuthMedia, authMediaValidate } from "../../utils/MediumAuth";
import { Auth, authValidate } from "../../utils/TutionAuth";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    changeTitle();
    let url;
    if (window.location.pathname === "/") {
      url = "HOME";
    } else if (window.location.pathname.split("/")[1] === "tutor-details") {
      url = "Tutor Details";
    } else if (window.location.pathname.split("/")[1] === "tutor-request") {
      url = "Tutor Request";
    } else {
      url = window.location.pathname
        .toString()
        .replace(/[^\w\s]/gi, " ")
        .toUpperCase();
    }

    setTitle((document.title = url));
  }, [title]);
  const changeTitle = (event) => {
    setTitle(window.location.pathname);
  };

  const onHandleLogout = () => {
    localStorage.removeItem("loginData");
    localStorage.removeItem("guardianLogin");
    localStorage.removeItem("mediaLogin");
    localStorage.clear();
    window.location.href = "/";
  };

  const onHandleMenu = () => {
    setShowMenu((previusState) => {
      return !previusState;
    });
  };

  const onHideDashboard = () => {
    setShowDashboard(false);
  };

  const onShowDashboard = () => {
    setShowDashboard(true);
  };

  const onHandleDiv = () => {
    scroller.scrollTo("search-area", {
      duration: 0,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div className="AllMainHeaderWrap">
      <div className="HeaderBottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="HeaderSocialInner">
                <div className="MailNumberWrap">
                  {/* <a className="NumberText" href="tel:01717400118">
                                        <i className="fa fa-phone PhoneIcon"></i> +8801717400118 </a> */}
                  <a
                    className="MailText"
                    href="mailto:tutorbd.network@gmail.com"
                  >
                    <i className="fa fa-envelope-o MessageIcon"></i>
                    tutorbd.network@gmail.com
                  </a>
                </div>
                <div className="SocialHeaderRight">
                  <ul className="SocialHeaderWrap">
                    <li>
                      <a
                        href="https://www.facebook.com/tutorbdhub"
                        className="RegSubSolidButton"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/"
                        className="RegSubSolidButton"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/"
                        className="RegSubSolidButton"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header className="mainHeaderHome">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <div className="Navbar_Img">
              <a href={home} className="navbar-brand">
                {/*<img src={headLogo} className="img-fluid head1Img" alt="logo"/>*/}
                <img
                  src={HeaderLogo}
                  className="img-fluid header-logo"
                  alt="logo"
                />
              </a>
            </div>
            <div className="ms-auto d-flex align-items-center">
              <button
                className="navbar-toggler"
                onClick={()=> onHandleMenu()}
                type="button"
              >
                <span className="toggler-logo">
                  <span className="logo top-bar"></span>
                  <span className="logo middle-bar"></span>
                  <span className="logo last-bar"></span>
                </span>
              </button>
              <div
                className={`collapse navbar-collapse mainMenunavbar ${
                  showMenu ? "show" : ""
                }`}
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 mainMenu">
                  <li className="nav-item">
                    <Link
                      to="/"
                      className={`nav-link ${
                        window.location.pathname === "/" ? "active-menu" : ""
                      }`}
                    >
                      HOME
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={tutors}
                      className={`nav-link ${
                        window.location.pathname === tutors ? "active-menu" : ""
                      }`}
                    >
                      TUTORS
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/tuition-wanted"}
                      className={`nav-link ${
                        window.location.pathname === "/tuition-wanted"
                          ? "active-menu"
                          : ""
                      }`}
                    >
                      {authValidate() ? "MY TUITION POSTS" : "TUITION WANTED"}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        window.location.pathname == "/tutor-wanted"
                          ? "active-menu"
                          : ""
                      }`}
                      to={tuition}
                    >
                      TUTOR WANTED
                    </Link>
                  </li>

                  {authValidate() ? (
                    <>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            window.location.pathname ==
                            `${tutor}/dashboard/notification`
                              ? "active-menu"
                              : ""
                          }`}
                          to={`${tutor}/dashboard/notification`}
                        >
                          NOTIFICATIONS
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            window.location.pathname ==
                            `${tutor}/request-tuition`
                              ? "active-menu"
                              : ""
                          }`}
                          to={`${tutor}/request-tuition`}
                        >
                          REQUEST A TUITION
                        </Link>
                      </li>
                      <li className="nav-item nav-item-right">
                        <Link
                          className={`nav-link ${
                            window.location.pathname ==
                            `${tutor}/dashboard/applied/job/list`
                              ? "active-menu"
                              : ""
                          }`}
                          to={`${tutor}/dashboard/applied/job/list`}
                        >
                          APPLIED JOBS
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {authGuardianValidate() ? (
                    <>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            window.location.pathname === `${guardian}/post`
                              ? "active-menu"
                              : ""
                          }`}
                          to={`${guardian}/post`}
                        >
                          MY POSTS
                        </Link>
                      </li>
                      <li className="nav-item nav-item-right">
                        <Link
                          className={`nav-link ${
                            window.location.pathname === `/all/tutor/response`
                              ? "active-menu"
                              : ""
                          }`}
                          to={`/all/tutor/response`}
                        >
                          TUTOR RESPONSE
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            window.location.pathname === tutor_request
                              ? "active-menu"
                              : ""
                          }`}
                          to={"/tutor-request"}
                        >
                          REQUEST A TUTOR
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {authValidate() || authGuardianValidate() || authMediaValidate() ? (
                    <>
                      {authValidate() ? (
                        <div className="profile-dropdown">
                          <NavDropdown
                            style={{
                              background: "transparent",
                              color: "red !important",
                              padding: "0",
                              margin: "0",
                              fontSize: "20px !important",
                            }}
                            title={
                              authValidate() ? (
                                <i class="fa fa-user-circle-o" />
                              ) : (
                                ""
                              )
                            }
                            id="basic-nav-dropdown"
                          >
                            <NavDropdown.Item
                              style={{ color: "#0c76b2" }}
                              href={`${tutor}/dashboard`}
                            >
                              {Auth().name}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              style={{ color: "#0c76b2" }}
                              href={`${tutor}/dashboard`}
                            >
                              Tutor Profile
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              onClick={() => {
                                onHandleLogout();
                              }}
                              style={{ cursor: "pointer", color: "#0c76b2" }}
                            >
                              LogOut
                            </NavDropdown.Item>
                          </NavDropdown>
                        </div>
                      ) : (
                        <></>
                      )}
                      {
                        authGuardianValidate() ? (
                          <>
                            <NavDropdown
                              title={
                                authGuardianValidate()
                                  ? AuthGuardian().g_name.substring(0, 10)
                                      .length > 4
                                    ? AuthGuardian()
                                        .g_name.split(" ")[0]
                                        .toUpperCase()
                                    : "GUARDIAN"
                                  : ""
                              }
                              id="basic-nav-dropdown"
                            >
                              <NavDropdown.Item href={`${guardian}/dashboard`}>Guardian Profile</NavDropdown.Item>
                              <NavDropdown.Divider />
                              <NavDropdown.Item
                                href="#"
                                onClick={() => {
                                  onHandleLogout();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                LogOut
                              </NavDropdown.Item>
                            </NavDropdown>
                          </>
                        ) : (
                          <></>
                        )
                      }
                       {
                        authMediaValidate() ? (
                          <>
                             <li className="nav-item">
                                <Link
                                  className={`nav-link ${
                                    window.location.pathname === `${media}/tutor-request`
                                      ? "active-menu"
                                      : ""
                                  }`}
                                  to={`${media}/tutor-request`}
                                >
                                  REQUEST A TUTOR
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                  className={`nav-link ${
                                    window.location.pathname === `${media}/my-posts`
                                      ? "active-menu"
                                      : ""
                                  }`}
                                  to={`${media}/my-posts`}
                                >
                                  MY POSTS
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                  className={`nav-link ${
                                    window.location.pathname === `${media}/tutor/response`
                                      ? "active-menu"
                                      : ""
                                  }`}
                                  to={`${media}/tutor/response`}
                                >
                                  TUTOR RESPONSE
                                </Link>
                            </li>
                            <NavDropdown
                              title={
                                authMediaValidate()
                                  ? AuthMedia().m_name.substring(0, 10)
                                      .length > 4
                                    ? AuthMedia()
                                        .m_name.split(" ")[0]
                                        .toUpperCase()
                                    : "Media"
                                  : ""
                              }
                              id="basic-nav-dropdown"
                            >
                              <NavDropdown.Item href={`${media}/dashboard`}>Media Profile</NavDropdown.Item>
                              <NavDropdown.Divider />
                              <NavDropdown.Item
                                href="#"
                                onClick={() => {
                                  onHandleLogout();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                LogOut
                              </NavDropdown.Item>
                            </NavDropdown>
                          </>
                        ) : (
                          <></>
                        )
                      }
                    </>
                  ) : (
                    <>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            window.location.pathname == tutor_request
                              ? "active-menu"
                              : ""
                          }`}
                          to={"/tutor-request"}
                        >
                          REQUEST A TUTOR
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={`${choose}/signup`}
                          className="nav-link JoinButton"
                        >
                          Join Free
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={`${choose}/login`}
                          className="nav-link JoinButton"
                        >
                          Login
                        </Link>
                      </li>
                    </>
                  )
                  }
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
