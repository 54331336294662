import _ from "lodash"
const districts = [
    {
      "value": 4,
      "title": "Barguna"
    },
    {
      "value": 6,
      "title": "Barisal"
    },
    {
      "value": 9,
      "title": "Bhola"
    },
    {
        "value": 12,
        "title": "Brahmanbaria"
    },
    {
        "value": 1,
        "title": "Bagerhat"
    },
    {
        "value": 10,
        "title": "Bogura"
    },
    {
        "value": 3,
        "title": "Bandarban"
    },
    {
        "value": 18,
        "title": "Chuadanga"
    },
    {
        "value": 13,
        "title": "Chandpur"
    },
    {
        "value": 15,
        "title": "Chattogram"
    },
    {
        "value": 19,
        "title": "Cumilla"
    },
    {
        "value": 22,
        "title": "Cox's Bazar"
    },
    {
        "value": 70,
        "title": "Chapainawabganj"
    },
    {
        "value": 26,
        "title": "Dhaka"
    },
    {
        "value": 27,
        "title": "Dinajpur"
    },
    {
        "value": 30,
        "title": "Feni"
    },
    {
        "value": 29,
        "title": "Faridpur"
    },
    {
        "value": 32,
        "title": "Gaibandha"
    },
    {
        "value": 33,
        "title": "Gazipur"
    },
    {
        "value": 35,
        "title": "Gopalganj"
    },
    {
        "value": 36,
        "title": "Habiganj"
    },
    {
        "value": 42,
        "title": "Jhalakathi"
    },
    {
        "value": 41,
        "title": "Jashore"
    },
    {
        "value": 44,
        "title": "Jhenaidah"
    },
    {
        "value": 39,
        "title": "Jamalpur"
    },
    {
        "value": 38,
        "title": "Joypurhat"
    },
    {
        "value": 47,
        "title": "Khulna"
    },
    {
        "value": 50,
        "title": "Kushtia"
    },
    {
        "value": 46,
        "title": "Khagrachhari"
    },
    {
        "value": 48,
        "title": "Kishoreganj"
    },
    {
        "value": 49,
        "title": "Kurigram"
    },
    {
        "value": 52,
        "title": "Lalmonirhat"
    },
    {
        "value": 51,
        "title": "Lakshmipur"
    },
    {
        "value": 54,
        "title": "Madaripur"
    },
    {
        "value": 56,
        "title": "Manikganj"
    },
    {
        "value": 59,
        "title": "Munshiganj"
    },
    {
        "value": 61,
        "title": "Mymensingh"
    },
    {
        "value": 58,
        "title": "Moulvibazar"
    },
    {
        "value": 72,
        "title": "Netrokona"
    },
    {
        "value": 64,
        "title": "Naogaon"
    },
    {
        "value": 69,
        "title": "Natore"
    },
    {
        "value": 67,
        "title": "Narayanganj"
    },
    {
        "value": 68,
        "title": "Narsingdi"
    },
    {
        "value": 75,
        "title": "Noakhali"
    },
    {
        "value": 73,
        "title": "Nilphamari"
    },
    {
        "value": 78,
        "title": "Patuakhali"
    },
    {
        "value": 79,
        "title": "Pirojpur"
    },
    {
        "value": 76,
        "title": "Pabna"
    },
    {
        "value": 77,
        "title": "Panchagarh"
    },
    {
        "value": 84,
        "title": "Rangamati"
    },
    {
        "value": 82,
        "title": "Rajbari"
    },
    {
        "value": 81,
        "title": "Rajshahi"
    },
    {
        "value": 85,
        "title": "Rangpur"
    },
    {
        "value": 86,
        "title": "Shariatpur"
    },
    {
        "value": 87,
        "title": "Satkhira"
    },
    {
        "value": 89,
        "title": "Sherpur"
    },
    {
        "value": 88,
        "title": "Sirajganj"
    },
    {
        "value": 93,
        "title": "Tangail"
    },
    {
        "value": 94,
        "title": "Thakurgaon"
    },
    {
        "value": 95,
        "title": "Magura"
    },
    {
        "value": 96,
        "title": "Meherpur"
    },
    {
        "value": 97,
        "title": "Narail"
    },
    {
        "value": 98,
        "title": "Sylhet"
    },
    {
        "value": 99,
        "title": "Sunamganj"
    }

]

export const districts_en = _.sortBy(districts, district => district.title)

