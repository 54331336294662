export const subject_en ={
    "11": [
        {
            "value": 111,
            "title": "বাংলা"
        },
        {
            "value": 112,
            "title": "ইংরেজি"
        },
        {
            "value": 113,
            "title": "প্রাথমিক গণিত"
        },
        {
            "value": 114,
            "title": "পরিবেশ পরিচিতি, সমাজ ও বিজ্ঞান"
        },
        {
            "value": 115,
            "title": "ইসলাম ও নৈতিক শিক্ষা"
        },
        {
            "value": 116,
            "title": "হিন্দুধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 117,
            "title": "খ্রিষ্টধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 118,
            "title": "বৌদ্ধধর্ম ও নৈতিক শিক্ষা"
        }
    ],
    "12": [
        {
            "value": 121,
            "title": "বাংলা"
        },
        {
            "value": 122,
            "title": "ইংরেজি"
        },
        {
            "value": 123,
            "title": "প্রাথমিক গণিত"
        },
        {
            "value": 124,
            "title": "পরিবেশ পরিচিতি, সমাজ ও বিজ্ঞান"
        },
        {
            "value": 125,
            "title": "ইসলাম ও নৈতিক শিক্ষা"
        },
        {
            "value": 126,
            "title": "হিন্দুধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 127,
            "title": "খ্রিষ্টধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 128,
            "title": "বৌদ্ধধর্ম ও নৈতিক শিক্ষা"
        }
    ],
    "13": [
        {
            "value": 131,
            "title": "বাংলা"
        },
        {
            "value": 132,
            "title": "ইংরেজি"
        },
        {
            "value": 133,
            "title": "প্রাথমিক গণিত"
        },
        {
            "value": 134,
            "title": "পরিবেশ পরিচিতি, সমাজ ও বিজ্ঞান"
        },
        {
            "value": 135,
            "title": "ইসলাম ও নৈতিক শিক্ষা"
        },
        {
            "value": 136,
            "title": "হিন্দুধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 137,
            "title": "খ্রিষ্টধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 138,
            "title": "বৌদ্ধধর্ম ও নৈতিক শিক্ষা"
        }
    ],
    "14": [
        {
            "value": 141,
            "title": "বাংলা"
        },
        {
            "value": 142,
            "title": "ইংরেজি"
        },
        {
            "value": 143,
            "title": "প্রাথমিক গণিত"
        },
        {
            "value": 144,
            "title": "পরিবেশ পরিচিতি, সমাজ ও বিজ্ঞান"
        },
        {
            "value": 145,
            "title": "ইসলাম ও নৈতিক শিক্ষা"
        },
        {
            "value": 146,
            "title": "হিন্দুধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 147,
            "title": "খ্রিষ্টধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 148,
            "title": "বৌদ্ধধর্ম ও নৈতিক শিক্ষা"
        }
    ],
    "15": [
        {
            "value": 151,
            "title": "বাংলা"
        },
        {
            "value": 152,
            "title": "ইংরেজি"
        },
        {
            "value": 153,
            "title": "প্রাথমিক গণিত"
        },
        {
            "value": 154,
            "title": "পরিবেশ পরিচিতি, সমাজ ও বিজ্ঞান"
        },
        {
            "value": 155,
            "title": "ইসলাম ও নৈতিক শিক্ষা"
        },
        {
            "value": 156,
            "title": "হিন্দুধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 157,
            "title": "খ্রিষ্টধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 158,
            "title": "বৌদ্ধধর্ম ও নৈতিক শিক্ষা"
        }
    ],
    "16": [
        {
            "value": 161,
            "title": "বাংলা ১ম ও ২য়"
        },
        {
            "value": 162,
            "title": "ইংরেজি ১ম ও ২য়"
        },
        {
            "value": 163,
            "title": "গণিত"
        },
        {
            "value": 164,
            "title": "বিজ্ঞান"
        },
        {
            "value": 165,
            "title": "তথ্য ও যোগযোগ প্রযুক্তি"
        },
        {
            "value": 166,
            "title": "ইসলাম ও নৈতিক শিক্ষা "
        },
        {
            "value": 167,
            "title": "হিন্দুধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 168,
            "title": "খ্রিষ্টধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 169,
            "title": "বাংলাদেশ ও বিশ্বপরিচয়"
        },
        {
            "value": 1610,
            "title": "কৃষি শিক্ষা"
        },
        {
            "value": 1611,
            "title": "কর্ম ও জীবনমুখী শিক্ষা"
        },
        {
            "value": 1612,
            "title": "গার্হস্থ্য বিজ্ঞান"
        },
        {
            "value": 1613,
            "title": "চারু ও কারুকলা"
        },
        {
            "value": 1614,
            "title": "ক্ষুদ্র ও নৃগোষ্ঠীর ভাষা ও সংস্কৃতি"
        },
        {
            "value": 1615,
            "title": "শারীরিক শিক্ষা ও স্বাস্থ্য"
        }
    ],
    "17": [
        {
            "value": 171,
            "title": "বাংলা ১ম ও ২য়"
        },
        {
            "value": 172,
            "title": "ইংরেজি ১ম ও ২য়"
        },
        {
            "value": 173,
            "title": "গণিত"
        },
        {
            "value": 174,
            "title": "বিজ্ঞান"
        },
        {
            "value": 175,
            "title": "তথ্য ও যোগযোগ প্রযুক্তি"
        },
        {
            "value": 176,
            "title": "ইসলাম ও নৈতিক শিক্ষা "
        },
        {
            "value": 177,
            "title": "হিন্দুধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 178,
            "title": "খ্রিষ্টধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 179,
            "title": "বাংলাদেশ ও বিশ্বপরিচয়"
        },
        {
            "value": 1710,
            "title": "কৃষি শিক্ষা"
        },
        {
            "value": 1711,
            "title": "কর্ম ও জীবনমুখী শিক্ষা"
        },
        {
            "value": 1712,
            "title": "গার্হস্থ্য বিজ্ঞান"
        },
        {
            "value": 1713,
            "title": "চারু ও কারুকলা"
        },
        {
            "value": 1714,
            "title": "ক্ষুদ্র ও নৃগোষ্ঠীর ভাষা ও সংস্কৃতি"
        },
        {
            "value": 1715,
            "title": "শারীরিক শিক্ষা ও স্বাস্থ্য"
        }
    ],
    "18": [
        {
            "value": 181,
            "title": "বাংলা ১ম ও ২য়"
        },
        {
            "value": 182,
            "title": "ইংরেজি ১ম ও ২য়"
        },
        {
            "value": 183,
            "title": "গণিত"
        },
        {
            "value": 184,
            "title": "বিজ্ঞান"
        },
        {
            "value": 185,
            "title": "তথ্য ও যোগযোগ প্রযুক্তি"
        },
        {
            "value": 186,
            "title": "ইসলাম ও নৈতিক শিক্ষা "
        },
        {
            "value": 187,
            "title": "হিন্দুধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 188,
            "title": "খ্রিষ্টধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 189,
            "title": "বাংলাদেশ ও বিশ্বপরিচয়"
        },
        {
            "value": 1810,
            "title": "কৃষি শিক্ষা"
        },
        {
            "value": 1811,
            "title": "কর্ম ও জীবনমুখী শিক্ষা"
        },
        {
            "value": 1812,
            "title": "গার্হস্থ্য বিজ্ঞান"
        },
        {
            "value": 1813,
            "title": "চারু ও কারুকলা"
        },
        {
            "value": 1814,
            "title": "শারীরিক শিক্ষা ও স্বাস্থ্য"
        }
    ],
    "19": [
        {
            "value": 191,
            "title": "বাংলা ১ম ও ২য়"
        },
        {
            "value": 192,
            "title": "ইংরেজি ১ম ও ২য়"
        },
        {
            "value": 193,
            "title": "গণিত"
        },
        {
            "value": 194,
            "title": "বিজ্ঞান"
        },
        {
            "value": 195,
            "title": "তথ্য ও যোগযোগ প্রযুক্তি"
        },
        {
            "value": 196,
            "title": "পদার্থবিজ্ঞান"
        },
        {
            "value": 197,
            "title": "রসায়ন"
        },
        {
            "value": 198,
            "title": "জীববিজ্ঞান"
        },
        {
            "value": 199,
            "title": "উচ্চতর গণিত"
        },
        {
            "value": 1910,
            "title": "হিসাববিজ্ঞান"
        },
        {
            "value": 1911,
            "title": "ফিন্যান্স ও ব্যাংকিং"
        },
        {
            "value": 1912,
            "title": "ব্যবসায় উদ্যোগ"
        },
        {
            "value": 1913,
            "title": "অর্থনীতি"
        },
        {
            "value": 1914,
            "title": "ভূগোল ও পরিবেশ"
        },
        {
            "value": 1915,
            "title": "পৌরনীতি ও নাগরিকতা"
        },
        {
            "value": 1916,
            "title": "ইসলাম ও নৈতিক শিক্ষা"
        },
        {
            "value": 1917,
            "title": "হিন্দু ধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 1918,
            "title": "বৌদ্ধধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 1919,
            "title": "খ্রিষ্টধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 1920,
            "title": "বাংলাদেশ ও বিশ্বপরিচয়"
        },
        {
            "value": 19210,
            "title": "বাংলাদেশের ইতিহাস ও বিশ্বসভ্যতা"
        },
        {
            "value": 19221,
            "title": "ক্যারিয়ার এডুকেশন"
        },
        {
            "value": 19231,
            "title": "কৃষিশিক্ষা"
        },
        {
            "value": 19241,
            "title": "গার্হস্থ্য বিজ্ঞান"
        },
        {
            "value": 19251,
            "title": "চারু ও কারুকলা"
        },
        {
            "value": 19261,
            "title": "শারীরিক শিক্ষা, স্বাস্থ্য বিজ্ঞান ও খেলাধুলা"
        },
    ],
    "191": [
        {
            "value": 201,
            "title": "বাংলা ১ম ও ২য়"
        },
        {
            "value": 202,
            "title": "ইংরেজি ১ম ও ২য়"
        },
        {
            "value": 203,
            "title": "গণিত"
        },
        {
            "value": 204,
            "title": "বিজ্ঞান"
        },
        {
            "value": 205,
            "title": "তথ্য ও যোগযোগ প্রযুক্তি"
        },
        {
            "value": 206,
            "title": "পদার্থবিজ্ঞান"
        },
        {
            "value": 207,
            "title": "রসায়ন"
        },
        {
            "value": 208,
            "title": "জীববিজ্ঞান"
        },
        {
            "value": 209,
            "title": "উচ্চতর গণিত"
        },
        {
            "value": 2010,
            "title": "হিসাববিজ্ঞান"
        },
        {
            "value": 2011,
            "title": "ফিন্যান্স ও ব্যাংকিং"
        },
        {
            "value": 2012,
            "title": "ব্যবসায় উদ্যোগ"
        },
        {
            "value": 2013,
            "title": "অর্থনীতি"
        },
        {
            "value": 2014,
            "title": "ভূগোল ও পরিবেশ"
        },
        {
            "value": 2015,
            "title": "পৌরনীতি ও নাগরিকতা"
        },
        {
            "value": 2016,
            "title": "ইসলাম ও নৈতিক শিক্ষা"
        },
        {
            "value": 2017,
            "title": "হিন্দু ধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 2018,
            "title": "বৌদ্ধধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 2019,
            "title": "খ্রিষ্টধর্ম ও নৈতিক শিক্ষা"
        },
        {
            "value": 2020,
            "title": "বাংলাদেশ ও বিশ্বপরিচয়"
        },
        {
            "value": 2021,
            "title": "বাংলাদেশের ইতিহাস ও বিশ্বসভ্যতা"
        },
        {
            "value": 2022,
            "title": "ক্যারিয়ার এডুকেশন"
        },
        {
            "value": 2023,
            "title": "কৃষিশিক্ষা"
        },
        {
            "value": 2024,
            "title": "গার্হস্থ্য বিজ্ঞান"
        },
        {
            "value": 2025,
            "title": "চারু ও কারুকলা"
        },
        {
            "value": 2026,
            "title": "শারীরিক শিক্ষা, স্বাস্থ্য বিজ্ঞান ও খেলাধুলা"
        },
    ],
    "192": [
        {
            "value": 1921,
            "title": "বাংলা ১ম ও ২য়"
        },
        {
            "value": 1922,
            "title": "ইংরেজি ১ম ও ২য়"
        },
        {
            "value": 1923,
            "title": "গণিত"
        },
        {
            "value": 1924,
            "title": "তথ্য ও যোগযোগ প্রযুক্তি"
        },
        {
            "value": 1925,
            "title": "পদার্থবিজ্ঞান"
        },
        {
            "value": 1926,
            "title": "রসায়ন"
        },
        {
            "value": 1927,
            "title": "জীববিজ্ঞান"
        },
        {
            "value": 1928,
            "title": "উচ্চতর গণিত"
        },
        {
            "value": 1929,
            "title": "হিসাববিজ্ঞান"
        },
        {
            "value": 19291,
            "title": "ফিন্যান্স ও ব্যাংকিং"
        },
        {
            "value": 19292,
            "title": "ব্যবসায় সংগঠন ও ব্যবস্থাপনা"
        },
        {
            "value": 19293,
            "title": "উৎপাদন ব্যবস্থাপনা ও বিপণন"
        },
        {
            "value": 19294,
            "title": "অর্থনীতি"
        },
        {
            "value": 19295,
            "title": "পৌরনীতি ও সুশাসন"
        },
        {
            "value": 19296,
            "title": "ইসলামের ইতিহাস ও সংস্কৃতি"
        },
        {
            "value": 19297,
            "title": "ইতিহাস"
        },
        {
            "value": 19298,
            "title": "সমাজ কর্ম"
        },
        {
            "value": 19299,
            "title": "সমাজ বিজ্ঞান"
        },
        {
            "value": 192991,
            "title": "যুক্তিবিদ্যা"
        },
        {
            "value": 192992,
            "title": "গার্হস্থ্য বিজ্ঞান"
        },
        {
            "value": 192993,
            "title": "ভুগোল"
        }
    ],

    "20": [
        {
            "value": 201,
            "title": "English"
        },
        {
            "value": 202,
            "title": "Bangla"
        },
        {
            "value": 203,
            "title": "Mathematics"
        },
        {
            "value": 204,
            "title": "Science"
        },
        {
            "value": 205,
            "title": "Art"
        },
        {
            "value": 206,
            "title": "General Knowledge"
        }
    ],
    "21": [
        {
            "value": 211,
            "title": "English"
        },
        {
            "value": 212,
            "title": "Bangla"
        },
        {
            "value": 213,
            "title": "Mathematics"
        },
        {
            "value": 214,
            "title": "Science"
        },
        {
            "value": 215,
            "title": "Computer Science"
        },
        {
            "value": 216,
            "title": "Geography"
        },
        {
            "value": 217,
            "title": "History"
        },
        {
            "value": 218,
            "title": "Ethics & Religion"
        },
        {
            "value": 219,
            "title": "Bangladesh Studies"
        },
        {
            "value": 220,
            "title": "Social Studies"
        },
        {
            "value": 2201,
            "title": "Art"
        }
    ],
    "22": [
        {
            "value": 221,
            "title": "English"
        },
        {
            "value": 222,
            "title": "Bangla"
        },
        {
            "value": 223,
            "title": "Mathematics"
        },
        {
            "value": 224,
            "title": "Science"
        },
        {
            "value": 225,
            "title": "Computer Science"
        },
        {
            "value": 226,
            "title": "Geography"
        },
        {
            "value": 227,
            "title": "History"
        },
        {
            "value": 228,
            "title": "Ethics & Religion"
        },
        {
            "value": 229,
            "title": "Bangladesh Studies"
        },
        {
            "value": 2300,
            "title": "Social Studies"
        },
        {
            "value": 2301,
            "title": "Art"
        }
    ],
    "23": [
        {
            "value": 231,
            "title": "English"
        },
        {
            "value": 232,
            "title": "Bangla"
        },
        {
            "value": 233,
            "title": "Mathematics"
        },
        {
            "value": 234,
            "title": "Science"
        },
        {
            "value": 235,
            "title": "Computer Science"
        },
        {
            "value": 236,
            "title": "Geography"
        },
        {
            "value": 237,
            "title": "History"
        },
        {
            "value": 238,
            "title": "Ethics & Religion"
        },
        {
            "value": 239,
            "title": "Bangladesh Studies"
        },
        {
            "value": 2400,
            "title": "Social Studies"
        },
        {
            "value": 2401,
            "title": "Art"
        }
    ],
    "24": [
        {
            "value": 241,
            "title": "English"
        },
        {
            "value": 242,
            "title": "Bangla"
        },
        {
            "value": 243,
            "title": "Mathematics"
        },
        {
            "value": 244,
            "title": "Science"
        },
        {
            "value": 245,
            "title": "Computer Science"
        },
        {
            "value": 246,
            "title": "Geography"
        },
        {
            "value": 247,
            "title": "History"
        },
        {
            "value": 248,
            "title": "Ethics & Religion"
        },
        {
            "value": 249,
            "title": "Bangladesh Studies"
        },
        {
            "value": 2500,
            "title": "Social Studies"
        },
        {
            "value": 2501,
            "title": "Art"
        }
    ],
    "25": [
        {
            "value": 251,
            "title": "English"
        },
        {
            "value": 252,
            "title": "Bangla"
        },
        {
            "value": 253,
            "title": "Mathematics"
        },
        {
            "value": 254,
            "title": "Science"
        },
        {
            "value": 255,
            "title": "Computing Studies"
        },
        {
            "value": 256,
            "title": "ICT"
        },
        {
            "value": 257,
            "title": "Chemistry"
        },
        {
            "value": 258,
            "title": "Biology"
        },
        {
            "value": 259,
            "title": "Physics"
        },
        {
            "value": 2600,
            "title": "Ethics & Religion"
        },
        {
            "value": 2601,
            "title": "Accounting"
        },
        {
            "value": 2602,
            "title": "Business Studies"
        },
        {
            "value": 2603,
            "title": "Commerce"
        },
        {
            "value": 2604,
            "title": "Economics"
        },
        {
            "value": 2605,
            "title": "Geography"
        },
        {
            "value": 2606,
            "title": "History"
        },
        {
            "value": 2607,
            "title": "Social Studies/Bangladesh Studies"
        },
        {
            "value": 2608,
            "title": "Art"
        },
    ],
    "26": [
        {
            "value": 261,
            "title": "English"
        },
        {
            "value": 262,
            "title": "Bangla"
        },
        {
            "value": 263,
            "title": "Mathematics"
        },
        {
            "value": 264,
            "title": "Science"
        },
        {
            "value": 265,
            "title": "Computing Studies"
        },
        {
            "value": 266,
            "title": "ICT"
        },
        {
            "value": 267,
            "title": "Chemistry"
        },
        {
            "value": 268,
            "title": "Biology"
        },
        {
            "value": 269,
            "title": "Physics"
        },
        {
            "value": 2700,
            "title": "Ethics & Religion"
        },
        {
            "value": 2701,
            "title": "Accounting"
        },
        {
            "value": 2702,
            "title": "Business Studies"
        },
        {
            "value": 2703,
            "title": "Commerce"
        },
        {
            "value": 2704,
            "title": "Economics"
        },
        {
            "value": 2705,
            "title": "Geography"
        },
        {
            "value": 2706,
            "title": "History"
        },
        {
            "value": 2707,
            "title": "Social Studies/Bangladesh Studies"
        },
        {
            "value": 2708,
            "title": "Art"
        },
    ],
    "27": [
        {
            "value": 271,
            "title": "English"
        },
        {
            "value": 272,
            "title": "Bangla"
        },
        {
            "value": 273,
            "title": "Mathematics"
        },
        {
            "value": 274,
            "title": "Science"
        },
        {
            "value": 275,
            "title": "Computing Studies"
        },
        {
            "value": 276,
            "title": "ICT"
        },
        {
            "value": 277,
            "title": "Chemistry"
        },
        {
            "value": 278,
            "title": "Biology"
        },
        {
            "value": 279,
            "title": "Physics"
        },
        {
            "value": 2800,
            "title": "Ethics & Religion"
        },
        {
            "value": 2801,
            "title": "Accounting"
        },
        {
            "value": 2802,
            "title": "Business Studies"
        },
        {
            "value": 2803,
            "title": "Commerce"
        },
        {
            "value": 2804,
            "title": "Economics"
        },
        {
            "value": 2805,
            "title": "Geography"
        },
        {
            "value": 2806,
            "title": "History"
        },
        {
            "value": 2807,
            "title": "Social Studies/Bangladesh Studies"
        },
        {
            "value": 2808,
            "title": "Art"
        },
    ],
    "28": [
        {
            "value": 281,
            "title": "English"
        },
        {
            "value": 282,
            "title": "Bangla"
        },
        {
            "value": 283,
            "title": "Mathematics"
        },
        {
            "value": 284,
            "title": "Science"
        },
        {
            "value": 285,
            "title": "Computing Studies"
        },
        {
            "value": 286,
            "title": "ICT"
        },
        {
            "value": 287,
            "title": "Chemistry"
        },
        {
            "value": 288,
            "title": "Biology"
        },
        {
            "value": 289,
            "title": "Physics"
        },
        {
            "value": 2900,
            "title": "Ethics & Religion"
        },
        {
            "value": 2901,
            "title": "Accounting"
        },
        {
            "value": 2902,
            "title": "Business Studies"
        },
        {
            "value": 2903,
            "title": "Commerce"
        },
        {
            "value": 2904,
            "title": "Economics"
        },
        {
            "value": 2905,
            "title": "Geography"
        },
        {
            "value": 2906,
            "title": "History"
        },
        {
            "value": 2907,
            "title": "Social Studies/Bangladesh Studies"
        },
        {
            "value": 2908,
            "title": "Art"
        },
    ],
    "29": [
        {
            "value": 291,
            "title": "English"
        },
        {
            "value": 292,
            "title": "Bangla"
        },
        {
            "value": 293,
            "title": "Mathematics"
        },
        {
            "value": 294,
            "title": "Science"
        },
        {
            "value": 295,
            "title": "Computing Studies"
        },
        {
            "value": 296,
            "title": "ICT"
        },
        {
            "value": 297,
            "title": "Chemistry"
        },
        {
            "value": 298,
            "title": "Biology"
        },
        {
            "value": 299,
            "title": "Physics"
        },
        {
            "value": 3000,
            "title": "Ethics & Religion"
        },
        {
            "value": 3001,
            "title": "Accounting"
        },
        {
            "value": 3002,
            "title": "Business Studies"
        },
        {
            "value": 3003,
            "title": "Commerce"
        },
        {
            "value": 3004,
            "title": "Economics"
        },
        {
            "value": 3005,
            "title": "Geography"
        },
        {
            "value": 3006,
            "title": "History"
        },
        {
            "value": 3007,
            "title": "Social Studies/Bangladesh Studies"
        },
        {
            "value": 3008,
            "title": "Art"
        },
    ],
    "291": [
        {
            "value": 2911,
            "title": "English"
        },
        {
            "value": 2912,
            "title": "Bangla"
        },
        {
            "value": 2913,
            "title": "Mathematics"
        },
        {
            "value": 2914,
            "title": "Science"
        },
        {
            "value": 2915,
            "title": "Computing Studies"
        },
        {
            "value": 2916,
            "title": "ICT"
        },
        {
            "value": 2917,
            "title": "Chemistry"
        },
        {
            "value": 2918,
            "title": "Biology"
        },
        {
            "value": 2919,
            "title": "Physics"
        },
        {
            "value": 29191,
            "title": "Ethics & Religion"
        },
        {
            "value": 29192,
            "title": "Accounting"
        },
        {
            "value": 29193,
            "title": "Business Studies"
        },
        {
            "value": 29194,
            "title": "Commerce"
        },
        {
            "value": 29195,
            "title": "Economics"
        },
        {
            "value": 29196,
            "title": "Geography"
        },
        {
            "value": 29197,
            "title": "History"
        },
        {
            "value": 29198,
            "title": "Social Studies/Bangladesh Studies"
        },
        {
            "value": 19199,
            "title": "Art"
        },
    ],
    "292": [
        {
            "value": 2921,
            "title": "English"
        },
        {
            "value": 2922,
            "title": "Bangla"
        },
        {
            "value": 2923,
            "title": "Mathematics"
        },
        {
            "value": 2924,
            "title": "Science"
        },
        {
            "value": 2925,
            "title": "Computing Studies"
        },
        {
            "value": 2926,
            "title": "Physics"
        },
        {
            "value": 2927,
            "title": "Chemistry"
        },
        {
            "value": 2928,
            "title": "Biology"
        },
        {
            "value": 2929,
            "title": "Business Studies"
        },
        {
            "value": 29291,
            "title": "Commerce"
        },
        {
            "value": 29292,
            "title": "Accounting"
        },
        {
            "value": 29293,
            "title": "Economics"
        },
        {
            "value": 29294,
            "title": "Geography"
        },
        {
            "value": 29295,
            "title": "History"
        },
        {
            "value": 29296,
            "title": "Religious Studies"
        },
        {
            "value": 29297,
            "title": "Social Studies/Bangladesh Studies"
        },
        {
            "value": 29298,
            "title": " Art"
        }
    ],
    "293": [
        {
            "value": 2931,
            "title": "English"
        },
        {
            "value": 2932,
            "title": "Bangla"
        },
        {
            "value": 2933,
            "title": "Mathematics"
        },
        {
            "value": 2934,
            "title": "Science"
        },
        {
            "value": 2935,
            "title": "Computing Studies"
        },
        {
            "value": 2936,
            "title": "Physics"
        },
        {
            "value": 2937,
            "title": "Chemistry"
        },
        {
            "value": 2938,
            "title": "Biology"
        },
        {
            "value": 2939,
            "title": "Business Studies"
        },
        {
            "value": 29391,
            "title": "Commerce"
        },
        {
            "value": 29392,
            "title": "Accounting"
        },
        {
            "value": 29393,
            "title": "Economics"
        },
        {
            "value": 29394,
            "title": "Geography"
        },
        {
            "value": 29395,
            "title": "History"
        },
        {
            "value": 29396,
            "title": "Religious Studies"
        },
        {
            "value": 29397,
            "title": "Social Studies/Bangladesh Studies"
        },
        {
            "value": 29398,
            "title": " Art"
        }
    ],

    "31": [
        {
            "value": 311,
            "title": "Bangla"
        },
        {
            "value": 312,
            "title": "English"
        },
        {
            "value": 313,
            "title": "Mathematics"
        },
        {
            "value": 314,
            "title": "Social Science"
        },
        {
            "value": 315,
            "title": "Islam and Moral Education"
        },
        {
            "value": 316,
            "title": "Hindu and Moral Education"
        },
        {
            "value": 317,
            "title": "Christian and Moral Education"
        },
        {
            "value": 319,
            "title": "Buddhist and Moral Education"
        }
    ],
    "32": [
        {
            "value": 321,
            "title": "Bangla"
        },
        {
            "value": 322,
            "title": "English"
        },
        {
            "value": 323,
            "title": "Mathematics"
        },
        {
            "value": 324,
            "title": "Social Science"
        },
        {
            "value": 325,
            "title": "Islam and Moral Education"
        },
        {
            "value": 326,
            "title": "Hindu and Moral Education"
        },
        {
            "value": 327,
            "title": "Christian and Moral Education"
        },
        {
            "value": 329,
            "title": "Buddhist and Moral Education"
        }
    ],
    "33": [
        {
            "value": 331,
            "title": "Bangla"
        },
        {
            "value": 332,
            "title": "English"
        },
        {
            "value": 333,
            "title": "Mathematics"
        },
        {
            "value": 334,
            "title": "Science"
        },
        {
            "value": 335,
            "title": "Bangladesh and Global Studies"
        },
        {
            "value": 336,
            "title": "Islam and Moral Education"
        },
        {
            "value": 337,
            "title": "Hindu and Moral Education"
        },
        {
            "value": 338,
            "title": "Christian and Moral Education"
        },
        {
            "value": 339,
            "title": "Buddhist and Moral Education"
        }
    ],
    "34": [
        {
            "value": 341,
            "title": "Bangla"
        },
        {
            "value": 342,
            "title": "English"
        },
        {
            "value": 343,
            "title": "Mathematics"
        },
        {
            "value": 344,
            "title": "Science"
        },
        {
            "value": 345,
            "title": "Bangladesh and Global Studies"
        },
        {
            "value": 346,
            "title": "Islam and Moral Education"
        },
        {
            "value": 347,
            "title": "Hindu and Moral Education"
        },
        {
            "value": 348,
            "title": "Christian and Moral Education"
        },
        {
            "value": 349,
            "title": "Buddhist and Moral Education"
        }
    ],
    "35": [
        {
            "value": 351,
            "title": "Bangla"
        },
        {
            "value": 352,
            "title": "English"
        },
        {
            "value": 353,
            "title": "Mathematics"
        },
        {
            "value": 354,
            "title": "Science"
        },
        {
            "value": 355,
            "title": "Bangladesh and Global Studies"
        },
        {
            "value": 356,
            "title": "Islam and Moral Education"
        },
        {
            "value": 357,
            "title": "Hindu and Moral Education"
        },
        {
            "value": 358,
            "title": "Christian and Moral Education"
        },
        {
            "value": 359,
            "title": "Buddhist and Moral Education"
        }
    ],
    "36": [
        {
            "value": 361,
            "title": "Bangla"
        },
        {
            "value": 362,
            "title": "English"
        },
        {
            "value": 363,
            "title": "Mathematics"
        },
        {
            "value": 364,
            "title": "Science"
        },
        {
            "value": 365,
            "title": "Information and Communication Technology"
        },
        {
            "value": 366,
            "title": "Islam and Moral Education"
        },
        {
            "value": 367,
            "title": "Hindu and Moral Education"
        },
        {
            "value": 368,
            "title": "Christian and Moral Education"
        },
        {
            "value": 369,
            "title": "Buddhist and Moral Education"
        },
        {
            "value": 370,
            "title": "Bangladesh and Global Studies"
        },
        {
            "value": 3715,
            "title": "Agriculture Studies"
        },
        {
            "value": 372,
            "title": "Work and Life Oriented Education"
        },
        {
            "value": 373,
            "title": "Home Science"
        },
        {
            "value": 374,
            "title": "Arts and Crafts"
        },
        {
            "value": 375,
            "title": "Language and Culture of Minority Ethnic Groups"
        },
        {
            "value": 376,
            "title": "Physical Education and Health"
        }
    ],
    "37": [
        {
            "value": 371,
            "title": "Bangla"
        },
        {
            "value": 3721,
            "title": "English"
        },
        {
            "value": 373,
            "title": "Mathematics"
        },
        {
            "value": 374,
            "title": "Science"
        },
        {
            "value": 375,
            "title": "Information and Communication Technology"
        },
        {
            "value": 3761,
            "title": "Islam and Moral Education"
        },
        {
            "value": 377,
            "title": "Hindu and Moral Education"
        },
        {
            "value": 378,
            "title": "Christian and Moral Education"
        },
        {
            "value": 379,
            "title": "Buddhist and Moral Education"
        },
        {
            "value": 3710,
            "title": "Bangladesh and Global Studies"
        },
        {
            "value": 3711,
            "title": "Agriculture Studies"
        },
        {
            "value": 3712,
            "title": "Work and Life Oriented Education"
        },
        {
            "value": 3713,
            "title": "Home Science"
        },
        {
            "value": 3714,
            "title": "Arts and Crafts"
        },
        {
            "value": 3751,
            "title": "Language and Culture of Minority Ethnic Groups"
        },
        {
            "value": 376,
            "title": "Physical Education and Health"
        }
    ],
    "38": [
        {
            "value": 381,
            "title": "Bangla"
        },
        {
            "value": 382,
            "title": "English"
        },
        {
            "value": 383,
            "title": "Mathematics"
        },
        {
            "value": 384,
            "title": "Science"
        },
        {
            "value": 385,
            "title": "Information and Communication Technology"
        },
        {
            "value": 386,
            "title": "Islam and Moral Education"
        },
        {
            "value": 387,
            "title": "Hindu and Moral Education"
        },
        {
            "value": 388,
            "title": "Christian and Moral Education"
        },
        {
            "value": 389,
            "title": "Buddhist and Moral Education"
        },
        {
            "value": 3810,
            "title": "Bangladesh and Global Studies"
        },
        {
            "value": 3811,
            "title": "Agriculture Studies"
        },
        {
            "value": 3812,
            "title": "Work and Life Oriented Education"
        },
        {
            "value": 3813,
            "title": "Home Science"
        },
        {
            "value": 3814,
            "title": "Arts and Crafts"
        },
        {
            "value": 3815,
            "title": "Physical Education and Health"
        }
    ],
    "39": [
        {
            "value": 391,
            "title": "Bangla"
        },
        {
            "value": 3929,
            "title": "English"
        },
        {
            "value": 392,
            "title": "Mathematics"
        },
        {
            "value": 393,
            "title": "Information and Communication Technology"
        },
        {
            "value": 394,
            "title": "Physics"
        },
        {
            "value": 395,
            "title": "Chemistry"
        },
        {
            "value": 396,
            "title": "Biology"
        },
        {
            "value": 397,
            "title": "Higher Mathematics"
        },
        {
            "value": 398,
            "title": "Accounting"
        },
        {
            "value": 399,
            "title": "Finance and Banking"
        },
        {
            "value": 3910,
            "title": "Business Entrepreneurship"
        },
        {
            "value": 39111,
            "title": "Economics"
        },
        {
            "value": 39121,
            "title": "Geography and Environments"
        },
        {
            "value": 39131,
            "title": "Civics and Citizenship"
        },
        {
            "value": 39141,
            "title": "Islam and Moral Education"
        },
        {
            "value": 39151,
            "title": "Hindu and Moral Education"
        },
        {
            "value": 3916,
            "title": "Buddhist and Moral Education"
        },
        {
            "value": 3917,
            "title": "Christian and Moral Education"
        },
        {
            "value": 39181,
            "title": "Bangladesh and Global Studies"
        },
        {
            "value": 39190,
            "title": "History of Bangladesh and World Civilization"
        },
        {
            "value": 3920,
            "title": "Career Education"
        },
        {
            "value": 3921,
            "title": "Agriculture Studies"
        },
        {
            "value": 39220,
            "title": "Home Science"
        },
        {
            "value": 39231,
            "title": "Arts and Crafts"
        },
        {
            "value": 39241,
            "title": "Physical Education, Health Science & Sports"
        }
    ],
    "391": [
        {
            "value": 3911,
            "title": "Bangla"
        },
        {
            "value": 3912,
            "title": "English"
        },
        {
            "value": 3913,
            "title": "Mathematics"
        },
        {
            "value": 3914,
            "title": "Information and Communication Technology"
        },
        {
            "value": 3915,
            "title": "Physics"
        },
        {
            "value": 39161,
            "title": "Chemistry"
        },
        {
            "value": 39171,
            "title": "Biology"
        },
        {
            "value": 3918,
            "title": "Higher Mathematics"
        },
        {
            "value": 3919,
            "title": "Accounting"
        },
        {
            "value": 39191,
            "title": "Finance and Banking"
        },
        {
            "value": 39192,
            "title": "Business Entrepreneurship"
        },
        {
            "value": 39193,
            "title": "Economics"
        },
        {
            "value": 39195,
            "title": "Geography and Environments"
        },
        {
            "value": 39196,
            "title": "Civics and Citizenship"
        },
        {
            "value": 39197,
            "title": "Islam and Moral Education"
        },
        {
            "value": 39198,
            "title": "Hindu and Moral Education"
        },
        {
            "value": 39199,
            "title": "Buddhist and Moral Education"
        },
        {
            "value": 391991,
            "title": "Christian and Moral Education"
        },
        {
            "value": 391992,
            "title": "Bangladesh and Global Studies"
        },
        {
            "value": 391993,
            "title": "History of Bangladesh and World Civilization"
        },
        {
            "value": 391994,
            "title": "Career Education"
        },
        {
            "value": 391995,
            "title": "Agriculture Studies"
        },
        {
            "value": 391996,
            "title": "Home Science"
        },
        {
            "value": 391997,
            "title": "Arts and Crafts"
        },
        {
            "value": 391998,
            "title": "Physical Education, Health Science & Sports"
        }
    ],
    "392": [
        {
            "value": 39211,
            "title": "Bangla"
        },
        {
            "value": 3922,
            "title": "English"
        },
        {
            "value": 3923,
            "title": "Mathematics"
        },
        {
            "value": 3924,
            "title": "Information and Communication Technology"
        },
        {
            "value": 3925,
            "title": "Physics"
        },
        {
            "value": 3926,
            "title": "Chemistry"
        },
        {
            "value": 3927,
            "title": "Biology"
        },
        {
            "value": 3928,
            "title": "Higher Mathematics"
        },
        {
            "value": 392291,
            "title": "Statistics"
        },
        {
            "value": 392211,
            "title": "Accounting"
        },
        {
            "value": 392221,
            "title": "Finance and Banking"
        },
        {
            "value": 392231,
            "title": "Business Organization and Management"
        },
        {
            "value": 392212,
            "title": "Production Management and Marketing"
        },
        {
            "value": 392251,
            "title": "Economics"
        },
        {
            "value": 392261,
            "title": "Civics and Citizenship"
        },
        {
            "value": 39227,
            "title": "Islamic History and Culture"
        },
        {
            "value": 39228,
            "title": "History"
        },
        {
            "value": 39229,
            "title": "Social Work"
        },
        {
            "value": 392210,
            "title": "Sociology"
        },
        {
            "value": 39221,
            "title": "Logic"
        },
        {
            "value": 39222,
            "title": "Home Science"
        },
        {
            "value": 39223,
            "title": "Career Education"
        },
        {
            "value": 39224,
            "title": "Agriculture Studies"
        },
        {
            "value": 39225,
            "title": "Home Science"
        },
        {
            "value": 39226,
            "title": "Geography"
        }
    ],

}
