import axios from "axios";
import React, { useCallback, useEffect, useState } from 'react';
import { Alert } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { districts_en } from "../../utils/location/District";
import { upazillas_en } from "../../utils/location/Upazilla";
import { class_name_en } from "../../utils/medium_data/ClassData";
import { medium_en } from "../../utils/medium_data/Medium";
import { subject_en } from "../../utils/medium_data/subject3";
import LoaderComponent from "../common/LoaderComponent";
import TutorsAdvetisement from "../common/TutorsAdvetisement";
import Layout from "../layout/Layout";
import GSideBarTutorRequest from "./GSideBarTutorRequest";

const GRequestEditFunc = () =>  {

    const [select_area, setSelectArea] = useState([])
    const [classNames, setClassNames] = useState([])
    const [subjects, setSelectSubjects] = useState([])
    const [errors, setErrors] = useState('')
    const [editData, setEditData] = useState([]);
    const [hasError, setHasError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [isOthers, setisOthers] = useState(false)


    const [name,setName] = useState(''); 
    const [studentSchool,setStudentSchool] = useState(''); 
    const [contactAddress,setContactAddress] = useState(''); 
    const [mobileNumber,setMobileNumber] = useState(''); 
    const [email,setEmail] = useState(''); 
    const [additionalInformation,setAdditionalInformation] = useState(''); 
    const [district, setDistrict] = useState('')
    const [area, setArea] = useState('')
    const [className, setClassName] = useState('')
    const [subject, setSelectSubject] = useState([])
    const [selectMedium, setSelectMedium] = useState('')
    const [daysPerWeek, setDaysPerWeek] = useState('')
    const [gender, setGender] = useState('')
    const [salaryRange, setSalaryRange] = useState('')
    const [desiredTutorGender, setDesiredTutorGender] = useState('')
    const params = useParams();
    const history = useHistory();

    
    


    const getTutorRequestBuyId = useCallback((id) => {

        const salaryRanges = [
            '1000-2000','2000-3000','3000-4000','4000-5000','5000-6000','6000-7000','7000-8000','8000-9000','9000-10000','Negotiable'
        ]

        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/get_tutor_request/${params.id}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(response=> {

                setEditData(response.data.data)
                setName(response.data.data.s_name)
                setStudentSchool(response.data.data.student_school)
                setMobileNumber(response.data.data.contact_mobile)
                setEmail(response.data.data.contact_email)
                setAdditionalInformation(response.data.data.additional_information)
                setClassName(response.data.data.class_name)
                setSelectSubject(JSON.parse(response.data.data.subject))
                setDaysPerWeek(response.data.data.days_per_weak)
                setGender(response.data.data.gender_of_student)
                setDesiredTutorGender(response.data.data.tutor_gender)
                setContactAddress(response.data.data.contact_address)
                setDistrict(response.data.data.district)
                setArea(response.data.data.area)

                //if salary range is others
                if(salaryRanges.includes(response.data.data.salary_range)){
                    setSalaryRange(response.data.data.salary_range)
                }else{
                    setisOthers(true)
                    setSalaryRange(response.data.data.salary_range)
                }
                
                setSelectArea(upazillas_en[districts_en.find(x => x.title === response.data.data.district).value])
                setClassNames(class_name_en[medium_en.find((medium) => medium.title === response.data.data.medium).value])
                let tempClassNames = class_name_en[medium_en.find((medium) => medium.title === response.data.data.medium).value];
                let classNameId = tempClassNames.find((className) => className.title === editData.class_name)?.value;
                setSelectSubjects(subject_en[classNameId])
                setSelectMedium(medium_en.find((medium) => medium.title == response.data.data.medium)?.title)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    },[editData.class_name,params.id])

    
    useEffect(() => {
        setTimeout(setLoading(false),5000)
        getTutorRequestBuyId()
    },[getTutorRequestBuyId])

    const handleChange = (event) => {

        const inputName = event.target.name;
        const inputValue = event.target.value;
        
        if(inputName === 's_name'){
            setName(inputValue)
        }

        if(inputName === 'student_school'){
            setStudentSchool(inputValue)
        }

        if(inputName === 'contact_address'){
            setContactAddress(inputValue)
        }

        if(inputName === 'contact_mobile'){
            setMobileNumber(inputValue)
        }

        if(inputName === 'contact_email'){
            setEmail(inputValue)
        }

        if(inputName === 'additional_information'){
            setAdditionalInformation(inputValue)
        }

        if(inputName === 'additional_information'){
            setAdditionalInformation(inputValue)
        }

        if(inputName === 'days_per_weak'){
            setDaysPerWeek(inputValue)
        }

        if(inputName === 'gender_of_student'){
            setGender(inputValue)
        }
        
        if(inputName === 'tutor_gender'){
            setDesiredTutorGender(inputValue)
        }

        if(inputName === 'salary_range'){
            setSalaryRange(inputValue)
            setisOthers(false)
        }

        if(inputName === 'salary_range_others'){
            setSalaryRange(inputValue)
        }
    }

    const onChangeDistrict = (e) => {
        if (e.target.value) {
            setDistrict(districts_en.find((district) => district.value == e.target.value)?.title)
            setSelectArea(upazillas_en[e.target.value])
        }
    }

    const onChangeArea = (e) => {
        if (e.target.value) {
            setArea(select_area.find((area) => area.value == e.target.value)?.title)
        }
    }

    const onChangeMedium = (e) => {
        if (e.target.value) { 
            setSelectMedium(medium_en.find((medium) => medium.value == e.target.value)?.title)
            setClassNames(class_name_en[e.target.value])
        }

        if(editData.medium != medium_en.find((medium) => medium.value == e.target.value)?.title){
            setSelectSubject([])
        }
    }

    const onChangeClass = (e) => {
        if (e.target.value) {    
            const tempMedium = medium_en.find((medium) => medium.title == selectMedium)?.value;
            setClassName(class_name_en[tempMedium].find((className) => className.value == e.target.value).title)
            setSelectSubjects(subject_en[e.target.value])
        }
    }

    const onChangeSubject = (e) => {
        let {name,value,checked} = e.target;
    
        if(checked) {
            if (subject.includes(value)) {
                setSelectSubject(subject.filter(sel => sel !== value));
            } else {
                setSelectSubject([...subject, value]);
            }
        }else {
            setSelectSubject(subject.filter(sel => sel !== value));
        }
    }


    const handleUpdateRequest = (event) => {
        event.preventDefault();
        
        const data = JSON.stringify({
            "guardian_post_id": params.id,
            "s_name": name,
            "district": district,
            "area": area,
            "medium": selectMedium,
            "class_name": className,
            "subject": JSON.stringify(subject),
            "student_school": studentSchool,
            "days_per_weak": daysPerWeek,
            "gender_of_student": gender,
            "salary_range": salaryRange,
            "tutor_gender": desiredTutorGender,
            "contact_address": contactAddress,
            "contact_email": email,
            "additional_information":additionalInformation
        });


        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/update_tutor_request',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                toast(response.data.message);
                history.push('/guardian/post')
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onCloseError  = () => {
        this.setState({
            hasError: false
        })
    }

    const handleChangeOthers = () => {
        setisOthers(true)
    }


    

    return (
            loading ? 
                <LoaderComponent/> : 
            (
                <Layout>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover/>
        
                    <div className="tuition-request">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-8 tuition-request-form">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Edit Guardian Tutor Request</h5>
                                        </div>
                                        <form onSubmit={handleUpdateRequest}>
                                            <div className="card-body">
                                                <div className="container submit-form">

                                                    {hasError ?
                                                        <Alert variant="danger" onClose={onCloseError} dismissible>
                                                            {errors}
                                                        </Alert>
                                                        :
                                                        <span></span>
                
                                                    }
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Student Full Name:</label>
                                                        <input type="text"
                                                            name="s_name"
                                                            onChange={handleChange}
                                                            value={name}
                                                            className="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Full Name..." required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Select District:</label>
                                                        <select className="form-select form-select-sm"
                                                                name="district"
                                                                onChange={onChangeDistrict}
                                                                aria-label=".form-select-sm example">
                                                            <option >Select District</option>
                                                            {
                                                                districts_en && districts_en.map((area)=>{
                                                                    return (
                                                                        <option value={area.value} selected={editData.district === area.title}>{area.title}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Select Area:</label>
                                                        <select className="form-select form-select-sm"
                                                                name="area"
                                                                onChange={onChangeArea}
                                                                aria-label=".form-select-sm example">
                                                            <option selected>Select area</option>
                                                            {
                                                                select_area && select_area.map((area)=>{
                                                                    return (
                                                                        <option value={area.value} selected={editData.area === area.title}>{area.title}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Select Medium:</label>
                                                        <select className="form-select form-select-sm"
                                                                name="medium"
                                                                onChange={onChangeMedium}
                                                                aria-label=".form-select-sm example">
                                                            <option selected>Select Medium</option>
                                                            {
                                                                medium_en && medium_en.map((medium)=>{
                                                                    return (
                                                                        <option value={medium.value} selected={editData.medium === medium.title}>{medium.title}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Select Class:</label>
                                                        <select className="form-select form-select-sm"
                                                                name="class_name"
                                                                onChange={onChangeClass}
                                                                aria-label=".form-select-sm example">
                                                            <option selected>Select Class</option>
                                                            {
                                                                classNames && classNames.map((class_name)=>{
                                                                    return (
                                                                        <option value={class_name.value} selected={editData.class_name === class_name.title}>{class_name.title}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Select Class:</label>
                                                        <br/>
                                                        {
                                                            subjects && subjects?.length > 0 ? subjects.map((tempSubject)=>{
                                                                return (
                                                                    <div className="form-check form-check-inline">
                                                                    <input className="form-check-input"
                                                                        type="checkbox"
                                                                        onChange={onChangeSubject}
                                                                        id="subjectChecbbox"
                                                                        checked={subject.indexOf(tempSubject.title) !== -1}
                                                                        value={tempSubject.title}/>
                                                                    {tempSubject.title}
                                                                    </div>
                                                                );
                                                            })
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Student School/ College:</label>
                                                        <input type="text"
                                                            name="student_school"
                                                            onChange={handleChange}
                                                            value={studentSchool}
                                                            className="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="" required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Days per week:</label>
                                                        <select className="form-select form-select-sm"
                                                                name="days_per_weak"
                                                                onChange={handleChange}
                                                                aria-label=".form-select-sm example">
                                                            <option selected value="Any Days">Any Days</option>
                                                            <option value="01" selected={daysPerWeek === '01'}>1 Day</option>
                                                            <option value="02" selected={daysPerWeek === '02'}>2 Days</option>
                                                            <option value="03" selected={daysPerWeek === '03'}>3 Days</option>
                                                            <option value="04" selected={daysPerWeek === '04'}>4 Days</option>
                                                            <option value="05" selected={daysPerWeek === '05'}>5 Days</option>
                                                            <option value="06" selected={daysPerWeek === '06'}>6 Days</option>
                                                            <option value="07" selected={daysPerWeek === '07'}>7 Days</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Gender of Student:</label>
                                                        <select className="form-select form-select-sm"
                                                                name="gender_of_student"
                                                                onChange={handleChange}
                                                                aria-label=".form-select-sm example">
                                                            <option selected>Select Gender of Student</option>
                                                            <option value="Male" selected={gender == 'Male'}>Male</option>
                                                            <option value="Female" selected={gender == 'Female'}>Female</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-lg-12">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Salary Range:  <span style={{color:"red"}}>*</span>
                                                        </label>
                                                        <br/>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                    type="radio"
                                                                    name="salary_range"
                                                                    id="1000-2000"
                                                                    onChange={handleChange}
                                                                    value="1000-2000"
                                                                    checked={salaryRange == '1000-2000'}
                                                                    />
                                                            1000-2000
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                    type="radio"
                                                                    name="salary_range"
                                                                    id="2000-3000"
                                                                    onChange={handleChange}
                                                                    checked={salaryRange == '2000-3000'}
                                                                    value="2000-3000"/>2000-3000
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                    type="radio"
                                                                    name="salary_range"
                                                                    id="3000-4000"
                                                                    onChange={handleChange}
                                                                    checked={salaryRange == '3000-4000'}
                                                                    value="3000-4000"/>3000-4000
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                    type="radio"
                                                                    name="salary_range"
                                                                    id="4000-5000"
                                                                    onChange={handleChange}
                                                                    checked={salaryRange == '4000-5000'}
                                                                    value="4000-5000"/>4000-5000
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                    type="radio"
                                                                    name="salary_range"
                                                                    id="5000-6000"
                                                                    onChange={handleChange}
                                                                    checked={salaryRange == '5000-6000'}
                                                                    value="5000-6000"/>5000-6000
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                    type="radio"
                                                                    name="salary_range"
                                                                    id="6000-7000"
                                                                    onChange={handleChange}
                                                                    checked={salaryRange == '6000-7000'}
                                                                    value="6000-7000"/>6000-7000
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                    type="radio"
                                                                    name="salary_range"
                                                                    id="7000-8000"
                                                                    onChange={handleChange}
                                                                    checked={salaryRange == '7000-8000'}
                                                                    value="7000-8000"/>7000-8000
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                    type="radio"
                                                                    name="salary_range"
                                                                    id="8000-9000"
                                                                    onChange={handleChange}
                                                                    checked={salaryRange == '8000-9000'}
                                                                    value="8000-9000"/>8000-9000
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                    type="radio"
                                                                    name="salary_range"
                                                                    id="9000-10000"
                                                                    onChange={handleChange}
                                                                    checked={salaryRange == '9000-10000'}
                                                                    value="9000-10000"/>9000-10000
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                    type="radio"
                                                                    name="salary_range"
                                                                    id="negotiable"
                                                                    onChange={handleChange}
                                                                    checked={salaryRange == 'Negotiable'}
                                                                    value="Negotiable"/>Negotiable
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                    type="radio"
                                                                    name="salary_range"
                                                                    id="others"
                                                                    onChange={handleChangeOthers}
                                                                    checked={isOthers === true}
                                                                    value="others"/>Others
                                                        </div>
                                                        {isOthers ?

                                                            <input type="number"
                                                                    name="salary_range_others"
                                                                    onChange={handleChange}
                                                                    className="form-control mt-2"
                                                                    id="salary_range_others"
                                                                    aria-describedby="salary_range_others"
                                                                    placeholder="Expected Salary..."
                                                                    value={salaryRange}
                                                                    />
                                                            :

                                                            <></>

                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Desired Tutor Gender:</label>
                                                        <select className="form-select form-select-sm"
                                                                name="tutor_gender"
                                                                onChange={handleChange}
                                                                aria-label=".form-select-sm example">
                                                            <option selected>Select desired tutor gender</option>
                                                            <option value="Male" selected={editData.gender_of_student === 'Male'}>Male</option>
                                                            <option value="Female" selected={editData.gender_of_student === 'Female'}>Female</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlTextarea1">Detail Address:</label>
                                                        <textarea className="form-control"
                                                                name="contact_address"
                                                                onChange={handleChange}
                                                                value={contactAddress}
                                                                id="exampleFormControlTextarea1" rows="3">
                                                        </textarea>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Mobile:</label>
                                                        <input type="text"
                                                            name="contact_mobile"
                                                            onChange={handleChange}
                                                            value={mobileNumber}
                                                            className="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="" readOnly/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Email:</label>
                                                        <input type="text"
                                                            name="contact_email"
                                                            onChange={handleChange}
                                                            value={email}
                                                            className="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="" required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlTextarea1">Additional Info in details (if multiple subjects mention here):</label>
                                                        <textarea className="form-control"
                                                                name="additional_information"
                                                                onChange={handleChange}
                                                                value={additionalInformation}
                                                                id="exampleFormControlTextarea1" rows="3">
                                                        </textarea>
                                                    </div>
                                                    
                                                </div>
                                        
                                            </div>
                                            <div className="card-footer">
                                                <div className="container">
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-primary">Update Tutor Request</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4">
                                    <GSideBarTutorRequest/>
                                    <TutorsAdvetisement/>
                                </div>
                            </div>
                        </div>
                    </div>
        
                </Layout>
            )
    );
   
}

export default GRequestEditFunc;