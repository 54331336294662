import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import GuardianBanglaText from "../../frontend/img/guardian/Guardian.png";
import GuardianImg from "../../frontend/img/guardian/img.png";
import tutorLoginImg from "../../frontend/img/tuitor/img.png";
import tutorLoginBanglaText from "../../frontend/img/tuitor/tutor-bangle-test.png";
import { guardian, media, tutor } from "../../route";
import { authGuardianValidate } from "../../utils/GuardianAuth";
import { authMediaValidate } from "../../utils/MediumAuth";
import { authValidate } from "../../utils/TutionAuth";
import Layout from "../layout/Layout";
import mediaBanglaText from "../../frontend/img/media/tutorChai.png";
import mediaImg from "../../frontend/img/media/agent.png";

const ChooseLogin = () => {

    useEffect(() => {
    if (authValidate()) {
      window.location.href = `${tutor}/dashboard`;
    }
    if (authGuardianValidate()) {
      window.location.href = `${guardian}/dashboard`;
    }

    if(authMediaValidate()){
      window.location.href = `${media}/dashboard`;
    }
  }, []);
  return (
    <Layout>
      <div className="choose-login chooseLoginWrap joinUsWrap">
        <div className="container">
          <div className="row align-items-center joinRow" style={{ minHeight: "50vh" }}>
            {authValidate() ? (
              <></>
            ) : (
              <div className="col-md-4 col-sm-12 col-12 joinCol"> 
                <div className="joinUsSingleItem tution">
                  <div className="joinUsInner">
                    <div className="joinUsImg">
                        <Link to={`${tutor}/login`} className="signBtn">
                          <img
                            src={tutorLoginImg}
                            alt="tutorLoginImg"
                            className="img-fluid"
                          />
                        </Link>
                    </div>
                    <div className="joinUsContent">
                        <Link to={`${tutor}/login`} className="signBtn">
                          <div className="joinUs">
                            <span>Join as</span>
                          </div>
                          <h3>Tutor</h3>
                          <div className="banglaText tutorLoginBanglaText">
                            <img
                              src={tutorLoginBanglaText}
                              alt="tutorLoginImg"
                              className="img-fluid"
                            />
                          </div>
                        </Link>
                      <div className="btnSignUp">
                        <Link to={`${tutor}/login`} className="signBtn">
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {authGuardianValidate() ? (
              <></>
            ) : (
              <div className="col-md-4 col-sm-12 col-12 joinCol">
                <div className="joinUsSingleItem guardian">
                  <div className="joinUsInner">
                    <div className="joinUsImg">
                        <Link to={`${guardian}/login`} className="signBtn">
                          <img
                            src={GuardianImg}
                            alt="tutorLoginImg"
                            className="img-fluid"
                          />
                        </Link>
                    </div>
                    <div className="joinUsContent">
                        <Link to={`${guardian}/login`} className="signBtn">
                          <div className="joinUs">
                            <span>Join as</span>
                          </div>
                          <h3>Guardian</h3>
                          <div className="banglaText GuardianBanglaText">
                            <img
                              src={GuardianBanglaText}
                              alt="tutorLoginImg"
                              className="img-fluid"
                            />
                          </div>
                        </Link>
                      <div className="btnSignUp">
                        <Link to={`${guardian}/login`} className="signBtn">
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {authMediaValidate() ? (
              <></>
            ) : (
              <div className="col-md-4 col-sm-12 col-12 joinCol">
                <div className="joinUsSingleItem guardian">
                  <div className="joinUsInner">
                    <div className="joinUsImg">
                        <Link to={`${media}/login`} className="signBtn">
                          <img
                            src={mediaImg}
                            alt="tutorLoginImg"
                            className="img-fluid"
                          />
                        </Link>
                    </div>
                    <div className="joinUsContent">
                        <Link to={`${media}/login`} className="signBtn">
                          <div className="joinUs">
                            <span style={{ backgroundColor: '#7c3bfc' }}>Join as</span>
                          </div>
                          <h3 style={{ color: '#7c3bfc' }}>Media</h3>
                          <div className="banglaText GuardianBanglaText">
                            <img
                              src={mediaBanglaText}
                              alt="tutorLoginImg"
                              className="img-fluid"
                            />
                          </div>
                        </Link>
                      <div className="btnSignUp">
                        <Link to={`${media}/login`} className="signBtn" style={{ backgroundColor: '#7c3bfc' }}>
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChooseLogin;
