import React from 'react';
import Layout from "../../layout/Layout";
import Loader from "../LoaderComponent";
import {Link} from "react-router-dom";

const NotFound = () => {
    return (
        <Layout>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 ">
                            <div className="col-sm-10 col-sm-offset-1 text-center pb-5">

                                    <h1 className="text-center ">404</h1>

                                    <a href="/" className="link_404">Go to Home</a>

                            </div>
                        </div>
                    </div>
                </div>
        </Layout>
    );
};

export default NotFound;