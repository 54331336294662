import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGetGuardianDetailsQuery } from "../../features/guardian/guardianApi";
import { guardian } from "../../route";
import { AuthGuardian, authGuardianValidate } from "../../utils/GuardianAuth";
import { districts_en } from "../../utils/location/District";
import { postcode_en } from "../../utils/location/post_code";
import { upazillas_en } from "../../utils/location/Upazilla";
import TutorsAdvetisement from "../common/TutorsAdvetisement";
import Layout from "../layout/Layout";

const GDashboard = () => {


  const[uuid,setUUID] = useState('')
  const[loader,setLoader] = useState(true)

  useEffect(() => {
    if (!authGuardianValidate()) {
      history.push(`${guardian}/login`);
    }else{
      const{uuid} = AuthGuardian();
      setUUID(uuid)
      setLoader(false)
    }
  },[])
  
  const [guardianDetails, setGuardianDetails] = useState([]);
  const [kidDetails, setKidDetails] = useState([
    {
      id : '1',
      g_kid_name: '',
      g_kid_class: '',
      g_kid_medium: '',
      g_kid_gender : ''
    }
  ]);
  const [isGuardianEdit, setIsGuardianEdit] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [isShowMsg, setIsShowMsg] = useState(false);
  const [select_area, setSelectArea] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const history = useHistory();



  useEffect(() => {
    if (authGuardianValidate()) {
      const config = {
        method: "get",
        url:
          process.env.REACT_APP_API_URL +
          `/api/web/v1/get_guardian_details/${uuid}`,
        headers: {
          Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
        },
      };
  
      axios(config)
        .then((response) => {
          setGuardianDetails(response.data.data);
          if (!response.data.data.g_district) {
          } else {
            const district_id = districts_en.find(
              (x) => x.title == response.data.data.g_district
            ).value;
            setSelectArea(upazillas_en[district_id]);
          }
          if (!response.data.data.g_postal) {
          } else {
            setPostalCode(postcode_en[response.data.data.g_area] ?? []);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [uuid]);


  const handleOnChange = (e) => {
    // if(e.target.value === "") {
    //     return;
    // }
    setGuardianDetails({
      ...guardianDetails,
      [e.target.name]: e.target.value,
    });
  };


  const getGuardianDetails = (uuid) => {
    const config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `/api/web/v1/get_guardian_details/${uuid}`,
      headers: {
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setGuardianDetails(response.data.data);
        if (!response.data.data.g_district) {
        } else {
          const district_id = districts_en.find(
            (x) => x.title == response.data.data.g_district
          ).value;
          setSelectArea(upazillas_en[district_id]);
        }
        if (!response.data.data.g_postal) {
        } else {
          setPostalCode(postcode_en[response.data.data.g_area] ?? []);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editGuardianDashboard = () => {
    setIsGuardianEdit(true);
    setIsShowMsg(false);
  };

  const onGuardianUpdate = (response) => {
    response.preventDefault();
    if (!guardianDetails.g_district.length) {
      setIsShowMsg(true);
      setResMsg("District is a required field!!");
      return;
    }
    if (!guardianDetails.g_area.length) {
      setIsShowMsg(true);
      setResMsg("Area is a required field!!");
      return;
    }
    // if(!guardianDetails.g_postal.length) {
    //     setIsShowMsg(true);
    //     setResMsg("Postal code is a required field!!");
    //     return;
    // }
    const data = JSON.stringify({
      uuid: guardianDetails.uuid,
      g_name: guardianDetails.g_name,
      g_msisdn: guardianDetails.g_msisdn,
      g_email: guardianDetails.g_email,
      g_address: guardianDetails.g_address,
      g_district: guardianDetails.g_district,
      g_area: guardianDetails.g_area,
      g_postal: guardianDetails.g_postal,
      g_no_kid: guardianDetails.g_no_kid,
    });

    const localData = JSON.parse(localStorage.getItem("guardianLogin"))
    let update_data = {...localData, g_name: guardianDetails.g_name};
    localStorage.setItem("guardianLogin", JSON.stringify(update_data));

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/guardian_update",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setResMsg(response.data.message);
        setIsShowMsg(true);
        setIsGuardianEdit(false);
        getGuardianDetails(AuthGuardian().uuid);
        onCloseMsgTimer();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChangeDistrict = (e) => {
    setPostalCode([]);
    setSelectArea([]);
    if (e.target.value === "") {
      setGuardianDetails({
        ...guardianDetails,
        "g_district": "",
        "g_area": "",
        "g_postal": "",
      });
      return;
    }
    setGuardianDetails({
      ...guardianDetails,
      [e.target.name]: e.target.value,
    });
    //setSelectArea(getLearningPlace().find(cntry => cntry.district_name === e.target.value).area)
    setGuardianDetails({
      ...guardianDetails,
      "g_district": districts_en.find((x) => x.value == e.target.value).title,
      "g_area": "",
      "g_postal": "",
    });
    setSelectArea(upazillas_en[e.target.value]);
    // this.setState({
    //     district: districts_en.find(x => x.value == e.target.value).title,
    //     select_area:
    // })
  };

  const onChangeArea = (e) => {
    //alert(e.target.value)
    setPostalCode([]);
    if (e.target.value === "") {
      return;
    }
    setGuardianDetails({
      ...guardianDetails,
      [e.target.name]: e.target.value,
    });
    if (typeof postcode_en[e.target.value] == "undefined") {
      setPostalCode([]);
    } else {
      setPostalCode(postcode_en[e.target.value]);
    }
    //setPostalCode(postcode_en[e.target.value])
  };

  const onCloseMsg = () => {
    setIsShowMsg(!isShowMsg);
  };

  const onCloseMsgTimer = () => {
    const timeId = setTimeout(() => {
      setIsShowMsg(false);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  };

  if (!authGuardianValidate()) {
    history.push("/guardian/login");
  }

  const handleKidOnChange = (e, index) => {

    const name = e.target.name;
    const value = e.target.value;

    const clone = [...kidDetails];
    const obj = clone[index];

    if(name === 'g_kid_name'){
      obj.g_kid_name = value
    }

    if(name === 'g_kid_class'){
      obj.g_kid_class = value
    }

    if(name === 'g_kid_medium'){
      obj.g_kid_medium = value
    }

    if(name === 'g_kid_gender'){
      obj.g_kid_gender = value
    }

    clone[index] = obj;
    setKidDetails([...clone])
  }

  const handleAddKid = () => {
    let id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    let newKid = {
      id : id,
      g_kid_name: '',
      g_kid_class: '',
      g_kid_medium: '',
      g_kid_gender : ''
    }
    setKidDetails([...kidDetails, newKid])
  }


  return (
    <Layout>
      {
        loader ? <div>Loading...</div>:
        <div className="guardian-dashboard">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="guardian-profile">
                  <>
                    {isShowMsg ? (
                      <Alert variant="success" onClick={onCloseMsg} dismissible>
                        {resMsg}
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </>
                  {isGuardianEdit ? (
                    <div className="guardian-profile-edit">
                      <form onSubmit={onGuardianUpdate}>
                        <div className="card">
                          <div className="card-header">
                            <h5>Guardian Profile Edit</h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="form-group col-12 col-lg-6">
                                <label htmlFor="g_name">Name</label>
                                <input
                                  type="text"
                                  name="g_name"
                                  onChange={handleOnChange}
                                  className="form-control"
                                  id="g_name"
                                  maxLength="120"
                                  value={guardianDetails.g_name}
                                  placeholder="Name..."
                                  required
                                />
                              </div>
                              <div className="form-group col-12 col-lg-6">
                                <label htmlFor="g_email">Email</label>
                                <input
                                  type="email"
                                  name="g_email"
                                  onChange={handleOnChange}
                                  className="form-control"
                                  id="g_email"
                                  value={guardianDetails.g_email}
                                  placeholder="Email..."
                                  required
                                />
                              </div>
                              <div className="form-group col-12 col-lg-6">
                                <label htmlFor="exampleInputEmail1">
                                  Select District:
                                </label>
                                <select
                                  className="form-select form-select-sm"
                                  name="g_district"
                                  onChange={onChangeDistrict}
                                  aria-label=".form-select-sm example"
                                >
                                  <option selected value="">
                                    ---Select District---
                                  </option>
                                  {districts_en.map((area) => {
                                    return (
                                      <option
                                        value={area.value}
                                        selected={
                                          area.title == guardianDetails.g_district
                                        }
                                      >
                                        {area.title}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group col-12 col-lg-6">
                                <label htmlFor="exampleInputEmail1">
                                  Select Area:
                                </label>
                                <select
                                  className="form-select form-select-sm"
                                  name="g_area"
                                  onChange={onChangeArea}
                                  aria-label=".form-select-sm example"
                                >
                                  <option selected value="">
                                    ---Select area---
                                  </option>
                                  {select_area.map((area) => {
                                    return (
                                      <option
                                        value={area.title}
                                        selected={
                                          area.title === guardianDetails.g_area
                                        }
                                      >
                                        {area.title}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group col-12 col-lg-6">
                                <label htmlFor="exampleInputEmail1">
                                  Select Postal Code:
                                </label>
                                <select
                                  className="form-select form-select-md"
                                  name="g_postal"
                                  onChange={handleOnChange}
                                  aria-label=".form-select-sm example"
                                >
                                  <option selected value="">
                                    ---Select Postal Code---
                                  </option>
                                  {postalCode.map((code) => {
                                    return (
                                      <option
                                        value={code}
                                        selected={
                                          code === guardianDetails.g_postal
                                        }
                                      >
                                        {code}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group col-12 col-lg-6">
                                <label htmlFor="g_no_kid">Number of Kids:</label>
                                <input
                                  type="number"
                                  name="g_no_kid"
                                  onChange={handleOnChange}
                                  className="form-control"
                                  id="g_no_kid"
                                  min={0}
                                  max={100}
                                  value={guardianDetails.g_no_kid}
                                  placeholder="Number of kids"
                                  required
                                />
                              </div>
                              <div className="form-group col-12 col-lg-12">
                                <label htmlFor="g_address">Details Address</label>
                                <textarea
                                  name="g_address"
                                  onChange={handleOnChange}
                                  className="form-control"
                                  id="g_address"
                                  value={guardianDetails.g_address}
                                  maxLength="120"
                                  placeholder="Address..."
                                  required
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn btn-sm profile-update-btn"
                            >
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                              Update Guardian
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div className="guardian-profile-details">
                      <div className="card">
                        <div className="card-header">
                          <h5>Guardian Profile</h5>
                        </div>
                        <div className="card-body">
                          <table className="table guardianDetailsTable">
                            <tr>
                              <td>
                                <b>Name</b>
                              </td>
                              <td>:</td>
                              <td>{guardianDetails.g_name}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Email</b>
                              </td>
                              <td>:</td>
                              <td>{guardianDetails.g_email}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Mobile Number</b>
                              </td>
                              <td>:</td>
                              <td>{guardianDetails.g_msisdn}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Address</b>
                              </td>
                              <td>:</td>
                              <td>{guardianDetails.g_address}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>District</b>
                              </td>
                              <td>:</td>
                              <td>{guardianDetails.g_district}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Area</b>
                              </td>
                              <td>:</td>
                              <td>{guardianDetails.g_area}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Postal Code</b>
                              </td>
                              <td>:</td>
                              <td>{guardianDetails.g_postal}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>No of Kids</b>
                              </td>
                              <td>:</td>
                              <td>{guardianDetails.g_no_kid}</td>
                            </tr>
                          </table>
                          {/* <div className="row">
                            <div className="col-6">
                              <b>Name:</b>
                            </div>
                            <div className="col-6">{guardianDetails.g_name}</div>
                            <div className="col-6">
                              <b>Email:</b>
                            </div>
                            <div className="col-6">{guardianDetails.g_email}</div>
                            <div className="col-6">
                              <b>Mobile Number:</b>
                            </div>
                            <div className="col-6">
                              {guardianDetails.g_msisdn}
                            </div>
                            <div className="col-6">
                              <b>Address:</b>
                            </div>
                            <div className="col-6">
                              {guardianDetails.g_address}
                            </div>
                            <div className="col-6">
                              <b>District:</b>
                            </div>
                            <div className="col-6">
                              {guardianDetails.g_district}
                            </div>
                            <div className="col-6">
                              <b>Area:</b>
                            </div>
                            <div className="col-6">{guardianDetails.g_area}</div>
                            <div className="col-6">
                              <b>Postal Code:</b>
                            </div>
                            <div className="col-6">
                              {guardianDetails.g_postal}
                            </div>
                            <div className="col-6">
                              <b>No of Kids:</b>
                            </div>
                            <div className="col-6">
                              {guardianDetails.g_no_kid}
                            </div>
                          </div> */}
                        </div>
                        <div className="card-footer">
                          <button
                            className="btn btn-sm profile-edit-btn"
                            onClick={editGuardianDashboard}
                          >
                            <i className="fa fa-edit"></i> Update Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-4">
                {/* <GSideBar/> */}
                <TutorsAdvetisement />
              </div>
            </div>
          </div>
        </div>
      }
    </Layout>
  );
};

export default GDashboard;
