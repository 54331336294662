import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { toast } from "react-toastify";
import { AuthGuardian, authGuardianValidate } from "../../utils/GuardianAuth";
import { districts_en } from "../../utils/location/District";
import { upazillas_en } from "../../utils/location/Upazilla";
import { class_name_en } from "../../utils/medium_data/ClassData";
import { medium_en } from "../../utils/medium_data/Medium";
import { subject_en } from "../../utils/medium_data/subject3";
import { Auth, authValidate } from "../../utils/TutionAuth";
import HeaderTitle from "../common/HeaderTitle";
import LoaderComponent from "../common/LoaderComponent";
import TutorsAdvetisement from "../common/TutorsAdvetisement";
import Layout from "../layout/Layout";
const TuitionList = () => {

    
    const [tuitionList,setTuitionList] = useState([]);
    const [loggedinTuitionList,setLoggedinTuitionList] = useState([]);
    const [allApplyJob,setAllApplyJob] = useState([]);
    const [loggedInTutor, setLoggedInTutor] = useState({});
    const pageSize = 5;
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [isLoader, setIsLoader] = useState(true);

    const [mediumId,setMediumId] = useState('');
    const [classId,setClassId] = useState('');
    const [allUpozilla,setAllUpozilla] = useState([])
    const [allClass, setAllClass] = useState([])
    const [allSubject, setAllSubject] = useState([])
    const [district,setDistrict] = useState('');
    const [upozilla,setUpozilla] = useState('');
    const [medium,setMedium] = useState('');
    const [className, setClassName] = useState('')
    const [subject, setSubject] = useState([])
    const authTutor = Auth();
    let {id} = useParams();
    let location = useLocation();


    useEffect(() => {
        onHandleFilter(district,upozilla,medium,className,subject,id)
        setIsLoader(false);
    },[])



    useEffect(() =>{
        setLoggedInTutor(JSON.parse(localStorage.getItem('loginData')))
    },[])

    
    const onHandleApplyJob = (tutors_id,post_id) => {

        const data = JSON.stringify({"tutors_id": tutors_id, "guardian_id": AuthGuardian().uuid,"g_msisdn":AuthGuardian().g_msisdn,"post_id": post_id});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/tuition-apply',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                AuthGuardian()?.uuid && getAllJob()
                toast(response.data.message);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    
    const getAllJob = () => {
        
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/get_applied_post_list_id/${AuthGuardian()?.uuid}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(function (response) {
                setAllApplyJob(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onHandleChangeDistrict = (e) => {
        const {name, value} = e.target;
        if (value === '') {
            setAllUpozilla([])
            onHandleFilter("","",medium,className,subject)
            return false
        }
        setDistrict(districts_en.find((district) => district.value == value)?.title)
        setAllUpozilla(upazillas_en[value])
        onHandleFilter(districts_en.find((district) => district.value == value)?.title,"",medium,className,[])
    }
    const onHandleChangeArea = (e) => {
        if(e.target.value === ''){
            setUpozilla('')
            onHandleFilter(district,'',medium,className,subject)
        }
        setUpozilla(e.target.value)
        onHandleFilter(district,e.target.value,medium,className,subject)
    }

    const onChangeMedium = (e) => {
        const { name, value } = e.target;
        if(value === ''){
            setAllClass([])
            setAllSubject([])
            setMedium("")
            onHandleFilter(district,upozilla,"","","")
            return true
        }
        setMediumId(value)
        setAllClass(class_name_en[value])
        setMedium(medium_en.find((medium) => medium.value == value)?.title)
        onHandleFilter(district,upozilla,medium_en.find((medium) => medium.value == value)?.title,"",[])
    }
    const onChangeClass = (e) => {
        const {name, value} = e.target;
        if (value === '') {
            setAllSubject([])
            onHandleFilter(district,upozilla,medium,"","")
            return false;
        }
        setClassId(value)
        setClassName(class_name_en[mediumId].find((className) => className.value == value)?.title)
        setAllSubject(subject_en[value])
        onHandleFilter(district,upozilla,medium,class_name_en[mediumId].find((className) => className.value == value)?.title,[])

    }
    const onChangeSubject = (e) => {
        let {name, value, checked} = e.target;
        if(e.target.value === ""){
            setSubject([])
            onHandleFilter(district,upozilla,medium,className,[])
            return false
        }
        
        if(checked){
            if(subject.includes(value)){
                setSubject(subject.filter(sel => sel !== value))
                onHandleFilter(district,upozilla,medium,className,subject.filter(sel => sel !== value))
            }else{
                setSubject([...subject,value])
                onHandleFilter(district,upozilla,medium,className,[...subject,value])
            }
        }else{
            setSubject(subject.filter(sel => sel !== value))
            onHandleFilter(district,upozilla,medium,className,subject.filter(sel => sel !== value))
        }
    
    }

    const onHandleFilter = (district,upozilla,medium,className, subject,id) => {

        const authTutor = Auth();

        let config = {};
        setLoading(true)
        const data = JSON.stringify({
            "district" : district ?? "",
            "area" : upozilla ?? "",
            "medium" : medium ?? "",
            "class" : className ?? "",
            "subject" : subject ?? []
        })
        if(!id){
            if(authTutor){
                config = {
                    method: 'post',
                    url: process.env.REACT_APP_API_URL + `/api/web/v1/my-tuition-list/tutors_id/${authTutor?.uuid}?page=1&pageSize=${pageSize}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
                    },
                    data: data
                };
            }else{
                config = {
                    method: 'post',
                    url: process.env.REACT_APP_API_URL + `/api/web/v1/tuition-list?page=1&pageSize=${pageSize}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
                    },
                    data: data
                };
            }
        }else{
            let string = '';
            if(!district && !upozilla && !medium && !subject.length>0 && !className){
                string = process.env.REACT_APP_API_URL + `/api/web/v1/tuition-list/${id}`;
            }else{
            
                if(authTutor){
                    string =process.env.REACT_APP_API_URL + `/api/web/v1/my-tuition-list/tutors_id/${authTutor?.uuid}?page=1&pageSize=${pageSize}`;
                }else{
                    string=process.env.REACT_APP_API_URL + `/api/web/v1/tuition-list?page=1&pageSize=${pageSize}`;
                }
            }
            config = {
                method: 'post',
                url: string,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
                },
                data: data
            };
        }

        

        axios(config)
            .then(function (response) {
                setLoading(false)
                setTotalPages(response.data.totalPages)
                setTuitionList(response.data.data)
                AuthGuardian()?.uuid && getAllJob()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onNextPage = () => {

        setPage(page + 1)
        let page1 = page + 1
        const authTutor = Auth();
        let config = {};
        setLoading(true)
        const data = JSON.stringify({
            "district" : district ?? "",
            "area" : upozilla ?? "",
            "medium" : medium ?? "",
            "class" : className ?? "",
            "subject" : subject ?? ""
        })

        if(authTutor){
            config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + `/api/web/v1/my-tuition-list/tutors_id/${authTutor.uuid}?page=${page1}&pageSize=${pageSize}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
                },
                data: data
            };
        }else{
            config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + `/api/web/v1/tuition-list?page=${page1}&pageSize=${pageSize}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
                },
                data: data
            };
        }

        axios(config)
            .then(function (response) {
                setLoading(false)
                setTotalPages(response.data.totalPages)
                
                setTuitionList([...tuitionList,...response.data.data])
                AuthGuardian()?.uuid && getAllJob()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    
    return (
        <>

            <Layout>
                <div className="tuition-page-content">
                    {
                        isLoader ? (
                            <LoaderComponent/>
                        ) : (
                            <div className="container">
                                <div className="row">
                                    <HeaderTitle title={'Tuition wanted'}/>
                                    <div className="col-lg-4">
                                        <div className="tuition-filter">
                                            <div className="card">
                                                <div className="card-header bg-header">
                                                    Search Tuition
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        {/*{selectDistrict} {selectArea}*/}
                                                        <div className="form-row">
                                                            <div className="form-group col-12">
                                                                <select className="form-select"
                                                                        name="district_filter"
                                                                        onChange={(e)=>{
                                                                            onHandleChangeDistrict(e)
                                                                        }}
                                                                        aria-label="Default select example">
                                                                    <option selected value="">Select District</option>
                                                                    {
                                                                        districts_en.map((district)=>{
                                                                            return (
                                                                                <option value={district.value}>{district.title}</option>
                                                                            );
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>

                                                            <div className="form-group col-12">
                                                                <select className="form-select"
                                                                        name="area_filter"
                                                                        onChange={(e)=> {
                                                                            onHandleChangeArea(e)
                                                                        }}
                                                                        aria-label="Default select example">
                                                                    <option selected value="" >Select area</option>
                                                                    {
                                                                        allUpozilla.map((area)=>{
                                                                            return (
                                                                                <option value={area.title}>{area.title}</option>
                                                                            );
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>

                                                            <div className="form-group col-12">
                                                                <select className="form-select"
                                                                        name="medium_filter"
                                                                        onChange={(e) => {
                                                                            onChangeMedium(e)
                                                                        }}
                                                                        aria-label="Default select example">
                                                                    <option selected value="">Select Medium</option>
                                                                    {
                                                                        medium_en.map((medium)=>{
                                                                            return (
                                                                                <option value={medium.value}>{medium.title}</option>
                                                                            );
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>

                                                            <div className="form-group col-12">
                                                                <select className="form-select"
                                                                        name="class_filter"
                                                                        onChange={(e)=>{
                                                                            onChangeClass(e)
                                                                        }}
                                                                        aria-label="Default select example">
                                                                    <option selected value="">Select Class</option>
                                                                    {
                                                                        allClass.map((classdata)=>{
                                                                            return (
                                                                                <option value={classdata.value}>{classdata.title}</option>
                                                                            );
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>

                                                            <div className="form-group col-12">
                                                                <label className="col-form-label">
                                                                    Preferred Subjects:
                                                                    <span className="text-danger">*</span>
                                                                </label>
                                                                <br/>
                                                                    {
                                                                        allSubject.map((subject,index)=>{
                                                                            return (
                                                                                <div key={index} className="form-check form-check-inline">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        //name="t_preferred_class[]"
                                                                                        onChange={onChangeSubject}
                                                                                        id={subject.value}
                                                                                        value={subject.title}
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor={subject.value}>
                                                                                        {subject.title} 
                                                                                    </label>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <TutorsAdvetisement/>
                                    </div>
                                    <div className="col-lg-8">

                                        {tuitionList.length > 0 ?
                                            (
                                                tuitionList && tuitionList.map((tuition_data,index)=>(
                                                    <div className="col-md-12">
                                                        <div key={index} className='allGuardianRequest'>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h5 className="t_title">I want to teach {tuition_data.class_name}, {tuition_data.medium} student.</h5>
                                                                    <div className="row py-2">
                                                                        <div className="col-md-6">
                                                                            <b className="custom-text-color">Expected Salary :</b> {tuition_data.salary_range} Tk/Month
                                                                        </div>
                                                                    </div>
                                                                    <small>
                                                                        <b>Teaching subjects :</b> {tuition_data?.subject?.replace(/\[|\]|"/g," ")}
                                                                        <br/>
                                                                        <b>Days: </b>{tuition_data.days_per_weak ?? tuition_data.days_per_weak} Days/Week
                                                                        <br/>
                                                                        <b>Class: </b> { tuition_data.class_name ?? tuition_data.class_name } 
                                                                        <br/>
                                                                        <b>Medium: </b> { tuition_data.medium ?? tuition_data.medium } 
                                                                    </small>
                                                                    <div className="row pt-2">
                                                                        <div className="col-md-8">
                                                                            <div>
                                                                                <>
                                                                                    <i className="fa fa-map-marker text-danger"></i> &nbsp;
                                                                                    {tuition_data.area},{tuition_data.district}
                                                                                    <br/>
                                                                                    <br/>
                                                                                    <small>
                                                                                        Published: <ReactTimeAgo date={tuition_data.created_at} locale="en-US"/>
                                                                                    </small>
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="custom-view-button pull-right">
                                                                        {authGuardianValidate() ?
                                                                            <>
                                                                                {allApplyJob.indexOf(tuition_data.tutors_post_id) === -1 ?

                                                                                    <button className="btn btn-sm btn-success pull-right"
                                                                                            variant="primary"
                                                                                            onClick={() => onHandleApplyJob(tuition_data.tutors_id,tuition_data.tutors_post_id)}>
                                                                                        Send request&nbsp;&nbsp;
                                                                                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                                                    </button>

                                                                                    :

                                                                                    <button className="btn btn-sm btn-danger pull-right"
                                                                                            disabled={true}>
                                                                                        Already Sent
                                                                                    </button>

                                                                                }
                                                                            </>
                                                                            :
                                                                    
                                                                            !authGuardianValidate () && !authValidate() ?
                                                                            <Link to={`/guardian/login?url=${location.pathname.slice(1)}`} className="btn btn-sm btn-danger pull-right"
                                                                                    >
                                                                                Only guardians can apply for this tuition.
                                                                            </Link>
                                                                            :
                                                                            !id &&
                                                                                <a href={`/tutor/request-tuition-edit/${tuition_data.tutors_post_id}`} className="btn btn-sm btn-danger pull-right">
                                                                                 Edit Request
                                                                                </a>
                                                                            

                                                                        

                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )

                                            :

                                            (
                                                <span>No Tuition Found...<br/><br/><br/><br/><br/></span>
                                            )
                                        }

                                        {totalPages > page ? (
                                            <button
                                                className="load-more"
                                                onClick={() => {
                                                onNextPage()
                                                }}
                                            >
                                                {loading ? "Loading..." : "Load More"}
                                            </button>
                                            ) : (
                                                <span>&nbsp;</span>
                                        )}
                                    </div>



                                </div>
                            </div>
                        )
                    }
                </div>
            </Layout>


        </>

    );
};

export default TuitionList;