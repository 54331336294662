import React from 'react';

const GSideBarTutorRequest = () => {
    return (
        <div className='helpInfo'>
            <div className="card">
            <div className="card-header">
                <h5>HELP & INFO</h5>
            </div>
            <div className="card-body">
                <strong>Q. How to get the desired tutor?</strong>
                <p className="mt-1 text-secondary">
                    A. Please fill up the “Request for a Tutor” form and press the “Request Tutor” button. This will help you find your desired tutor.
                </p>

                <strong>Q. After filling up the form, what will happen?</strong>
                <p className="mt-1 text-secondary">
                    A. The information will be sent to our support team, and they will publish your post in the “Tuitions” section.
                </p>
            </div>
        </div>
            </div>
    );
};

export default GSideBarTutorRequest;