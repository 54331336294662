import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { guardian, media } from "../../route";
import { authGuardianValidate } from "../../utils/GuardianAuth";
import { authMediaValidate } from "../../utils/MediumAuth";
import { authValidate } from "../../utils/TutionAuth";
import { msisdnValidate } from "../../utils/Validation";
import Captcha from "../common/Captcha/Captcha";
// import TuitionSuggestion from "../common/TuitionSuggestion";
import Layout from "../layout/Layout";
import GRequestTutorResponse from "./GRequestTutorResponse";

function GLogin(props) {
    const {register, handleSubmit, watch, formState: {errors}} = useForm();

    const [inputData,setInputData] = useState({
        msisdn : "",
        otp_code: "",
        g_name: "",
        g_email: "",
        g_msisdn: "",
        g_password: "",
        g_photo: "",
        g_login_by: ""
    })
    const [formType,setFormType] = useState("loginType");

    const [isRequestSubmit,setIsRequestSubmit] = useState(false)
    const [resMsg, setResMsg] = useState("");
    const [isShowMsg, setIsShowMsg] = useState(false);
    const [isButtonDisable,setIsButtonDisable] = useState(true)
    const [otpData,setOtpData] = useState('')
    const [isShowPassword, setIsShowPassword] = useState(false);
    const onShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    };


    const history = useHistory();

    useEffect(()=> {
        document.title = "GUARDIAN LOGIN";
    },[])
    
    const handleOnChange = (e) => {
        setResMsg("");
        setIsShowMsg(false);

        setInputData({
            ...inputData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmitLogin = (e) => {
        e.preventDefault();

        const data = JSON.stringify({"g_msisdn": inputData.g_msisdn, "g_password": inputData.g_password});

        if (!msisdnValidate(inputData.g_msisdn)) {
            setResMsg("Enter Valid Phone Number.");
            setIsShowMsg(true);
            return false;
        }

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/guardian_login`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if(response.data.success) {
                    toast(response.data.message)
                    localStorage.clear();
                    localStorage.setItem('guardianLogin', JSON.stringify(response.data.data));
                    setIsRequestSubmit(true)
                } else {
                    setIsShowMsg(true)
                    setResMsg(response.data.message)
                }
            })
            .catch(function (error) {
                setIsShowMsg(true);
                setResMsg(error.response.data.message);
            });


    }

    //social_login
    const responseMobile = () => {
        setFormType("msisdnForm")
    }

    const responseGoogle = (response) => {
        setFormType("msisdnForm")
        setInputData({
            msisdn : "",
            otp_code: "",
            g_name: response.profileObj.name ?? "",
            g_email: response.profileObj.email ?? "",
            g_photo: response.profileObj.imageUrl ?? "",
            g_login_by: "google_login"
        })
    }

    const errorresponseGoogle = (response) => {
        console.log(response)
    }

    const responseFacebook = (response) => {
      console.log(response.status);
      if(response.status == "unknown") {
          return false
      }
        setFormType("msisdnForm")
        setInputData({
            msisdn : "",
            otp_code: "",
            g_name: response.name ?? "",
            g_email: response.email ?? "",
            g_photo: response.picture.data.url ?? "",
            g_login_by: "facebook_login"
        })
    }
    //end social login

    const onSubmitMsisdn = (event) => {
        event.preventDefault()

        if (!msisdnValidate(inputData.msisdn)) {
            setResMsg("Please enter valid mobile number.");
            setIsShowMsg(true);
            return false;
        }

        const data = JSON.stringify({"g_msisdn": inputData.msisdn});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/is_guardian_exists`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if(response.data.success) {
                    sendOtp()
                } else {
                    setIsShowMsg(true)
                    setResMsg(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const sendOtp = () => {

        if (!msisdnValidate(inputData.msisdn)) {
            setResMsg("Please enter valid mobile number.");
            setIsShowMsg(true);
            return false;
        }

        const data = JSON.stringify({"msisdn": inputData.msisdn});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp_send',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                if(response.data.success){
                    setFormType("OTPVerificationForm")
                    setResMsg("")
                    setIsShowMsg(false)
                    setOtpData(response.data.data.secret_key)
                } else {
                    setIsShowMsg(true)
                    setResMsg(response.data.message)
                }
            })
            .catch(error=> {
                setResMsg("Invalid Mobile Number")
                console.log(error);
            });


    }

    const onSubmitVerifyOTP = (event) => {
        event.preventDefault()

        const data = JSON.stringify({"msisdn": inputData.msisdn, "secret_key": inputData.otp_code});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp/verification',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                if(response.data.success){
                    formSubmit()
                } else {
                    setIsShowMsg(true)
                    setResMsg(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    if (authGuardianValidate()) {
        if(history.location.search){
            history.push(`/${history.location.search.slice(5)}`);
        }else{
            history.push("/guardian/dashboard");
        }
    }

    if (authValidate()) {
        //return <TutorDashboard/>
        history.push("/tutor/dashboard");
    }

    if (authMediaValidate()) {
        history.push(`${media}/dashboard`);
    }

    const formSubmit = () => {
        const data = JSON.stringify({
            "g_name": inputData.g_name,
            "g_email": inputData.g_email,
            "g_msisdn": inputData.msisdn,
            "g_password": inputData.msisdn,
            "g_photo": inputData.g_photo,
            "g_login_by": inputData.g_login_by
        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/guardian_signup',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                toast(response.data.message)
                localStorage.clear();
                localStorage.setItem('guardianLogin', JSON.stringify(response.data.data));
                setIsRequestSubmit(true)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onCloseMsg = () => {
        setIsShowMsg(!isShowMsg);
    }

    const onReSendOtp = (e) => {
        e.preventDefault()
        const data = JSON.stringify({"msisdn": inputData.msisdn});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp_send',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setIsShowMsg(true)
                setResMsg("ReSend OTP")
            })
            .catch(error=> {
                setIsShowMsg(true)
                setResMsg("Invalid Mobile Number")
                console.log(error);
            });
    }

    const onChangeHandleCaptch = (value) => {
        setIsButtonDisable(!value)
        if (!msisdnValidate(inputData.msisdn)) {
            setResMsg("Please enter valid mobile number.");
            setIsShowMsg(true);
            return false;
        }

    }

    if(isRequestSubmit){
        return (
            <GRequestTutorResponse/>
        )
    }

    if(formType === "msisdnForm") {
        return (
            <Layout>
                <div className="guardianLoginWrap">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <div className="formWrap">
                                    <div className="formInner">
                                        <div className="formCenter">
                                            <h3 className="text-center">Guardian LOGIN</h3>
                                            <form onSubmit={onSubmitMsisdn}>
                                                    <label>Enter Phone Number</label>
                                                    <input type="number"
                                                           className="form-control form-control-lg"
                                                           name="msisdn"
                                                           onChange={handleOnChange}
                                                           placeholder="Mobile Number..." required/>
                                                    <div className="text-danger">
                                                        {resMsg}
                                                    </div>
                                                    <div className="pt-2 pb-2">
                                                        <Captcha onChangeHandleCaptch={onChangeHandleCaptch}/>
                                                    </div>
                                                    <center>
                                                        <button className="btn btn-md otp-button" disabled={isButtonDisable}>
                                                            Send OTP
                                                            <i className="fa fa-send"></i>
                                                        </button>
                                                    </center>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
    else if(formType === "OTPVerificationForm"){
        return (
            <Layout>
                <div className="guardianLoginWrap">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <div className="formWrap">
                                    <div className="formInner">
                                        <div className="formCenter">
                                            <h3 className="text-center">Guardian LOGIN</h3>
                                            <form onSubmit={onSubmitVerifyOTP}>
                                                <fieldset>

                                                    <p>Verification code sent to {inputData.msisdn}.
                                                        Enter the code you received below.</p>
                                                    <>
                                                        {isShowMsg ?
                                                            <Alert variant="success" onClick={onCloseMsg} dismissible>
                                                                {resMsg}
                                                            </Alert>
                                                            :
                                                            <></>
                                                        }
                                                    </>

                                                    <label>Verification OTP: {otpData}</label>
                                                    <input type="number"
                                                           className="form-control form-control-lg"
                                                           name="otp_code"
                                                           onChange={handleOnChange}
                                                           placeholder="Verification OTP..."
                                                           required/>
                                                    <center className="pt-2">
                                                        <button className="btn btn-md otp-button">
                                                            Verify OTP
                                                            <i className="fa fa-send"></i>
                                                        </button>
                                                    </center>
                                                    <div className="text-center">
                                                        Didn't receive your OTP? <Link onClick={onReSendOtp}>Resend OTP</Link>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    } else {
        return (
            <Layout>
                <div className="guardianLoginWrap">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <div className="formWrap">
                                    <div className="formInner">
                                        <div className="formCenter">
                                            <h3 className="text-center">Guardian Log In</h3>

                                            <>
                                                {isShowMsg ?
                                                    <Alert variant="danger" onClick={onCloseMsg} dismissible>
                                                        {resMsg}
                                                    </Alert>
                                                    :
                                                    <></>
                                                }
                                            </>

                                            <form onSubmit={handleSubmitLogin}>
                                                <div className="form-group">
                                                    <label htmlFor="mobile_number">Mobile Number</label>
                                                    <input type="number"
                                                           name="g_msisdn"
                                                           className="form-control"
                                                           onChange={handleOnChange}
                                                           id="mobile_number"
                                                           placeholder="Mobile Number..."
                                                           required={true}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Password</label>
                                                    <div className="loginInput">
                                                        <input type={isShowPassword ? "text" : "password"}
                                                            name="g_password"
                                                            className="form-control"
                                                            onChange={handleOnChange}
                                                            id="exampleInputPassword1"
                                                            placeholder="Password"/>
                                                             <i
                                                                onClick={onShowPassword}
                                                                className={
                                                                isShowPassword
                                                                    ? "fa fa-eye"
                                                                    : "fa fa-eye-slash"
                                                                }
                                                            ></i>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-between">
                                                    <div className="rem">
                                                        <div className="remItem">
                                                            <label htmlFor="rem">
                                                                <input type="checkbox" id='rem'/> Remember me
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        <Link to={`${guardian}/forget-password`} className="form-check-label"
                                                              htmlFor="exampleCheck1">Forgot Password?</Link>
                                                    </p>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary form-control">Login
                                                    </button>
                                                </div>
                                                <div className="text-center">
                                                    OR
                                                </div>
                                            </form>

                                            {/*<button className="btn btn-md btn-dark mobile-login" onClick={responseMobile}>*/}
                                            {/*    <i className="fa fa-phone"></i>&nbsp; Login with Mobile Number*/}
                                            {/*</button>*/}

                                            {/* <>
                                                <GoogleLogin
                                                    clientId="57603680101-3e4coig0d6dfcjal5u5u8ps1a3bje0q0.apps.googleusercontent.com"
                                                    buttonText="Login with Google"
                                                    theme="dark"
                                                    onSuccess={responseGoogle}
                                                    onFailure={errorresponseGoogle}
                                                    cookiePolicy="single_host_origin"
                                                    id="customBtn"
                                                    className="google-login-btn"
                                                />
                                            </>

                                            <>
                                                <FacebookLogin
                                                    appId="410827307447319"
                                                    size="small"
                                                    autoLoad={false}
                                                    fields="name,email,picture"
                                                    scope="public_profile,user_friends"
                                                    callback={responseFacebook}
                                                    icon="fa-facebook"
                                                    textButton="Login with Facebook"
                                                    cssClass="kep-login-facebook"
                                                />
                                            </> */}

                                            <div className="pt-2 text-center">
                                                Don't have an account.
                                                <Link to="/guardian/signup" className="form-check-label"
                                                      htmlFor="exampleCheck1">
                                                    {"  "} Sign Up {" "}
                                                </Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <TuitionSuggestion/> */}
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default GLogin;