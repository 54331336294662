import axios from "axios";
import React, { Component } from 'react';
import { toast, ToastContainer } from "react-toastify";
import { AuthGuardian, authGuardianValidate } from "../../../utils/GuardianAuth";
import Captcha from "../../common/Captcha/Captcha";
import GRequestTutorResponse from "../../guardian/GRequestTutorResponse";
import GSideBarTutorRequest from "../../guardian/GSideBarTutorRequest";
import Layout from "../../layout/Layout";

import { districts_en } from "../../../utils/location/District";
import { upazillas_en } from "../../../utils/location/Upazilla";
import { class_name_en } from "../../../utils/medium_data/ClassData";
import { medium_en } from "../../../utils/medium_data/Medium";
import { subject_en } from "../../../utils/medium_data/subject3";

import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { guardian } from '../../../route';
import { msisdnValidate } from "../../../utils/Validation";
import LoaderComponent from "../../common/LoaderComponent";
import TutorsAdvetisement from "../../common/TutorsAdvetisement";
import TuitionRequestLogin from "./TuitionRequestLogin";

class TutorRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            select_area : [],
            className: [],
            select_subject: [],
            isLoading: true,

            isOtpShow: false,
            isShowOtpMsg: false,
            otpMessage: '',

            selectMedium: '',
            errors: {},
            hasError: false,
            isButtonDisable: true,
            isRequestSubmit: false,
            otpData:'',
            selectSubjects: [],
            isOthers: false,
            isAllSubj: false,
            componentType: ''

        }
        this.handleChange = this.handleChange.bind(this);
        this.onCloseError = this.onCloseError.bind(this);

        this.onHandleOTPSend = this.onHandleOTPSend.bind(this);
        this.onHandleVerifyOTP = this.onHandleVerifyOTP.bind(this);
        this.handleSubmitRequest = this.handleSubmitRequest.bind(this);
        this.handleSubmitRequestLogin = this.handleSubmitRequestLogin.bind(this);

        this.onChangeDistrict = this.onChangeDistrict.bind(this);
        this.onChangeMedium = this.onChangeMedium.bind(this);
        this.onChangeClass = this.onChangeClass.bind(this);

        this.onChangeHandleCaptch = this.onChangeHandleCaptch.bind(this);
        this.handleReSubmitOTP = this.handleReSubmitOTP.bind(this);
        this.onCloseMsg = this.onCloseMsg.bind(this);
        this.handleOnChangeSubject = this.handleOnChangeSubject.bind(this);
        this.handleChangeOthers = this.handleChangeOthers.bind(this);
        this.handleOnChangeAllSubject = this.handleOnChangeAllSubject.bind(this);
        this.onRequestOTP = this.onRequestOTP.bind(this);
        this.onCheckGuardianExists = this.onCheckGuardianExists.bind(this);

    }

    componentDidMount() {
        this.setState({
            isLoading: false
        })
        document.title = "TUTOR REQUEST";
    }

    handleChange(event) {

        const newItem = event.target.name;
        const newValue = event.target.value;
        this.setState({
            [newItem] :newValue
        })

        if(newItem == 'salary_range') {
            this.setState({
                isOthers: false
            })
        }

    }

    onChangeDistrict(e) {
        if(e.target.value === "") {
           this.setState({
               district: '',
               area: '',
               select_area: []
           })
           return;
        }
        this.setState({
            district: districts_en.find(x => x.value == e.target.value).title,
            select_area: upazillas_en[e.target.value]
        })
    }

    onChangeMedium(e) {
        this.setState({
            medium: "",
            className: [],
            class_name: "",
            select_subject: []
        })
        if(e.target.value === "") {
            return false;
        }
        this.setState({
            medium: medium_en.find(mn => mn.value == e.target.value).title ?? "",
            medium_id: e.target.value,
            className: class_name_en[e.target.value] ?? []
        })
        // //alert(JSON.stringify(getEducationMedium().find(cntry => cntry.medium_name === e.target.value).class_name));
        // this.setState({
        //     className : getEducationMedium().find(m => m.medium_name === e.target.value).class_name
        // });
    }

    onChangeClass(e) {
        this.setState({
            class_name: "",
            class_name_id: "",
            select_subject: [],
            subject: []
        })
        if(e.target.value === "") {
            return false;
        }
        this.setState({
            class_name: class_name_en[this.state.medium_id].find(cn => cn.value == e.target.value).title ?? "",
            class_name_id: e.target.value,
            select_subject: subject_en[e.target.value] ?? []
        })
    }

    onHandleOTPSend(e) {
        e.preventDefault()

        if(this.validate()){
            return false;
        }

        if(authGuardianValidate()) {

            this.handleSubmitRequestLogin();

        } else {

            this.onCheckGuardianExists(this.state.contact_mobile)

        }
    }

    onCheckGuardianExists(contact_mobile) {
        const data = JSON.stringify({"g_msisdn": contact_mobile});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/is_guardian_exists`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));

                if(response.data.success) {
                    this.setState({
                        componentType:'LOGIN',
                    })
                } else {
                    this.onRequestOTP(contact_mobile)
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onRequestOTP(contact_mobile){
        const data = JSON.stringify({"msisdn": contact_mobile});
        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/otp_send',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                toast(response.data.message);
                if (response.data.success) {
                    this.setState({
                        isOtpShow: true,
                        componentType: 'OTP',
                        otpData: response.data.data.secret_key
                    })
                } else {
                    alert(response.data);
                    this.setState({
                        isOtpShow: false
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onHandleVerifyOTP(e) {
        e.preventDefault();

        const data = JSON.stringify({"msisdn": this.state.contact_mobile, "secret_key": this.state.OTPCODE});
        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp/verification',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                if(response.data.success){
                    toast(response.data.message);
                    this.handleSubmitRequest();
                } else {
                    this.setState({
                        isShowOtpMsg: true,
                        otpMessage: response.data.message
                    })
                }
            })
            .catch(function (error) {
                console.log(error);

            });
    }

    

    handleSubmitRequest() {
        const data = JSON.stringify({
            "s_name": this.state.s_name ?? "N/A",
            "district": this.state.district,
            "area": this.state.area,
            "medium": this.state.medium,
            "class_name": this.state.class_name,
            "subject": JSON.stringify(this.state.selectSubjects),
            "student_school":this.state.student_school ?? '',
            "days_per_weak": this.state.days_per_weak,
            "gender_of_student": this.state.gender_of_student,
            "salary_range": this.state.isOthers ? this.state.salary_range_others : this.state.salary_range,
            "tutor_gender": this.state.tutor_gender,
            "contact_address": this.state.contact_address ?? "N/A",
            "msisdn": this.state.contact_mobile,
            "contact_email": this.state.contact_email ?? "N/A",
            "additional_information": this.state.additional_information ?? "N/A"
        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/insert_tutor_request_without_id',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                localStorage.clear();
                localStorage.setItem('guardianLogin', JSON.stringify(response.data.data));
                this.setState({
                    isOtpShow: false,
                    isRequestSubmit: true
                })
                toast(response.data.message);
                window.location.href = `${guardian}/tutor-request-successful`;
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    handleSubmitRequestLogin() {

        const data = JSON.stringify({
            "guardian_id": AuthGuardian().uuid,
            "s_name": this.state.s_name ?? "N/A",
            "district": this.state.district,
            "area": this.state.area,
            "medium": this.state.medium,
            "class_name": this.state.class_name,
            "subject": JSON.stringify(this.state.selectSubjects),
            "student_school": this.state.student_school ?? '',
            "days_per_weak": this.state.days_per_weak,
            "gender_of_student": this.state.gender_of_student,
            "salary_range": this.state.isOthers ? this.state.salary_range_others : this.state.salary_range,
            "tutor_gender": this.state.tutor_gender,
            "contact_address": this.state.contact_address ?? "N/A",
            "msisdn": AuthGuardian().g_msisdn ?? this.state.contact_mobile,
            "contact_email": this.state.contact_email ?? "N/A",
            "additional_information": this.state.additional_information ?? "N/A"
        });


        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/insert_tutor_request',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                this.setState({
                    isOtpShow: false,
                    isRequestSubmit: true
                })
                toast(response.data.message);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onCloseError (){
        this.setState({
            hasError: false
        })
    }

    onChangeHandleCaptch(value) {
        this.setState({
            isButtonDisable: !value
        });
    }

    validate() {
        let errors = {};
        let hasError = false;

        if (!msisdnValidate(this.state.contact_mobile)) {
            hasError = true;
            errors['contact_mobile'] = "Please enter valid phone number."
        }

        // if (!this.state.s_name) {
        //     hasError = true;
        //     errors['s_name'] = "Name field cannot be empty."
        // }

        if (!this.state.district) {
            hasError = true;
            errors["district"] = "District Field Cannot be empty"
        }

        if (!this.state.area) {
            hasError = true;
            errors["area"] = "Area Field Cannot be empty"
        }

        if (!this.state.medium) {
            hasError = true;
            errors["medium"] = "Medium Field Cannot be empty"
        }
        //
        if (!this.state.class_name) {
            hasError = true;
            errors['class_name'] = "Class Field Cannot be empty"
        }
        //
        if (!this.state.selectSubjects || this.state.selectSubjects.length <= 0) {
           hasError = true;
           errors['subject'] = "Subject Field Cannot be empty"
        }

        // if (!this.state.student_school) {
        //    hasError = true;
        //    errors['student_school'] = "This Field Cannot be empty"
        // }
        //
        if (!this.state.days_per_weak) {
            hasError = true;
            errors['days_per_weak'] = "Days per week Field Cannot be empty"
        }

        if(this.state.isOthers) {
            if (!this.state.salary_range_others) {
                hasError = true;
                errors['salary_range'] = "Salary Range Field Cannot be empty"
            }
        } else {
            if (!this.state.salary_range) {
                hasError = true;
                errors['salary_range'] = "Salary Range Field Cannot be empty"
            }
        }

        if (!this.state.gender_of_student) {
            hasError = true;
            errors['gender_of_student'] = "Gender of a student Field Cannot be empty"
        }

        if (!this.state.tutor_gender) {
            hasError = true;
            errors['tutor_gender'] = "Tutor Gender Field Cannot be empty"
        }
        //
        if (typeof this.state.contact_mobile !== "undefined") {
            const pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(this.state.contact_mobile)) {

              hasError = true;
              errors['contact_mobile'] = "Please enter valid phone number."

            } else if (this.state.contact_mobile.length !== 11){

               hasError = true;
               errors['contact_mobile'] = "Please enter valid phone number."
            }

        }

        this.setState({
            errors: errors
        });

        return hasError;
    }

    handleReSubmitOTP(e) {
        e.preventDefault();
        const data = JSON.stringify({"msisdn": this.state.contact_mobile});
        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/otp_resend',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                toast(response.data.message);
                this.setState({
                    isShowOtpMsg: true,
                    otpMessage: response.data.message
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onCloseMsg() {
        this.setState({
            isShowOtpMsg: !this.state.isShowOtpMsg,
            otpMessage: ''
        })
    }

    handleOnChangeSubject(e) {
        const {name,value,checked} = e.target;
        if(this.state.selectSubjects.indexOf(value) == -1) {
            this.setState({
                selectSubjects: this.state.selectSubjects.concat(value)
            })
        } else {
            this.setState({
                selectSubjects: this.state.selectSubjects.filter(a => a !== value)
            })
        }

    }

    handleChangeOthers() {
        this.setState({
            isOthers: true
        })
    }

    handleOnChangeAllSubject(e) {
        const {name,value,checked} = e.target;
        // alert(this.state.select_subject.map((data,index)=>{
        //     return data.title
        // }))
        if(checked) {
            this.setState({
                selectSubjects: this.state.select_subject.map((data,index)=>{
                    return data.title
                }),
                isAllSubj: true
            })
        } else {
            this.setState({
                selectSubjects: [],
                isAllSubj: false
            })
        }
    }

    render() {
        // if(authGuardianValidate()){
        //     return (
        //         <GRequestTutor/>
        //     )
        // }
        if(this.state.isLoading){
            return (
                <>
                    <LoaderComponent/>
                </>
            )
        }
        if(this.state.isRequestSubmit){
            return (
                <GRequestTutorResponse/>
            )
        } else {
            
            return (
                <Layout>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover/>

                    <div className="tuition-request">

                        <div className="container">
                            <div className="row">

                                <div className="col-12 col-lg-8 tuition-request-form">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>{this.state.isOtpShow ? 'Verify Phone Number' : 'REQUEST FOR A TUTOR'}</h5>
                                        </div>

                                        <div className="card-body">
                                            {this.state.componentType === 'OTP' || this.state.isOtpShow ?

                                                <div className="container otp-section">
                                                    <form onSubmit={this.onHandleVerifyOTP}>
                                                        <div className="row">

                                                            <>
                                                                {this.state.isShowOtpMsg ?
                                                                    <Alert variant="success" onClick={this.onCloseMsg} dismissible>
                                                                        {this.state.otpMessage}
                                                                    </Alert>
                                                                    :
                                                                    <></>
                                                                }
                                                            </>

                                                            <span>Verification OTP sent to {this.state.contact_mobile}. Enter the OTP you received below.</span>
                                                            <div className="form-group">
                                                                <div className="col-12 col-lg-6">
                                                                <input type="number"
                                                                       className="form-control"
                                                                       name="OTPCODE"
                                                                       onChange={this.handleChange}
                                                                       id="formGroupExampleInput" placeholder="Verification OTP..."/>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="col-12">
                                                                    <button type="submit" className="btn btn-primary">
                                                                        <i className="fa fa-sign-in"></i> &nbsp;
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="col-12">
                                                                    <span >
                                                                        Didn't receive your OTP? <Link onClick={this.handleReSubmitOTP}>Resend OTP</Link>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                                :

                                                <>
                                                    {this.state.componentType === 'LOGIN' ?

                                                        <TuitionRequestLogin handleSubmitRequest={this.handleSubmitRequest}/>

                                                        :

                                                        <div className="container submit-form">

                                                            <form onSubmit={this.onHandleOTPSend}>
                                                                <div className="form-group">
                                                                    <label htmlFor="s_name">
                                                                        Post Title 
                                                                    </label>
                                                                    <input type="text"
                                                                            name="s_name"
                                                                            onChange={this.handleChange}
                                                                            className="form-control" id="s_name"
                                                                            aria-describedby="s_name" placeholder="Post Title"/>
                                                                    <div className="text-danger">
                                                                        {this.state.errors.s_name}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="form-group col-lg-6">
                                                                        <label htmlFor="exampleInputEmail1" className="custom-label">
                                                                            Select District:<span style={{color:"red"}}>*</span>
                                                                        </label>
                                                                        <select className="form-select form-select-sm"
                                                                                name="district"
                                                                                onChange={this.onChangeDistrict}
                                                                                aria-label=".form-select-sm example">
                                                                            <option selected value="">---Select District---</option>
                                                                            {
                                                                                districts_en.map((area)=>{
                                                                                    return (
                                                                                        <option value={area.value}>{area.title}</option>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </select>
                                                                        <div className="text-danger">
                                                                            {this.state.errors.district}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-lg-6">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Select Area:<span style={{color:"red"}}>*</span>
                                                                        </label>
                                                                        <select className="form-select form-select-sm"
                                                                                name="area"
                                                                                onChange={this.handleChange}
                                                                                aria-label=".form-select-sm example">
                                                                            <option selected value="">---Select area---</option>
                                                                            {
                                                                                this.state.select_area.map((area)=>{
                                                                                    return (
                                                                                        <option value={area.title}>{area.title}</option>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </select>
                                                                        <div className="text-danger">
                                                                            {this.state.errors.area}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-lg-6">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Select Medium:<span style={{color:"red"}}>*</span>
                                                                        </label>
                                                                        <select className="form-select form-select-sm"
                                                                                name="medium"
                                                                                onChange={this.onChangeMedium}
                                                                                aria-label=".form-select-sm example">
                                                                            <option selected value="">---Select Medium---</option>
                                                                            {
                                                                                medium_en.map((medium)=>{
                                                                                    return (
                                                                                        <option value={medium.value}>{medium.title}</option>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </select>
                                                                        <div className="text-danger">
                                                                            {this.state.errors.medium}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-lg-6">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Select Class:<span style={{color:"red"}}>*</span>
                                                                        </label>
                                                                        <select className="form-select form-select-sm"
                                                                                name="class_name"
                                                                                onChange={this.onChangeClass}
                                                                                aria-label=".form-select-sm example">
                                                                            <option selected value="">---Select Class---</option>
                                                                            {
                                                                                this.state.className.map((class_name)=>{
                                                                                    return (
                                                                                        <option value={class_name.value}>{class_name.title}</option>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </select>
                                                                        <div className="text-danger">
                                                                            {this.state.errors.class_name}
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group col-lg-12">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Select Subject:<span style={{color:"red"}}>*</span>
                                                                        </label>
                                                                        <br/>

                                                                        {this.state.select_subject.length > 0 ?

                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input"
                                                                                       type="checkbox"
                                                                                       name="subject"
                                                                                       onChange={this.handleOnChangeAllSubject}
                                                                                       id="inlineCheckbox1"
                                                                                       value="All"/>
                                                                                All Subject
                                                                            </div>

                                                                            :

                                                                            <></>

                                                                        }

                                                                        {this.state.select_subject.map((subject,index) => (

                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input"
                                                                                       type="checkbox"
                                                                                       name="subject"
                                                                                       onChange={this.handleOnChangeSubject}
                                                                                       id="inlineCheckbox1"
                                                                                       value={subject.title}
                                                                                       checked={this.state.selectSubjects.indexOf(subject.title) != -1}
                                                                                />
                                                                                {subject.title}
                                                                            </div>

                                                                        ))
                                                                        }
                                                                        <div className="text-center">
                                                                            {this.state.select_subject.length <= 0 ? "No Subject List. Please Select Medium and Class." : ""}
                                                                        </div>
                                                                        <div className="text-danger">
                                                                            {this.state.errors.subject}
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Student School/ College:
                                                                        </label>
                                                                        <input type="text"
                                                                               name="student_school"
                                                                               onChange={this.handleChange}
                                                                               className="form-control" id="exampleInputEmail1"
                                                                               aria-describedby="emailHelp" placeholder="Student School/College"/>
                                                                        <div className="text-danger">
                                                                            {this.state.errors.student_school}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-lg-4">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Days per week:<span style={{color:"red"}}>*</span>
                                                                        </label>
                                                                        <select className="form-select form-select-sm"
                                                                                name="days_per_weak"
                                                                                onChange={this.handleChange}
                                                                                aria-label=".form-select-sm example">
                                                                            <option selected value="">---Select Days---</option>
                                                                            <option value="Suitable">Suitable Day</option>
                                                                            <option value="01">1 Day</option>
                                                                            <option value="02">2 Days</option>
                                                                            <option value="03">3 Days</option>
                                                                            <option value="04">4 Days</option>
                                                                            <option value="05">5 Days</option>
                                                                            <option value="06">6 Days</option>
                                                                            <option value="07">7 Days</option>
                                                                        </select>
                                                                        <div className="text-danger">
                                                                            {this.state.errors.days_per_weak}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-lg-4">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Gender of Student:<span style={{color:"red"}}>*</span>
                                                                        </label>
                                                                        <select className="form-select form-select-sm"
                                                                                name="gender_of_student"
                                                                                onChange={this.handleChange}
                                                                                aria-label=".form-select-sm example">
                                                                            <option selected>Select Gender of Student</option>
                                                                            <option value="Male">Male</option>
                                                                            <option value="Female">Female</option>
                                                                        </select>
                                                                        <div className="text-danger">
                                                                            {this.state.errors.gender_of_student}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-lg-4">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Desired Gender of Tutor:<span style={{color:"red"}}>*</span>
                                                                        </label>
                                                                        <select className="form-select form-select-sm"
                                                                                name="tutor_gender"
                                                                                onChange={this.handleChange}
                                                                                aria-label=".form-select-sm example">
                                                                            <option selected value="">
                                                                                Select Desired Tutor Gender 
                                                                            </option>
                                                                            <option value="Any Gender">Any Gender</option>
                                                                            <option value="Male">Male</option>
                                                                            <option value="Female">Female</option>
                                                                        </select>
                                                                        <div className="text-danger">
                                                                            {this.state.errors.tutor_gender}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-lg-12">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Salary Range:  <span style={{color:"red"}}>*</span>
                                                                        </label>
                                                                        <br/>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input"
                                                                                   type="radio"
                                                                                   name="salary_range"
                                                                                   id="1000-2000"
                                                                                   onChange={this.handleChange}
                                                                                   value="1000-2000"/>
                                                                            1000-2000
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input"
                                                                                   type="radio"
                                                                                   name="salary_range"
                                                                                   id="2000-3000"
                                                                                   onChange={this.handleChange}
                                                                                   value="2000-3000"/>2000-3000
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input"
                                                                                   type="radio"
                                                                                   name="salary_range"
                                                                                   id="3000-4000"
                                                                                   onChange={this.handleChange}
                                                                                   value="3000-4000"/>3000-4000
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input"
                                                                                   type="radio"
                                                                                   name="salary_range"
                                                                                   id="4000-5000"
                                                                                   onChange={this.handleChange}
                                                                                   value="4000-5000"/>4000-5000
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input"
                                                                                   type="radio"
                                                                                   name="salary_range"
                                                                                   id="5000-6000"
                                                                                   onChange={this.handleChange}
                                                                                   value="5000-6000"/>5000-6000
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input"
                                                                                   type="radio"
                                                                                   name="salary_range"
                                                                                   id="6000-7000"
                                                                                   onChange={this.handleChange}
                                                                                   value="6000-7000"/>6000-7000
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input"
                                                                                   type="radio"
                                                                                   name="salary_range"
                                                                                   id="7000-8000"
                                                                                   onChange={this.handleChange}
                                                                                   value="7000-8000"/>7000-8000
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input"
                                                                                   type="radio"
                                                                                   name="salary_range"
                                                                                   id="8000-9000"
                                                                                   onChange={this.handleChange}
                                                                                   value="8000-9000"/>8000-9000
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input"
                                                                                   type="radio"
                                                                                   name="salary_range"
                                                                                   id="9000-10000"
                                                                                   onChange={this.handleChange}
                                                                                   value="9000-10000"/>9000-10000
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input"
                                                                                   type="radio"
                                                                                   name="salary_range"
                                                                                   id="negotiable"
                                                                                   onChange={this.handleChange}
                                                                                   value="Negotiable"/>Negotiable
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input"
                                                                                   type="radio"
                                                                                   name="salary_range"
                                                                                   id="others"
                                                                                   onChange={this.handleChangeOthers}
                                                                                   value="others"/>Others
                                                                        </div>
                                                                        {this.state.isOthers ?

                                                                            <input type="number"
                                                                                   name="salary_range_others"
                                                                                   onChange={this.handleChange}
                                                                                   className="form-control mt-2"
                                                                                   id="salary_range_others"
                                                                                   aria-describedby="salary_range_others"
                                                                                   placeholder="Expected Salary..."/>

                                                                            :

                                                                            <></>

                                                                        }
                                                                        <div className="text-danger">
                                                                            {this.state.errors.salary_range}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="exampleFormControlTextarea1">
                                                                            Detail Address:
                                                                        </label>
                                                                        <textarea className="form-control"
                                                                                  name="contact_address"
                                                                                  onChange={this.handleChange}
                                                                                  id="exampleFormControlTextarea1" rows="3"></textarea>
                                                                        <div className="text-danger">
                                                                            {this.state.errors.contact_address}
                                                                        </div>
                                                                    </div>

                                                                    {authGuardianValidate() ?

                                                                        <div className="form-group col-lg-6">
                                                                            <label htmlFor="mobile">
                                                                                Mobile:<span style={{color:"red"}}>*</span>
                                                                            </label>
                                                                            <input type="number"
                                                                                   name="contact_mobile"
                                                                                   onChange={this.handleChange}
                                                                                   className="form-control" id="mobile"
                                                                                   value={authGuardianValidate() ? AuthGuardian().g_msisdn : ""}
                                                                                   aria-describedby="mobile" placeholder="" required disabled={authGuardianValidate()}/>
                                                                            <div className="text-danger">
                                                                                {this.state.errors.contact_mobile}
                                                                            </div>
                                                                        </div>

                                                                        :

                                                                        <div className="form-group col-lg-6">
                                                                            <label htmlFor="mobile">
                                                                                Mobile:<span style={{color:"red"}}>*</span>
                                                                            </label>
                                                                            <input type="number"
                                                                                   name="contact_mobile"
                                                                                   onChange={this.handleChange}
                                                                                   className="form-control" id="mobile"
                                                                                   aria-describedby="mobile" placeholder="" required/>
                                                                            <div className="text-danger">
                                                                                {this.state.errors.contact_mobile}
                                                                            </div>
                                                                        </div>

                                                                    }

                                                                    <div className="form-group col-lg-6">
                                                                        <label htmlFor="exampleInputEmail1">Email:</label>
                                                                        <input type="email"
                                                                               name="contact_email"
                                                                               onChange={this.handleChange}
                                                                               className="form-control" id="exampleInputEmail1"
                                                                               aria-describedby="emailHelp" placeholder=""/>
                                                                        <div className="text-danger">
                                                                            {this.state.errors.contact_email}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="exampleFormControlTextarea1">
                                                                            Additional Info in details (If multiple subjects are needed):</label>
                                                                        <textarea className="form-control"
                                                                                  name="additional_information"
                                                                                  onChange={this.handleChange}
                                                                                  id="exampleFormControlTextarea1" rows="3"></textarea>
                                                                        <div className="text-danger">
                                                                            {this.state.errors.additional_information}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-lg-8">
                                                                        <Captcha onChangeHandleCaptch={this.onChangeHandleCaptch}/>
                                                                    </div>
                                                                    <center className="form-group">
                                                                        <button type="submit" className="btn btn-submit-request" disabled={this.state.isButtonDisable}>
                                                                            Request Tutor
                                                                            <i className="fa fa-send"></i>
                                                                        </button>
                                                                    </center>
                                                                </div>
                                                            </form>

                                                        </div>

                                                    }
                                                </>


                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 tuition-request-help-info">
                                    <GSideBarTutorRequest/>
                                    <TutorsAdvetisement/>
                                </div>


                            </div>
                        </div>

                    </div>

                </Layout>
            );
        }

    }
}

export default TutorRequest;