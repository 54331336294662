export function msisdnValidate(msisdn) {
    let isValid = true;
    if (typeof msisdn !== "undefined") {

        const phone_len = `${msisdn}`.toString().length;
        
        if ((phone_len === 11 || phone_len === 13) && (msisdn.substring(0,2) === "01" || msisdn.substring(0,4) === "8801")) {
            isValid = true;
        } else {
            isValid = false;
        }

        
        const pattern = new RegExp(/^[0-9\b]+$/);
        const pattern1 = new RegExp(/^\+?88|88?0?1[3456789][0-9]{8}\b/g);

        if (pattern.test(msisdn) || pattern1.test(msisdn)) {
            isValid = true;
        }else if(phone_len === 11 || phone_len !== 13){
            isValid = true;
        }else{
            isValid = false
        }

    }
    
    return isValid;
}

export function fieldValidate(filed_name) {
    let isValid = true;
    if (!filed_name) {
        isValid = false;
    }
    return isValid;
}