import React, {Suspense, useEffect} from 'react';
import Layout from "../layout/Layout";
import LoaderComponent from "../common/LoaderComponent";
import HomeFeatureTutor from './HomeFeatureTutor';
import RecentlyJoined from './RecentlyJoined';
const Banner = React.lazy(() => import('./Banner'));
const Search = React.lazy(() => import('../menu/Search/Search'));
const Banner2 = React.lazy(() => import('./Banner2'));
const Search2 = React.lazy(() => import('../menu/Search/Search2'));
// const HomeFeatureTutor = React.lazy(() => import('./HomeFeatureTutor'));

const AchieveGoals = React.lazy(() => import('./AchieveGoals'));
// const RecentlyJoined = React.lazy(() => import('./RecentlyJoined'));
const FindOutMore = React.lazy(() => import('./FindOutMore'));

const Home = () => {
    useEffect(()=> {
        document.title = "HOME";
    },[]);
    return (
        <Layout>

            {/*Banner*/}
            <div className="LearnSearchTutorWrap">
                <Suspense fallback={<LoaderComponent/>}>
                    <Banner2/>
                </Suspense>
                {/*End Banner*/}

                {/*Search*/}
                <Suspense fallback={<LoaderComponent/>}>
                    <Search2/>
                </Suspense>
                {/*End Search*/}
            </div>

            {/*TutorCoursedWrap Section*/}
            <Suspense fallback={<LoaderComponent/>}>
                <FindOutMore/>
            </Suspense>
            {/*TutorCoursedWrap Section*/}

            {/*Feature Tutor    */}
            <Suspense fallback={<LoaderComponent/>}>
                <HomeFeatureTutor/>
            </Suspense>
            {/*End Feature Tutor    */}

            {/*AchieveGoals Section*/}
            <Suspense fallback={<LoaderComponent/>}>
                <AchieveGoals/>
            </Suspense>
            {/*end AchieveGoals Section*/}

            {/*FeaturedTutorsWrap*/}
            <Suspense fallback={<LoaderComponent/>}>
                <RecentlyJoined/>
            </Suspense>
            {/*end  FeaturedTutorsWrap  */}

        </Layout>
    );
};

export default Home;