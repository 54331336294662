import axios from "axios";
import React, { useCallback, useEffect, useState } from 'react';
import { ProgressBar } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import circle2 from "../../frontend/img/circle2.png";
import Biman from "../../frontend/img/default_logo.png";
import graduationsmall from "../../frontend/img/graduationsmall.png";
import primiumshape from "../../frontend/img/primiumshape.png";
import shadowpic2 from "../../frontend/img/shadowpic2.png";
import { guardian } from "../../route";
import { AuthGuardian, authGuardianValidate } from "../../utils/GuardianAuth";
import { authMediaValidate } from "../../utils/MediumAuth";
import { subject_en } from "../../utils/medium_data/subject3";
import { authValidate } from "../../utils/TutionAuth";
import TutoritemMini from "../common/TutorItem/TutoritemMini";
import TutorsAdvetisement from "../common/TutorsAdvetisement";
import Layout from "../layout/Layout";
import TutorsNotification from "../tutors/TutorsNotification";
import GuardianLogin from "./Common/GuardianLogin";
import GuardianSignUp from "./Common/GuardianSignUp";

const TutorDetails = () => {
    
    const [userPersonalInfo, setUserPersonalInfo] = useState([]);
    const [userProfilePercentage, setUserProfilePercentage] = useState('');
    const [allEdu, setAllEdu] = useState([]);
    const [allExperience, setAllExperience] = useState([]);
    const [featureTutor,setFeatureTutors] = useState([]);
    const [similarTutor,setSimilarTutors] = useState([]);

    const [isAuthenticate,setIsAuthenticate] = useState(false)
    const [isSelectOption,setIsSelectOption] = useState(false);
    const [loginStatus,setLoginStatus] = useState('login')
    const [isNotComplete,setIsNotComplete] = useState(false);

    const [preferredSubjectsID,setPreferredSubjectsID] = useState([])
    const [preferredClassID,setPreferredClassID] = useState([])
    const [subjects,setSubjects] = useState([])
    

    let {id} = useParams();

    const findSubjects = useCallback((classIDArr) => {
        const subjectsArr = [];
        classIDArr.map((classID) => {
            subject_en[classID].map((subject) => {
                if(preferredSubjectsID.includes(subject.value)){
                    subjectsArr.push(subject.title)
                }
            })
        })

        return subjectsArr;
    },[preferredSubjectsID])

    useEffect(() => {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/get_perosnal_info/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(response => {
                if (response.data.success) {
                    setUserPersonalInfo(response.data.data);
                    getFetureTutors(response.data.data.uuid);
                    getSimilarTutor(response.data.data.uuid,response.data.data.t_preferred_district_to_teach)
                    setPreferredClassID(JSON.parse(response.data.data.t_preferred_class_id))
                    setPreferredSubjectsID(JSON.parse(response.data.data.t_preferred_subjects))
                } else {
                    window.location.href = "/"
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        getProfilePercentage(id);
        getAllEdu(id);
        getAllExperience(id);
        if(authGuardianValidate()) {
            isCompleteGProfile(AuthGuardian().uuid)
        }
        
    }, [id]);

    useEffect(() => {
        setSubjects(findSubjects(preferredClassID))
    },[preferredClassID,findSubjects])



   

    const getProfilePercentage = (e) => {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/profile_percentage/${e}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(response => {
                setUserProfilePercentage(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getAllEdu = (id) => {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/get_all_education/${id}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };
        axios(config)
            .then(response => {
                setAllEdu(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const getAllExperience = (id) => {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/get_all_experience/${id}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(response => {
                setAllExperience(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getFetureTutors = (id) => {

        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/get_three_featured_tutors?id=${id}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(response=> {
                setFeatureTutors(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getSimilarTutor = (id,address) => {
        
        const data = JSON.stringify({"uuid": id, "district": address});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_three_similler_tutors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                setSimilarTutors(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleOnShowMore = () => {
        
        const dots = document.getElementById("dots");
        const moreText = document.getElementById("more");
        const btnText = document.getElementById("myBtn");

        if (dots.style.display === "none") {
            dots.style.display = "inline ";
            btnText.innerHTML = "View More <i class='fa fa-angle-down'>";
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = "View Less <i class='fa fa-angle-up'>";
            moreText.style.display = "inline";
        }
    }

    //tutor_login
    const onChangeAuth = (status) => {
        setIsAuthenticate(status)
        if(authGuardianValidate()) {
            isCompleteGProfile(AuthGuardian().uuid)
        }
    }
    const changeLoginStatus = (status) => {
        setLoginStatus(status)
    }
    const handleSelectOption = () => {
        setIsSelectOption(true)
    }
    //end_tutor_login

    const isCompleteGProfile = (id) => {

        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/is_guardian_profile_complete/${id}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };
        axios(config)
            .then(function (response) {
                setIsNotComplete(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    console.log(allExperience);


    return (
        <Layout>

            <div className="bgGray pt-2">
                {/*  Tutor Personal Info   */}
                <div className="SocialProfileWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-12 ppImg">
                                <div className="ProfileWrapInner">
                                    <div className="shadowimg1">
                                        <img src={shadowpic2} className="img-fluid" alt="Responsive"/>
                                    </div>
                                    <div className="circle2">
                                        <img src={circle2} className="img-fluid" alt="Responsive"/>
                                    </div>
                                    <div className="PrimiumImg">
                                        <img src={primiumshape} className="img-fluid" alt="Responsive"/>
                                    </div>
                                    <div className="ProfileImg">
                                        <div className="proImgInner">
                                            <div className="uploadImg" style={{display: "none"}}>
                                                <div className="iconInner">
                                                    <i className="i fa fa-camera"></i>
                                                </div>
                                            </div>
                                            <img src={userPersonalInfo.t_photo} className="img-fluid" alt="Responsive"/>
                                            <div className="dashboard-verified-icon">
                                                { userPersonalInfo.is_verified === 1 && <img src="/verified.png" alt="" className="personal-infor-verified"/> }
                                            </div>
                                        </div>
                                    </div>



                                    <div className="ProfileTextwrap" title={userPersonalInfo.t_name}>
                                        <h1 className="UserNametext" style={{textTransform:"capitalize"}}>
                                            {userPersonalInfo.t_name}
                                        </h1>
                                        <p className="Graduation" title={userPersonalInfo.t_highest_degree}>
                                            <img src={graduationsmall} className="img-fluid" alt="Responsive"/>
                                            &nbsp;
                                            {userPersonalInfo.t_highest_degree}
                                        </p>
                                        <span className="Location" title={`${userPersonalInfo.t_preferred_area_to_teach}`
                                            .replace(/\[|\]|"/g,'')}>
                                            <i className="fs-5 fa fa-map-marker">&nbsp;</i>
                                            {`${userPersonalInfo.t_preferred_area_to_teach}`
                                                .replace(/\[|\]|"/g,'')
                                            }
                                       </span>
                                    </div>
                                </div>



                                <div className="VersityWorksInner">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="ContactWrap">
                                                <span className="WorksItem">Studied at</span>
                                                <p className="ContactText DU-logo">
                                                    <i className="fa fa-graduation-cap">&nbsp;</i>
                                                    <span title={userPersonalInfo.t_studied_at}>
                                                        {userPersonalInfo.t_studied_at}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="ContactWrap">
                                                <span className="WorksItem">Works at</span>
                                                <p className="ContactText" title={userPersonalInfo.t_works_at}>
                                                    <i className="fa fa-briefcase" >&nbsp;</i>
                                                    {userPersonalInfo.t_works_at}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="ContactWrap">
                                                <span className="WorksItem">Home Location</span>
                                                <p className="ContactText" title={userPersonalInfo.t_address}>
                                                    <i className="fa fa-map-marker">&nbsp;</i>
                                                    {`${userPersonalInfo.t_address}`}
                                                    
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="ContactWrap">
                                                <span className="WorksItem">Years Of Experience</span>
                                                <p className="ContactText" title={userPersonalInfo.t_address}>
                                                    {`${userPersonalInfo.t_year_exp}`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="ContactWrap">
                                                <span className="WorksItem">Total Student Service</span>
                                                <p className="ContactText" title={userPersonalInfo.t_address}>
                                                    {`${userPersonalInfo.t_student_serve}`}
                                                    
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="AboutTextInner">
                                            <div className="pb-2 pt-2">
                                                <h5>Profile Completion: {userProfilePercentage} %</h5>
                                                <ProgressBar variant="success" now={userProfilePercentage}
                                                            label={`${userProfilePercentage}%`}/>
                                            </div>
                                            <div className="aBoutViewIn col-12">

                                                <div className="ServiceInnerWrap">
                                                    <h3 className="AboutText">About</h3> 

                                                    {`${userPersonalInfo.t_about}`.length > 0 ?

                                                        <p className="TeachingText">
                                                            {` ${userPersonalInfo.t_about}`.substr(0, 202) ?? " "} {}
                                                            <span id="dots" style={{display: "inline"}}>
                                                                {` ${userPersonalInfo.t_about}`.length > 202 ?
                                                                    <span>...</span>
                                                                    :
                                                                    <></>
                                                                }
                                                            </span>
                                                                <span id="more" style={{display: "none"}}>
                                                                {` ${userPersonalInfo.t_about}`.substr(202, `${userPersonalInfo.t_about}`.length) ?? " "}
                                                            </span>
                                                        </p>

                                                        :

                                                        <p className="TeachingText">
                                                            <span id="dots" style={{display: "inline"}}>
                                                                Empty...
                                                            </span>
                                                            <span id="more" style={{display: "none"}}>

                                                            </span>
                                                        </p>

                                                    }

                                                </div>

                                            </div>
                                        </div>
                                        {`${userPersonalInfo.t_about}`.length > 200 ?
                                            <div className="ViewMoreInner">
                                                <button onClick={handleOnShowMore} id="myBtn">View more <i
                                                    className='fa fa-angle-up'></i></button>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>

                            </div>
                            {/*Contact with this tutors*/}
                            {
                                !authMediaValidate() && 
                                <div className="col-lg-4 col-12">
                                    {authGuardianValidate() ?

                                        <>
                                            <TutorsNotification tutors_id={id}/>
                                            {isNotComplete < 100 ?

                                                <>
                                                    <h5 style={{color: "#f48f1d"}}>
                                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Alert
                                                    </h5>
                                                    To get better tutor suggestion around your area, please complete your <Link to={`${guardian}/dashboard`}>profile.</Link>
                                                </>

                                                :

                                                <>
                                                </>

                                            }
                                        </>

                                        :

                                        <>
                                        {authValidate() ?
                                            <>

                                                <TutorsAdvetisement/>

                                            </>

                                            :

                                            <div className="tutor-send-msg-form">

                                                <div className="card row">
                                                    <div className="card-header">
                                                        <h5 className="ContactTutors">Contact with this tutors</h5>
                                                        <span className="text-center">Please Login before contact with this tutor.</span>
                                                    </div>
                                                    <div className="card-body">

                                                        {isAuthenticate ?

                                                            <TutorsNotification tutors_id={id}/>

                                                            :

                                                            <>
                                                            {isSelectOption ?


                                                                <>
                                                                    {loginStatus === 'login' ?

                                                                        <>
                                                                            <GuardianLogin onChangeAuth={onChangeAuth}/>
                                                                            <div className="pt-2 text-center">
                                                                                Don't have an account.
                                                                                <button style={{ background: 'transparent',border: 'none',color: 'blue',cursor: 'pointer' }} onClick={()=>{
                                                                                    changeLoginStatus("signup")
                                                                                }} className="form-check-label"
                                                                                    htmlFor="exampleCheck1">
                                                                                    {"  "} Sign Up {" "}
                                                                                </button>
                                                                            </div>
                                                                        </>

                                                                        :

                                                                        <>
                                                                            <GuardianSignUp onChangeAuth={onChangeAuth}/>
                                                                            <div className="pt-2 text-center">
                                                                                Already have an account?
                                                                                <button style={{ background: 'transparent',border: 'none',color: 'blue',cursor: 'pointer' }} onClick={()=>{
                                                                                    changeLoginStatus("login")
                                                                                }}
                                                                                    className="form-check-label"
                                                                                    htmlFor="exampleCheck1">
                                                                                    {"  "} Sign In {" "}
                                                                                </button>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>

                                                                :

                                                                <button style={{ background: 'transparent',border: 'none',color: 'blue',cursor: 'pointer' }} onClick={handleSelectOption}>Login/Signup</button>

                                                            }
                                                            </>

                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                        }
                                        </>
                                    }
                                </div>
                            }
                            {/*end Contact with this tutors*/}
                        </div>
                    </div>
                </div>
                {/*  end Tutor Personal Info   */}

                {/*About section*/}
                <div className="AboutWrap bgGray">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">

                                <div className="Tuition-InfoWrap">


                                    {/*end_tuition-Info*/}
                                    <div className="Tuition-InfoWrap">


                                        <div className="TuitionInfoItem">
                                            <div className="col-12">
                                            <div className="tuitionInfoInner">
                                                <h2 className="Tuition-InfoText">Tuition-Info</h2>
                                            </div>
                                            <div className="tuitionInner">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                        Expected Minimum Salary
                                                    </label>
                                                </div>
                                                <p className="MonthText">
                                                    {userPersonalInfo.t_salary ?? '0'} tk/month
                                                    <span className="NegTaiableText">
                                                    {userPersonalInfo.t_is_Negotiable ? ': Negotiable' : ''}
                                                </span>
                                                </p>
                                            </div>
                                            <div className="tuitionInner">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                        Days in a week
                                                    </label>
                                                </div>
                                                <p className="WeekText">
                                                    {`${userPersonalInfo.t_days_per_week}`
                                                        .replace(/\[|\]|"/g,'')
                                                    }
                                                </p>
                                            </div>
                                            <div className="tuitionInner">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                        Preferred Tutoring Style
                                                    </label>
                                                </div>
                                                <p className="PrivateText">
                                                    {`${userPersonalInfo.t_preferred_tuition}`
                                                        .replace(/\[|\]|"/g,'')
                                                    }
                                                </p>
                                            </div>
                                            <div className="tuitionInner">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                        Place of Learning
                                                    </label>
                                                </div>
                                                <p className="VisitPara">
                                                    {`${userPersonalInfo.t_place_of_learning}`
                                                        .replace(/\[|\]|"/g,'')
                                                    }
                                                </p>
                                            </div>
                                            <div className="tuitionInner">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                        Extra Facilities
                                                    </label>
                                                </div>
                                                <p className="ProvideText">
                                                    {`${userPersonalInfo.t_extra_facilities}`
                                                        .replace(/\[|\]|"/g,'')
                                                    }
                                                </p>
                                            </div>
                                            <div className="tuitionInner">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                        Preferred Medium of Education
                                                    </label>
                                                </div>
                                                <p className="MediumPara">
                                                    {`${userPersonalInfo.t_preferred_medium_of_Eduction}`
                                                        .replace(/\[|\]|"/g,'')
                                                    }
                                                </p>
                                            </div>
                                            <div className="tuitionInner">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                        Preferred Subjects
                                                    </label>
                                                </div>
                                                <p className="SubjectText">
                                                    {
                                                        subjects && subjects.map((subject) => (
                                                            <span>{ subject + ", " }</span>
                                                        ))
                                                    }
                                                </p>
                                            </div>
                                            <div className="tuitionInner">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                        Preferred District to Teach
                                                    </label>
                                                </div>
                                                <p className="Edit">
                                                    {`${ userPersonalInfo.t_preferred_district_to_teach &&  userPersonalInfo.t_preferred_district_to_teach}`
                                                        .replace(/\[|\]|"/g,'')
                                                    }
                                                </p>
                                            </div>
                                            <div className="tuitionInner">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                        Preferred Areas to Teach
                                                    </label>
                                                </div>
                                                <p className="Edit">
                                                    {`${ userPersonalInfo.t_preferred_area_to_teach &&  userPersonalInfo.t_preferred_area_to_teach}`
                                                        .replace(/\[|\]|"/g,'')
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        </div>


                                    </div>
                                    {/*end_tuition-Info*/}

                                    {/*EducationWrap Section*/}
                                    <div className="EducationWrap">
                                        <div className="col-12">
                                            <div className="tuitionInfoInner">
                                                <h2 className="Tuition-InfoText">Education</h2>
                                            </div>
                                            <div>
                                                {allEdu && allEdu.map(allEducation => {
                                                    return (
                                                        <div className="AcademyContactWrap">
                                                            <img src={Biman} className="img-fluid" alt="Responsive"/>
                                                            <div className="ProfileTextInner">
                                                                <div>
                                                                <h3 className="ProfileName">{allEducation.institute_name}</h3>
                                                                <p className="Designation">{allEducation.subject}</p>
                                                                <span
                                                                    className="YearText">{allEducation.start_year} - {allEducation.passing_year}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    {/*End EducationWrap Section*/}


                                    {/*ExperienceProfileWrap Section*/}
                                    <div className="ExperienceProfileWrap">
                                        <div className="col-12">
                                            <div className="tuitionInfoInner">
                                                <h2 className="Tuition-InfoText">Experience</h2>
                                            </div>
                                            {allExperience && allExperience.map((experience, index) => {

                                                return (
                                                    <div className="SocialContactWrap">

                                                        <img src={Biman} className="img-fluid"
                                                             alt="Responsive"/>
                                                            <div className="ProfileTextWrap1">
                                                                <div className="MainTrainerInnerText">
                                                                    <div className="TrainerInner">
                                                                        <h3 className="ProfileName">{experience.title} at {experience.company_name}</h3>
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div className="DateYearText" style={{ display: 'flex', marginLeft: "20px"}}>
                                                                    <p className="DateYearPara" style={{ marginTop: '0px', marginRight: "20px" }}>
                                                                        {experience.start_month}, {experience.start_date}
                                                                        {experience.is_current ?
                                                                            <span> - Present</span>
                                                                            :
                                                                            <span>  {experience.end_month} - {experience.end_date}</span>
                                                                        }
                                                                    </p>
                                                                    <p className="DesignationText">{experience.location}</p>
                                                                </div>
                                                                <p style={{ marginLeft: "20px" }}>
                                                                    {experience.description}
                                                                </p>
                                                            </div>
                                                    </div>
                                                );

                                            })}
                                        </div>

                                    </div>
                                    {/*End ExperienceProfileWrap Section*/}


                                </div>
                            </div>


                            <div className="col-lg-4">
                                {/*featured_tutors*/}
                                <div className="PeopleInner2Wrap">
                                    <h2 className="ViewText">Featured Tutors</h2>
                                    {featureTutor.length > 0 ?
                                        featureTutor && featureTutor.map((item,i)=>{
                                            return(
                                                <TutoritemMini item={item} key={i}/>
                                            );
                                        })
                                        :
                                        <span>No Feature Tutor...</span>
                                    }

                                </div>
                                {/*end featured_tutors*/}
                                {/*advertise*/}
                                {authValidate() ?

                                    <></>

                                    :

                                    <TutorsAdvetisement/>

                                }
                                {/*advertise*/}
                                {/*  Similar Tutor  */}
                                <div className="PeopleInner2Wrap">
                                    <h2 className="ViewText">Similar Tutors</h2>
                                    {similarTutor.length > 0 ?
                                        similarTutor.map((item, i) =>
                                            <TutoritemMini item={item} key={i}/>
                                        )
                                        :
                                        <span>No Similar Tutor...</span>
                                    }
                                </div>
                                {/*  end_Similar Tutor  */}
                            </div>


                        </div>
                    </div>
                </div>
                {/*end About section*/}
            </div>
        </Layout>
    );
};

export default TutorDetails;