export const medium_en = [
    {
      "value": 1,
      "title": "Bangla Medium"
    },
    {
        "value": 3,
        "title": "English Version"
    },
    {
        "value": 2,
        "title": "English Medium"
    }
  ]