import React, { Component } from 'react';
import Layout from "../layout/Layout";

import axios from "axios";
import { Alert } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { getLearningPlace } from '../../utils/Area';
import { AuthGuardian } from "../../utils/GuardianAuth";
import { getEducationMedium } from '../../utils/Subject';

class GRequestTutor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            select_area : [],
            className: [],
            select_subject: [],

            selectMedium: '',
            errors: "",
            hasError: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.onCloseError = this.onCloseError.bind(this);
        this.handleSubmitRequest = this.handleSubmitRequest.bind(this);
        this.onChangeDistrict = this.onChangeDistrict.bind(this);
        this.onChangeMedium = this.onChangeMedium.bind(this);
        this.onChangeClass = this.onChangeClass.bind(this);

    }

    handleChange(event) {

        const newIteam = event.target.name;
        const newValue = event.target.value;
        this.setState({
            [newIteam] :newValue
        })

    }

    onChangeDistrict(e) {
        this.setState({
            'district': e.target.value
        })
        //alert(e.target.value);
        //this.setState({selectedCountry: e.target.value});
        //alert(JSON.stringify(getLearningPlace().find(cntry => cntry.district_name === e.target.value).area));
        this.setState({
            select_area : getLearningPlace().find(cntry => cntry.district_name === e.target.value).area
        });
    }

    onChangeMedium(e) {
        this.setState({
            medium: e.target.value,
            selectMedium: e.target.value
        })
        //alert(JSON.stringify(getEducationMedium().find(cntry => cntry.medium_name === e.target.value).class_name));
        this.setState({
            className : getEducationMedium().find(m => m.medium_name === e.target.value).class_name
        });
    }

    onChangeClass(e) {
        this.setState({
            class_name: e.target.value
        })
        const stats = getEducationMedium().find(m => m.medium_name === this.state.selectMedium).class_name;
        this.setState({select_subject : stats.find(stat => stat.class_name === e.target.value).subject});
    }

    handleSubmitRequest(event) {
        event.preventDefault()

        if (!this.state.district) {
            this.setState({
                hasError: true,
                errors: "District Field Cannot be empty"
            })
            return;
        }

        if (!this.state.area) {
            this.setState({
                hasError: true,
                errors: "Area Field Cannot be empty"
            })
            return;
        }

        if (!this.state.medium) {
            this.setState({
                hasError: true,
                errors: "Medium Field Cannot be empty"
            })
            return;
        }

        if (!this.state.class_name) {
            this.setState({
                hasError: true,
                errors: "Class Field Cannot be empty"
            })
            return;
        }

        if (!this.state.subject) {
            this.setState({
                hasError: true,
                errors: "Subject Field Cannot be empty"
            })
            return;
        }

        if (!this.state.days_per_weak) {
            this.setState({
                hasError: true,
                errors: "Days per week Field Cannot be empty"
            })
            return;
        }

        if (typeof this.state.contact_mobile !== "undefined") {
            const pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(this.state.contact_mobile)) {
                this.setState({
                    hasError: true,
                    errors: "Please enter valid phone number."
                })
                return;
            } else if (this.state.contact_mobile.length != 11){
                this.setState({
                    hasError: true,
                    errors: "Please enter valid phone number."
                })
                return;
            }

        }

        const data = JSON.stringify({
            "guardian_id": AuthGuardian().id,
            "s_name": this.state.s_name,
            "district": this.state.district,
            "area": this.state.area,
            "medium": this.state.medium,
            "class_name": this.state.class_name,
            "subject": this.state.subject,
            "student_school": this.state.student_school,
            "days_per_weak": this.state.days_per_weak,
            "gender_of_student": this.state.gender_of_student,
            "salary_range": this.state.salary_range,
            "tutor_gender": this.state.tutor_gender,
            "contact_address": this.state.contact_address,
            "msisdn": this.state.contact_mobile,
            "contact_email": this.state.contact_email,
            "additional_information": this.state.additional_information
        });

        console.log(data);

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/web/v1/insert_tutor_request',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                toast(response.data.message);
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    onCloseError (){
        this.setState({
            hasError: false
        })
    }

    render() {
        return (
            <Layout>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover/>

                <div className="container">
                    <div className="row">

                        <div className="col-12 col-lg-8">
                            <h5>আপনার গৃহশিক্ষক দরকার হলে নিচের ফরমটা পুরন করুন । গৃহশিক্ষকেরা আপনার ফোনে যোগাযোগ করবে। </h5>

                            {this.state.hasError ?
                                <Alert variant="danger" onClose={this.onCloseError} dismissible>
                                    {this.state.errors}
                                </Alert>
                                :
                                <span></span>

                            }

                            <form onSubmit={this.handleSubmitRequest}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Student Full Name:</label>
                                    <input type="text"
                                           name="s_name"
                                           onChange={this.handleChange}
                                           className="form-control" id="exampleInputEmail1"
                                           aria-describedby="emailHelp" placeholder="Full Name..." required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Select District:</label>
                                    <select className="form-select form-select-sm"
                                            name="district"
                                            onChange={this.onChangeDistrict}
                                            aria-label=".form-select-sm example">
                                        <option selected>Select District</option>
                                        {
                                            getLearningPlace().map((area)=>{
                                                return (
                                                    <option value={area.district_name}>{area.district_name}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Select Area:</label>
                                    <select className="form-select form-select-sm"
                                            name="area"
                                            onChange={this.handleChange}
                                            aria-label=".form-select-sm example">
                                        <option selected>Select area</option>
                                        {
                                            this.state.select_area.map((area)=>{
                                                return (
                                                    <option value={area.area_name}>{area.area_name}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Select Medium:</label>
                                    <select className="form-select form-select-sm"
                                            name="medium"
                                            onChange={this.onChangeMedium}
                                            aria-label=".form-select-sm example">
                                        <option selected>Select Medium</option>
                                        {
                                            getEducationMedium().map((medium)=>{
                                                return (
                                                    <option value={medium.medium_name}>{medium.medium_name}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Select Class:</label>
                                    <select className="form-select form-select-sm"
                                            name="class_name"
                                            onChange={this.onChangeClass}
                                            aria-label=".form-select-sm example">
                                        <option selected>Select Class</option>
                                        {
                                            this.state.className.map((class_name)=>{
                                                return (
                                                    <option value={class_name.class_name}>{class_name.class_name}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Select Subject:</label>
                                    <select className="form-select form-select-sm"
                                            name="subject"
                                            onChange={this.handleChange}
                                            aria-label=".form-select-sm example">
                                        <option selected value="All Subject">All Subject</option>
                                        {
                                            this.state.select_subject.map((subject)=>{
                                                return (
                                                    <option value={subject.subject_name}>{subject.subject_name}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Student School/ College:</label>
                                    <input type="text"
                                           name="student_school"
                                           onChange={this.handleChange}
                                           className="form-control" id="exampleInputEmail1"
                                           aria-describedby="emailHelp" placeholder="" required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Days per week:</label>
                                    <select className="form-select form-select-sm"
                                            name="days_per_weak"
                                            onChange={this.handleChange}
                                            aria-label=".form-select-sm example">
                                        <option selected value="Any">Any Days</option>
                                        <option value="01">1 Day</option>
                                        <option value="02">2 Days</option>
                                        <option value="03">3 Days</option>
                                        <option value="04">4 Days</option>
                                        <option value="05">5 Days</option>
                                        <option value="06">6 Days</option>
                                        <option value="07">7 Days</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Gender of Student:</label>
                                    <select className="form-select form-select-sm"
                                            name="gender_of_student"
                                            onChange={this.handleChange}
                                            aria-label=".form-select-sm example">
                                        <option selected>Select Gender of Student</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Salary Range:</label>
                                    <input type="text"
                                           name="salary_range"
                                           onChange={this.handleChange}
                                           className="form-control" id="exampleInputEmail1"
                                           aria-describedby="emailHelp" placeholder="1000 -2000" required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Desired Tutor Gender:</label>
                                    <select className="form-select form-select-sm"
                                            name="tutor_gender"
                                            onChange={this.handleChange}
                                            aria-label=".form-select-sm example">
                                        <option selected>Select desired tutor gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlTextarea1">Detail Address:</label>
                                    <textarea className="form-control"
                                              name="contact_address"
                                              onChange={this.handleChange}
                                              id="exampleFormControlTextarea1" rows="3"></textarea>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Mobile:</label>
                                    <input type="text"
                                           name="contact_mobile"
                                           onChange={this.handleChange}
                                           className="form-control" id="exampleInputEmail1"
                                           aria-describedby="emailHelp" placeholder="" required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email:</label>
                                    <input type="text"
                                           name="contact_email"
                                           onChange={this.handleChange}
                                           className="form-control" id="exampleInputEmail1"
                                           aria-describedby="emailHelp" placeholder="" required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlTextarea1">Additional Info in details (if multiple subjects mention here):</label>
                                    <textarea className="form-control"
                                              name="additional_information"
                                              onChange={this.handleChange}
                                              id="exampleFormControlTextarea1" rows="3"></textarea>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">Request Tutor</button>
                                </div>
                            </form>




                        </div>

                        <div className="col-12 col-lg-4">
                            {/* <GSideBar/> */}
                        </div>
                    </div>
                </div>

            </Layout>
        );
    }
}

export default GRequestTutor;