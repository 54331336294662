import axios from "axios";
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { tutor } from "../../../../route";
import { authGuardianValidate } from "../../../../utils/GuardianAuth";
import { authMediaValidate } from "../../../../utils/MediumAuth";
import { Auth, authValidate } from "../../../../utils/TutionAuth";
import Layout from "../../../layout/Layout";
import JobApplyMessage from "../JobApplyMessage";

const TuitionsDetails = () => {
    const [viewDetail,setViewDetail] = useState([]);
    const [applyShow,setApplyShow] = useState(false);
    const [allApplyJob,setAllApplyJob] = useState([])
    const [applyInfo,setApplyInfo] = useState({});
    const [showMsg,setShowMsg] = useState('');
    const [isShowMsg,setIsShowMsg] = useState(false);

    const history = useHistory();

    let {id} = useParams();
    

    const handleOnChange = (e) => {
        setApplyInfo({
            ...applyInfo,
            [e.target.name]: e.target.value
        })
    }

    const viewDetails = useCallback((id) => {

        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/get_tutor_request/${id}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };
        //alert(config);
        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                if(!response.data.data){
                    history.push("/");
                }
                setViewDetail(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    },[history])
    
    useEffect(()=> {
        if(authValidate()) {
            allapplyJob()
        }
        viewDetails(id)
        document.title = "TUITIONS DETAILS";
    },[id,viewDetails])

    const onHandleApplyJob = (e) => {
        e.preventDefault();
        const data = JSON.stringify({
            "name": Auth().name,
            "mobile": Auth().msisdn,
            "tutors_id": Auth().id,
            "guardian_id": viewDetail.guardian_id,
            "job_id": viewDetail.id
        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/apply_job',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setApplyShow(false)
                setShowMsg(response.data.message)
                setIsShowMsg(true)
            })
            .catch(function (error) {
                console.log(error);
                setApplyShow(false)
                setShowMsg(error)
                setIsShowMsg(true)
            });

    }

    const allapplyJob = () => {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/get_applied_jobslist_id/${Auth().uuid}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setAllApplyJob(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <Layout>
            <div className="tuitionRequestDetails bgGray">
                <div className="container">
                    <div className="row">


                        {isShowMsg ?

                            <>
                                <JobApplyMessage showMsg={showMsg}/>
                            </>

                            :

                            <>
                                <div className="col-lg-12">


                                    <div className="card">
                                        <div className="card-body" >
                                                <p className="t_title text-center">Need {viewDetail.medium} ({viewDetail.tutor_gender}) Tutor for a Student of {viewDetail.class_name} Student</p>
                                                <small className="text-muted">Tuition ID: {viewDetail.tuition_uniq_id}</small>
                                                <div className="row py-2">
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Post Title :</b> <br/>{viewDetail.s_name}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Student School :</b>
                                                        <br/>{viewDetail.student_school}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Education Medium :</b>
                                                        <br/>
                                                        {viewDetail.medium}
                                                    </div>
                                                </div>
                                                <div className="row py-2">
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Class :</b>
                                                        <br/>
                                                        {viewDetail.class_name}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <small>
                                                            <b>Subjects :</b> {
                                                            viewDetail.subject && JSON.parse(viewDetail.subject).map((subject) => (
                                                                <span>{ subject + ", " }</span>
                                                            ))
                                                        }
                                                        </small>
                                                    </div>
                                                    
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Salary :</b>
                                                        <br/>
                                                        {viewDetail.salary_range} Tk/Month
                                                    </div>
                                                </div>
                                                <div className="row py-2">
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Student Gender:</b>
                                                        <br/>
                                                        {viewDetail.gender_of_student}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Desired Tutor Gender:</b>
                                                        <br/>
                                                        {viewDetail.tutor_gender}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Days:</b>
                                                        <br/>
                                                        {viewDetail.days_per_weak} Days/Week
                                                    </div>
                                                </div>
                                                <small>
                                                    <b>Status:</b> {viewDetail.is_hired ? 'Already Hired' : "Not Hired"}
                                                </small>
                                                <div className="row pt-2">
                                                    <div className="col-md-4">
                                                        <div>
                                                            <>
                                                                <i className="fa fa-map-marker text-danger"></i> &nbsp;
                                                                {viewDetail.area},{viewDetail.district}
                                                            </>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col-md-4 mt-2">
                                                        <small><strong>Additional Information</strong> : {viewDetail?.additional_information}</small>
                                                    </div>
                                                    <div className="col-md-4 mt-2">
                                                        <small><strong>Detail Address</strong> : {viewDetail?.contact_address}</small>
                                                    </div>
                                                </div>
                                                <div className="custom-view-button pull-right">
                                                    {authValidate() ?
                                                        <span>

                                                            {allApplyJob.indexOf(viewDetail.guardian_post_id) == -1 ?
                                                                <button className="btn btn-sm btn-success pull-right"
                                                                        variant="primary"
                                                                        onClick={() => setApplyShow(true)}>
                                                                    Apply this job
                                                                </button>
                                                                :
                                                                <button className="btn btn-sm btn-danger pull-right"
                                                                        disabled={true}>
                                                                    Already Applied
                                                                </button>
                                                            }
                                                        </span>
                                                        :
                                                        <>
                                                            {
                                                                authGuardianValidate() || authMediaValidate() ?
                                                                    <div className="text-warning pull-right">
                                                                        <span>Guardian or Media can not apply job!</span>
                                                                    </div>
                                                                    :
                                                                    <Link className="btn btn-sm btn-view pull-right" to={`${tutor}/login`}>
                                                                        Login in as a Tutor to Apply for this job
                                                                    </Link>
                                                            }
                                                        </>

                                                    }
                                                </div>







                                            </div>

                                    </div>

                                </div>
                            </>

                        }


                    </div>
                </div>
            </div>

        {/*    Model    */}
            <Modal
                show={applyShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setApplyShow(false)}
            >
                <form onSubmit={onHandleApplyJob}>
                    <Modal.Body>

                        <div className="awesome-button-section">
                            <div className="awesome-button">
                                <span className="fa fa-check checked-icon"></span>
                                <h2 className="awesome-heaading">Confirmation</h2>
                                <p className="booking-text">Are you sure?<br/> You want to apply this job!!!</p>

                                <button type="submit" className="ok-button">Apply <i className="fa fa-send"></i></button>

                            </div>
                        </div>

                    </Modal.Body>
                </form>
            </Modal>
        {/*    End Model    */}

        </Layout>
    );
};

export default TuitionsDetails;