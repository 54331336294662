import axios from "axios";
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import { tutor } from "../../../route";
import { getExtraFacilities, getLearningPlace, getWeek } from "../../../utils/Common";
import { districts_en } from "../../../utils/location/District";
import { upazillas_en } from "../../../utils/location/Upazilla";
import { class_name_en } from "../../../utils/medium_data/ClassData";
import { medium_en } from "../../../utils/medium_data/Medium";
import { subject_en } from "../../../utils/medium_data/subject3";
import { Auth, authValidate } from "../../../utils/TutionAuth";
import Layout from "../../layout/Layout";
import Login from "../Login";

const TuitionInfoEdit = () => {
    const [activeMedium,setActiveMedium] = useState([])
    const [activeClass,setActiveClass] = useState([])

    const [allClass,setAllClass] = useState([]);
    const [allSubject,setAllSubject] = useState([]);
    const [allArea,setAllArea] = useState([]);
    const [errors,setErrors] = useState({});

    const [getTuitionInfo,setGetTuitionInfo] = useState({
        "user_id": '',
        "t_salary": '',
        "t_is_Negotiable": '',
        "t_days_per_week": [],
        "t_preferred_tuition": [],
        "t_place_of_learning": [],
        "t_extra_facilities": [],
        "t_preferred_medium_of_Eduction": [],
        "t_preferred_class": [],
        "t_preferred_subjects": [],
        "t_preferred_area_to_teach": []
    });

    const [tuitionInfo,setTuitionInfo] = useState({
        "user_id": '',
        "t_salary": '',
        "t_is_Negotiable": '',
        "t_days_per_week": [],
        "t_preferred_tuition": [],
        "t_place_of_learning": [],
        "t_extra_facilities": [],
        "t_preferred_medium_of_Eduction": [],
        "t_preferred_class": [],
        "t_preferred_subjects": [],
        "t_preferred_area_to_teach": []
    });

    const [salary,setSalary] = useState('')
    const [isNegotiable,setIsNegotiable] = useState(0)
    const [tDaysPerWeek,settDaysPerWeek] = useState([])
    const [preferredTuition,setPreferredTuition] = useState([])
    const [placeOFLearning,setPlaceOFLearning] = useState([])
    const [extraFacilities,setExtraFacilities] = useState([])

    const [preferredMediumOFEduction,setPreferredMediumOFEduction] = useState([])
    const [preferredClass,setPreferredClass] = useState([])
    const [preferredSubjects,setPreferredSubjects] = useState([])

    const [tDistrict,setTDistrict] = useState('')
    const [preferredAreaToTeach,setPreferredAreaToTeach] = useState([])
    const [tutorClasses,setTutorClasses] = useState([])
 
    const [errorMsg,setErrorMsg] = useState({
        "t_salary": ''
    })

    useEffect(()=> {
        const getPersonalInfo = () => {
            const config = {
                method: 'get',
                url: process.env.REACT_APP_API_URL+ `/api/web/v1/get_perosnal_info/${Auth().uuid}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
                }
            };
    
            axios(config)
                .then(response=> {
                    setSalary(response.data.data.t_salary)
                    setIsNegotiable(response.data.data.t_is_Negotiable)
                    settDaysPerWeek(JSON.parse(response.data.data.t_days_per_week))
                    setPreferredTuition(JSON.parse(response.data.data.t_preferred_tuition))
                    setPlaceOFLearning(JSON.parse(response.data.data.t_place_of_learning))
                    setExtraFacilities(JSON.parse(response.data.data.t_extra_facilities))
    
                    setTDistrict(response.data.data.t_preferred_district_to_teach)
                    setAllArea(upazillas_en[districts_en.find(d => d.title == response.data.data.t_preferred_district_to_teach).value]);
                    setPreferredAreaToTeach(JSON.parse(response.data.data.t_preferred_area_to_teach))
    
                    setPreferredMediumOFEduction(JSON.parse(response.data.data.t_preferred_medium_of_Eduction))
                    setActiveMedium(JSON.parse(response.data.data.t_preferred_medium_of_Eduction_id))
                    getAllClass(JSON.parse(response.data.data.t_preferred_medium_of_Eduction_id));
    
                    setPreferredClass(JSON.parse(response.data.data.t_preferred_class))
                    setPreferredClass(JSON.parse(response.data.data.t_preferred_class))
                    setActiveClass(JSON.parse(response.data.data.t_preferred_class_id))
                    getAllSubject(JSON.parse(response.data.data.t_preferred_class_id))
    
                    setPreferredSubjects(JSON.parse(response.data.data.t_preferred_subjects))
    
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        getPersonalInfo()
    },[])


    const handleOnChange = (event) => {
        const {name,value} = event.target;

        if(event.target.name == 't_salary') {
            if(event.target.value.length > 5) {
                setErrorMsg({
                    ...errorMsg,
                    [event.target.name]: "Salary not more than 5 digit!"
                })
            } else {
                setErrorMsg({
                    ...errorMsg,
                    [event.target.name]: ""
                })
            }
        }

        if(event.target.name == 't_is_Negotiable') {
            setIsNegotiable(event.target.checked ? 1 : 0)
        } else {
            setSalary(event.target.value)
        }
    }

    const handleOnChangeWeek = (event) => {
        console.log(event.target.checked);
        if(event.target.checked) {
                if (tDaysPerWeek.includes(event.target.value)) {
                    settDaysPerWeek(tDaysPerWeek.filter(sel => sel !== event.target.value));
                } else {
                    settDaysPerWeek([...tDaysPerWeek, event.target.value]);
                }
        } else {
            settDaysPerWeek(tDaysPerWeek.filter(sel => sel !== event.target.value));
        }
    }

    const handleOnTutoring = (event) => {
        if(event.target.checked) {
            if (preferredTuition.includes(event.target.value)) {
                setPreferredTuition(preferredTuition.filter(sel => sel !== event.target.value));
            } else {
                setPreferredTuition([...preferredTuition, event.target.value]);
            }
        } else {
            setPreferredTuition(preferredTuition.filter(sel => sel !== event.target.value));
        }
    }

    const onHandleExtraFacilities = (event) => {
        if(event.target.checked) {
            if (extraFacilities.includes(event.target.value)) {
                setExtraFacilities(extraFacilities.filter(sel => sel !== event.target.value));
            } else {
                setExtraFacilities([...extraFacilities, event.target.value]);
            }
        } else {
            setExtraFacilities(extraFacilities.filter(sel => sel !== event.target.value));
        }
    }

    const handleOnChangePlace = (event) => {
        const {name,value,checked} = event.target;
        if(checked) {
            if (placeOFLearning.includes(value)) {
                setPlaceOFLearning(placeOFLearning.filter(sel => sel !== value));
            } else {
                setPlaceOFLearning([...placeOFLearning, value]);
            }
        } else {
            setPlaceOFLearning(placeOFLearning.filter(sel => sel !== value));
        }
    }

    const changeMedium = (e) => {
        let {name,value,checked} = e.target;

        //medium_store
        const mValue = medium_en.find(x => x.value == e.target.value).title;
        if(checked) {
            if (preferredMediumOFEduction.includes(value)) {
                setPreferredMediumOFEduction(preferredMediumOFEduction.filter(sel => sel !== mValue));
            } else {
                setPreferredMediumOFEduction([...preferredMediumOFEduction, mValue]);
            }
        } else {
            setPreferredMediumOFEduction(preferredMediumOFEduction.filter(sel => sel !== mValue));
        }
        //medium_store

        getSelectMediumID(checked,value)
    }

    const getSelectMediumID = (checked,id) => {
        let tmp = activeMedium;
        if(checked) {
            tmp.push(id)
        } else {
            const index = tmp.indexOf(id);
            if (index !== -1) {
                tmp.splice(index, 1);
            }
        }
        setActiveMedium(tmp);
        getAllClass(activeMedium);
    }

    const getAllClass = (activeMedium) => {
        let tmp_array = [];
        activeMedium.map(ab => {
            return tmp_array = [...tmp_array, ...class_name_en[ab]];
        })
        setAllClass(tmp_array)
    }

    const onChangeClass = (e, mediumId) => {
        let {name,value,checked} = e.target;
        const cName = allClass.find(c => c.value == e.target.value)?.value;
        
        if(checked) {
            if (preferredClass.includes(value)) {
                setPreferredClass(preferredClass.filter(sel => sel !== cName));
            } else {
                setPreferredClass([...preferredClass, cName]);
                // setPreferredClass([...preferredClass, { medium: mediumId, title: cName }]);
            }
        } else {
            setPreferredClass(preferredClass.filter(sel => sel !== cName));
        }


        let tmp = activeClass;
        if(checked) {
            tmp.push(e.target.value)
        } else {
            const index = tmp.indexOf(e.target.value);
            if (index !== -1) {
                tmp.splice(index, 1);
            }
        }
        // console.log(tmp)
        setActiveClass(tmp);
        getAllSubject(activeClass);
    }

    const getAllSubject = (activeClass) => {
        let tmp_array = [];
        activeClass.map(ab =>  tmp_array = [...tmp_array, ...subject_en[ab] ?? []])
        // console.log(tmp_array);

        setAllSubject(tmp_array)
    }

    const onChangeSubject = (event) => {
        let {name,value,checked} = event.target;

        value = Number(value)

        if(checked) {
            if (preferredSubjects.includes(value)) {
                setPreferredSubjects(preferredSubjects.filter(sel => sel !== value));
                // setPreferredSubjects(...preferredSubjects, { classId: classId, subject: value });
            } else {
                // setPreferredSubjects([...preferredSubjects, value]);
                setPreferredSubjects([...preferredSubjects, value]);
            }
        } else {
            setPreferredSubjects(preferredSubjects.filter(sel => sel !== value));
        }


    }


    const onChangeDistrict = (event) => {
        setPreferredAreaToTeach([])
        if(event.target.value == ""){
            setTDistrict(null);
            setAllArea([]);
            return;
        }
        setTDistrict(districts_en.find(x => x.value == event.target.value).title ?? "")


        setAllArea(upazillas_en[event.target.value]);

    }
    //============================================onHandleChangeArea
    const onHandleChangeArea = (event) => {
        if(event.target.checked) {
            if (preferredAreaToTeach.includes(event.target.value)) {
                setPreferredAreaToTeach(preferredAreaToTeach.filter(sel => sel !== event.target.value));
            } else {
                setPreferredAreaToTeach([...preferredAreaToTeach, event.target.value]);
            }
        } else {
            setPreferredAreaToTeach(preferredAreaToTeach.filter(sel => sel !== event.target.value));
        }


    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setErrors({})
        if(validate()){
            return false;
        }

            if(tuitionInfo.t_salary.length > 5) {
                setErrorMsg({
                    ...errorMsg,
                    't_salary': "Salary not more than 5 digit!"
                })
                return;
            }

        const data = JSON.stringify({
            "uuid": Auth().uuid,
            "t_salary": salary,
            "t_is_Negotiable": isNegotiable ? 1 : 0,
            "t_days_per_week": JSON.stringify(tDaysPerWeek),
            "t_status": "1",
            "t_preferred_tuition": JSON.stringify(preferredTuition),
            "t_place_of_learning": JSON.stringify(placeOFLearning),
            "t_extra_facilities": JSON.stringify(extraFacilities),
            "t_preferred_medium_of_Eduction": JSON.stringify(preferredMediumOFEduction),
            "t_preferred_medium_of_Eduction_id": JSON.stringify(activeMedium),
            "t_preferred_class": JSON.stringify(preferredClass),
            "t_preferred_class_id":JSON.stringify(activeClass),
            "t_preferred_subjects": JSON.stringify(preferredSubjects),
            "t_preferred_district_to_teach": tDistrict,
            "t_preferred_area_to_teach": JSON.stringify(preferredAreaToTeach)
        });
        console.log(data);
        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/update_tution_info',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(response.data);
                window.location.href=`${tutor}/dashboard`;
                toast(response.data.message);
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const validate = () => {
        let errors = {};
        let hasError = false;

        if (tDaysPerWeek.length <= 0) {
            hasError = true;
            errors["t_days_per_week"] = "Days per week is a required field!!!"
        }

        if (preferredTuition.length <= 0) {
            hasError = true;
            errors["preferredTuition"] = "Preferred Tuition is a required field!!!"
        }

        if (placeOFLearning.length <= 0) {
            hasError = true;
            errors["placeOFLearning"] = "Place of Learning is a required field!!!"
        }

        if (extraFacilities.length <= 0) {
            hasError = true;
            errors["extraFacilities"] = "Extra Facilities is a required field!!!"
        }

        if (preferredMediumOFEduction.length <= 0) {
            hasError = true;
            errors["preferredMediumOFEduction"] = "Preferred Medium of Eduction is a required field!!!"
        }

        if (preferredClass.length <= 0) {
            hasError = true;
            errors["preferredClass"] = "Preferred Class is a required field!!!"
        }

        if (preferredSubjects.length <= 0) {
            hasError = true;
            errors["preferredSubjects"] = "Preferred Subject is a required field!!!"
        }


        if (preferredAreaToTeach.length <= 0) {
            hasError = true;
            errors["preferredAreaToTeach"] = "Preferred Area To Teach is a required field!!!"
        }

        setErrors(errors);

        return hasError;
    }

    if(!authValidate()) {
        return <Login/>
    }

    return (
        <Layout>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover/>



            <div className="AboutWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="tuition-info-edit">

                                <div className="card">
                                    <div className="card-header">
                                        Tuition Info Edit<br/>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>

                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                                                    Expected Minimum Salary:
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-sm-5">
                                                    <input type="number"
                                                           className="form-control"
                                                           name="t_salary"
                                                           value={salary}
                                                           onChange={handleOnChange}
                                                           id="inputEmail3"
                                                           min="1"
                                                           max="99999"
                                                           placeholder="e.x: 20000"
                                                           required
                                                    />
                                                    <span className="text-danger">{errorMsg.t_salary}</span>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-check">
                                                        <input type="checkbox"
                                                               className="form-check-input"
                                                               name="t_is_Negotiable"
                                                               value={isNegotiable == 1 ? true : false}
                                                               onChange={handleOnChange}
                                                               id="gridCheck1"
                                                               checked={isNegotiable == 1 ? true : false}
                                                        />
                                                        <label className="form-check-label" htmlFor="gridCheck1">
                                                            Negotiable
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="form-group row">
                                                    <legend className="col-form-label col-sm-4 pt-0">
                                                        Available day:
                                                        <span className="text-danger">*</span>
                                                        {/*{tDaysPerWeek.indexOf("SUNDAY1")}*/}
                                                    </legend>
                                                    <div className="col-sm-8 row">
                                                        {
                                                            getWeek().map((weekdate, index)=>{
                                                                return(
                                                                    <div className="form-check col-6 col-lg-6" key={index}>
                                                                        <input className="form-check-input"
                                                                               type="checkbox"
                                                                               name="t_days_per_week"
                                                                               id={weekdate.slug}
                                                                               value={weekdate.name}
                                                                               onChange={handleOnChangeWeek}
                                                                               checked={tDaysPerWeek.indexOf(weekdate.name) != -1}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="gridRadios1">
                                                                            {weekdate.name}
                                                                        </label>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                        <span className="text-danger">
                                                            {errors.t_days_per_week}
                                                        </span>
                                                    </div>
                                                </div>

                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-form-label col-sm-4 pt-0">
                                                    Preferred Tutoring Style:
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-sm-8 row">
                                                    <div className="form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            value="Group Tuition"
                                                            onChange={handleOnTutoring}
                                                            checked={preferredTuition.indexOf("Group Tuition") != -1}
                                                            id="group_tuition"/>
                                                        <label className="form-check-label" htmlFor="group_tuition">
                                                            Group Tuition
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            onChange={handleOnTutoring}
                                                            checked={preferredTuition.indexOf("Private Tuition") != -1}
                                                            id="private_tuition"
                                                            value="Private Tuition"/>
                                                        <label className="form-check-label" htmlFor="private_tuition">
                                                            Private Tuition
                                                        </label>
                                                    </div>
                                                    <span className="text-danger">
                                                            {errors.preferredTuition}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-form-label col-sm-4 pt-0">
                                                    Place of Learning:
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-sm-8 row">
                                                    {
                                                        getLearningPlace().map((learningPlaceData)=> {
                                                            return (
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id={learningPlaceData.slug}
                                                                        onChange={handleOnChangePlace}
                                                                        value={learningPlaceData.name}
                                                                        checked={placeOFLearning.indexOf(learningPlaceData.name) != -1}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={learningPlaceData.slug}>
                                                                        {learningPlaceData.name}
                                                                    </label>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <span className="text-danger">
                                                            {errors.placeOFLearning}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-form-label col-sm-4 pt-0">
                                                    Extra Facilities:
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-sm-8 row">
                                                    {
                                                        getExtraFacilities().map((facilities)=> {
                                                            return (
                                                                <div key={facilities.id} className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        onChange={onHandleExtraFacilities}
                                                                        id={facilities.slug}
                                                                        value={facilities.name}
                                                                        checked={extraFacilities.indexOf(facilities.name) != -1}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={facilities.slug}>
                                                                        {facilities.name}
                                                                    </label>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <span className="text-danger">
                                                        {errors.extraFacilities}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-form-label col-sm-4 pt-0">
                                                    Preferred Medium of Education:
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-sm-8 row">
                                                    {

                                                        medium_en.map((medium)=> {
                                                            return (
                                                                <div key={medium.value} className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        onChange={changeMedium}
                                                                        id={medium.value}
                                                                        value={medium.value}
                                                                        checked={preferredMediumOFEduction.indexOf(medium.title) != -1}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={medium.value}>
                                                                        {medium.title}
                                                                    </label>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <span className="text-danger">
                                                        {errors.preferredMediumOFEduction}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-form-label col-sm-4 pt-0">
                                                    Preferred Classes:
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-sm-8 row">
                                                    {
                                                        allClass.map((class_name, index)=>{
                                                            return (
                                                                <div key={index} className="form-check col-12 col-lg-3">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        //name="t_preferred_class[]"
                                                                        onChange={(e) =>  onChangeClass(e, class_name.value)}
                                                                        id={class_name.value}
                                                                        value={class_name.value}
                                                                        checked={preferredClass.indexOf(class_name.value) !== -1}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={class_name.value}>
                                                                        {class_name.title} 
                                                                    </label>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <span className="text-danger">
                                                        {errors.preferredClass}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-form-label col-sm-4 pt-0">
                                                    Preferred Subjects:
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-sm-8 row">
                                                    {
                                                        allSubject.map((subject_name, index)=>{
                                                            return (
                                                                <div key={index} className="form-check col-12 col-lg-6">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        //name="t_preferred_class[]"
                                                                        onChange={onChangeSubject}
                                                                        id={subject_name.value}
                                                                        value={subject_name.value}
                                                                        checked={preferredSubjects.indexOf(subject_name.value) !== -1}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={subject_name.value}>
                                                                        {subject_name.title} 
                                                                    </label>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <span className="text-danger">
                                                        {errors.preferredSubjects}
                                                    </span>
                                                </div>
                                            </div>
                                            <br/>

                                            <div className="form-group row">
                                                <label className="col-form-label col-sm-4 pt-0">
                                                    Preferred District to Teach:
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-sm-8 row">

                                                    <div className="form-group col-12 col-lg-8">
                                                        <select
                                                                name="t_district"
                                                                id="inputState"
                                                                onChange={onChangeDistrict}
                                                                className="form-control">
                                                            <option selected value="">Select Districts...</option>
                                                            {
                                                                districts_en.map((district, index)=>{
                                                                    return (
                                                                        <option value={district.value} selected={district.title === tDistrict} key={index}>{district.title}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <span className="text-danger">
                                                        {errors.tDistrict}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-form-label col-sm-4 pt-0">
                                                    Preferred Areas to Teach:
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-sm-8 row">
                                                    {
                                                        allArea.map((area, index)=>{
                                                            return (
                                                                <div key={index} className="form-check col-12 col-lg-6">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        onChange={onHandleChangeArea}
                                                                        id={area.value}
                                                                        value={area.title}
                                                                        checked={preferredAreaToTeach.indexOf(area.title) != -1}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={area.value}>
                                                                        {area.title}
                                                                    </label>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <span className="text-danger">
                                                        {errors.preferredAreaToTeach}
                                                    </span>
                                                </div>
                                            </div>
                                            <center>
                                                <button className="btn btn-md btn-success">Update Tuition Personal Info <i className="fa fa-send"></i></button>
                                            </center>

                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default TuitionInfoEdit;