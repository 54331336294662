import axios from "axios";
import React, { useEffect, useState } from "react";
import HeaderTitle from "../common/HeaderTitle";
import LoaderComponent from "../common/LoaderComponent";
import TutorItem from "../common/TutorItem/TutorItem";
import Layout from "../layout/Layout";
import { useDispatch } from "react-redux";
import { useGetTutorsQuery } from "../../features/tutor/tutorApi";
import { apiSlice} from "../../features/api/apiSlice";
const Tutors = () => {
  const pageSize = 12;
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { data, isLoading, isError, error } = useGetTutorsQuery({page:1,pageSize:12}) || {};

  
  useEffect(() => {
    document.title = "Tutors";
  }, []);

  const handleLoadmore = ()=>{
   
    dispatch(apiSlice.endpoints.getMoreTutors.initiate({page:page+1,pageSize}));
    setPage(page + 1)
  }
  console.log(data?.totalPages > Math.floor(data?.data.length/12))
  return (
    <Layout>
      <div className="FeaturedTutorsWrap">
        {isLoading ? (
          <LoaderComponent />
        ) : (
          data?.totalPages &&
            <div className="container">
            <div className="row">
              <HeaderTitle title="Our Tutor List" />

              {data?.data.length > 0 ? (
                data?.data.map((item, i) => <TutorItem item={item} key={i} />)
              ) : (
                <span>No Tutor Found...</span>
              )}
            </div>
            {(data?.totalPages*12 > data?.data.length && !(data?.totalPages*12-12 < data?.data.length && data?.data.length < data?.totalPages*12)) ? (
              <button
                className="load-more"
                onClick={handleLoadmore}
              >
                {isLoading ? "Loading..." : "Load More"}
              </button>
            ) : (
              <span>&nbsp;</span>
            )}
            </div>
        )}
      </div>
    </Layout>
  );
};

export default Tutors;
