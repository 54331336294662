import { apiSlice } from "../api/apiSlice";
export const guardianApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getGuardianDetails: builder.query({
            query: ({id}) =>{
                let string = `/api/web/v1/get_guardian_details/${id}`
                return string
            },
            keepUnusedDataFor:86400,
            providesTags: ['guardian_details'],
        }),
        getAllTutorRequest: builder.query({
            query: ({id}) =>{
                let string = `/api/web/v1/get_all_tutor_request/${id}`
                return string
            },
            // refetchOnMountOrArgChange:true,
            keepUnusedDataFor:1,
            providesTags: ['all_tutor_request'],
        }),
        getTutorRequest: builder.query({
            query: ({id}) =>{
                let string = `/api/web/v1/get_tutor_request/${id}`;
                return string
            },
            keepUnusedDataFor:86400,
            providesTags: ['tutor_request'],
        }),
    }),
});

export const {
   useGetGuardianDetailsQuery,
   useGetAllTutorRequestQuery,
   useGetTutorRequestQuery
} = guardianApi;
