import React from 'react';

const HeaderTitle = (props) => {
    return (
        <div className="col-12 text-start ExplorerIteM">
            <h4 className="propertyWrap">
                {props.title} <span className="FeaturedTexts"></span>
            </h4>
        </div>
    );
};

export default HeaderTitle;