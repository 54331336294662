import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import GAllRequestList from "./component/guardian/GAllRequestList";
import GDashboard from "./component/guardian/GDashboard";
import GuardianLogin from "./component/guardian/GLogin";
import GRequestTutor from "./component/guardian/GRequestTutor";
import GSignUp from "./component/guardian/GSignUp";
import OtpVerificationPage from "./component/guardian/OTPVerificationPage";
import Home from "./component/home/Home";
import ImageUplodeTest from "./component/home/ImageUplodeTest";
import TutorDetails from "./component/home/TutorDetails";
import ChooseLogin from "./component/menu/ChooseLogin";
import SearchDetails from "./component/menu/Search/SearchDetails";
import TutorRequest from "./component/menu/tuitions/TutorRequest";
import Tuitons from "./component/menu/Tuitons";
import Tutors from "./component/menu/Tutors";
import Login from "./component/tutors/Login";
import TuitorNotificationTable from "./component/tutors/notification/TuitorNotificationTable";
import SignUp from "./component/tutors/SignUp";
import TutorDashboard from "./component/tutors/TutorDashboard";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import '../src/frontend/sass/style.scss';
import NotFound from "./component/common/NotFound/NotFound";
import GChangePassword from "./component/guardian/forgetpassword/ChangePassword";
import GForgetPassword from "./component/guardian/forgetpassword/ForgetPassword";
import GVerifyOtp from "./component/guardian/forgetpassword/VerifyOtp";
import GRequestEditFunc from "./component/guardian/GRequestEditFunc";
import GRequestTutorResponse from "./component/guardian/GRequestTutorResponse";
import GRequestResponse from "./component/guardian/GuardianRequest/GRequestResponse";
import Faq from "./component/home/Faq";
import FeMaleTutors from "./component/home/FeMaleTutors";
import Help from "./component/home/Help";
import ChooseSignup from "./component/menu/ChooseSignup";
import RequestTution from "./component/menu/request_tuition/RequestTution";
import TuitionList from "./component/menu/TuitionList";
import TuitionsDetails from "./component/menu/tuitions/details/TuitionsDetails";
import TutionEdit from "./component/menu/TutionEdit";
import ChangePassword from "./component/tutors/forgetpassword/ChangePassword";
import ForgetPassword from "./component/tutors/forgetpassword/ForgetPassword";
import VerifyOtp from "./component/tutors/forgetpassword/VerifyOtp";
import JobsList from "./component/tutors/jobs/JobsList";
import RequestTuitionEdit from "./component/tutors/RequestTuitionEdit";
import TuitionInfoEdit from "./component/tutors/tutioninfo/TuitionInfoEdit";
import {
  choose, faq, fe_tutors,
  guardian, help,
  home,
  image_upload, media, medium, tuition, tutor, tutors, tutor_details,
  tutor_request
} from "./route";
import MediumSignUp from "./component/media/MediaSignUp";
import MediumLogin from "./component/media/MediaLogin";
import MediaSignup from "./component/media/MediaSignUp";
import MediaLogin from "./component/media/MediaLogin";
import MediaProfile from "./component/media/MediaProfile";
import MediaTutorRequest from "./component/media/MediaTutorRequest";
import MediaAllPostsList from "./component/media/MeadiaAllPosts";
import EditMediaTutorRequest from "./component/media/EditMediaTutorRequest";
import MediaForgetPassword from "./component/media/forgetpassword/MediaForgetPassword";
import MediaChangePassword from "./component/media/forgetpassword/MediaChangePassword";
import MediaVerifyOtp from "./component/media/forgetpassword/MediaVerifyOtp";
import MediaTuitionPostResponse from "./component/media/MediaTuitionPostResponse";

function App() {
  return (
      <Router>
          <>
            <Switch>
              <Route exact path={home} component={Home}/>
              <Route exact path={faq} component={Faq}/>
              <Route exact path={help} component={Help}/>
              <Route exact path="/search_details" component={SearchDetails}/>
              <Route exact path={`${tutor_details}/:id`} component={TutorDetails}/>
              <Route exact path={image_upload} component={ImageUplodeTest}/>
              <Route exact path={tutors} component={Tutors}/>
              <Route exact path={fe_tutors} component={FeMaleTutors}/>
              <Route exact path={tutor_request} component={TutorRequest}/>
              <Route exact path={`${tutor_request}/:id`} component={TuitionsDetails}/>
              <Route exact path={tuition} component={Tuitons}/>
              <Route exact path={`${tuition}/:id`} component={Tuitons}/>

              <Route exact path={`${guardian}/login`} component={GuardianLogin}/>
              <Route exact path={`${guardian}/signup`} component={GSignUp}/>

              <Route exact path={`${guardian}/forget-password`} component={GForgetPassword}/>
              <Route exact path={`${guardian}/otp-verify`} component={GVerifyOtp}/>
              <Route exact path={`${guardian}/change-password`} component={GChangePassword}/>

              <Route exact path={`${guardian}/dashboard`} component={GDashboard}/>
              <Route exact path={`${guardian}/tutor_request`} component={GRequestTutor}/>
              <Route exact path={`${guardian}/tutor-request/edit/:id`} component={GRequestEditFunc}/>
              <Route exact path={`${guardian}/tutor-request-successful`} component={GRequestTutorResponse}/>
              <Route exact path={`${guardian}/post`} component={GAllRequestList}/>
              <Route exact path={`/all/tutor/response`} component={GRequestResponse}/>
              <Route exact path={`${guardian}/otp_verify`} component={OtpVerificationPage}/>

              <Route exact path={`${choose}/signup`} component={ChooseSignup}/>
              <Route exact path={`${choose}/login`} component={ChooseLogin}/>

              <Route exact path={`${tutor}/signup`} component={SignUp}/>
              <Route exact path={`${tutor}/login`} component={Login}/>
              <Route exact path='/forget-password' component={ForgetPassword}/>
              <Route exact path='/otp-verify' component={VerifyOtp}/>
              <Route exact path='/change-password' component={ChangePassword}/>
              <Route exact path={`${tutor}/dashboard`} component={TutorDashboard}/>
              <Route exact path={`${tutor}/dashboard/notification`} component={TuitorNotificationTable}/>
              <Route exact path={`${tutor}/dashboard/applied/job/list`} component={JobsList}/>
              <Route exact path={`${tutor}/tuition-info/edit`} component={TuitionInfoEdit}/>
              <Route exact path={`${tutor}/request-tuition`} component={RequestTution}/>
              <Route exact path={`${tutor}/request-tuition-edit/:id`} component={RequestTuitionEdit}/>
              <Route exact path={`/tuition-wanted/`} component={TuitionList}/>
              <Route exact path={`/tuition-wanted/:id`} component={TuitionList}/>
              <Route exact path={`/tution/:id`} component={TutionEdit}/>

               {/* media */}
               <Route exact path={`${media}/signup`} component={MediaSignup}/>
               <Route exact path={`${media}/login`} component={MediaLogin}/>
               <Route exact path={`${media}/dashboard`} component={MediaProfile}/>
               <Route exact path={`${media}/tutor-request`} component={MediaTutorRequest}/>
               <Route exact path={`${media}/my-posts`} component={MediaAllPostsList}/>
               <Route exact path={`${media}/tutor-request/edit/:id`} component={EditMediaTutorRequest}/>
               <Route exact path={`${media}/forget-password`} component={MediaForgetPassword}/>
               <Route exact path={`${media}/otp-verify`} component={MediaVerifyOtp}/>
               <Route exact path={`${media}/change-password`} component={MediaChangePassword}/>
               <Route exact path={`${media}/tutor/response`} component={MediaTuitionPostResponse}/>


              <Route path={"*"} component={NotFound}/>
              <Route path="/not-found" component={NotFound}/>


             

            </Switch>
          </>
      </Router>
  );
}

export default App;
