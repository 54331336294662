import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const LoaderComponent = () => {
  return (
    <div className="loader">
      <div className="loaderInner">
          <i className="fa fa-refresh"></i>
      </div>
    </div>
  );
};

export default LoaderComponent;
