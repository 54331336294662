import React from 'react';
import Layout from "../../layout/Layout";
import {Link} from "react-router-dom";
import {tuition, tutor_request} from "../../../route";
import {Modal} from "react-bootstrap";

const JobApplyMessage = ({showMsg}) => {
    return (
        <>
            <div className="text-center pt-5 pb-5">
                <h2>{showMsg}</h2>
            </div>
        </>
    );
};

export default JobApplyMessage;