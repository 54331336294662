import React, {useEffect, useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import {Alert} from "react-bootstrap";
import axios from "axios";
import {msisdnValidate} from "../../../../utils/Validation";
import {useHistory} from "react-router-dom";
import {guardian} from "../../../../route";

const ChangePassword = ({handleSubmitRequest}) => {

    const [passwordData,setPasswordData] = useState({
        password: '',
        re_password: ''
    })
    const [msisdn,setMsisdn] = useState('');
    const [isError,setIsError] = useState(false);
    const [errorMsg,setErrorMsg] = useState('');

    const history = useHistory();
    useEffect(()=> {
        const get_data = localStorage.getItem('g_forget_msisdn');

        if(!get_data) {
            window.location.href = "/";
        } else {
            setMsisdn(get_data);
        }
    },[])

    const handleOnChange = (event) => {
        const {name,value} = event.target;

        setPasswordData({
            ...passwordData,
            [name] : value
        })

    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        const data = JSON.stringify({
            "msisdn": msisdn,
            "password": passwordData.password,
            "re_password": passwordData.re_password
        });

        if(!msisdnValidate(msisdn)) {
            setIsError(true);
            setErrorMsg("Enter Valid Phone Number!!")
            return;
        }

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/guardian_change_password`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if(response.data.success) {
                    localStorage.setItem('guardianLogin', JSON.stringify(response.data.data));
                    //history.push(`${guardian}/dashboard`);
                    handleSubmitRequest();
                    toast(response.data.message);
                } else {
                    setIsError(true);
                    setErrorMsg(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                setIsError(true);
                setErrorMsg(error)
            });

    }

    const handleClose = () => {
        setIsError(!isError);
    }


    return (
        <>



                                            <h3 className="text-center">Change Password</h3>

                                            <>
                                                {isError ?

                                                    <Alert variant='danger' onClick={handleClose} dismissible={true}>
                                                        {errorMsg}
                                                    </Alert>
                                                    :
                                                    <span></span>
                                                }
                                            </>

                                            <form onSubmit={handleChangePassword}>
                                                <div className="form-group">
                                                    <h6 className="text-center text-capitalize">Mobile NO: {msisdn}</h6>
                                                    <label htmlFor="otpCode">New Password</label>
                                                    <input type="password"
                                                           name="password"
                                                           value={passwordData.password}
                                                           className="form-control"
                                                           id="password"
                                                           onChange={handleOnChange}
                                                           placeholder="Password" required/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="otpCode">Confirm Password</label>
                                                    <input type="password"
                                                           name="re_password"
                                                           value={passwordData.re_password}
                                                           className="form-control"
                                                           id="re_password"
                                                           onChange={handleOnChange}
                                                           placeholder="Re-Type Password" required/>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary form-control">Change Password
                                                    </button>
                                                </div>
                                            </form>




        </>
    );
};

export default ChangePassword;