import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { guardian, media, tutor } from "../../route";
import { AuthGuardian, authGuardianValidate } from "../../utils/GuardianAuth";
import { districts_en } from "../../utils/location/District";
import { postcode_en } from "../../utils/location/post_code";
import { upazillas_en } from "../../utils/location/Upazilla";
import { AuthMedia, authMediaValidate } from "../../utils/MediumAuth";
import { authValidate } from "../../utils/TutionAuth";
import TutorsAdvetisement from "../common/TutorsAdvetisement";
import Layout from "../layout/Layout";

const MediaProfile = () => {

  const history = useHistory();

  
  const[uuid,setUUID] = useState('')
  const[loader,setLoader] = useState(true)

  if(authValidate()){
    history.push(`${tutor}/dashboard`);
  }

  if(authGuardianValidate()){
    history.push(`${guardian}/dashboard`);
  }

  useEffect(() => {
    if (!authMediaValidate()) {
      history.push(`${media}/login`);
    }else if(AuthMedia()){
      const{uuid} = AuthMedia();
      setUUID(uuid)
      setLoader(false)
    }
  },[])
  
  const [mediaDetails, setMediaDetails] = useState([]);

  const [isMediaEdit, setIsMediaEdit] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [isShowMsg, setIsShowMsg] = useState(false);
  const [select_area, setSelectArea] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
 



  useEffect(() => {
    if (authMediaValidate()) {
      const config = {
        method: "get",
        url:
          process.env.REACT_APP_API_URL +
          `/api/web/v1/media/get_media_details/${uuid}`,
        headers: {
          Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
        },
      };
  
      axios(config)
        .then((response) => {
          setMediaDetails(response.data.data);
          if (!response.data.data.m_district) {
          } else {
            const district_id = districts_en.find(
              (x) => x.title == response.data.data.m_district
            ).value;
            setSelectArea(upazillas_en[district_id]);
          }
          if (!response.data.data.m_postal) {
          } else {
            setPostalCode(postcode_en[response.data.data.m_area] ?? []);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [uuid]);


  const handleOnChange = (e) => {
    setMediaDetails({
      ...mediaDetails,
      [e.target.name]: e.target.value,
    });
  };


  const getGuardianDetails = (uuid) => {
    const config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `/api/web/v1/get_guardian_details/${uuid}`,
      headers: {
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
    };

    axios(config)
      .then((response) => {
        setMediaDetails(response.data.data);
        if (!response.data.data.m_district) {
        } else {
          const district_id = districts_en.find(
            (x) => x.title == response.data.data.m_district
          ).value;
          setSelectArea(upazillas_en[district_id]);
        }
        if (!response.data.data.m_postal) {
        } else {
          setPostalCode(postcode_en[response.data.data.m_area] ?? []);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editGuardianDashboard = () => {
    setIsMediaEdit(true);
    setIsShowMsg(false);
  };

  const onGuardianUpdate = (response) => {
    response.preventDefault();
    if (!mediaDetails.m_district.length) {
      setIsShowMsg(true);
      setResMsg("District is a required field!!");
      return;
    }
    if (!mediaDetails.m_area.length) {
      setIsShowMsg(true);
      setResMsg("Area is a required field!!");
      return;
    }
    
    const data = JSON.stringify({
      uuid: mediaDetails.uuid,
      m_name: mediaDetails.m_name,
      m_msisdn: mediaDetails.m_msisdn,
      m_email: mediaDetails.m_email,
      m_address: mediaDetails.m_address,
      m_district: mediaDetails.m_district,
      m_area: mediaDetails.m_area,
      m_postal: mediaDetails.m_postal,
      g_no_kid: mediaDetails.g_no_kid,
    });

    const localData = JSON.parse(localStorage.getItem("mediaLogin"))
    let update_data = {...localData, m_name: mediaDetails.m_name};
    localStorage.setItem("mediaLogin", JSON.stringify(update_data));

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/media/media_update",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setResMsg(response.data.message);
        setIsShowMsg(true);
        setIsMediaEdit(false);
        getGuardianDetails(AuthGuardian().uuid);
        onCloseMsgTimer();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChangeDistrict = (e) => {
    setPostalCode([]);
    setSelectArea([]);
    if (e.target.value === "") {
      setMediaDetails({
        ...mediaDetails,
        "m_district": "",
        "m_area": "",
        "m_postal": "",
      });
      return;
    }
    setMediaDetails({
      ...mediaDetails,
      [e.target.name]: e.target.value,
    });

    setMediaDetails({
      ...mediaDetails,
      "m_district": districts_en.find((x) => x.value == e.target.value).title,
      "m_area": "",
      "m_postal": "",
    });
    setSelectArea(upazillas_en[e.target.value]);
    
  };

  const onChangeArea = (e) => {

    setPostalCode([]);
    if (e.target.value === "") {
      return;
    }
    setMediaDetails({
      ...mediaDetails,
      [e.target.name]: e.target.value,
    });
    if (typeof postcode_en[e.target.value] == "undefined") {
      setPostalCode([]);
    } else {
      setPostalCode(postcode_en[e.target.value]);
    }
    //setPostalCode(postcode_en[e.target.value])
  };

  const onCloseMsg = () => {
    setIsShowMsg(!isShowMsg);
  };

  const onCloseMsgTimer = () => {
    const timeId = setTimeout(() => {
      setIsShowMsg(false);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  };

  

  
  return (

    <Layout>
      {
        loader ? <div>Loading...</div> :
        <div className="guardian-dashboard">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="guardian-profile">
                  <>
                    {isShowMsg ? (
                      <Alert variant="success" onClick={onCloseMsg} dismissible>
                        {resMsg}
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </>
                  {isMediaEdit ? (
                    <div className="guardian-profile-edit">
                      <form onSubmit={onGuardianUpdate}>
                        <div className="card">
                          <div className="card-header">
                            <h5>Media Profile Edit</h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="form-group col-12 col-lg-6">
                                <label htmlFor="m_name">Name</label>
                                <input
                                  type="text"
                                  name="m_name"
                                  onChange={handleOnChange}
                                  className="form-control"
                                  id="m_name"
                                  maxLength="120"
                                  value={mediaDetails.m_name}
                                  placeholder="Name..."
                                  required
                                />
                              </div>
                              <div className="form-group col-12 col-lg-6">
                                <label htmlFor="m_email">Email</label>
                                <input
                                  type="email"
                                  name="m_email"
                                  onChange={handleOnChange}
                                  className="form-control"
                                  id="m_email"
                                  value={mediaDetails.m_email}
                                  placeholder="Email..."
                                  required
                                />
                              </div>
                              <div className="form-group col-12 col-lg-6">
                                <label htmlFor="exampleInputEmail1">
                                  Select District:
                                </label>
                                <select
                                  className="form-select form-select-sm"
                                  name="m_district"
                                  onChange={onChangeDistrict}
                                  aria-label=".form-select-sm example"
                                >
                                  <option selected value="">
                                    ---Select District---
                                  </option>
                                  {districts_en.map((area) => {
                                    return (
                                      <option
                                        value={area.value}
                                        selected={
                                          area.title == mediaDetails.m_district
                                        }
                                      >
                                        {area.title}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group col-12 col-lg-6">
                                <label htmlFor="exampleInputEmail1">
                                  Select Area:
                                </label>
                                <select
                                  className="form-select form-select-sm"
                                  name="m_area"
                                  onChange={onChangeArea}
                                  aria-label=".form-select-sm example"
                                >
                                  <option selected value="">
                                    ---Select area---
                                  </option>
                                  {select_area.map((area) => {
                                    return (
                                      <option
                                        value={area.title}
                                        selected={
                                          area.title === mediaDetails.m_area
                                        }
                                      >
                                        {area.title}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group col-12 col-lg-6">
                                <label htmlFor="exampleInputEmail1">
                                  Select Postal Code:
                                </label>
                                <select
                                  className="form-select form-select-md"
                                  name="m_postal"
                                  onChange={handleOnChange}
                                  aria-label=".form-select-sm example"
                                >
                                  <option selected value="">
                                    ---Select Postal Code---
                                  </option>
                                  {postalCode.map((code) => {
                                    return (
                                      <option
                                        value={code}
                                        selected={
                                          code === mediaDetails.m_postal
                                        }
                                      >
                                        {code}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group col-12 col-lg-12">
                                <label htmlFor="m_address">Details Address</label>
                                <textarea
                                  name="m_address"
                                  onChange={handleOnChange}
                                  className="form-control"
                                  id="m_address"
                                  value={mediaDetails.m_address}
                                  maxLength="120"
                                  placeholder="Address..."
                                  required
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn btn-sm profile-update-btn"
                            >
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                              Update Guardian
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div className="guardian-profile-details">
                      <div className="card">
                        <div className="card-header">
                          <h5>Media Profile</h5>
                        </div>
                        <div className="card-body">
                          <table className="table guardianDetailsTable">
                            <tr>
                              <td>
                                <b>Name</b>
                              </td>
                              <td>:</td>
                              <td>{mediaDetails.m_name}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Email</b>
                              </td>
                              <td>:</td>
                              <td>{mediaDetails.m_email}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Mobile Number</b>
                              </td>
                              <td>:</td>
                              <td>{mediaDetails.m_msisdn}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Address</b>
                              </td>
                              <td>:</td>
                              <td>{mediaDetails.m_address}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>District</b>
                              </td>
                              <td>:</td>
                              <td>{mediaDetails.m_district}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Area</b>
                              </td>
                              <td>:</td>
                              <td>{mediaDetails.m_area}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Postal Code</b>
                              </td>
                              <td>:</td>
                              <td>{mediaDetails.m_postal}</td>
                            </tr>
                          </table>
                        </div>
                        <div className="card-footer">
                          <button
                            className="btn btn-sm profile-edit-btn"
                            onClick={editGuardianDashboard}
                          >
                            <i className="fa fa-edit"></i> Update Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-4">
                {/* <GSideBar/> */}
                <TutorsAdvetisement />
              </div>
            </div>
          </div>
        </div>
      }
    </Layout>
  );
};

export default MediaProfile;
