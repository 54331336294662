import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { apiSlice } from "../../features/api/apiSlice";
import { guardianApi, useGetAllTutorRequestQuery } from "../../features/guardian/guardianApi";
import { media } from "../../route";
import { AuthMedia, authMediaValidate } from "../../utils/MediumAuth";
import HeaderTitle from "../common/HeaderTitle";
import LoaderComponent from "../common/LoaderComponent";
import TutorsAdvetisement from "../common/TutorsAdvetisement";
import Layout from "../layout/Layout";


const MediaAllPostsList = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [uuid,setUuid]=useState('');
    const [loader,setLoader]=useState(true);



    useEffect(()=>{
        if(!authMediaValidate()){
            history.push(`${media}/login`);
        }else{
            const{uuid} = AuthMedia();
            if(uuid){
                setUuid(uuid)
                setLoader(false)
            }
        }
    },[])

    const [isViewDetails,setIsViewDetails] = useState(false);
    const [viewDetail,setViewDetail] = useState([]);

   

    const { data, isError, error } = useGetAllTutorRequestQuery({id:uuid}) || {};

    const viewDetails = (id) => {
        setIsViewDetails(true);
        dispatch(guardianApi.endpoints.getTutorRequest.initiate({id}))
        .unwrap()
        .then((data) => {
            setViewDetail((data?.data))
        })
    }

    const viewDetailsClose = () => {
        setIsViewDetails(false);
    }

    const changeHiredRequest = (e) => {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/hired_tutor_request/${e}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(function (response) {
                toast(response.data.message);
                dispatch(apiSlice.util.invalidateTags(['all_tutor_request']));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleDeleteRequest = (id) => {

        const data = {
            "guardian_post_id" : id
        }

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/delete_tutor_request`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                window.location.reload();
                toast(response.data.message);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    

    return (
        loader ?
        <LoaderComponent/>
        :
        <Layout>
        <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover/>

        <div className="container">
            <div className="row">
                <HeaderTitle first_title="All Tutor" second_title="Request"/>
                <div className="col-lg-8 pt-3">

                    {/*All Request*/}
                    <div className="guardian-tutor-request-list">
                        {data?.data.length > 0 ?
                            data?.data.map((tuition_data) => {
                                return (
                                    <>
                                        <div className="card" key={tuition_data.guardian_post_id}>
                                            <div className="card-body">


                                                {/* <small className="text-muted">Tuition ID: {tuition_data.tuition_uniq_id}</small> */}
                                                <p className="t_title">Need {tuition_data.medium} ({tuition_data.tutor_gender} ) Tutor for {tuition_data.class_name} student</p>
                                                <div className="row py-2">
                                                    <div className="col-md-6">
                                                        <b className="custom-text-color">Education Medium :</b> {tuition_data.medium}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <b className="custom-text-color">Class :</b> {tuition_data.class_name}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <b className="custom-text-color">Salary :</b> {tuition_data.salary_range} Tk/Month
                                                    </div>
                                                </div>
                                                <small>
                                                    <b>Subjects :</b> 
                                                    {tuition_data.subject.replace(/\[|\]|"/g," ")}
                                                    <br/>
                                                    <b>Days: </b><span>{tuition_data.days_per_weak}</span> Days/Week
                                                </small>
                                                
                                                <div className="row pt-2">
                                                    <div className="col-md-8">
                                                        <div>
                                                            <>
                                                                <i className="fa fa-map-marker text-danger"></i> &nbsp;
                                                                {tuition_data.area},{tuition_data.district}
                                                            </>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 mt-2">
                                                        {/*<small>Published Time : {tuition_data.created_at}</small>*/}
                                                    </div>
                                                </div>
                                                <span className="custom-view-button pull-right"></span>

                                            </div>
                                            <div className="card-footer">
                                                <button className="btn btn-danger"
                                                        onClick={() => handleDeleteRequest(tuition_data.guardian_post_id)}
                                                        style={{ marginLeft: '5px !important' }}
                                                        >
                                                    <i className="fa fa-trash"></i>
                                                    Delete
                                                </button>
                                                <button className="btn btn-success mx-1"
                                                        onClick={ () => history.push(`${media}/tutor-request/edit/${tuition_data.guardian_post_id}`)}
                                                        >
                                                    <i className="fa fa-pencil"></i>
                                                    Edit Request
                                                </button>
                                                <button  className="btn btn-view-tutor-req"
                                                        onClick={() => {
                                                            viewDetails(tuition_data.guardian_post_id)
                                                        }}>
                                                    <i className="fa fa-envelope"></i>
                                                    View Details
                                                </button>
                                                
                                                <button className={tuition_data.is_hired ? 'btn btn-success': 'btn btn-danger'} onClick={() => {
                                                    changeHiredRequest(tuition_data.guardian_post_id)
                                                }}>
                                                    {tuition_data.is_hired ?
                                                        <span>
                                                                <i className="fa fa-trash"></i>
                                                                Remove Hired
                                                            </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-check-circle"></i>
                                                            Make Hired
                                                        </span>
                                                    }
                                                </button>
                                                
                                            </div>
                                        </div>
                                    </>
                                );
                            })
                            :
                            <span>
                                    No Tutor Request... <Link to={`${media}/tutor-request`}>Click here to request a tutor.</Link>
                            </span>
                        }
                    </div>
                    {/*End All request */}



                </div>
                <div className="col-lg-4">
                    {/* <GSideBar/> */}
                    <TutorsAdvetisement/>
                </div>
            </div>
        </div>

        {/*  Details view  */}
        <Modal
            show={isViewDetails}
            onHide={viewDetailsClose}
            size="lg"
            backdrop="static"
            keyboard={false}
            centered
            className='modalCOntrolS'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p className="t_title text-center">Need {viewDetail?.medium} ({viewDetail?.tutor_gender}) Tutor for a Student of {viewDetail?.class_name} Student</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {/*  Details  */}
                {/* <small className="text-muted">Tuition ID: {viewDetail?.tuition_uniq_id}</small> */}
                <div className="row py-2">
                    <div className="col-md-4">
                        <b className="text-muted">Post Title :</b> <br/>{viewDetail?.s_name}
                    </div>
                    <div className="col-md-4">
                        <b className="text-muted">Student School :</b>
                        <br/>{viewDetail?.student_school}
                    </div>
                    <div className="col-md-4">
                        <b className="text-muted">Education Medium :</b>
                        <br/>
                        {viewDetail?.medium}
                    </div>
                </div>
                <div className="row py-2">
                    <div className="col-md-4">
                        <b className="text-muted">Class :</b>
                        <br/>
                        {viewDetail?.class_name}
                    </div>
                    <div className="col-md-4">
                        <b className="text-muted">Subject :</b>
                        <br/>
                        <p>
                            {
                            viewDetail?.subject && JSON.parse(viewDetail?.subject).map((subject) => (
                                    <span>{ subject + ", " }</span>
                                ))
                            }
                        </p>
                    </div>
                    <div className="col-md-4">
                        <b className="text-muted">Salary :</b>
                        <br/>
                        {viewDetail?.salary_range} Tk/Month
                    </div>
                </div>
                <div className="row py-2">
                    <div className="col-md-4">
                        <b className="text-muted">Student Gender:</b>
                        <br/>
                        {viewDetail?.gender_of_student}
                    </div>
                    <div className="col-md-4">
                        <b className="text-muted">Desired Tutor Gender:</b>
                        <br/>
                        {viewDetail?.tutor_gender}
                    </div>
                    <div className="col-md-4">
                        <b className="text-muted">Days:</b>
                        <br/>
                        {viewDetail?.days_per_weak} Days/Week
                    </div>
                </div>
                <small>
                    <b>Status:</b> {viewDetail?.is_hired ? 'Already Hired' : "Not Hired"}
                </small>
                <div className="row pt-2">
                    <div className="col-md-4">
                        <div>
                            <>
                                <i className="fa fa-map-marker text-danger"></i> &nbsp;
                                {viewDetail?.area},{viewDetail?.district}
                            </>
                        </div>
                    </div>
                    <div className="col-md-4 mt-2">
                        <small><strong>Additional Information</strong> : {viewDetail?.additional_information}</small>
                    </div>
                    <div className="col-md-4 mt-2">
                        <small><strong>Detail Address</strong> : {viewDetail?.contact_address}</small>
                    </div>
                </div>
                {/*  End Details  */}

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-danger" onClick={viewDetailsClose}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
        {/*  End Details view  */}

        </Layout>
    );
};

export default MediaAllPostsList;