import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Header2 from "./Header2";

const Layout = ({children}) => {
    return (
        <>
            <Header2/>
            <main>
                {children}
            </main>
            <Footer/>
        </>
    );
};

export default Layout;