import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import axios from "axios";
import {msisdnValidate} from "../../utils/Validation";
import {Alert} from "react-bootstrap";

const TutorsNotification = ({tutors_id}) => {
    const { register, handleSubmit, watch, formState: { errors },setValue } = useForm();
    const [isMsg,setIsMsg] = useState(false);
    const [nReturnMsg,setNReturnMsg] = useState("");

    useEffect(()=>{
        //alert(JSON.stringify(tutors_id));
    },[])

    const onNotificationSubmit = (e) => {
        if(!msisdnValidate(e.mobile)){
            setNReturnMsg("Enter Valid Phone Number.");
            setIsMsg(true);
            return false;
        }
        const data = JSON.stringify({
            "tutors_id": tutors_id,
            "name": e.name,
            "email": e.email,
            "mobile": e.mobile,
            "details": e.details
        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/insert_notification',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                setNReturnMsg(response.data.message);
                setIsMsg(true);
                setValue('name', '');
                setValue('email', '');
                setValue('mobile', '');
                setValue('details', '');
                onCloseMsgTimer()
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    
    const onCloseMsg = () => {
        setIsMsg(false)
    }

    const onCloseMsgTimer = () => {
        const timeId = setTimeout(() => {
            setIsMsg(false)
        }, 5000)

        return () => {
            clearTimeout(timeId)
        }
    }

    return (
        <>

            <div className="tutor-send-msg-form">

                <div className="card row">
                    <div className="card-header">
                        <h5 className="ContactTutors">Contact with this tutors</h5>
                    </div>
                    <div className="card-body">
                        {isMsg ?
                            <Alert variant="success" onClick={onCloseMsg} dismissible>
                                {nReturnMsg}
                            </Alert>
                            :
                            <></>
                        }
                        <form className="form-group" onSubmit={handleSubmit(onNotificationSubmit)}>
                            <input type="text"
                                   name="name"
                                   {...register("name", { required: true })}
                                   className="form-control"
                                   maxLength="120"
                                   placeholder="Name"
                            />
                            <input type="email"
                                   name="email"
                                   {...register("email", { required: true })}
                                   maxLength="120"
                                   className="form-control" placeholder="Email"/>
                            <input type="number"
                                   name="mobile"
                                   {...register("mobile", { required: true })}
                                   min="0"
                                   className="form-control" placeholder="Number"/>
                            <textarea name="details"
                                      {...register("details", { required: true })}
                                      className="form-control text_Details"
                                      maxLength="350"
                                      placeholder="Enter Details"></textarea><br/>
                            <button type="submit" className="btn btn-primary form-control">Send Message <i className="fa fa-send-o"></i></button>
                        </form>
                    </div>
                </div>
            </div>

        </>
    );
};

export default TutorsNotification;