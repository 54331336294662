import axios from "axios";
import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { apiSlice } from "../../../features/api/apiSlice";
import { tutor } from "../../../route";
import { authGuardianValidate } from "../../../utils/GuardianAuth";
import { authMediaValidate } from "../../../utils/MediumAuth";
import { Auth, authValidate } from "../../../utils/TutionAuth";

const TuitionsDetails = ({tuitionId,handleShowTuitionDetailsHide}) => {
    const [viewDetail,setViewDetail] = useState([]);
    const [applyShow,setApplyShow] = useState(false);
    const [msgShow,setMsgShow] = useState(false);
    const [allApplyJob,setAllApplyJob] = useState([])
    const [applyInfo,setApplyInfo] = useState({});
    const [showMsg,setShowMsg] = useState('');
    const [isShowMsg,setIsShowMsg] = useState(false);
    let location = useLocation();
    const history = useHistory();

    let {id} = tuitionId;
    //let {id} = useParams();

    useEffect(()=> {
        viewDetails(tuitionId)
        document.title = "TUITIONS DETAILS";
    },[])
    const dispatch = useDispatch()
    const allapplyJob = useCallback(() => {
        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/get_applied_jobslist_id/${Auth().uuid}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setAllApplyJob(response.data.data);
                // if(authValidate()) {
                //     // allapplyJob()
                // }
            })
            .catch(function (error) {
                console.log(error);
            });
    },[])


    useEffect(() => {
        if(authValidate()) {
            allapplyJob()
        }
    },[allapplyJob])

    const handleOnChange = (e) => {
        setApplyInfo({
            ...applyInfo,
            [e.target.name]: e.target.value
        })
    }

    const viewDetails = (id) => {

        const config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+`/api/web/v1/get_tutor_request/${id}`,
            headers: {
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            }
        };
        //alert(config);
        axios(config)
            .then(response=> {
                if(!response.data.data){
                    history.push("/");
                }
                setViewDetail(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onHandleApplyJob = (e) => {
        e.preventDefault();

        let data = {}

        if(viewDetail.type === 'media'){
            data = JSON.stringify({
                "name": Auth().name,
                "mobile": Auth().msisdn,
                "tutors_id": Auth().uuid,
                "guardian_id": viewDetail.guardian_id,
                "job_id": viewDetail.guardian_post_id,
                "type": "media"
            });
        }else{
            data = JSON.stringify({
                "name": Auth().name,
                "mobile": Auth().msisdn,
                "tutors_id": Auth().uuid,
                "guardian_id": viewDetail.guardian_id,
                "job_id": viewDetail.guardian_post_id
            });

            
        }

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/apply_job',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };
       

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setApplyShow(false)
                setShowMsg(response.data.message)
                setIsShowMsg(true)
                dispatch(apiSlice.util.invalidateTags(['applied_joblist']));
            })
            .catch(function (error) {
                console.log(error);
                setApplyShow(false)
                setShowMsg(error)
                setIsShowMsg(true)
            });

    }

    

    const onHandleHideMsg = (status) => {
        setIsShowMsg(status)
        if(authValidate()) {
            allapplyJob()
        }
    }

    return (
        <>
            <div className="tuitionRequestDetails">
                <div className="">
                    <div className="row">
                            <>
                                <div className="col-lg-12">
                                    <div className="card">
                                        <h2 className="p-2" style={{textAlign:"right"}}>
                                            <Link onClick={()=>{
                                                handleShowTuitionDetailsHide(false)
                                            }} style={{color:"#f48f1d"}}>
                                                <i className="fa fa-times-circle" aria-hidden="true"></i>
                                            </Link>
                                        </h2>
                                        <div className="card-body" >
                                                <p className="t_title text-center">Need {viewDetail.medium} ({viewDetail.tutor_gender}) Tutor for a Student of {viewDetail.class_name} Student</p>
                                                <small className="text-muted">Tuition ID: {viewDetail.tuition_uniq_id}</small>
                                                <div className="row py-2">
                                                    {/*<div className="col-md-4">*/}
                                                    {/*    <b className="text-muted">Student Name :</b> <br/>{viewDetail.s_name}*/}
                                                    {/*</div>*/}
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Student School :</b>
                                                        <br/>{viewDetail.student_school && viewDetail.student_school}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Education Medium :</b>
                                                        <br/>
                                                        {viewDetail.medium}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Class :</b>
                                                        <br/>
                                                        {viewDetail.class_name}
                                                    </div>
                                                </div>
                                                <div className="row py-2">
                                                    <div className="col-md-8">
                                                        <b className="text-muted">Subject :</b>
                                                        <br/>
                                                        {viewDetail.subject && viewDetail.subject.length > 0 && viewDetail.subject.replace(/\[|\]|"/g,"")}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Salary :</b>
                                                        <br/>
                                                        {viewDetail.salary_range} Tk/Month
                                                    </div>
                                                </div>
                                                <div className="row py-2">
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Student Gender:</b>
                                                        <br/>
                                                        {viewDetail.gender_of_student}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Desired Tutor Gender:</b>
                                                        <br/>
                                                        {viewDetail.tutor_gender}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <b className="text-muted">Days:</b>
                                                        <br/>
                                                        {viewDetail.days_per_weak} Days/Week
                                                    </div>
                                                </div>
                                                <small>
                                                    <b>Status:</b> {viewDetail.is_hired ? 'Already Hired' : "Not Hired"}
                                                </small>
                                               
                                                <div className="row pt-2 pb-2">
                                                    <div className="col-md-8">
                                                        <div>
                                                            <>
                                                                <i className="fa fa-map-marker text-danger"></i> &nbsp;
                                                                {viewDetail.area},{viewDetail.district}
                                                                <br/>
                                                                <br/>
                                                                {
                                                                    viewDetail.type === 'media' && <Badge bg="warning">Paid</Badge>
                                                                } 
                                                            </>
                                                        </div>
                                                    </div>
                                                </div>
                                                {  viewDetail?.additional_information && 
                                                    <small>
                                                        <b>Additional Information:</b> {viewDetail?.additional_information}
                                                    </small>
                                                }
                                                <div className="custom-view-button pull-right">
                                                    {authValidate() ?
                                                        <span>

                                                            {allApplyJob.indexOf(viewDetail.guardian_post_id) == -1 ?
                                                                <button className="btn btn-sm btn-success pull-right"
                                                                        variant="primary"
                                                                        onClick={() => setApplyShow(true)}>
                                                                    Apply this job
                                                                </button>
                                                                :
                                                                <button className="btn btn-sm btn-danger pull-right"
                                                                        disabled={true}>
                                                                    Already Applied
                                                                </button>
                                                            }
                                                        </span>
                                                        :
                                                        <>
                                                            {
                                                                authGuardianValidate() || authMediaValidate() ?
                                                                    <div className="text-warning pull-right">
                                                                        <span>Guardian or Media can not apply job!</span>
                                                                    </div>
                                                                    :
                                                                    <Link className="btn btn-sm btn-view pull-right" to={`${tutor}/login?url=${location.pathname.slice(1)}`}>
                                                                        Log in as a Tutor to apply for this job
                                                                    </Link>
                                                            }
                                                        </>

                                                    }
                                                </div>

                                            </div>

                                    </div>
                                </div>
                            </>
                    </div>
                </div>
            </div>

            <>
                {/*<JobApplyMessage showMsg={showMsg}/>*/}
                <Modal
                    show={isShowMsg}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => setIsShowMsg(false)}
                >
                    <Modal.Body>

                        <div className="awesome-button-section">
                            <div className="awesome-button">
                                <span className="fa fa-check checked-icon"></span>
                                <h5 className="awesome-heaading">{showMsg}</h5>

                                <button type="submit" onClick={() => onHandleHideMsg(false)} className="ok-button">OK <i className="fa fa-check"></i></button>

                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </>

            <Modal
                show={applyShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setApplyShow(false)}
            >
                <form onSubmit={onHandleApplyJob}>
                    <Modal.Body>

                        <div className="awesome-button-section">
                            <div className="awesome-button">
                                <span className="fa fa-check checked-icon"></span>
                                <h2 className="awesome-heaading">Confirmation!</h2>
                                <p className="booking-text">Do you want to apply?</p>

                                <button type="submit" className="ok-button">Apply <i className="fa fa-send"></i></button>

                            </div>
                        </div>

                    </Modal.Body>
                </form>
            </Modal>

        </>
    );
};

export default TuitionsDetails;