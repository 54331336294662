import axios from "axios";
import React, { useState } from 'react';
import { Alert } from "react-bootstrap";
import { toast } from "react-toastify";

const GuardianLogin = ({onChangeAuth}) => {

    const [inputData,setInputData] = useState({
        msisdn : "",
        otp_code: "",
        g_name: "",
        g_email: "",
        g_msisdn: "",
        g_password: "",
        g_photo: "",
        g_login_by: ""
    })
    const [resMsg, setResMsg] = useState("");
    const [isShowMsg, setIsShowMsg] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const onShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    };

    const handleOnChange = (e) => {
        setResMsg("");
        setIsShowMsg(false);

        setInputData({
            ...inputData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmitLogin = (e) => {
        e.preventDefault();

        const data = JSON.stringify({"g_msisdn": inputData.g_msisdn, "g_password": inputData.g_password});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/guardian_login`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if(response.data.success) {
                    toast(response.data.message)
                    localStorage.clear();
                    localStorage.setItem('guardianLogin', JSON.stringify(response.data.data));
                    onChangeAuth(true)
                } else {
                    setIsShowMsg(true)
                    setResMsg(response.data.message)
                }
            })
            .catch(function (error) {
                setIsShowMsg(true)
                setResMsg(error.response.data.message)
            });


    }

    const onCloseMsg = () => {
        setIsShowMsg(!isShowMsg);
    }

    return (
        <div>

            <>
                {isShowMsg ?
                    <Alert variant="danger" onClick={onCloseMsg} dismissible>
                        {resMsg}
                    </Alert>
                    :
                    <></>
                }
            </>

            <form onSubmit={handleSubmitLogin}>
                <div className="form-group">
                    <label htmlFor="mobile_number">Mobile Number</label>
                    <input type="number"
                           name="g_msisdn"
                           className="form-control"
                           onChange={handleOnChange}
                           id="mobile_number"
                           placeholder="Mobile Number..."
                           required={true}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">
                    Password
                    </label>
                    <div className="loginInput">
                    <input
                        type={isShowPassword ? "text" : "password"}
                        name="g_password"
                        onChange={handleOnChange}
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        required={true}
                    />
                    <i
                        onClick={onShowPassword}
                        className={
                        isShowPassword
                            ? "fa fa-eye"
                            : "fa fa-eye-slash"
                        }
                    ></i>
                    </div>
                </div>
                <div className="form-group d-flex justify-content-between">
                    <div className="rem">
                        <div className="remItem">
                            <label htmlFor="rem">
                                <input type="checkbox" id='rem'/> Remember me
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary form-control">Login
                    </button>
                </div>
            </form>

        </div>
    );
};

export default GuardianLogin;