import { Tooltip } from "bootstrap";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { useGetGuardianAppliedTuitionListMutation, useGetNotificationQuery } from "../../../features/tutor/tutorApi";
import { Auth, authValidate } from "../../../utils/TutionAuth";
import LoaderComponent from "../../common/LoaderComponent";
import Layout from "../../layout/Layout";
import Login from "../Login";

const TuitorNotificationTable = () => {
  // const [notification, setNumberNotification] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [getGuardianAppliedTuitionList, {isSuccess}] =useGetGuardianAppliedTuitionListMutation();
  const { data:allNotification, isError, error } = useGetNotificationQuery({id:Auth().uuid}) || {};
  const { list } = useSelector((state) => state.tutor) || {};
  useEffect(() => {
    if (authValidate()) {
      // getTotalNotification();
      setTimeout(() =>{
        setIsLoading(false)
      },0)
    }
  }, []);

  useEffect(() => {
      getGuardianAppliedTuitionList({id:Auth().uuid})
  }, []);

  // const getTotalNotification = () => {
  //   const config = {
  //     method: "get",
  //     url:
  //       process.env.REACT_APP_API_URL +
  //       `/api/web/v1/number_of_notification/${Auth().uuid}`,
  //     headers: {
  //       Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       setNumberNotification(response.data.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  

  if (!authValidate()) {
    return <Login />;
  }


  const columns = [
    {
        name: 'Name',
        selector: row => row.g_name,
    },
    {
        name: 'Address',
        selector: row => row.g_address,
    },
    {
      name: 'Mobile',
      selector: row => row.g_msisdn,
    },
    {
      name: 'Salary Tk/Months',
      selector: row => row.salary_range,
    },
    {
      name: 'Medium',
      selector: row => row.medium,
    },
    {
      name: 'Class',
      selector: row => row.class_name,
    },
    {
      name: 'Days Per Week',
      selector: row => row.days_per_weak
    },
    {
      name: 'Response Send Date',
      selector: row => moment(row.tuition_post_created).format('Do MMMM YYYY, h:mm A')
    },
    {
      name: 'Location',
      selector: row => row.district + ',' + row.area
    }
  ];

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Simple tooltip
    </Tooltip>
  );
  
  const columns2 = [
    {
        name: 'Name',
        selector: row => row.name,
    },

    {
      name: 'Mobile',
      selector: row => row.mobile,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Message',
      selector: row => row.details,
      Cell:  (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <span >Hover me to see</span>
      </OverlayTrigger>
    )
    },
  ];

  


  const paginationComponentOptions1 = {
    rowsPerPageText: 'Per Page',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Tuition',
  };

  const paginationComponentOptions2 = {
    rowsPerPageText: 'Per Page',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Notification',
  };



  return (
    <>
      <Layout>
        {
          isLoading ?
          <LoaderComponent/> :
          <div className="bgGray">
            <div className="container pt-5">
              <div className="row align-items-center">
                <div className="col-lg-12 col-12">
                  <div className="tutor-notification-table mb-4">
                    <div className="card">
                      <div className="card-header">
                        {/* <h5>My Notification</h5> */}
                        <h5>Message sent by guardian</h5>
                        {/* Unread:{" "}
                        <span className="custom-badge-danger">
                          {notification}
                        </span> */}
                      </div>
                      <div className="card-body">
                        {allNotification?.data.length > 0 ? (
                          <DataTable columns={columns2} data={allNotification?.data} pagination paginationComponentOptions={paginationComponentOptions2} />
                        ) : (
                          <span> No Notification</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="tutor-notification-table mb-4">
                    <div className="card">
                      <div className="card-header">
                        <h5>Tuition Response By Guardian</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <DataTable columns={columns} data={list?.data} pagination paginationComponentOptions={paginationComponentOptions1} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </Layout>
    </>
  );
};



export default TuitorNotificationTable;
