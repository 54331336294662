import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { msisdnValidate } from "../../../utils/Validation";
import Layout from "../../layout/Layout";

const ChangePassword = () => {

    const [passwordData,setPasswordData] = useState({
        password: '',
        re_password: ''
    })
    const [msisdn,setMsisdn] = useState('');
    const [isError,setIsError] = useState(false);
    const [errorMsg,setErrorMsg] = useState('');
    const [isShowPassword1, setIsShowPassword1] = useState(false);
    const [isShowPassword2, setIsShowPassword2] = useState(false);
    const onShowPassword = () => {
        setIsShowPassword1(!isShowPassword1);
    };

    const onShowPassword2 = () => {
        setIsShowPassword2(!isShowPassword2);
    };

    const history = useHistory();
    useEffect(()=> {
        const get_data = localStorage.getItem('forget_msisdn');

        if(!get_data) {
            window.location.href = "/";
        } else {
            setMsisdn(get_data);
        }
    },[])

    const handleOnChange = (event) => {
        const {name,value} = event.target;

        setPasswordData({
            ...passwordData,
            [name] : value
        })

    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        const data = JSON.stringify({"msisdn": msisdn, "password": passwordData.password, "re_password": passwordData.re_password});

        if(!msisdnValidate(msisdn)) {
            setIsError(true);
            setErrorMsg("Enter Valid Phone Number!!")
            return;
        }

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/change_password',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                if(response.data.success) {
                    localStorage.setItem('loginData', JSON.stringify(response.data.data));
                    history.push("/tutor/dashboard");
                    toast(response.data.message);
                } else {
                    setIsError(true);
                    setErrorMsg(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                setIsError(true);
                setErrorMsg(error)
            });
    }

    const handleClose = () => {
        setIsError(!isError);
    }


    return (
        <>

            <Layout>
                <div className="guardianLoginWrap">
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover/>

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4">

                                <div className="formWrap">
                                    <div className="formInner">
                                        <div className="formCenter">
                                            <h6>Reset Password</h6>

                                            <>
                                                {isError ?

                                                    <Alert variant='danger' onClick={handleClose} dismissible={true}>
                                                        {errorMsg}
                                                    </Alert>
                                                    :
                                                    <span></span>
                                                }
                                            </>

                                            <form onSubmit={handleChangePassword}>
                                                <div className="form-group">
                                                    <label htmlFor="otpCode">New Password</label>
                                                    <h5>Mobile NO: {msisdn}</h5>
                                                    <div className="loginInput">
                                                        <input type={isShowPassword1 ? "text" : "password"}
                                                            name="password"
                                                            value={passwordData.password}
                                                            className="form-control"
                                                            id="password"
                                                            onChange={handleOnChange}
                                                            placeholder="Password" required/>
                                                        <i
                                                            onClick={onShowPassword}
                                                            className={
                                                            isShowPassword1
                                                                ? "fa fa-eye"
                                                                : "fa fa-eye-slash"
                                                            }
                                                        ></i>
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group">
                                                    <label htmlFor="otpCode">Confirm Password</label>
                                                    <div className="loginInput">
                                                        <input type={isShowPassword2 ? "text" : "password"}
                                                            name="re_password"
                                                            value={passwordData.re_password}
                                                            className="form-control"
                                                            id="re_password"
                                                            onChange={handleOnChange}
                                                            placeholder="Re-Type Password" required/>
                                                        <i
                                                            onClick={onShowPassword2}
                                                            className={
                                                            isShowPassword2
                                                                ? "fa fa-eye"
                                                                : "fa fa-eye-slash"
                                                            }
                                                        ></i>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary form-control">Change Password
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>

        </>
    );
};

export default ChangePassword;