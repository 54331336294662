import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { districts_en } from "../../../utils/location/District";
import { upazillas_en } from "../../../utils/location/Upazilla";
import { class_name_en } from "../../../utils/medium_data/ClassData";
import { medium_en } from "../../../utils/medium_data/Medium";
import { subject_en } from "../../../utils/medium_data/subject3";

const ReSearch = () => {
    const history = useHistory();
    const search = useLocation().search;

    //onchange
    const [district,setDistrict] = useState('')
    const [medium_id, setMediumId] = useState('')
    const [class_id, setClassId] = useState('')

    const [allUpozilla,setAllUpozilla] = useState([])
    const [allClass, setAllClass] = useState([])
    const [allSubject, setAllSubject] = useState([])
    //end_onchange

    const [searchData, setSearchData] = useState({
        district_search: new URLSearchParams(search).get('district_search') ?? '',
        location_search: new URLSearchParams(search).get('location_search') ?? '',
        medium_search: new URLSearchParams(search).get('medium_search') ?? '',
        class_search: new URLSearchParams(search).get('class_search'),
        subject_search: new URLSearchParams(search).get('subject_search')
    });

    useEffect(()=> {
        setDistrict(new URLSearchParams(search).get('district'))
        setAllUpozilla(upazillas_en[new URLSearchParams(search).get('district')])

        setMediumId(new URLSearchParams(search).get('medium'));
        setAllClass(class_name_en[new URLSearchParams(search).get('medium')] ?? []); //next

        setClassId(new URLSearchParams(search).get('class'));
        setAllSubject(subject_en[new URLSearchParams(search).get('class')] ?? []);

    },[])

    const handleOnChangeArea = (e) => {
        if(e.target.value === "") {
            setAllUpozilla([])
            return false;
        }
        setSearchData({
            ...searchData,
            ['district_search']: districts_en.find(x => x.value == e.target.value).title ?? "",
            ['location_search']: "",
        });
        setDistrict(e.target.value)
        setAllUpozilla(upazillas_en[e.target.value])
    }

    const handleOnChangeUpozilla = (e) => {
        if(e.target.value === "") {
            setSearchData({
                ...searchData,
                ['location_search']: "",
            });
            return false;
        }
        setSearchData({
            ...searchData,
            ['location_search']: e.target.value ?? "",
        });
    }

    const onChangeMedium = (e) => {
        if(e.target.value === ""){
            setAllClass([])
            setAllSubject([])
            setSearchData({
                ...searchData,
                ['medium_search']: "",
                ['class_search']: "",
                ['subject_search']: "",
            });
            setMediumId('')
            setClassId('')
            return true
        }

        setSearchData({
            ...searchData,
            ['medium_search']: medium_en.find(mn => mn.value == e.target.value).title,
            ['class_search']: "",
            ['subject_search']: "",
        });

        setMediumId(e.target.value);
        setAllClass(class_name_en[e.target.value] ?? []); //next
    }

    const onChangeClass = (e) => {
        if(e.target.value === ""){
            setAllSubject([])
            setSearchData({
                ...searchData,
                ['class_search']: "",
                ['subject_search']: ""
            });
            return true
        }
        setSearchData({
            ...searchData,
            ['class_search']: class_name_en[medium_id].find(cn => cn.value == e.target.value).title ?? "",
            ['subject_search']: ""
        });
        setClassId(e.target.value);
        setAllSubject(subject_en[e.target.value] ?? []);

    }

    const handleOnChangeSubject = (e) => {
        if(e.target.value === "") {
            return false;
        }
        setSearchData({
            ...searchData,
            [e.target.name]: e.target.value,
        });
    }

    const handleSearch = (evt) => {
        evt.preventDefault()
        if(searchData.district_search == "") {
            //alert("Please Select Area");
            toast("Please Select District...");
            return false;
        }
        window.location.href=`/search_details?district=${district}&medium=${medium_id}&class=${class_id}&district_search=${searchData.district_search}&location_search=${searchData.location_search}&medium_search=${searchData.medium_search}&class_search=${searchData.class_search}&subject_search=${searchData.subject_search}`
    }
    return (

        <div className="FormWrap">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={handleSearch} method="POST">
                            <div className="FormInner">

                                <select className="form-select FormSelection form-select-lg"
                                        onChange={handleOnChangeArea}
                                        name="location_search"
                                        aria-label=".form-select-lg example">
                                    <option selected value="">Select District</option>
                                    {
                                        districts_en.map((d)=>{
                                            return (
                                                <option value={d.value} selected={district == d.value}>{d.title}</option>
                                            );
                                        })
                                    }
                                </select>

                                <select className="form-select FormSelection form-select-lg"
                                        onChange={handleOnChangeUpozilla}
                                        name="location_search"
                                        aria-label=".form-select-lg example">
                                    <option selected value="">Select Area</option>
                                    {
                                        allUpozilla.map((u)=>{
                                            return (
                                                <option value={u.title} selected={searchData.location_search == u.title}>{u.title}</option>
                                            );
                                        })
                                    }
                                </select>

                                <select className="form-select FormSelection form-select-lg"
                                        name="medium_search"
                                        onChange={onChangeMedium}
                                        aria-label=".form-select-lg example">
                                    <option selected value="">Medium</option>
                                    {
                                        medium_en.map((medium)=>{
                                            return (
                                                <option value={medium.value} selected={medium_id == medium.value}>{medium.title}</option>
                                            );
                                        })
                                    }
                                </select>

                                <select className="form-select FormSelection form-select-lg"
                                        name="class_search"
                                        onChange={onChangeClass}
                                        aria-label=".form-select-lg example">
                                    <option selected value="">Class</option>
                                    {
                                        allClass.map((classdata) => {
                                            return (
                                                <option value={classdata.value} selected={class_id == classdata.value}>{classdata.title}</option>
                                            );
                                        })
                                    }
                                </select>

                                <select className="form-select FormSelection form-select-lg"
                                        onChange={handleOnChangeSubject}
                                        name="subject_search"
                                        aria-label=".form-select-lg example">
                                    <option selected value="">Subject</option>
                                    {
                                        allSubject.map((subject)=>{
                                            return (
                                                <option value={subject.title} selected={searchData.subject_search == subject.title}>{subject.title}</option>
                                            );
                                        })
                                    }
                                </select>
                                <div className="TutorButton">
                                    <button className="TuitorSolidButtton TutorLightButton" type="submit"> Search Tutor
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReSearch;