import axios from "axios";
import React, { useState } from 'react';
import { Alert } from "react-bootstrap";
import { msisdnValidate } from "../../../utils/Validation";

const GuardianSignUp = ({onChangeAuth}) => {
    const [inputData,setInputData] = useState({
        msisdn : "",
        otp_code: "",
        g_name: "",
        g_email: "",
        g_msisdn: "",
        g_photo: "",
        g_login_by: ""
    })
    const [isOtpForm,setIsOtpForm] = useState(false);
    const [otpData,setOtpData] = useState('');
    const [resMsg,setResMsg] = useState("");
    const [isShowMsg,setIsShowMsg] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const onShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    };

    const handleOnChange = (e) => {
        setResMsg("");
        setIsShowMsg(false);

        setInputData({
            ...inputData,
            [e.target.name]: e.target.value
        });
    }

    const isGuardianExists = (e) => {
        e.preventDefault();

        if (!msisdnValidate(inputData.g_msisdn)) {
            setResMsg("Please enter valid mobile number.");
            setIsShowMsg(true);
            return false;
        }

        //check_exist

        const existData = JSON.stringify({"g_msisdn": inputData.g_msisdn});

        const existConfig = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/api/web/v1/is_guardian_exists`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: existData
        };

        axios(existConfig)
            .then(function (response) {
                if(response.data.success) {
                    setResMsg(response.data.message)
                    setIsShowMsg(true)
                    return true;
                } else {
                    onSubmitMsisdn();
                }
            })
            .catch(function (error) {
                setIsShowMsg(true)
                setResMsg(error.response.data.message)
                console.log(error);
            });

        //end_check_exist
    }

    const onSubmitMsisdn = () => {

        if (!msisdnValidate(inputData.g_msisdn)) {
            setResMsg("Please enter valid mobile number.");
            setIsShowMsg(true);
            return false;
        }

        const data = JSON.stringify({"msisdn": inputData.g_msisdn});

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp_send',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                if(response.data.success) {
                    setResMsg("")
                    setIsShowMsg(false)
                    setOtpData(response.data.data.secret_key)
                    setIsOtpForm(true)
                } else {
                    setIsShowMsg(true)
                    setResMsg(response.data.message)
                }
            })
            .catch(error=> {
                setResMsg("Invalid Mobile Number")
                console.log(error);
            });
    }

    const onSubmitVerifyOTP = (e) => {
        e.preventDefault()

        const data = JSON.stringify({
            "msisdn": inputData.g_msisdn,
            "secret_key": inputData.otp_code
        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp/verification',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                if(response.data.success){
                    onGuardianSignupSubmit()
                } else {
                    setIsShowMsg(true)
                    setResMsg(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onGuardianSignupSubmit = () => {

        const data = JSON.stringify({
            "g_name": inputData.g_name,
            "g_email": "",
            "g_msisdn": inputData.g_msisdn,
            "g_password": inputData.g_password,
            "g_photo": "",
            "g_login_by": 'manually'
        });

        if(!msisdnValidate(inputData.g_msisdn)){
            setResMsg("Enter Valid Phone Number.");
            setIsShowMsg(true);
            return false;
        }

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/guardian_signup',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                if(response.data.success){
                    localStorage.setItem('guardianLogin',JSON.stringify(response.data.data));
                    onChangeAuth(true)
                } else {
                    setResMsg(response.data.message);
                    setIsShowMsg(true);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onCloseMsg = () => {
        setIsShowMsg(!isShowMsg);
    }

    return (
        <>

            <>
                {isShowMsg ?
                    <Alert variant="danger" onClick={onCloseMsg} dismissible>
                        {resMsg}
                    </Alert>
                    :
                    <></>
                }
            </>

            {isOtpForm ?

                <>

                    <>
                        Verification sent to {inputData.g_msisdn}.
                    </>

                    <form onSubmit={onSubmitVerifyOTP}>
                        <div className="form-group">
                            <input type="number"
                                   name="otp_code"
                                   className="form-control form-control-lg"
                                   id="otp_code"
                                   onChange={handleOnChange}
                                   aria-describedby="otp_verify"
                                   placeholder="OTP"
                                   required/>
                        </div>
                        <center className="">
                            <button className="btn btn-primary form-control">
                                Verify OTP {" "}
                                <i className="fa fa-send"></i>
                            </button>
                        </center>
                    </form>

                </>

                :

                <form onSubmit={isGuardianExists}>

                    <div className="form-group">
                        <label htmlFor="g_name">Name</label>
                        <input type="text"
                               name="g_name"
                               className="form-control"
                               id="g_name"
                               onChange={handleOnChange}
                               placeholder="Name..."
                               required={true}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mobile_number">Mobile Number</label>
                        <input type="number"
                               name="g_msisdn"
                               className="form-control"
                               id="mobile_number"
                               onChange={handleOnChange}
                               placeholder="Mobile Number..."
                               required={true}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <div className="loginInput">
                            <input
                                type={isShowPassword ? "text" : "password"}
                                name="g_password"
                                onChange={handleOnChange}
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="Password"
                                required={true}
                            />
                            <i
                                onClick={onShowPassword}
                                className={
                                isShowPassword
                                    ? "fa fa-eye"
                                    : "fa fa-eye-slash"
                                }
                            ></i>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary form-control">Signup</button>

                </form>

            }

        </>
    );
};

export default GuardianSignUp;