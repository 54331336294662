import React, {useState} from 'react';
import {tutor_request} from "../../../route";
import ReactTimeAgo from "react-time-ago";
import TuitionsDetails from "../../menu/tuitions/TuitionsDetails";
import { Badge } from 'react-bootstrap';

const RequestIteam = ({tuition_data,index}) => {
    const [isShowDetails,setIsShowDetails] = useState(false);
    const [tuitionId,setTuitionId] = useState('');

    const handleShowTuitionDetails = (id) => {
        setIsShowDetails(true)
        setTuitionId(id)
    }

    const handleShowTuitionDetailsHide = (status) => {
        setIsShowDetails(status)
    }
    
    return (
        <>
        {isShowDetails ?

                <>

                    <TuitionsDetails tuitionId={tuitionId} handleShowTuitionDetailsHide={handleShowTuitionDetailsHide}/>

                </>

                :

                <div key={index} className='allGuardianRequest'>
                    <div className="card">
                        <div className="card-body">
                            <small className="text-muted">Tuition ID: {tuition_data.tuition_uniq_id}</small>
                            <p className="t_title">Need {tuition_data.medium} ({tuition_data.tutor_gender} ) Tutor for {tuition_data.class_name} student</p>
                            <div className="row py-2">
                                <div className="col-md-6">
                                    <b className="custom-text-color">Education Medium :</b> {tuition_data.medium}
                                </div>
                                <div className="col-md-2">
                                    <b className="custom-text-color">Class :</b> {tuition_data.class_name}
                                </div>
                                <div className="col-md-4">
                                    <b className="custom-text-color">Salary :</b> {tuition_data.salary_range} Tk/Month
                                </div>
                            </div>
                            <small>
                                <b>Subjects :</b> {tuition_data.subject.replace(/\[|\]|"/g," ")}
                                <br/>
                                <b>Days: </b>{tuition_data.days_per_weak} Days/Week
                            </small>
                            <div className="row pt-2">
                                <div className="col-md-8">
                                    <div>
                                        <>
                                            <i className="fa fa-map-marker text-danger"></i> &nbsp;
                                            {tuition_data.area},{tuition_data.district}
                                            <br/>
                                            <br/>
                                            <small>
                                                Published: <ReactTimeAgo date={tuition_data.created_at} locale="en-US"/>
                                            </small>
                                            <br/>
                                            {
                                                tuition_data.type === 'media' && <Badge bg="warning">Paid</Badge>
                                            }
                                           
                                        </>
                                    </div>
                                </div>
                            </div>
                            <span className="custom-view-button pull-right">
                                <button className="btn btn-sm btn-view" onClick={()=>{
                                    handleShowTuitionDetails(tuition_data.guardian_post_id)
                                    }}>
                                    View Details <i className="fa fa-arrow-right"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>

        }
        </>
    );
};

export default RequestIteam;