import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers, { getState, endpoint }) => {
            headers.set("Authorization", `Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog`);
            return headers;
    },
});


export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: async (args, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions);
        return result;
    },
    tagTypes: ['education_info','tutor_request','all_tutor_request','applied_joblist'],
    endpoints: (builder) => ({}),
});