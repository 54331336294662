import axios from "axios";
import React, { useState } from "react";
import { Alert, Badge } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AuthGuardian } from "../../utils/GuardianAuth";
import { authValidate } from "../../utils/TutionAuth";
import { msisdnValidate } from "../../utils/Validation";
// import JobListSuggestion from "../common/JobListSuggestion";
import { useCallback, useEffect } from "react";
import { districts_en } from "../../utils/location/District";
import { upazillas_en } from "../../utils/location/Upazilla";
import { medium_en } from "../../utils/medium_data/Medium";
import Layout from "../layout/Layout";
import { authMediaValidate } from "../../utils/MediumAuth";
import { media } from "../../route";
const SignUp = () => {
  const [tutor, setTutor] = useState({
    name: "",
    msisdn: "",
    password: "",
    t_gender: "",
    otp_code: "",
  });
  const [formType, setFormType] = useState("sign-up");
  // const [formType, setFormType] = useState("otp-verification");
  const [otpData, setOtpData] = useState("");
  const [resMsg, setResMsg] = useState("");
  const [isShowMsg, setIsShowMsg] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [timer, setTimer] = useState(0);
  const [select_area, setSelectArea] = useState([])
  const [district, setDistrict] = useState('')
  const [area, setArea] = useState([])
  const [medium, setMedium] = useState([])
  const [mediumId, setMediumId] = useState([])
  const [signupResponse, setSignupResponse] = useState();
  const [tutorUUID, setTutorUUID] = useState('');
  const [inputErrors,setErrors] = useState({});

  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
  

  useEffect(() => {
      let time;
      if( timer > 0 ){
          time = setTimeout(timeOutCallback, 1000);
      } 
      return ()=>{
        clearTimeout(time);
      }

  }, [ timer, timeOutCallback]);


  const onShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const history = useHistory();

  const onHandleChange = (e) => {
    setTutor({
      ...tutor,
      [e.target.name]: e.target.value,
    });
  };

  const checkTutorExists = (e) => {
    e.preventDefault();
    const data2 = JSON.stringify({ msisdn: tutor.msisdn });

    if (!msisdnValidate(tutor.msisdn)) {
      setResMsg("Enter Valid Phone Number.");
      setIsShowMsg(true);
      return false;
    }

    //check
    const config2 = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/is_tuitor_exists",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data2,
    };

    axios(config2)
      .then(function (response) {
        if (response.data.success) {
          setResMsg("You have already registered.Please Login.");
          setIsShowMsg(true);
          return false;
        } else {
          onSubmitMsisdn();
        }
      })
      .catch(function (error) {
        console.log(error);
        setResMsg(error.response.data.message);
        setIsShowMsg(true);
      });
    //endcheck
  };

  //submit msisdn
  const onSubmitMsisdn = (event) => {
    const data = JSON.stringify({ msisdn: tutor.msisdn });

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/otp_send",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success) {
          setFormType("otp-verification");
          setResMsg("");
          setOtpData(response.data.data.secret_key);
          setTimer(60*2);
          setIsShowMsg(false);
        } else {
          setIsShowMsg(true);
          setResMsg(response.data.message);
        }
      })
      .catch((error) => {
        setResMsg("Invalid Mobile Number");
        console.log(error);
      });
  };

  //submit and verify opt
  const onSubmitVerifyOTP = (event) => {
    event.preventDefault();

    const data = JSON.stringify({
      msisdn: tutor.msisdn,
      secret_key: tutor.otp_code,
    });

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/otp/verification",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          onSubmit();
          setResMsg(response.data.message)
        } else {
          setIsShowMsg(true);
          setResMsg('Invalid OTP');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //signup
  const onSubmit = (e) => {
    const data = JSON.stringify({
      name: tutor.name,
      msisdn: tutor.msisdn,
      password: tutor.password,
      gender:tutor.t_gender,
      type: "tutor",
    });

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/tutors_signup",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setTutorUUID(response.data.data.uuid)
          setSignupResponse(JSON.stringify(response.data.data))
          setFormType("preprofile");
        } else {
          toast(response.data.message);
          setResMsg(response.data.message);
          setIsShowMsg(true);
        }
      })
      .catch(function (error) {
        toast(error);
        setResMsg(error);
        setIsShowMsg(true);
      });
  };

  const onReSendOtp = (e) => {
    e.preventDefault();
    const data = JSON.stringify({ msisdn: tutor.msisdn });

    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/api/web/v1/otp_resend",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setIsShowMsg(true);
        setResMsg("OTP Resent");
        setTimer(60*2)
      })
      .catch((error) => {
        setIsShowMsg(true);
        setResMsg("Invalid Mobile Number");
        console.log(error);
      });
  };

  if (authValidate() || AuthGuardian()) {
    history.push("/tutor/dashboard");
  }

  const onCloseMsg = () => {
    setIsShowMsg(!isShowMsg);
  };

  
  //update tutor tuition prefrrence area

  const onChangeDistrict = (e) => {
    e.preventDefault()
    setSelectArea([])
    setDistrict(districts_en.find((district) => district.value == e.target.value)?.title)
    setSelectArea(upazillas_en[e.target.value])
  }


  const onChangeArea = (event) => {
    if(event.target.checked) {
        if (area.includes(event.target.value)) {
            setArea(area.filter(sel => sel !== event.target.value));
        } else {
            setArea([...area, event.target.value]);
        }
    } else {
        setArea(area.filter(sel => sel !== event.target.value));
    }
  }

  const onChangeMedium = (e) => {
      let {name,value,checked} = e.target;

      //medium_store
      const mValue = medium_en.find(x => x.value == e.target.value).title;
      if(checked) {
          if (medium.includes(value)) {
              setMedium(medium.filter(sel => sel != mValue));
          } else {
              setMedium([...medium, mValue]);
          }
      } else {
          setMedium(medium.filter(sel => sel != mValue));
      }
      getSelectMediumID(checked,value)
  }


  const getSelectMediumID = (checked,id) => {
      let tmp = mediumId;
      if(checked) {
          tmp.push(id)
      } else {
          const index = tmp.indexOf(id);
          if (index !== -1) {
              tmp.splice(index, 1);
          }
      }
      setMediumId(tmp)
  }

  const validate = () => {
      let errors = {};
      let hasError = false;


      if (medium.length <= 0) {
          hasError = true;
          errors["preferredMediumOFEduction"] = "Preferred Medium of Eduction is a required field!!!"
      }

      if (area.length <= 0) {
          hasError = true;
          errors["preferredAreaToTeach"] = "Preferred Area To Teach is a required field!!!"
      }

      setErrors(errors);

      return hasError;
  }



  const handlePrefrredSubmit = (e) => {

      e.preventDefault();
      
      setErrors({})
      if(validate()){
          return false;
      }

      const data = JSON.stringify({
          "uuid": tutorUUID,
          "t_salary": '',
          "t_is_Negotiable": 0,
          "t_days_per_week": '',
          "t_status": "1",
          "t_preferred_tuition": '',
          "t_place_of_learning": '',
          "t_extra_facilities": '',
          "t_preferred_class": '',
          "t_preferred_class_id": '',
          "t_preferred_subjects": '',
          "t_preferred_medium_of_Eduction": JSON.stringify(medium),
          "t_preferred_medium_of_Eduction_id": JSON.stringify(mediumId),
          "t_preferred_district_to_teach": district,
          "t_preferred_area_to_teach": JSON.stringify(area)
      });

      const config = {
          method: 'post',
          url: process.env.REACT_APP_API_URL+'/api/web/v1/update_tution_info',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
          },
          data: data
      };

      axios(config)
          .then(response=> {
              localStorage.clear();
              localStorage.setItem("loginData", signupResponse);
              window.location.href=`/tutor/dashboard`;
              toast(response.data.message);
          })
          .catch(function (error) {
              console.log(error);
          });
  }


  if (authMediaValidate()) {
      history.push(`${media}/dashboard`);
  }


  if (formType == "otp-verification") {
    return (
      <Layout>
        <div className="guardianLoginWrap">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="formWrap">
                  <div className="formInner">
                    <div className="formCenter">
                      <h3 className="text-center">OTP Verification</h3>
                     
                      <form onSubmit={onSubmitVerifyOTP}>
                        <fieldset>
                          <p className="mb-2">
                            Verification OTP sent to {tutor.msisdn}.
                          </p>

                          <>
                            {isShowMsg ? (
                              <Alert
                                variant="info"
                                onClick={onCloseMsg}
                                dismissible
                              >
                                {resMsg}
                              </Alert>
                            ) : (
                              <></>
                            )}
                          </>

                          <input
                            type="number"
                            className="form-control form-control-lg"
                            name="otp_code"
                            onChange={onHandleChange}
                            placeholder="Verification OTP..."
                            required
                          />
                          <center className="py-4">
                            <button className="btn btn-md otp-button">
                              Verify OTP
                              <i className="fa fa-send"></i>
                            </button>
                          </center>
                          <div className="d-flex justify-content-between">
                          Didn't receive your OTP?
                          {timer === 0 ? (
                              <Link onClick={onReSendOtp}>Resend OTP</Link>
                            ) : (
                              <b>
                                {Math.floor(timer / 60)}:
                                {timer - Math.floor(timer / 60) * 60}
                              </b>
                            )}
                        </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  } else if(formType === 'preprofile'){
    return(
      <Layout>
          <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="tuition-info-edit mb-4">
                        <div className="card">
                            <div className="card-header">
                                Tuition Prefrred Info<br/>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handlePrefrredSubmit}>
                                    <div className="form-group row">
                                        <label className="col-form-label col-sm-4 pt-0">
                                            Preferred District to Teach:
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="col-sm-8 row">

                                            <div className="form-group col-12 col-lg-8">
                                                <select
                                                        name="t_district"
                                                        id="inputState"
                                                        onChange={onChangeDistrict}
                                                        className="form-control">
                                                    <option selected value="">Select Districts...</option>
                                                    {
                                                        districts_en.map((district, index)=>{
                                                            return (
                                                                <option value={district.value} key={index}>{district.title}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-form-label col-sm-4 pt-0">
                                            Preferred Areas to Teach:
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="col-sm-8 row">
                                            {
                                                select_area.map((area, index)=>{
                                                    return (
                                                        <div key={index} className="form-check col-12 col-lg-6">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                onChange={onChangeArea}
                                                                id={area.value}
                                                                value={area.title}
                                                            />
                                                            <label className="form-check-label" htmlFor={area.value}>
                                                                {area.title}
                                                            </label>
                                                        </div>
                                                    );
                                                })
                                            }
                                            <span className="text-danger">
                                              {inputErrors.preferredAreaToTeach}
                                            </span>
                                        </div>
                                    </div>
                                      <div className="form-group row">
                                          <label className="col-form-label col-sm-4 pt-0">
                                              Preferred Medium of Education:
                                              <span className="text-danger">*</span>
                                          </label>
                                          <div className="col-sm-8 row">
                                              {

                                                  medium_en.map((data,index)=> {
                                                      return (
                                                          <div key={index} className="form-check">
                                                              <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  onChange={onChangeMedium}
                                                                  id={data.value}
                                                                  value={data.value}
                                                                  checked={medium.indexOf(data.title) != -1}
                                                              />
                                                              <label className="form-check-label" htmlFor={data.value}>
                                                                  {data.title}
                                                              </label>
                                                          </div>
                                                      );
                                                  })
                                              }
                                              <span className="text-danger">
                                                  {inputErrors.preferredMediumOFEduction}
                                              </span>
                                          </div>
                                    </div>
                                    <center>
                                        <button type='submit' className="btn btn-md btn-success">Update Tuition Info <i className="fa fa-send"></i></button>
                                    </center>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </Layout>
    )
  }else {
    return (
      <>
        <Layout>
          <div className="tutorLoginWrap">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />

            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="formWrap">
                    <div className="formInner">
                      <div className="formCenter">
                        <h3 className="text-center">Tutor Sign Up</h3>

                        <>
                          {isShowMsg ? (
                            <Alert
                              variant="info"
                              onClick={onCloseMsg}
                              dismissible
                            >
                              {resMsg}
                            </Alert>
                          ) : (
                            <></>
                          )}
                        </>

                        <form onSubmit={checkTutorExists}>
                          <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                              type="text"
                              name="name"
                              onChange={onHandleChange}
                              className="form-control"
                              id="name"
                              placeholder="Name..."
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="mobile_number">Mobile Number</label>
                            <input
                              type="number"
                              name="msisdn"
                              onChange={onHandleChange}
                              className="form-control"
                              id="mobile_number"
                              placeholder="Mobile Number..."
                              required
                            />
                          </div>
                          <div class="form-group">
                              <label for="exampleInputEmail1">Select Gender:</label>
                              <select class="form-select form-select-sm" name="t_gender" aria-label=".form-select-sm example" onChange={onHandleChange}>
                                <option value="">Select gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                              <div class="text-danger"></div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Password
                            </label>
                            <div className="loginInput">
                              <input
                                type={isShowPassword ? "text" : "password"}
                                name="password"
                                onChange={onHandleChange}
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="Password"
                                required
                              />
                              <i
                                onClick={onShowPassword}
                                className={
                                  isShowPassword
                                    ? "fa fa-eye"
                                    : "fa fa-eye-slash"
                                }
                              ></i>
                            </div>
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-primary form-control"
                            >
                              Signup
                            </button>
                          </div>
                        </form>
                        <div className="text-center">
                          Already have an account?
                          <Link
                            to="/tutor/login"
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                          >
                            &nbsp; Sign In{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <JobListSuggestion/> */}

              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
};

export default SignUp;
