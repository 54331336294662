import axios from "axios";
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { tutor_details } from "../../../route";
import { titleCase } from "../../../utils/helper";

const TutoritemMini = ({item,key}) => {
    const { register, handleSubmit, watch, formState: { errors },setValue } = useForm();
    const [isShowBox,setIsShowBox] = useState(false);

    const handleClose = () => {
        setIsShowBox(!isShowBox);
    }

    const onNotificationSubmit = (e) => {
        const data = JSON.stringify({
            "tutors_id": e.tutors_id,
            "name": e.name,
            "email": e.email,
            "mobile": e.mobile,
            "details": e.details
        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/insert_notification',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Tutors4mh5ln64ron5t26kpvm3toBlog'
            },
            data: data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                setIsShowBox(false);
                //toast(response.data.message);
                setValue('tutors_id','');
                setValue('name','');
                setValue('email','');
                setValue('mobile','');
                setValue('details','');
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="FeaturedContactWrap pt-3">
            <a href={`${tutor_details}/${item.uuid}`} style={{textDecoration:"none"}}>
                <div className="d-flex">
                    <img src={item.t_photo} className="img-fluid" alt={item.t_name}/>
                    <div className="Habibtext">
                        <h3 className="ProfileName">{titleCase(item.t_name)}</h3>
                        <span className="DesignationText">{item.t_salary}tk/month</span>
                    </div>
                </div>
            </a>
            {/*  model  */}
            <Modal className="modal" show={isShowBox} onHide={handleClose} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Send Message</Modal.Title>
                </Modal.Header>

                <form className="Inputform" onSubmit={handleSubmit(onNotificationSubmit)}>
                    <Modal.Body>
                        {/*    Tutor Notification*/}
                        <input type="hidden" name="tutors_id"
                               {...register("tutors_id", { required: true })}
                               value={item.id}/>
                        <input type="text"
                               name="name"
                               {...register("name", { required: true })}
                               className="input_field"
                               placeholder="Name"/>
                        <input type="text"
                               name="email"
                               {...register("email", { required: true })}
                               className="input_field" placeholder="Email"/>
                        <input type="number"
                               name="mobile"
                               {...register("mobile", { required: true })}
                               className="input_field" placeholder="Number"/>
                        <textarea name="details"
                                  {...register("details", { required: true })}
                                  className="input_field text_Details"
                                  placeholder="Enter Details"></textarea><br/>

                        {/*    Tutor Notification*/}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger" onClick={handleClose}>
                            Close
                        </button>
                        <button className="btn btn-success">
                            Send Message
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/*  end_model  */}

        </div>
    );
};

export default TutoritemMini;