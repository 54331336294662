export const home =  '/';
export const faq =  '/faq';
export const help =  '/help';
export const search_details =  '/search-details';
export const tutor_details =  '/tutor-details';
export const image_upload =  '/image-upload';
export const tutors =  '/tutors';
export const fe_tutors =  '/tutors/female';
export const tutor_request =  '/tutor-request';
export const tuition =  '/tutor-wanted';
export const guardian =  '/guardian';
export const media =  '/media';
export const choose =  '/choose';
export const tutor =  '/tutor';