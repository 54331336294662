export const class_name_en ={
    "1": [
        {
            "value": 11,
            "title": "Class 1"
        },
        {
            "value": 12,
            "title": "Class 2"
        },
        {
            "value": 13,
            "title": "Class 3"
        },
        {
            "value": 14,
            "title": "Class 4"
        },
        {
            "value": 15,
            "title": "Class 5"
        },
        {
            "value": 16,
            "title": "Class 6"
        },
        {
            "value": 17,
            "title": "Class 7"
        },
        {
            "value": 18,
            "title": "Class 8"
        },
        {
            "value": 19,
            "title": "Class 9 & 10"
        },
        {
            "value": 191,
            "title": "SSC"
        },
        {
            "value": 192,
            "title": "HSC"
        }
    ],
    "2": [
        {
            "value": 20,
            "title": "Nursery"
        },
        {
            "value": 21,
            "title": "Class 1"
        },
        {
            "value": 22,
            "title": "Class 2"
        },
        {
            "value": 23,
            "title": "Class 3"
        },
        {
            "value": 24,
            "title": "Class 4"
        },
        {
            "value": 25,
            "title": "Class 5"
        },
        {
            "value": 26,
            "title": "Class 6"
        },
        {
            "value": 27,
            "title": "Class 7"
        },
        {
            "value": 28,
            "title": "Class 8"
        },
        {
            "value": 29,
            "title": "Class 9"
        },
        {
            "value": 291,
            "title": "Class 10"
        },
        {
            "value": 292,
            "title": "Class 11"
        },
        {
            "value": 293,
            "title": "Class 12"
        }

    ],
    "3": [
        {
            "value": 31,
            "title": "Class 1"
        },
        {
            "value": 32,
            "title": "Class 2"
        },
        {
            "value": 33,
            "title": "Class 3"
        },
        {
            "value": 34,
            "title": "Class 4"
        },
        {
            "value": 35,
            "title": "Class 5"
        },
        {
            "value": 36,
            "title": "Class 6"
        },
        {
            "value": 37,
            "title": "Class 7"
        },
        {
            "value": 38,
            "title": "Class 8"
        },
        {
            "value": 39,
            "title": "Class 9 & 10"
        },
        {
            "value": 391,
            "title": "SSC"
        },
        {
            "value": 392,
            "title": "HSC"
        },
    ]
}
